import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import Script from 'react-load-script';
import { withFirestore, firestoreConnect } from "react-redux-firebase";
import { getUsersForAdminPage } from './adminActions'
import AdminUsersList from './AdminUsersList'
import { Segment, Item, Icon, List, Label, Button } from "semantic-ui-react";
//UI Components
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import { Loader } from "semantic-ui-react";
import _ from 'lodash'


const styles = theme => ({
  searchbox: {
    marginTop: "7%",
    marginLeft: "auto",
    marginRight: "auto",
    width: 900,
    zIndex: 1,
    borderTop: '5px solid #4ca7ab',
    padding: '0% 5% 3% 3%',
    display: 'inlineBlock',
    borderRadius: '7px',
    overflow: 'visible',
    [theme.breakpoints.up('xs')]: {
      height: 180
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 120,
      width: '95%',
      marginLeft: 'auto',
      marginRight: 'auto'
    },
  },
  badge: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '80px',
    height: '200%',
    width: '100vw',
    borderRadius: '0px',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  filterDash: {
    maxWidth: '900px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  filter1: {
    marginTop: 40,
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  filter2: {
    marginTop: 40,
    [theme.breakpoints.down('xs')]: {
      marginTop: '120px'
    }
  },
  divider: {
    width: 30,
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
})

const mapState = (state, ownProps) => {
  return {
    user: state.firebase.auth,
    //users: state.firestore.ordered.users,
    users: state.usersInAdminPage,
    loading: state.async.loading,
  };
};

const actions = {
  getUsersForAdminPage
};


class AdminPage extends Component {


  state = {
    ok: false
  };

  async componentDidMount() {
    const { firebase, user, firestore, match, tutorProfile } = this.props;

    this.props.getUsersForAdminPage()

    if (user.uid) {
      await firestore.collection(`users`).doc(`${user.uid}`).get().then((user) => {
        console.log("USER DATA", user.data().admin)
        if (user.data().admin) {
          this.setState({ ok: false })
        } else {
          this.setState({ ok: true })
        }
      })
    }

  }

  handleContextRef = contextRef => this.setState({ contextRef });

  render() {
    const { loading, users, classes } = this.props;
    let j = 0;

    _.forEach(users, (user) => {
      if(user.hasProfile){
        j++
      }
    })
    

    if (this.state.ok) {
      return <Redirect to='/' />
    } else {
      return (
        <div>
          <Avatar className={classes.badge} src="/assets/BadgeBanner.png" alt="banner"  >
          </Avatar>
          <Item.Header>
            Hello
          </Item.Header>
          <Script
            url="https://maps.googleapis.com/maps/api/js?key=AIzaSyC_B3o6e4mHhWjdZij2lYWPqK_Tw5O_jC8&libraries=places&language=en"
            onLoad={this.handleScriptLoad}
          />
          <div  >
            <Grid className={classes.filterDash} container>
              <Grid className={classes.filter1} item xs={12} md={4}>
                <h1>filters</h1>
              </Grid>
              <Grid className={classes.filter2} item xs={12} md={8} >
                <h1>
                  total users: {users.length}
                </h1>
                <h1>
                  total tutors: {j}
                </h1>
                <AdminUsersList users={users} />
              </Grid>
            </Grid>
            <Loader active={loading} />
          </div>
        </div>
      );
    }
  }
}

// export default withStyles(styles)(withFirestore(connect(mapState,actions)((AdminPage))));

export default withStyles(styles)(connect(mapState, actions)(firestoreConnect([{ collection: 'users' }])(AdminPage)))