import React, { Component } from "react";
import { connect } from "react-redux";
import { uploadProfileVideo } from "../../user/userAction";
import { toastr } from "react-redux-toastr";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import { withStyles } from '@material-ui/core/styles';
import cuid from "cuid";
import Dropzone from "react-dropzone";
import { Icon, Button } from "semantic-ui-react";

const styles = theme => ({
    registerStyle: {
        marginTop: 20,
        overflow: 'visible',
        width: '95%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: -60,
        boxShadow: 'none',
        [theme.breakpoints.down('xs')]: {
          width: '100%',
          marginTop: 0,
          borderRadius: 0,
          boxShadow: 'none'
        }
    },
    registrationStyle: {
        marginTop: 160,
        fontSize: '2.5rem',
        maxWidth: '1180px',
        marginRight: 'auto',
        marginLeft: 'auto',
        fontWeight: 900,
        color: '#585c60',
        letterSpacing: '2px',
        [theme.breakpoints.down('xs')]: {
            width: '90%',
            fontSize: '2rem',
            marginLeft: 0
        }
    },
    paragraphStyle: {
        margin: 0,
        padding: 0,
        fontWeight: 'bold',

    },
    continuebtn: {
        color: '#fff',
        fontWeight: 600,
        padding: '10px 42px',
        fontSize: '1.1rem',
        letterSpacing: '1.7px',
        float: 'right',
        marginTop: '60px',
        backgroundColor: '#669fff',
        borderRadius: '5px',
        cursor: 'pointer',
        border: '1px solid #4ca7ab',
        marginBottom: 120,
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginTop: 20,
            marginBottom: 0
        }
    },
    backbtn: {
        fontWeight: 600,
        padding: '10px 42px',
        fontSize: '1.1rem',
        letterSpacing: '1.7px',
        marginTop: '60px',
        borderRadius: '5px',
        cursor: 'pointer',
        marginBottom: 120,
        float: 'left',
        marginTop: '60px',
        color: '#f68484',
        backgroundColor: '#fff',
        border: '1px solid #f68484',
        marginRight: 20,
        '&:hover': {
            color: '#fff',
            backgroundColor: '#f68484',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginTop: 20,
            marginBottom: 20

        }


    },
    cardStyle: {
        width: '100%',
        height: '400px',
        marginTop: 20,
        padding: 'auto',
        boxShadow: 'none',
        border: '1px solid #e1e1e6',
        [theme.breakpoints.down('xs')]: {
            height: '300px',
        
        }
    },
    uploadBtn: {
        fontWeight: 600,
        fontSize: '1.5rem',
        color: '#4ca7ab',
        backgroundColor: 'white',
        padding: '8px 35px 8px 35px',
        borderRadius: '5px',
        cursor: 'pointer',
        border: '1px solid #4ca7ab',
        marginTop: 180,
        maxWidth: '250px',
        lineHeight: '1.7rem',
        marginRight: 'auto',
        marginLeft: 'auto',
        '&:hover': {
            color: '#fff',
            backgroundColor: '#4ca7ab',
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: 60
        }
    },
    imageStyle: {
        border: 'none',
        textAlign: 'center',
        verticalAlign: 'middle'
    },
    btnAlign: {
        textAlign: 'center',
        marginTop: '30%'
    },
    descStyle: {
        marginLeft: '40px',
        marginTop: 130,
        [theme.breakpoints.down('xs')]: {
            marginLeft: 0,
            marginTop: 40,
            paddingTop: 20,
            borderTop: '1px solid #e1e1e6'
        }
    },
    howToDesc: {
        fontSize: '1rem',
        fontWeight: 100,
        marginTop: 10
    },
    btnStyle: {
        textAlign: 'center',
        marginTop: 20
    },
    tipStyle: {
        margin: 0,
        padding: 0,
        letterSpacing: '2px',
        fontFamily: 'proxima-nova, sans-serif ',
        color: '#585c60',
        fontWeight: 600,
        marginBottom: 10,
        fontSize: '2rem'
    },
    tipStyle2: {
        margin: 0,
        padding: 0,
        fontSize: '1.2rem',
        marginTop: 40
    },
    bulbStyle: {
        width: '14px',
        marginLeft: 5,
        marginTop: -2,
        verticalAlign: 'middle',

    }
})

const required = value => value ? undefined : 'Required'



const query = ({ auth }) => {
    return [{
        collection: 'users',
        doc: auth.uid,
        subcollections: [{ collection: 'videos' }],
        storeAs: 'videos'
    }]
}

const actions = {
    uploadProfileVideo,
};

const mapState = (state) => ({
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    videos: state.firestore.ordered.videos,
    loading: state.async.loading
})

class TutorProfileVideoUpload extends Component {
    state = {
        files: [],
        fileName: "",
        cropResult: null,
        video: {}
    };

    uploadVideo = async () => {
        try {
            await this.props.uploadProfileVideo(
                this.state.video,
                this.state.fileName
            );
            toastr.success("Success!", "Photo has been uploaded");
        } catch (error) {
            toastr.error("Oops", error.message);
        }
    };

    onDrop = files => {
        var file = new File([files[0]], files[0].name, {
            type: files[0].type,
        });
        console.log(files[0])
        var fileType = files[0].name.split('.').pop().toLowerCase();;

        this.setState({
            video: file,
            files,
            fileName: `${this.props.auth.uid}.${fileType}`
        });

    };


    cancelUpload = () => {
        this.setState({
            files: [],
            image: {}
        });
    };


    // fileUploadHandler = async () => {
    //     try {
    //         await this.props.uploadProfileVideo(
    //             this.state.video,
    //             this.state.fileName
    //         );
    //         this.cancelUpload();
    //         toastr.success("Success!", "Photo has been uploaded");
    //     } catch (error) {
    //         toastr.error("Oops", error.message);
    //     }
    // };

    fileSelectedHandler = event => {
        const state = this.state
        state[event.target.name] = event.target.value;
        this.setState(state);
    }

    render() {
        const {
            classes,
            nextStep,
            prevStep,
            loading,
        } = this.props;
        const { scriptLoaded } = this.state;
        return (
            <div className={classes.registerStyle}>
                <Grid container>

                    <Grid item xs={12} md={12}>
                        <Card className={classes.cardStyle}>
                            <form >
                                <Dropzone
                                    accept='video/*'
                                    className={classes.imageStyle}
                                    onDrop={this.onDrop}
                                    multiple={false}
                                >
                                    <div className={classes.uploadBtn}>Upload 1 Minute Intro Video</div >
                                </Dropzone>
                                {this.state.files.length > 0 ? <div
                                    style={{
                                        fontSize: '1rem',
                                        marginTop: 40,
                                        textAlign: 'center'
                                    }}>
                                    <p>Uploading {this.state.files[0].name} files...</p>
                                    <p>Click Check box to upload your video</p>
                                </div> : null}
                                {/* <button onClick={this.fileUploadHandler}>Upload</button> */}
                            </form>
                            {this.state.files[0] && (
                                <div className={classes.btnStyle}>
                                    <Button.Group>
                                        <Button
                                            loading={loading}
                                            onClick={this.uploadVideo}
                                            style={{
                                                width: '100px',
                                                backgroundColor: '#f68484',
                                                color: 'white',

                                            }}
                                            icon='check' />
                                    </Button.Group>
                                </div>
                            )}
                        </Card>

                    </Grid>
                    
                </Grid>

            </div>
        );
    }
}

export default withStyles(styles)(connect(mapState, actions)(TutorProfileVideoUpload));
