import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import { composeValidators, combineValidators, isRequired, hasLengthGreaterThan} from 'revalidate'
import firebase from '../../../app/config/firebase'
import { Segment, Form, Button } from 'semantic-ui-react'
import cuid from 'cuid'
import { createStudent, updateStudent } from '../StudentActions'
import TextInput from '../../../app/common/form/TextInput';
import TextArea from '../../../app/common/form/TextArea';
import DateInput from '../../../app/common/form/DateInput';

const mapState = (state, ownProps) => {
    const studentId = ownProps.match.params.id;

    let student = {

    }

    if (studentId && state.students.length > 0) {
        student = state.students.filter(student => student.id === studentId)[0];
    }

    return {
        initialValues: student
    }
}

const actions = {
    createStudent,
    updateStudent
}

const validate = combineValidators({
    title: isRequired({message: 'The title is required'}),
    category: isRequired({message: 'The category is required'}),
    description: composeValidators(
        isRequired({message: 'Please enter a description'}),
        hasLengthGreaterThan(10)({message: 'Description needs to be at least 10 characers'})
    )(),
    city: isRequired('city'),
    venue: isRequired('venue'),
    date: isRequired('date')
})

class StudentForm extends Component {




    // handleCreateEvent = (newEvent) => {
    //     newEvent.id = cuid();
    //     newEvent.hostPhotoURL = '/assets/user.png';
    //     this.setState(({ events }) => ({
    //         events: [...events, newEvent],
    //         isOpen: false
    //     }))

    //     // firebase.firestore().collection('studentPostings').doc(`${Date.now()}`).set({...newEvent});
    // }

    onFormSubmit = (values) => {

        if (this.props.initialValues.id) {
            this.props.updateStudent(values);
            this.props.history.push(`/students/${this.props.initialValues.id}`)
        } else {
            const newStudent = {
                ...values,
                id: cuid(),
                hostPhotoURL: '/assets/user.png',
                hostedBy: 'Bob'

            }
            this.props.createStudent(newStudent);
            this.props.history.push(`/students/${newStudent.id}`)
        }

    }

    handleInputChange = ({ target: { name, value } }) => {
        this.setState({
            [name]: value
        })
    }

    render() {
        const { history, initialValues, invalid, submitting, pristine } = this.props;
        return (
            <Segment style={{ marginTop: 100 }}>
                <Form onSubmit={this.props.handleSubmit(this.onFormSubmit)} autoComplete='off'>
                    <Field name='title' component={TextInput} placeholder='title' />
                    <Field name='category' component={TextInput} placeholder='category' />
                    <Field name='description' component={TextArea} rows={5} placeholder='description' />
                    <Field name='city' component={TextInput} placeholder='city' />
                    <Field name='venue' component={TextInput} placeholder='venue' />
                    <Field name='date' component={DateInput} placeholder='date' />
                    <Button disabled={invalid || submitting || pristine} positive type="submit">
                        SUBMIT
                    </Button>
                    <Button type="button" onClick={initialValues.id ? () => history.push(`/students/${initialValues.id}`)
                        : () => history.push('/students')} > CANCEL</Button>


                </Form>
            </Segment>
        )
    }
}


export default connect(mapState, actions)(reduxForm({ form: 'studentForm',validate })(StudentForm));