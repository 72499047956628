import firebase from "../config/firebase"
import { toastr } from "react-redux-toastr";
import {
    asyncActionStart,
    asyncActionFinish,
    asyncActionError
} from "../../features/async/asyncActions";
import { GET_CATEGORY_REQUEST, GET_CATEGORY_SUCCESS, GET_CATEGORY_ERROR } from './categoryContants'
import moment from "moment";
import compareAsc from 'date-fns/compare_asc';
import cuid from "cuid";


// export const getCategories = (payload) => {

//     return async (dispatch, getState, { getFirebase, getFirestore }) => {
//         dispatch(asyncActionStart());
//         dispatch({ type: POST_SEARCH_REQUEST });
//         const { langList, subject, location } = payload;
//         console.log("SearchByRequest", payload);
//         const firestore = getFirestore();

//         try {
//             // query languages
//             let result = [];
//             let docIds = [];
//             let dbPromises = [];
//             let docIds2 = [];

//             //reference to the documents that maches the distance query
//             var docRef = firestore
//                 .collection("tutorProfiles")
//                 .where("location", ">=", smallGeoPt)
//                 .where("location", "<=", bigGeoPt)

//             //Creating the Array of Documents Ids that matches the distance query
//             await docRef.get().then(snapshot => {
//                 snapshot.docs.forEach(doc => {
//                     if (!docIds2.includes(doc.id)) {
//                         docIds2.push(doc.id);
//                     }
//                 })
//             })

//             //reference to the documents that matches the subject and language query
//             _.forEach(langList, (lang) => {
//                 let profileRef = firestore
//                     .collection("tutorProfiles")
//                     .where(`subjectList`, "array-contains", `${subject}`)
//                     .where(`languages.${lang}`, "==", true);

//                 //
//                 dbPromises.push(
//                     profileRef.get().then(snapshot => {
//                         snapshot.docs.forEach(async (doc) => {
//                             if (docIds2.includes(doc.id)) {
//                                 if (!docIds.includes(doc.id)) {
//                                     await docIds.push(doc.id)
//                                     let merged = { ...doc.data(), searchedSubject: `${subject}` };
//                                     await result.push(merged)
//                                 }
//                             }
//                         })
//                     })
//                 )
//             })


//             console.log("THose survived the filter", docIds)
//             Promise.all(dbPromises)
//                 .then(() => {
//                     dispatch({
//                         type: POST_SEARCH_SUCCESS,
//                         result,
//                         subject
//                     });
//                 })
//                 .catch(error => {
//                     const { message } = error;
//                     dispatch({ type: POST_SEARCH_ERROR, message });
//                 });
//             dispatch(asyncActionFinish());

//         } catch (error) {
//             console.log(error);
//             dispatch(asyncActionError());
//             toastr.error("Oops", "Something went wrong");
//             this.props.history.goBack();
//         }
//     };
// };


export const getCategoryRequest = () => {
    return async (dispatch, { getFirebase, getFirestore }) => {
        try {
            dispatch({ type: GET_CATEGORY_REQUEST });
            const firestore = firebase.firestore();
            let result;

            await firestore.collection('profiles').doc('subjects').get().then(snapshot => {
                result= snapshot.data()
            })
            // console.log("In CategoryAction result:", result);

            
            dispatch({ 
                type: GET_CATEGORY_SUCCESS,
                result
             });
        } catch (error) {
            dispatch({type: GET_CATEGORY_ERROR});
            console.log(error)
        }
    }
}