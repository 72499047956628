import React, { Component } from 'react'
import firebase from '../../app/config/firebase'
import _ from 'lodash'
import { courseOptions } from "../../app/common/courseOptions";
import { connect } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import CourseList from '../TestPrep/CourseList';
import StudentPassBanner from './StudentPassBanner/StudentPassBanner';
import TutorVideoDemo from './StudentPassBanner/TutorVideoDemo';

const styles = theme => ({
    courseStyle: {
        marginTop: 120
    },
    secondhead: {
        zIndex: -1,
        fontSize: '3rem',
        fontWeight: 600,
        color: '#585c60',
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '1180px',
        marginBottom: 40,
        fontFamily: 'proxima-nova, sans-serif ',
        [theme.breakpoints.down('xs')]: {
            width: '80%',
            fontSize: '2em',

        },
    },
    midtermDescription: {
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '1180px',
        marginTop: -30,
        color: '#585c60',
        [theme.breakpoints.down('xs')]: {
            width: '80%',
            marginRight: 'auto',
            marginLeft: 'auto',
            fontSize: '1.2rem'
        }
    },
    seneca: {
        width: 400,
        height: 'auto',
    
        marginLeft: 'auto',
        marginRight: 'auto',
        borderRadius: '0px',
        [theme.breakpoints.down('xs')]: {
          width: 300,
          float: 'center'
        },
      },
      senecabox: {
        display: 'block',
        marginTop: '100px',
        paddingTop: '40px',
        backgroundColor: 'white',
        height: 140,
        width: '100vw',
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.down('xs')]: {
          height: 80,
          float: 'center',
          marginTop: '150px',
    
        },
      },
    

});


const mapState = (state) => ({
    auth: state.firebase.auth,
    admin: state.firebase.profile.admin,
    profile: state.firebase.profile,
})

class Course extends Component {
    render() {
        const { classes, auth, admin, profile } = this.props

        return (
            <div className={classes.courseStyle}>
                <h2 className={classes.secondhead} id="midtermPrep">Test Preps- Part A</h2>
                <div className={classes.midtermDescription}>
                    <p
                    >
                        100% Follwing the Ontario Curriculum and designed to teach students from concepts to competition level problems. Part A includes the first four chapters of the course.
          </p>
                </div>
                <CourseList
                    courseOptions={courseOptions}
                    auth={auth}
                    admin={admin}
                    profile={profile}
                    firebase={firebase}
                />
                <StudentPassBanner />
                <TutorVideoDemo />

                <div className={classes.senecabox}>
                    <Avatar className={classes.seneca} src="/assets/SenecaHelix.png" alt="helix"  >
                    </Avatar>
                </div>

            </div >
        );
    }
};

export default withStyles(styles)(connect(mapState, null)(Course));
