import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import home from './assets/aboutUs.png';


const styles = theme => ({
    root: {
        display: 'flex',
        maxWidth: 1180,
        marginRight: 'auto',
        marginLeft: 'auto',
        flexDirection: 'column',
        [theme.breakpoints.down('xs')]: {
            marginLeft: 20,
            marginRight: 20
        }
    },
    aboutUsHeader: {
        textAlign: 'left',
        paddingTop: 200,
        fontSize: '3rem',
        color: '#4ca7ab',
        paddingBottom: 10,
        [theme.breakpoints.down('xs')]: {
            paddingTop: 140,
            fontSize: '2.5rem',

        }
    },
    aboutUsMain: {
        fontSize: '1.5rem',
        paddingTop: 20
    },
    aboutUsH3: {
        color: '#4ca7ab',
        marginTop: 100,
        fontSize: '2rem',
        fontWeight: 600,
        [theme.breakpoints.down('xs')]: {
            marginTop: 50
        }
    },
    firstDot: {
        width: '20px',
        display: 'block',
        marginBottom: 20
    },
    secondDot: {
        width: '10px',
        display: 'block',
        marginBottom: 13,
        marginLeft: '4px',

    },
    thirdDot: {
        marginLeft: '6.5px',
        width: '5px',
        display: 'block',
        marginBottom: 13
    }

});


export const TermsConditionPage = ({ classes }) => (
    <div className={classes.root}>
        <h2 className={classes.aboutUsHeader} >
            Terms <span style={{ color: '#585c60' }}> &#38; </span> Conditions
        </h2>
        <img
            className={classes.thirdDot}
            src='/assets/Dot.png'
        />
        <img
            className={classes.secondDot}
            src='/assets/Dot.png'
        />

        <img
            className={classes.firstDot}
            src='/assets/Dot.png'
        />


        <div className={classes.aboutUsMain}>
            <h3 className={classes.aboutUsH3}>1. Subject</h3>
            <p>Tossmath has developed a private tutoring platform accessible on a website at theaddress, www.Tossmath.com, and designed to display tutor profile that users posted.(hereinafter referred to as the “Platform”).</p>
            <p>These terms and conditions have the purpose of governing access to and the terms ofuse of the Platform. Please read them carefully. You understand and recognise thatTossmath is not party to any agreement, contract or contractual relations, of any nature,entered into between the Members of its Platform.</p>
            <p>By clicking “Login with Facebook”, “Login with Google” or “Register with an emailaddress”, you recognise having read and accepted all these general conditions of use.</p>

            <h3 className={classes.aboutUsH3}>2. Definitions</h3>
            <p>In this document,</p>
            <p>“Advert” means an  advertisement/profile  about  a  Tutor’s  credentials/experiencesregarding the Lesson he will provide, posted on the Platform by a Tutor;</p>
            <p>“Tossmath” has the meaning given to it in Article 1 above;</p>
            <p>“T&#38;Cs” means these Terms and Conditions;</p>
            <p>“Account” means the account that must be created in order to become a Member andaccess certain services offered by the Platform;</p>
            <p>“Facebook Account” has the meaning given to it in Article 3.2 below;</p>
            <p>“Google Account” has the meaning given to it in Article 3.2 below;</p>

            <p>“Tutor” means the Member using the Platform, to offer lessons to another individual inexchange for the monetary compensation, for a type of lesson and at a time defined bythe Tutor alone;</p>
            <p>“Member Content” has the meaning given to it in Article 8.2 below</p>
            <p>“Member” means any individual having created an Account on the Platform;</p>
            <p>“Student” means the Member having accepted the offer to be taught by the Tutor or,where applicable, the person on whose behalf a Member has booked a Lesson;</p>
            <p>“Lesson” means the lesson booked by a Student;</p>
            <p>“Platform” has the meaning given to it in Article 1 above;</p>
            <p> “Services” means all services delivered by Tossmath via the Platform;</p>
            <p>“Website” means the website accessible at the address www.Tossmath.ca;</p>

            <h3 className={classes.aboutUsH3}>3. Registration on the Platform and creation of an Account</h3>
            <p>3.1. Conditions of registration on the Platform</p>
            <p>The Platform may be used by individuals at any age.</p>
            <p>3.2. Creation of an Account</p>
            <p>The Platform enables Members to post and view Adverts and to interact with each otherto book a Lesson. You can view the Adverts if you are not registered on the Platform.However, you cannot post an Advert or book a Lesson without having first created anAccount and become a Member.</p>
            <p>To create your Account, you can:</p>
            <p>(i) either complete all mandatory fields on the registration form;</p>
            <p>(ii) or log in to your Facebook account via our Platform (hereinafter referred to as your“Facebook Account”). In using such functionality, you understand that Tossmath willhave access to, will publish on the Platform, and will keep certain information from your
Facebook Account. If you wish to find out more about the use of your data from yourFacebook Account, read our Privacy Policy and that of Facebook. To register on thePlatform, you must have read and accepted these T&#38;Cs and the Privacy Policy.</p>
            <p>(iii) or log in to your Google account via our Platform (hereinafter referred to as your“Google Account”). In using such functionality, you understand that Tossmath will haveaccess to, will publish on the Platform, and will keep certain information from yourGoogle Account. If you wish to find out more about the use of your data from yourGoogle Account, read our Privacy Policy and that of Google. To register on the Platform,you must have read and accepted these T&Cs and the Privacy Policy.</p>
            <p>When creating your Account, regardless of the method chosen, you agree to provideaccurate and true information, and to update it through your profile or by notifyingTossmath, in order to guarantee its relevance and accuracy throughout your contractualrelations with Tossmath.</p>
            <p>In the case of registration by email, you agree to keep secret the password chosenupon creating your Account and not to communicate it to anybody. If you lose ordisclose your password, you undertake to inform Tossmath immediately. You alone areresponsible for the use of your Account by third parties, unless you have expresslynotified Tossmath of the loss, the fraudulent use by a third party, or the disclosure ofyour password to a third party.</p>
            <p>You agree not to create or use, under your own identity or that of a third party, Accountsother than that initially created</p>
            <p>3.3.    Verification</p>
            <p>Tossmath may, for the purposes of transparency, improving trust, or prevention ordetection of fraud, set up a system for verification of some of the information youprovide onyour profile. This is notably the case when you enter your telephone numberor provide us with an Identity document.</p>
            <p>You recognise and accept that any reference on the Platform or the Services to“verified” information, or any similar term, means only that a Member has successfullypassed the verification procedure existing on the Platform or the Services in order toprovide you with more information about the Member with whom you are consideringhaving a lesson/lessons. Tossmath cannot guarantee the truthfulness, reliability orvalidity of the information subject of the verification procedure.</p>

            <h3 className={classes.aboutUsH3}>4.    Use of the Services</h3>
            <p>4.1.    Posting Adverts</p>
            <p>As Member, and providing you fulfil the conditions below, you can create and postAdverts on the Platform by entering information about your credentials and lessons youintend to provide (academic institutions, experiences, tutoring fee, etc.).</p>
            <p>You recognise that you are solely responsible for the content of the Advert you post onthe Platform. Consequently, you represent and warrant the accuracy and truthfulness ofall information contained in your Advert, and you undertake to fulfil the Lesson under theconditions described in your Advert.</p>
            <p>Providing your Advert complies with the T&#38;Cs, it will be posted on the Platform andtherefore visible to Members and all visitors, even non-members, conducting a searchon the Platform or on the website of Tossmath’s partners. Tossmath reserves the right,at its sole discretion and without notice, to not post or to remove, at any time, any Advertthat does not comply with the T&#38;Cs or that it considers as damaging to its image, that ofthe Platform, or that of the Services.</p>
            <p>You recognise and accept that the criteria taken into account in the classification andthe order of display of your Advert among the other Adverts are at the sole discretion ofTossmath.</p>

            <h3 className={classes.aboutUsH3}>4.2.    Booking a Lesson</h3>
            <p>The method of booking is that Students/Members must contact the Tutor based on theinformation provided under Adverts. You are solely responsible for the booking process.</p>

            <h3 className={classes.aboutUsH3}>4.3.    Review system</h3>
            <p>4.3.1.    Operation</p>
            <p>Tossmath encourages you to leave a review about a Tutor (if you are a Student).However, you are not permitted to leave a review about a Member with whom you havenot had a lesson with.</p>
            <p>Your review, and the review left by another Member about you, if any, are visible andpublished on the Platform after the shorter of the following periods: (i) immediately afteryou left a review.</p>
            <p>4.3.2.    Moderation</p>
            <p>You recognise and accept that Tossmath reserves the right not to publish or to deleteany review, any question, any comment or any response if it deems its contentbreaches these T&#38;Cs.</p>
            <p>4.3.3.    Limit</p>
            <p>Tossmath reserves the right to suspend your Account, limit your access to the Services,or terminate these T&#38;Cs in the case where (i) you have received at least three reviewsand (ii) the average review you have received is less than or equal to 3.</p>
            <h3 className={classes.aboutUsH3}>5.    Financial conditions</h3>
            <p>Access to and registration on the Platform, as well as searching, viewing and postingAdverts, are free of charge.However, Prep Materials sold on Platform is charged andnon-refundable and also the messaging to Tutors is charged under the conditionsdescribed below.</p>
            <p>5.1.    Cost Contribution</p>
            <p>The Cost Contribution is determined by you, as Tutor, under your sole responsibility.</p>
            <p>5.2.    Methods of payment and repayment of the Cost Contribution to the Tutor</p>
            <p>5.2.1.    Payment of the Cost Contribution to the Tutor</p>
            <p>After the Lessons, the Students shall handle the money to the Tutor. The method ofpayment is solely depending on an agreement between the Tutor and the Student.
Whether it will be handled in cash, via bank transfer or using a PayPal account, etc. TheContribution Costs is solely depending on the Tutor.</p>

            <h3 className={classes.aboutUsH3}>6.    Suspension of accounts, limitation of access and termination</h3>
            <p>You can terminate your contractual relations with Tossmath at any time, without costand without reason. To do this, simply send an email to support@tossmath.ca</p>
            <p>In the event of (i) breach by you of these T&#38;Cs, including but limited to your obligationsas Member (ii) exceeding the limit set out in Article 4.3.3 above, or (iii) if Tossmath hasgenuine reason to believe that this is necessary to protect its security and its integrity,that of the Members or third parties, or for the purpose of prevention of fraud orinvestigations, Tossmath reserves the right to:</p>
            <p>(i) terminate the T&#38;Cs binding you with Tossmath immediately and without notice;and/or</p>
            <p>(ii) prevent the posting of or remove any review, Advert, message, content, or anycontent posted by you on the Platform; and/or</p>
            <p>(iii) limit your access and your use of the Platform; and/or</p>
            <p>(iv) temporarily or permanently suspend your Account.</p>
            <p>When this is necessary, you will be notified of the establishment of such measure inorder to enable you to give explanations to Tossmath. Tossmath will decide, at its solediscretion, whether or not to lift the measures put in place.</p>

            <h3 className={classes.aboutUsH3}>7.    Personal data</h3>
            <p>In the context of your use of the Platform, Tossmath will collect and process some ofyour personal data. In using the Platform and registering as Member, you recognise andaccept the processing of your personal data by Tossmath in compliance with theapplicable law and the stipulations of the Privacy Policy.</p>
            <h3 className={classes.aboutUsH3}>8.    Intellectual property</h3>
            <p>8.1. Content published by Tossmath</p>
            <p>Subject to the contents provided by its Members, Tossmath is the sole holder of allintellectual property rights relating to the Service, the Platform, its content (notably texts,images, designs, logos, videos, sounds, data, graphics) and to the software anddatabases ensuring their operation.</p>
            <p>Tossmath grants you a non-exclusive, personal and non-transferable right to use thePlatform and the Services, for your personal and private use, on a non-commercialbasis andin compliance with the purposes of the Platform and the Services.</p>
            <p>You are prohibited from any other use or exploitation of the Platform and Services, andtheir content, without the prior written permission of Tossmath. Notably, you areprohibited from:</p>
            <p>(i)   reproducing,   modifying,   adapting,   distributing,   publicly   representing   anddisseminating the Platform, the Services and the content, with the exception of thatexpressly authorised by Tossmath;</p>
            <p>(ii) decompiling and reverse engineering the Platform or Services, subject to theexceptions stipulated by the texts in force;</p>
            <p>(iii) extracting or attempting to extract (notably using data mining robots or any othersimilar data collection tool) a substantial part of the data of the Platform.</p>
            <p>8.2. Content posted by you on the Platform</p>
            <p>In order to enable the provision of the Services, and in compliance with the purpose ofthe Platform, you grant Tossmath a non-exclusive licence to use the content and datayou provide in the context of your use of the Services (hereinafter referred to as your“Member Content”). In order to enable Tossmath to distribute via the digital network andin accordance with any communication protocol (notably internet and mobile network),and to provide the content of the Platform to the public, you authorise Tossmath, for thewhole world and throughout the duration of your contractual relations with Tossmath, toreproduce, represent, adapt and translate your Member Content as follows:</p>
            <p>(i) you authorise Tossmath to reproduce all or part of your Member Content on anydigital recording media, known or as yet unknown, and notably on any server, hard disk,memory card, or any other equivalent media, in any format and by any process, known
or as yet unknown, to the extent necessary to any operation of storage, backup,transmission or download linked to the operation of the Platform and the provision of theService;</p>
            <p>(ii) you authorise Tossmath to adapt and translate your Member Content, and toreproduce these adaptations on any digital media, current or future, stipulated in point(i) above, with the aim of providing the Services, notably in different languages. Thisright notably includes the option to make modifications to the formatting of your MemberContent, with respect for your moral right, for the purposes of respecting the graphicscharter of the Platform and/or making it technically compatible with a view to itspublication via the Platform.</p>
            
            <h3 className={classes.aboutUsH3}>9.    Role of Tossmath</h3>
            <p>The Platform constitutes an online networking platform on which the Members cancreate and post Adverts. These Adverts can notably be viewed by the other Members tofind out the terms of the Lesson, and where applicable, to book a Lesson with theMember having posted the Advert on the Platform. </p>
            <p>In using the Platform and in accepting these T&#38;Cs, you recognise that Tossmath is notparty to any agreement entered into between you and the other Members with apayment or any other issues.</p>
            <p>Tossmath has no control over the behaviour of its Members and the users of thePlatform. It does not own, exploit, supply or manage the vehicles subject of the Adverts,and it does not offer any Lessons on the Platform.</p>
            <p>You recognise and accept that Tossmath does not control the validity, truthfulness orlegality of the Adverts, and Lessons offered. In its capacity of tutoring intermediary,Tossmath does not provide any tutoring service and does not act in the capacity of tutor;the role of Tossmath is limited to facilitating access to the Platform.</p>
            <p>The Members (Students or Tutors) act under their sole and full responsibility.</p>
            <p>In its capacity of intermediary, Tossmath cannot be held liable for the effectiveoccurrence of a Lesson, and notably owing to:</p>
            <p>(i) erroneous information communicated by the Tutor in his Advert, or by any othermeans, with regard to the Lesson and its terms;</p>
            <p>(ii) cancellation or modification of a Lesson by a Member;</p>
            <p>(iii) non-payment of a Student;</p>
            <p>(iv) the behaviour of its Members during, before or after the Lesson.</p>
          
            <h3 className={classes.aboutUsH3}>10.    Operation, availability and functionalities of the Platform</h3>
            <p>Tossmath shall try as far as possible to maintain the Platform accessible 7 days a weekand 24 hours a day. Nevertheless, access to the Platform may be temporarilysuspended, without notice, owing to technical maintenance, migration or updateoperations, or owing to outrages or constraints linked to the operation of the network.</p>
            <p>Furthermore, Tossmath reserves the right to modify or suspend all or part of access tothe Platform or its functionalities, at its sole discretion, temporarily or permanently.   </p>
            <h3 className={classes.aboutUsH3}>11.    Modification of the T&#38;Cs</h3>
            <p>These T&#38;Cs and the documents integrated by reference express the entire agreementbetween you and Tossmath relative to your use of the Services. Any other document,notably any mention on the Platform (FAQ, etc.), is for guideline purposes only.</p>
            <p>Tossmath may modify these T&#38;Cs in order to adapt to its technological and commercialenvironment, and to comply with the legislation in force. Any modification to these T&#38;Cswill be published on the Platform with mention of the date of effect, and you will benotified by Tossmath before it takes effect.</p>
            
            <h3 className={classes.aboutUsH3}>12.    Applicable law </h3>
            <p>These T&#38;Cs are written in English and subject to Canadian law.</p>

            <h3 className={classes.aboutUsH3}>13.    Legal notices</h3>
            <p>The Platform is published by CrownTutor Inc.</p>
            <p>For any question, you can contact support@tossmath.ca</p>

        </div>
    </div>
)

TermsConditionPage.propTypes = {
    classes: PropTypes.object // from enhancer (withStyles)
}

export default withStyles(styles)(TermsConditionPage)
