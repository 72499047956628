import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

//Material UI
import { Grid } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';

const styles = theme => ({
    box: {
        borderBottom: '1px solid #e1e1e6',
        paddingBottom: 80,
        marginBottom: 60
    },
    previewStyle:{
        width: '100%',
        height: '100%',
        borderRadius: 0
    }

})


const PreviewPage = ({
    courseInfo,
    classes
}) => {

    // console.log('@@@@2', courseInfo.previewSolution)
    return (
        <div className={classes.box}>
            <Avatar
                src={courseInfo.preview}
                className={classes.previewStyle}
            />
            <Divider
                style={{marginTop: 40, marginBottom: 40}}
            />
            <Avatar
                src={courseInfo.preview2}
                className={classes.previewStyle}
            />
            {/* <Avatar
                src={courseInfo.previewSolution}
                className={classes.previewStyle}
            /> */}
        </div>
    )
}

export default withStyles(styles)(PreviewPage)