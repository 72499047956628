export const categories = [
    {
        id: 1,
        label: 'Academic',
        value: 'Academic',
    },
    {
        id: 2,
        label: 'Test Prep',
        value: 'Test Prep',
    },
    {
        id: 3,
        label: 'Language',
        value: 'Language',
    },
    {
        id: 4,
        label: 'Music',
        value: 'Music',
    },
    {
        id: 5,
        label: 'Art & Design',
        value: 'Art & Design',
    },
    {
        id: 6,
        label: 'Health & Fitness',
        value: 'Health & Fitness',
    },
    {
        id: 7,
        label: 'Computer & IT',
        value: 'Computer & IT',
    },
    {
        id: 8,
        label: 'Business & Marketing',
        value: 'Business & Marketing',
    },
    {
        id: 9,
        label: 'Personal Development',
        value: 'Personal Development',
    },
    {
        id: 10,
        label: 'Life Style',
        value: 'Life Style',
    },
]

export const subjects = [
    //ACADEMICS 
    {
        id: 1,
        value: "English(Academic) & Essay",
        label: "English(Academic) & Essay",
        parentId: 1,
    },{
        id: 2,
        value: "Math",
        label: "Math",
        parentId: 1,
    },{
        id: 3,
        value: "Science",
        label: "Science",
        parentId: 1,
    },{
        id: 4,
        value: "Biology",
        label: "Biology",
        parentId: 1,
    },{
        id: 5,
        value: "Chemistry",
        label: "Chemistry",
        parentId: 1,
    },{
        id: 6,
        value: "Physics",
        label: "Physics",
        parentId: 1,
    },{
        id: 7,
        value: "Stats & Probability",
        label: "Stats & Probability",
        parentId: 1,
    },{
        id: 8,
        value: "Geography",
        label: "Geography",
        parentId: 1,
    },{
        id: 9,
        value: "History",
        label: "History",
        parentId: 1,
    },{
        id: 10,
        value: "Psychology",
        label: "Psychology",
        parentId: 1,
    },{
        id: 11,
        value: "Sociology",
        label: "Sociology",
        parentId: 1,
    },{
        id: 12,
        value: "Civic Education",
        label: "Civic Education",
        parentId: 1,
    },{
        id: 13,
        value: "Philosophy",
        label: "Philosophy",
        parentId: 1,
    },{
        id: 14,
        value: "Engineering",
        label: "Engineering",
        parentId: 1,
    },{
        id: 15,
        value: "Journalism",
        label: "Journalism",
        parentId: 1,
    },{
        id: 16,
        value: "Astronomy",
        label: "Astronomy",
        parentId: 1,
    },{
        id: 17,
        value: "Theology",
        label: "Theology",
        parentId: 1,
    },
    //TEST PREPS
    {
        id: 1,
        label: "SAT",
        value: "SAT",
        parentId: 2,
    },{
        id: 2,
        label: "ACT",
        value: "ACT",
        parentId: 2,
    },{
        id: 3,
        label: "TOEFL",
        value: "TOEFL",
        parentId: 2,
    },{
        id: 4,
        label: "IELTS",
        value: "IELTS",
        parentId: 2,
    },{
        id: 5,
        label: "MCAT",
        value: "MCAT",
        parentId: 2,
    },{
        id: 6,
        label: "GMAT",
        value: "GMAT",
        parentId: 2,
    },{
        id: 7,
        label: "GRE",
        value: "GRE",
        parentId: 2,
    },{
        id: 8,
        label: "LSAT",
        value: "LSAT",
        parentId: 2,
    },{
        id: 9,
        label: "PMP",
        value: "PMP",
        parentId: 2,
    },{
        id: 10,
        label: "CAPM",
        value: "CAPM",
        parentId: 2,
    },{
        id: 11,
        label: "CELPIP",
        value: "CELPIP",
        parentId: 2,
    },{
        id: 12,
        label: "TESOL",
        value: "TESOL",
        parentId: 2,
    },{
        id: 13,
        label: "CPA (Certified Public Accountant)",
        value: "CPA (Certified Public Accountant)",
        parentId: 2,
    },{
        id: 14,
        label: "CMA (Certified Management Accountant)",
        value: "CMA (Certified Management Accountant)",
        parentId: 2,
    },{
        id: 15,
        label: "CFA (Chartered Financial Analyst)",
        value: "CFA (Chartered Financial Analyst)",
        parentId: 2,
    },{
        id: 16,
        label: "DAT",
        value: "DAT",
        parentId: 2,
    },{
        id: 17,
        label: "OAT",
        value: "OAT",
        parentId: 2,
    },
    //Languages
    {   id: 0,
        value: "ESL & English",
        label: "ESL & English",
        parentId: 3
    },
    {   id: 1,
        value: "French",
        label: "French",
        parentId: 3
    },
    {   id: 2,
        value: "Chinese(Mandarin)",
        label: "Chinese(Mandarin)",
        parentId: 3
    },
    {   id: 3,
        value: "Chinese(Cantonese)",
        label: "Chinese(Cantonese)",
        parentId: 3
    },
    {   id: 4,
        value: "Akan",
        label: "Akan",
        parentId: 3
    },
    {   id: 5,
        value: "Albanian",
        label: "Albanian",
        parentId: 3
    },
    {   id: 6,
        value: "Amharic",
        label: "Amharic",
        parentId: 3
    },
    {   id: 7,
        value: "Arabic",
        label: "Arabic",
        parentId: 3
    },
    {   id: 8,
        value: "Aragonese",
        label: "Aragonese",
        parentId: 3
    },
    {   id: 9,
        value: "Armenian",
        label: "Armenian",
        parentId: 3
    },
    {   id: 10,
        value: "Assamese",
        label: "Assamese",
        parentId: 3
    },
    {   id: 11,
        value: "Avaric",
        label: "Avaric",
        parentId: 3
    },
    {   id: 12,
        value: "Avestan",
        label: "Avestan",
        parentId: 3
    },
    {   id: 13,
        value: "Aymara",
        label: "Aymara",
        parentId: 3
    },
    {   id: 14,
        value: "Azerbaijani",
        label: "Azerbaijani",
        parentId: 3
    },
    {   id: 15,
        value: "Bambara",
        label: "Bambara",
        parentId: 3
    },
    {   id: 16,
        value: "Bashkir",
        label: "Bashkir",
        parentId: 3
    },
    {   id: 17,
        value: "Basque",
        label: "Basque",
        parentId: 3
    },
    {   id: 18,
        value: "Belarusian",
        label: "Belarusian",
        parentId: 3
    },
    {   id: 19,
        value: "Bengali",
        label: "Bengali",
        parentId: 3
    },
    {   id: 20,
        value: "Bihari",
        label: "Bihari",
        parentId: 3
    },
    {   id: 21,
        value: "Bislama",
        label: "Bislama",
        parentId: 3
    },
    {   id:22,
        value: "Bosnian",
        label: "Bosnian",
        parentId: 3
    },
    {   id: 23,
        value: "Breton",
        label: "Breton",
        parentId: 3
    },
    {   id: 24,
        value: "Bulgarian",
        label: "Bulgarian",
        parentId: 3
    },
    {   id: 25,
        value: "Burmese",
        label: "Burmese",
        parentId: 3
    },
    {   id: 26,
        value: "Catalan; Valencian",
        label: "Catalan; Valencian",
        parentId: 3
    },
    {   id: 27,
        value: "Chamorro",
        label: "Chamorro",
        parentId: 3
    },
    {   id:28,
        value: "Chechen",
        label: "Chechen",
        parentId: 3
    },
    {   id:29,
        value: "Chichewa; Chewa; Nyanja",
        label: "Chichewa; Chewa; Nyanja",
        parentId: 3
    },
    {   id: 30,
        value: "Afrikaans",
        label: "Afrikaans",
        parentId: 3
    },
    {   id: 32,
        value: "Chuvash",
        label: "Chuvash",
        parentId: 3
    },
    {   id: 33,
        value: "Cornish",
        label: "Cornish",
        parentId: 3
    },
    {   id: 34,
        value: "Corsican",
        label: "Corsican",
        parentId: 3
    },
    {   id: 35,
        value: "Cree",
        label: "Cree",
        parentId: 3
    },
    {   id: 36,
        value: "Croatian",
        label: "Croatian",
        parentId: 3
    },
    {   id: 37,
        value: "Czech",
        label: "Czech",
        parentId: 3
    },
    {   id: 38,
        value: "Danish",
        label: "Danish",
        parentId: 3
    },
    {   id: 39,
        value: "Divehi; Dhivehi; Maldivian;",
        label: "Divehi; Dhivehi; Maldivian;",
        parentId: 3
    },
    {   id: 40,
        value: "Dutch",
        label: "Dutch",
        parentId: 3
    },
    {   id: 41,
        value: "Abkhaz",
        label: "Abkhaz",
        parentId: 3
    },
    {   id: 42,
        value: "Esperanto",
        label: "Esperanto",
        parentId: 3
    },
    {   id: 43,
        value: "Estonian",
        label: "Estonian",
        parentId: 3
    },
    {   id: 44,
        value: "Ewe",
        label: "Ewe",
        parentId: 3
    },
    {   id: 45,
        value: "Faroese",
        label: "Faroese",
        parentId: 3
    },
    {   id: 46,
        value: "Fijian",
        label: "Fijian",
        parentId: 3
    },
    {   id: 47,
        value: "Finnish",
        label: "Finnish",
        parentId: 3
    },
    {   id: 48,
        value: "Afar",
        label: "Afar",
        parentId: 3
    },
    {   id: 49,
        value: "Fula; Fulah; Pulaar; Pular",
        label: "Fula; Fulah; Pulaar; Pular",
        parentId: 3
    },
    {   id: 50,
        value: "Galician",
        label: "Galician",
        parentId: 3
    },
    {   id: 51, 
        value: "Georgian",
        label: "Georgian",
        parentId: 3
    },
    {   id: 52,
        value: "German",
        label: "German",
        parentId: 3
    },
    {   id: 53,
        value: "Greek, Modern",
        label: "Greek, Modern",
        parentId: 3
    },
    {   id: 54,
        value: "Guaraní",
        label: "Guaraní",
        parentId: 3
    },
    {   id: 55,
        value: "Gujarati",
        label: "Gujarati",
        parentId: 3
    },
    {   id: 56,
        value: "Haitian; Haitian Creole",
        label: "Haitian; Haitian Creole",
        parentId: 3
    },
    {   id: 57,
        value: "Hausa",
        label: "Hausa",
        parentId: 3
    },
    {   id: 58,
        value: "Hebrew (modern)",
        label: "Hebrew (modern)",
        parentId: 3
    },
    {   id: 59,
        value: "Herero",
        label: "Herero",
        parentId: 3
    },
    {   id: 60,
        value: "Hindi",
        label: "Hindi",
        parentId: 3
    },
    {   id: 61,
        value: "Hiri Motu",
        label: "Hiri Motu",
        parentId: 3
    },
    {   id: 62,
        value: "Hungarian",
        label: "Hungarian",
        parentId: 3
    },
    {   id: 63,
        value: "Interlingua",
        label: "Interlingua",
        parentId: 3
    },
    {   id: 64,
        value: "Indonesian",
        label: "Indonesian",
        parentId: 3
    },
    {   id: 65,
        value: "Interlingue",
        label: "Interlingue",
        parentId: 3
    },
    {   id: 66,
        value: "Irish",
        label: "Irish",
        parentId: 3
    },
    {   id: 67,
        value: "Igbo",
        label: "Igbo",
        parentId: 3
    },
    {   id: 68,
        value: "Inupiaq",
        label: "Inupiaq",
        parentId: 3
    },
    {   id: 69,
        value: "Ido",
        label: "Ido",
        parentId: 3
    },
    {   id: 70,
        value: "Icelandic",
        label: "Icelandic",
        parentId: 3
    },
    {   id: 71,
        value: "Italian",
        label: "Italian",
        parentId: 3
    },
    {   id: 72,
        value: "Inuktitut",
        label: "Inuktitut",
        parentId: 3
    },
    {   id: 73,
        value: "Japanese",
        label: "Japanese",
        parentId: 3
    },
    {   id: 74,
        value: "Javanese",
        label: "Javanese",
        parentId: 3
    },
    {   id: 75,
        value: "Kalaallisut, Greenlandic",
        label: "Kalaallisut, Greenlandic",
        parentId: 3
    },
    {   id: 76,
        value: "Kannada",
        label: "Kannada",
        parentId: 3
    },
    {   id: 77,
        value: "Kanuri",
        label: "Kanuri",
        parentId: 3
    },
    {   id: 78,
        value: "Kashmiri",
        label: "Kashmiri",
        parentId: 3
    },
    {   id: 79,
        value: "Kazakh",
        label: "Kazakh",
        parentId: 3
    },
    {   id: 80,
        value: "Khmer",
        label: "Khmer",
        parentId: 3
    },
    {   id: 81, 
        value: "Kikuyu, Gikuyu",
        label: "Kikuyu, Gikuyu",
        parentId: 3
    },
    {   id: 82,
        value: "Kinyarwanda",
        label: "Kinyarwanda",
        parentId: 3
    },
    {   id: 83,
        value: "Kirghiz, Kyrgyz",
        label: "Kirghiz, Kyrgyz",
        parentId: 3
    },
    {   id: 84,
        value: "Komi",
        label: "Komi",
        parentId: 3
    },
    {   id: 85,
        value: "Kongo",
        label: "Kongo",
        parentId: 3
    },
    {   id: 86,
        value: "Korean",
        label: "Korean",
        parentId: 3
    },
    {   id: 87,
        value: "Kurdish",
        label: "Kurdish",
        parentId: 3
    },
    {   id: 88,
        value: "Kwanyama, Kuanyama",
        label: "Kwanyama, Kuanyama",
        parentId: 3
    },
    {   id: 89,
        value: "Latin",
        label: "Latin",
        parentId: 3
    },
    {   id: 90,
        value: "Luxembourgish, Letzeburgesch",
        label: "Luxembourgish, Letzeburgesch",
        parentId: 3
    },
    {   id: 91,
        value: "Luganda",
        label: "Luganda",
        parentId: 3
    },
    {   id: 92,
        value: "Limburgish, Limburgan, Limburger",
        label: "Limburgish, Limburgan, Limburger",
        parentId: 3
    },
    {   id: 93,
        value: "Lingala",
        label: "Lingala",
        parentId: 3
    },
    {   id: 94,
        value: "Lao",
        label: "Lao",
        parentId: 3
    },
    {   id: 95,
        value: "Lithuanian",
        label: "Lithuanian",
        parentId: 3
    },
    {   id: 96,
        value: "Luba-Katanga",
        label: "Luba-Katanga",
        parentId: 3
    },
    {   id: 97,
        value: "Latvian",
        label: "Latvian",
        parentId: 3
    },
    {   id: 98,
        value: "Manx",
        label: "Manx",
        parentId: 3
    },
    {   id: 99,
        value: "Macedonian",
        label: "Macedonian",
        parentId: 3
    },
    {   id: 100,
        value: "Malagasy",
        label: "Malagasy",
        parentId: 3
    },
    {   id: 101,
        value: "Malay",
        label: "Malay",
        parentId: 3
    },
    {   id: 102,
        value: "Malayalam",
        label: "Malayalam",
        parentId: 3
    },
    {   id: 103,
        value: "Maltese",
        label: "Maltese",
        parentId: 3
    },
    {   id: 104,
        value: "Māori",
        label: "Māori",
        parentId: 3
    },
    {   id: 105,
        value: "Marathi (Marāṭhī)",
        label: "Marathi (Marāṭhī)",
        parentId: 3
    },
    {   id: 106,
        value: "Marshallese",
        label: "Marshallese",
        parentId: 3
    },
    {   id: 107,
        value: "Mongolian",
        label: "Mongolian",
        parentId: 3
    },
    {   id: 108,
        value: "Nauru",
        label: "Nauru",
        parentId: 3
    },
    {   id: 109,
        value: "Navajo, Navaho",
        label: "Navajo, Navaho",
        parentId: 3
    },
    {   id: 110,
        value: "Norwegian Bokmål",
        label: "Norwegian Bokmål",
        parentId: 3
    },
    {   id: 111,
        value: "North Ndebele",
        label: "North Ndebele",
        parentId: 3
    },
    {   id: 112,
        value: "Nepali",
        label: "Nepali",
        parentId: 3
    },
    {   id: 113,
        value: "Ndonga",
        label: "Ndonga",
        parentId: 3
    },
    {   id: 114,
        value: "Norwegian Nynorsk",
        label: "Norwegian Nynorsk",
        parentId: 3
    },
    {   id: 115,
        value: "Norwegian",
        label: "Norwegian",
        parentId: 3
    },
    {   id: 116,
        value: "Nuosu",
        label: "Nuosu",
        parentId: 3
    },
    {   id: 117,
        value: "South Ndebele",
        label: "South Ndebele",
        parentId: 3
    },
    {   id: 118,
        value: "Occitan",
        label: "Occitan",
        parentId: 3
    },
    {   id: 119,
        value: "Ojibwe, Ojibwa",
        label: "Ojibwe, Ojibwa",
        parentId: 3
    },
    // {   id: 120,
    //     value: "Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic",
    //     label: "Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic",
    //     parentId: 3
    // },
    {   id: 121,
        value: "Oromo",
        label: "Oromo",
        parentId: 3
    },
    {   id: 122,
        value: "Oriya",
        label: "Oriya",
        parentId: 3
    },
    {   id: 123,
        value: "Ossetian, Ossetic",
        label: "Ossetian, Ossetic",
        parentId: 3
    },
    {   id: 124,
        value: "Panjabi, Punjabi",
        label: "Panjabi, Punjabi",
        parentId: 3
    },
    {   id: 125,
        value: "Pāli",
        label: "Pāli",
        parentId: 3
    },
    {   id: 126,
        value: "Persian",
        label: "Persian",
        parentId: 3
    },
    {   id: 127,
        value: "Polish",
        label: "Polish",
        parentId: 3
    },
    {   id: 128,
        value: "Pashto, Pushto",
        label: "Pashto, Pushto",
        parentId: 3
    },
    {   id: 129,
        value: "Portuguese",
        label: "Portuguese",
        parentId: 3
    },
    {   id: 130,
        value: "Quechua",
        label: "Quechua",
        parentId: 3
    },
    {   id: 131,
        value: "Romansh",
        label: "Romansh",
        parentId: 3
    },
    {   id: 132,
        value: "Kirundi",
        label: "Kirundi",
        parentId: 3
    },
    {   id: 133,
        value: "Romanian, Moldavian, Moldovan",
        label: "Romanian, Moldavian, Moldovan",
        parentId: 3
    },
    {   id: 134,
        value: "Russian",
        label: "Russian",
        parentId: 3
    },
    {   id: 135,
        value: "Sanskrit (Saṁskṛta)",
        label: "Sanskrit (Saṁskṛta)",
        parentId: 3
    },
    {   id: 136,
        value: "Sardinian",
        label: "Sardinian",
        parentId: 3
    },
    {   id: 137,
        value: "Sindhi",
        label: "Sindhi",
        parentId: 3
    },
    {   id: 138,
        value: "Northern Sami",
        label: "Northern Sami",
        parentId: 3
    },
    {   id: 139,
        value: "Samoan",
        label: "Samoan",
        parentId: 3
    },
    {   id: 140,
        value: "Sango",
        label: "Sango",
        parentId: 3
    },
    {   id: 141,
        value: "Serbian",
        label: "Serbian",
        parentId: 3
    },
    {   id: 142,
        value: "Scottish Gaelic; Gaelic",
        label: "Scottish Gaelic; Gaelic",
        parentId: 3
    },
    {   id: 143,
        value: "Shona",
        label: "Shona",
        parentId: 3
    },
    {   id: 144,
        value: "Sinhala, Sinhalese",
        label: "Sinhala, Sinhalese",
        parentId: 3
    },
    {   id: 145,
        value: "Slovak",
        label: "Slovak",
        parentId: 3
    },
    {   id: 146,
        value: "Slovene",
        label: "Slovene",
        parentId: 3
    },
    {   id: 147,
        value: "Somali",
        label: "Somali",
        parentId: 3
    },
    {   id: 148,
        value: "Southern Sotho",
        label: "Southern Sotho",
        parentId: 3
    },
    {   id: 149,
        value: "Spanish; Castilian",
        label: "Spanish; Castilian",
        parentId: 3
    },
    {   id: 150,
        value: "Sundanese",
        label: "Sundanese",
        parentId: 3
    },
    {   id: 151,
        value: "Swahili",
        label: "Swahili",
        parentId: 3
    },
    {   id: 152,
        value: "Swati",
        label: "Swati",
        parentId: 3
    },
    {   id: 153,
        value: "Swedish",
        label: "Swedish",
        parentId: 3
    },
    {   id: 154,
        value: "Tamil",
        label: "Tamil",
        parentId: 3
    },
    {   id: 155,
        value: "Telugu",
        label: "Telugu",
        parentId: 3
    },
    {   id: 156,
        value: "Tajik",
        label: "Tajik",
        parentId: 3
    },
    {   id: 157,
        value: "Thai",
        label: "Thai",
        parentId: 3
    },
    {   id: 158,
        value: "Tigrinya",
        label: "Tigrinya",
        parentId: 3
    },
    {   id: 159,
        value: "Tibetan Standard, Tibetan, Central",
        label: "Tibetan Standard, Tibetan, Central",
        parentId: 3
    },
    {   id: 160,
        value: "Turkmen",
        label: "Turkmen",
        parentId: 3
    },
    {   id: 161,
        value: "Tagalog",
        label: "Tagalog",
        parentId: 3
    },
    {   id: 162,
        value: "Tswana",
        label: "Tswana",
        parentId: 3
    },
    {   id: 163,
        value: "Tonga (Tonga Islands)",
        label: "Tonga (Tonga Islands)",
        parentId: 3
    },
    {   id: 164,
        value: "Turkish",
        label: "Turkish",
        parentId: 3
    },
    {   id: 165,
        value: "Tsonga",
        label: "Tsonga",
        parentId: 3
    },
    {   id: 166,
        value: "Tatar",
        label: "Tatar",
        parentId: 3
    },
    {   id: 167,
        value: "Twi",
        label: "Twi",
        parentId: 3
    },
    {   id: 168,
        value: "Tahitian",
        label: "Tahitian",
        parentId: 3
    },
    {   id: 169,
        value: "Uighur, Uyghur",
        label: "Uighur, Uyghur",
        parentId: 3
    },
    {   id: 170,
        value: "Ukrainian",
        label: "Ukrainian",
        parentId: 3
    },
    {   id: 171,
        value: "Urdu",
        label: "Urdu",
        parentId: 3
    },
    {   id: 172,
        value: "Uzbek",
        label: "Uzbek",
        parentId: 3
    },
    {   id: 173,
        value: "Venda",
        label: "Venda",
        parentId: 3
    },
    {   id: 174,
        value: "Vietnamese",
        label: "Vietnamese",
        parentId: 3
    },
    {   id: 175,
        value: "Volapük",
        label: "Volapük",
        parentId: 3
    },
    {   id: 176,
        value: "Walloon",
        label: "Walloon",
        parentId: 3
    },
    {   id: 177,
        value: "Welsh",
        label: "Welsh",
        parentId: 3
    },
    {   id: 178,
        value: "Wolof",
        label: "Wolof",
        parentId: 3
    },
    {   id: 179,
        value: "Western Frisian",
        label: "Western Frisian",
        parentId: 3
    },
    {   id: 180,
        value: "Xhosa",
        label: "Xhosa",
        parentId: 3
    },
    {   id: 181,
        value: "Yiddish",
        label: "Yiddish",
        parentId: 3
    },
    {   id: 182,
        value: "Yoruba",
        label: "Yoruba",
        parentId: 3
    },
    {   id: 183,
        value: "Zhuang, Chuang",
        label: "Zhuang, Chuang",
        parentId: 3
    },
    //MUSIC
    {
        id: 1,
        value: "Piano",
        label: "Piano",
        parentId: 4,
    },{
        id: 2,
        value: "Guitar",
        label: "Guitar",
        parentId: 4,
    },{
        id: 3,
        value: "Drums",
        label: "Drums",
        parentId: 4,
    },{
        id: 4,
        value: "Vocal",
        label: "Vocal",
        parentId: 4,
    },{
        id: 5,
        value: "Rap & Beatbox & DJ",
        label: "Rap & Beatbox & DJ",
        parentId: 4,
    },{
        id: 6,
        value: "Composition & Song Writing",
        label: "Composition & Song Writing",
        parentId: 4,
    },{
        id: 7,
        value: "Production & Mixing",
        label: "Production & Mixing",
        parentId: 4,
    },{
        id: 8,
        value: "Violin",
        label: "Violin",
        parentId: 4,
    },{
        id: 9,
        value: "Cello",
        label: "Cello",
        parentId: 4,
    },{
        id: 10,
        value: "Viola",
        label: "Viola",
        parentId: 4,
    },{
        id: 11,
        value: "Flute",
        label: "Flute",
        parentId: 4,
    },{
        id: 12,
        value: "Clarinet",
        label: "Clarinet",
        parentId: 4,
    },{
        id: 13,
        value: "Trumpet",
        label: "Trumpet",
        parentId: 4,
    },{
        id: 14,
        value: "Saxophone",
        label: "Saxophone",
        parentId: 4,
    },{
        id: 15,
        value: "Percussions",
        label: "Percussions",
        parentId: 4,
    },{
        id: 16,
        value: "Oboe",
        label: "Oboe",
        parentId: 4,
    },{
        id: 17,
        value: "Picolo",
        label: "Picolo",
        parentId: 4,
    },{
        id: 18,
        value: "Recorder",
        label: "Recorder",
        parentId: 4,
    },{
        id: 19,
        value: "Ocarina",
        label: "Ocarina",
        parentId: 4,
    },{
        id: 20,
        value: "Bassoon",
        label: "Bassoon",
        parentId: 4,
    },{
        id: 21,
        value: "Organ",
        label: "Organ",
        parentId: 4,
    },{
        id: 22,
        value: "Clarion",
        label: "Clarion",
        parentId: 4,
    },{
        id: 23,
        value: "Didgeridoo",
        label: "Didgeridoo",
        parentId: 4,
    },{
        id: 24,
        value: "Trombone",
        label: "Trombone",
        parentId: 4,
    },{
        id: 25,
        value: "Bugle",
        label: "Bugle",
        parentId: 4,
    },{
        id: 26,
        value: "Euphonium",
        label: "Euphonium",
        parentId: 4,
    },{
        id: 27,
        value: "Tuba",
        label: "Tuba",
        parentId: 4,
    },{
        id: 28,
        value: "Harmonica",
        label: "Harmonica",
        parentId: 4,
    },{
        id: 29,
        value: "Bagpipes",
        label: "Bagpipes",
        parentId: 4,
    },{
        id: 30,
        value: "Harp",
        label: "Harp",
        parentId: 4,
    },{
        id: 31,
        value: "Music Theory & Music Education",
        label: "Music Theory & Music Education",
        parentId: 4,
    },{
        id: 32,
        value: "Musical",
        label: "Musical",
        parentId: 4,
    },{
        id: 33,
        value: "Opera",
        label: "Opera",
        parentId: 4,
    },{
        id: 34,
        value: "Accordian",
        label: "Accordian",
        parentId: 4,
    },{
        id: 35,
        value: "Djembe",
        label: "Djembe",
        parentId: 4,
    },{
        id: 36,
        value: "Marimba",
        label: "Marimba",
        parentId: 4,
    },{
        id: 37,
        value: "Melodica",
        label: "Melodica",
        parentId: 4,
    },{
        id: 38,
        value: "Banjo",
        label: "Banjo",
        parentId: 4,
    },{
        id: 39,
        value: "Bongo",
        label: "Bongo",
        parentId: 4,
    },{
        id: 40,
        value: "Cornet",
        label: "Cornet",
        parentId: 4,
    },{
        id: 41,
        value: "Cymbal",
        label: "Cymbal",
        parentId: 4,
    },{
        id: 42,
        value: "French Horn",
        label: "French Horn",
        parentId: 4,
    },{
        id: 43,
        value: "Gong",
        label: "Gong",
        parentId: 4,
    },{
        id: 44,
        value: "Harpsichord",
        label: "Harpsichord",
        parentId: 4,
    },
    {
        id: 45,
        value: "Jew's Harp",
        label: "Jew's Harp",
        parentId: 4,
    },{
        id: 46,
        value: "Lute",
        label: "Lute",
        parentId: 4,
    },{
        id: 47,
        value: "Lyre",
        label: "Lyre",
        parentId: 4,
    },{
        id: 48,
        value: "Mandolin",
        label: "Mandolin",
        parentId: 4,
    },{
        id: 49,
        value: "Bass",
        label: "Bass",
        parentId: 4,
    },{
        id: 50,
        value: "Pan Pipes",
        label: "Pan Pipes",
        parentId: 4,
    },{
        id: 51,
        value: "Tambourine",
        label: "Tambourine",
        parentId: 4,
    },{
        id: 52,
        value: "Timpani",
        label: "Timpani",
        parentId: 4,
    },{
        id: 53,
        value: "Synthesizer",
        label: "Synthesizer",
        parentId: 4,
    },{
        id: 54,
        value: "Sitar",
        label: "Sitar",
        parentId: 4,
    },
    //Art and Design
    {
        id: 1,
        value: "Painting (general)",
        label: "Painting (general)",
        parentId: 5,
    },{
        id: 2,
        value: "Oil Painting",
        label: "Oil Painting",
        parentId: 5,
    },{
        id: 3,
        value: "Abstract Painting",
        label: "Abstract Painting",
        parentId: 5,
    },{
        id: 4,
        value: "Fine Arts",
        label: "Fine Arts",
        parentId: 5,
    },{
        id: 5,
        value: "East Asian Painting",
        label: "East Asian Painting ",
        parentId: 5,
    },{
        id: 6,
        value: "Caligraphy",
        label: "Caligraphy",
        parentId: 5,
    },{
        id: 7,
        value: "Comics & Manga",
        label: "Comics & Manga",
        parentId: 5,
    },{
        id: 8,
        value: "Caricature",
        label: "Caricature",
        parentId: 5,
    },{
        id: 9,
        value: "Illutrstation",
        label: "Illutrstation",
        parentId: 5,
    },{
        id: 10,
        value: "Graffiti",
        label: "Graffiti",
        parentId: 5,
    },{
        id: 11,
        value: "Watercolor Painting",
        label: "Watercolor Painting",
        parentId: 5,
    },{
        id: 12,
        value: "Engraving",
        label: "Engraving",
        parentId: 5,
    },{
        id: 13,
        value: "Sculpture",
        label: "Sculpture",
        parentId: 5,
    },{
        id: 14,
        value: "Printmaking",
        label: "Printmaking",
        parentId: 5,
    },{
        id: 15,
        value: "Acrylic Painting",
        label: "Acrylic Painting",
        parentId: 5,
    },{
        id: 16,
        value: "Crafts (general)",
        label: "Crafts (general)",
        parentId: 5,
    },{
        id: 17,
        value: "Ceramic Crafts",
        label: "Ceramic Crafts",
        parentId: 5,
    },{
        id: 18,
        value: "Earthenware Crafts",
        label: "Earthenware Crafts",
        parentId: 5,
    },{
        id: 19,
        value: "Leather Crafts",
        label: "Leather Crafts",
        parentId: 5,
    },{
        id: 20,
        value: "Pottery",
        label: "Pottery",
        parentId: 5,
    },{
        id: 21,
        value: "Metalsmithing",
        label: "Metalsmithing",
        parentId: 5,
    },{
        id: 22,
        value: "Jewlery Crafts",
        label: "Jewlery Crafts",
        parentId: 5,
    },{
        id: 23,
        value: "Floral Arts",
        label: "Floral Arts",
        parentId: 5,
    },{
        id: 24,
        value: "Landscaping",
        label: "Landscaping",
        parentId: 5,
    },{
        id: 25,
        value: "Gardening",
        label: "Gardening",
        parentId: 5,
    },{
        id: 26,
        value: "Performing Arts",
        label: "Performing Arts",
        parentId: 5,
    },{
        id: 27,
        value: "Acting",
        label: "Acting",
        parentId: 5,
    },{
        id: 28,
        value: "Musical Theatre",
        label: "Musical Theatre",
        parentId: 5,
    },{
        id: 29,
        value: "Stand-up Comedy",
        label: "Stand-up Comedy",
        parentId: 5,
    },{
        id: 30,
        value: "Photography",
        label: "Photography",
        parentId: 5,
    },{
        id: 31,
        value: "Art Theory & Art Education",
        label: "Art Theory & Art Education",
        parentId: 5,
    },{
        id: 32,
        value: "Portfolio Prep",
        label: "Portfolio Prep",
        parentId: 5,
    },{
        id: 33,
        value: "Fashion Design",
        label: "Fashion Design",
        parentId: 5,
    },{
        id: 34,
        value: "Knitting & Sewing",
        label: "Knitting & Sewing",
        parentId: 5,
    },{
        id: 35,
        value: "Cinema & Film",
        label: "Cinema & Film",
        parentId: 5,
    },{
        id: 36,
        value: "Film Production",
        label: "Film Production",
        parentId: 5,
    },{
        id: 37,
        value: "Scriptwriting",
        label: "Scriptwriting",
        parentId: 5,
    },{
        id: 38,
        value: "Origami",
        label: "Origami",
        parentId: 5,
    },{
        id: 39,
        value: "Graphic Design",
        label: "Graphic Design",
        parentId: 5,
    },{
        id: 40,
        value: "Advertising",
        label: "Advertising",
        parentId: 5,
    },{
        id: 41,
        value: "Creative Writing",
        label: "Creative Writing",
        parentId: 5,
    },{
        id: 42,
        value: "Digital Painting",
        label: "Digitla Painting",
        parentId: 5,
    },{
        id: 43,
        value: "Interior Design",
        label: "Interior Design",
        parentId: 5,
    },{
        id: 44,
        value: "Environmental Design",
        label: "Environmental Design",
        parentId: 5,
    },
    {
        id: 45,
        value: "Industrial Design",
        label: "Industrial Design",
        parentId: 5,
    },{
        id: 46,
        value: "Integrated Media",
        label: "Integrated Media",
        parentId: 5,
    },{
        id: 47,
        value: "Animation",
        label: "Animation",
        parentId: 5,
    },{
        id: 48,
        value: "3D",
        label: "3D",
        parentId: 5,
    },{
        id: 49,
        value: "User Experience & User Interface",
        label: "User Experience & User Interface",
        parentId: 5,
    },{
        id: 50,
        value: "Web Design",
        label: "Web Design",
        parentId: 5,
    },{
        id: 51,
        value: "Architectural Design",
        label: "Architectural Design",
        parentId: 5,
    },{
        id: 52,
        value: "Game Design",
        label: "Game Design",
        parentId: 5,
    },{
        id: 53,
        value: "Photoshop",
        label: "Photoshop",
        parentId: 5,
    },{
        id: 54,
        value: "After Effects",
        label: "After Effects",
        parentId: 5,
    },{
        id: 55,
        value: "Adobe Illustrator",
        label: "Adobe Illustrator",
        parentId: 5,
    },{
        id: 56,
        value: "Premiere Pro",
        label: "Premiere Pro",
        parentId: 5,
    },{
        id: 57,
        value: "Video Editing",
        label: "Video Editiong",
        parentId: 5,
    },{
        id: 58,
        value: "Broadcast",
        label: "Broadcast",
        parentId: 5,
    },{
        id: 59,
        value: "YouTube",
        label: "YouTube",
        parentId: 5,
    },
    //Health and Fitness
    {
        id: 1,
        value: "Personal Training",
        label: "Personal Training",
        parentId: 6,
    },{
        id: 2,
        value: "Running & Marathon",
        label: "Running & Marathon",
        parentId: 6,
    },{
        id: 3,
        value: "Crossfit",
        label: "Crossfit",
        parentId: 6,
    },{
        id: 4,
        value: "Bodybuilding",
        label: "Bodybuilding",
        parentId: 6,
    },{
        id: 5,
        value: "Weight Loss & Diet",
        label: "Weight Loss & Diet ",
        parentId: 6,
    },{
        id: 6,
        value: "Yoga",
        label: "Yoga",
        parentId: 6,
    },{
        id: 7,
        value: "Pilates",
        label: "Pilates",
        parentId: 6,
    },{
        id: 8,
        value: "Home Workout",
        label: "Home Workout",
        parentId: 6,
    },{
        id: 9,
        value: "Acrobatic",
        label: "Acrobatic",
        parentId: 6,
    },{
        id: 10,
        value: "Swimming",
        label: "Swimming",
        parentId: 6,
    },{
        id: 11,
        value: "Gymnastics",
        label: "Gymnastics",
        parentId: 6,
    },{
        id: 12,
        value: "Kinesiology",
        label: "Kinesiology",
        parentId: 6,
    },{
        id: 13,
        value: "Soccer",
        label: "Soccer",
        parentId: 6,
    },{
        id: 14,
        value: "Basketball",
        label: "Basketball",
        parentId: 6,
    },{
        id: 15,
        value: "Tennis",
        label: "Tennis",
        parentId: 6,
    },{
        id: 16,
        value: "Golf",
        label: "Golf",
        parentId: 6,
    },{
        id: 17,
        value: "Sports Coaching",
        label: "Sports Coaching",
        parentId: 6,
    },{
        id: 18,
        value: "Martial Arts",
        label: "Martial Arts",
        parentId: 6,
    },{
        id: 19,
        value: "MMA",
        label: "MMA",
        parentId: 6,
    },{
        id: 20,
        value: "Karate",
        label: "Karate",
        parentId: 6,
    },{
        id: 21,
        value: "Self Defence",
        label: "Self Defence",
        parentId: 6,
    },{
        id: 22,
        value: "TaeKwonDo",
        label: "TaeKwonDo",
        parentId: 6,
    },{
        id: 23,
        value: "Judo",
        label: "Judo",
        parentId: 6,
    },{
        id: 24,
        value: "Kendo",
        label: "Kendo",
        parentId: 6,
    },{
        id: 25,
        value: "Aikido",
        label: "Aikido",
        parentId: 6,
    },{
        id: 26,
        value: "Wrestling",
        label: "Wrestling",
        parentId: 6,
    },{
        id: 27,
        value: "Badminton",
        label: "Badminton",
        parentId: 6,
    },{
        id: 28,
        value: "Water Polo",
        label: "Water Polo",
        parentId: 6,
    },{
        id: 29,
        value: "American Football",
        label: "American Football",
        parentId: 6,
    },{
        id: 30,
        value: "Rugby",
        label: "Rugby",
        parentId: 6,
    },{
        id: 31,
        value: "Fencing",
        label: "Fencing",
        parentId: 6,
    },{
        id: 32,
        value: "Archery",
        label: "Archery",
        parentId: 6,
    },{
        id: 33,
        value: "Triathlon",
        label: "Triathlon",
        parentId: 6,
    },{
        id: 34,
        value: "Volleyball",
        label: "Volleyball",
        parentId: 6,
    },{
        id: 35,
        value: "Cycling",
        label: "Cycling",
        parentId: 6,
    },{
        id: 36,
        value: "Equestrian",
        label: "Equestrian",
        parentId: 6,
    },{
        id: 37,
        value: "Hockey",
        label: "Hockey",
        parentId: 6,
    },{
        id: 38,
        value: "Rowing",
        label: "Rowing",
        parentId: 6,
    },{
        id: 39,
        value: "Ice Hockey",
        label: "Ice Hockey",
        parentId: 6,
    },{
        id: 40,
        value: "Shooting",
        label: "Shooting",
        parentId: 6,
    },{
        id: 41,
        value: "Table Tennis",
        label: "Table Tennis",
        parentId: 6,
    },{
        id: 42,
        value: "Weight Lifting",
        label: "Weight Lifting",
        parentId: 6,
    },{
        id: 43,
        value: "Skiing",
        label: "Skiing",
        parentId: 6,
    },{
        id: 44,
        value: "Snowboarding",
        label: "Snowboarding",
        parentId: 6,
    },
    {
        id: 45,
        value: "Skating",
        label: "Skating",
        parentId: 6,
    },{
        id: 46,
        value: "Curling",
        label: "Curling",
        parentId: 6,
    },{
        id: 47,
        value: "Figure Skating",
        label: "Figure Skating",
        parentId: 6,
    },{
        id: 48,
        value: "Free Running & Parkour",
        label: "Free Running & Parkour",
        parentId: 6,
    },{
        id: 49,
        value: "Boxing",
        label: "Boxing",
        parentId: 6,
    },{
        id: 50,
        value: "Kick Boxing",
        label: "Kick Boxing",
        parentId: 6,    
    },{
        id: 51,
        value: "Muay Thai",
        label: "Muay Thai",
        parentId: 6,
    },{
        id: 52,
        value: "Combat Sambo",
        label: "Combat Sambo",
        parentId: 6,
    },{
        id: 53,
        value: "Systema",
        label: "Systema",
        parentId: 6,
    },{
        id: 54,
        value: "Wing Chun",
        label: "Wing Chun",
        parentId: 6,
    },{
        id: 55,
        value: "TaiChi",
        label: "TaiChi",
        parentId: 6,
    },{
        id: 56,
        value: "Aerobics",
        label: "Aerobics",
        parentId: 6,
    },{
        id: 57,
        value: "Dancing",
        label: "Dancing",
        parentId: 6,
    },{
        id: 58,
        value: "Baseball",
        label: "Baseball",
        parentId: 6,
    },{
        id: 59,
        value: "Dieting",
        label: "Dieting",
        parentId: 6,
    },{
        id: 60,
        value: "Nutrition",
        label: "Nutrition",
        parentId: 6,
    },{
        id: 61,
        value: "Jiu-Jitsu",
        label: "Jiu-Jitsu",
        parentId: 6,
    },{
        id: 62,
        value: "Herbalism",
        label: "Herbalism",
        parentId: 6,
    },{
        id: 63,
        value: "Aromatherapy",
        label: "Aromatherapy",
        parentId: 6,
    },{
        id: 64,
        value: "Massaging",
        label: "Massaging",
        parentId: 6,
    },{
        id: 65,
        value: "Physiotherapy",
        label: "Physiotherapy",
        parentId: 6,
    },{
        id: 66,
        value: "Chiropratic",
        label: "Chiropratic",
        parentId: 6,
    },{
        id: 67,
        value: "Acupuncture",
        label: "Acupuncture",
        parentId: 6,
    },{
        id: 68,
        value: "Mental Health",
        label: "Mental Health",
        parentId: 6,
    },{
        id: 69,
        value: "Reflexology",
        label: "Reflexology",
        parentId: 6,
    },{
        id: 70,
        value: "Shiatsu",
        label: "Shiatsu",
        parentId: 6,
    },{
        id: 71,
        value: "Eating Well",
        label: "Eating Well",
        parentId: 6,
    },{
        id: 72,
        value: "Well-Being",
        label: "Well-Being",
        parentId: 6,
    },{
        id: 73,
        value: "Music Therapy",
        label: "Music Therapy",
        parentId: 6,
    },{
        id: 74,
        value: "Graphotherapy",
        label: "Graphotherapy",
        parentId: 6,
    },{
        id: 75,
        value: "First-Aid",
        label: "First-Aid",
        parentId: 6,
    },{
        id: 76,
        value: "Ballet",
        label: "Ballet",
        parentId: 6,
    },
    //IT & Computer
    {
        id: 1,
        value: "Web Development",
        label: "Web Development",
        parentId: 7,
    },{
        id: 2,
        value: "Javascript",
        label: "Javascript",
        parentId: 7,
    },{
        id: 3,
        value: "HTML & CSS",
        label: "HTML & CSS",
        parentId: 7,
    },{
        id: 4,
        value: "Full-stack Development",
        label: "Full-stack Development",
        parentId: 7,
    },{
        id: 5,
        value: "Front-end Development",
        label: "Front-end Development",
        parentId: 7,
    },{
        id: 6,
        value: "Back-end Development",
        label: "Back-end Development",
        parentId: 7,
    },{
        id: 7,
        value: "React",
        label: "React",
        parentId: 7,
    },{
        id: 8,
        value: "AngularJS",
        label: "AngularJS",
        parentId: 7,
    },{
        id: 9,
        value: "Vue.Js",
        label: "Vue.Js",
        parentId: 7,
    },{
        id: 10,
        value: "Node.Js",
        label: "Node.Js",
        parentId: 7,
    },{
        id: 11,
        value: "Database",
        label: "Database",
        parentId: 7,
    },{
        id: 12,
        value: "SQL & MYSQL",
        label: "SQL & MYSQL",
        parentId: 7,
    },{
        id: 13,
        value: "NOSQL",
        label: "NOSQL",
        parentId: 7,
    },{
        id: 14,
        value: "Mobile App Development",
        label: "Mobile App Development",
        parentId: 7,
    },{
        id: 15,
        value: "Android Development",
        label: "Android Development",
        parentId: 7,
    },{
        id: 16,
        value: "IOS Development",
        label: "IOS Development",
        parentId: 7,
    },{
        id: 17,
        value: "Swift",
        label: "Swift",
        parentId: 7,
    },{
        id: 18,
        value: "React Native",
        label: "React Native",
        parentId: 7,
    },{
        id: 19,
        value: "Java",
        label: "Java",
        parentId: 7,
    },{
        id: 20,
        value: "Kotlin",
        label: "Kotlin",
        parentId: 7,
    },{
        id: 21,
        value: "Google Flutter",
        label: "Google Flutter",
        parentId: 7,
    },{
        id: 22,
        value: "Ionic",
        label: "Ionic",
        parentId: 7,
    },{
        id: 23,
        value: "Redux Framework",
        label: "Redux Framework",
        parentId: 7,
    },{
        id: 24,
        value: "C",
        label: "C",
        parentId: 7,
    },{
        id: 25,
        value: "C++",
        label: "C++",
        parentId: 7,
    },{
        id: 26,
        value: "C#",
        label: "C#",
        parentId: 7,
    },{
        id: 27,
        value: "Python",
        label: "Python",
        parentId: 7,
    },{
        id: 28,
        value: "Ruby & Ruby on Rails",
        label: "Ruby & Ruby on Rails",
        parentId: 7,
    },{
        id: 29,
        value: "Objective C",
        label: "Objective C",
        parentId: 7,
    },{
        id: 30,
        value: "PHP",
        label: "PHP",
        parentId: 7,
    },{
        id: 31,
        value: "Game Development",
        label: "Game Development",
        parentId: 7,
    },{
        id: 32,
        value: "Unity",
        label: "Unity",
        parentId: 7,
    },{
        id: 33,
        value: "Unreal Engine",
        label: "Unreal Engine",
        parentId: 7,
    },{
        id: 34,
        value: "Oracle Certification",
        label: "Oracle Certification",
        parentId: 7,
    },{
        id: 35,
        value: "Software Development",
        label: "Software Development",
        parentId: 7,
    },{
        id: 36,
        value: "Automation",
        label: "Automation",
        parentId: 7,
    },{
        id: 37,
        value: "Machine Learning",
        label: "Machine Learning",
        parentId: 7,
    },{
        id: 38,
        value: "AI",
        label: "AI",
        parentId: 7,
    },{
        id: 39,
        value: "Data Science",
        label: "Data Science",
        parentId: 7,
    },{
        id: 40,
        value: "Scrum",
        label: "Scrum",
        parentId: 7,
    },{
        id: 41,
        value: "Firebase",
        label: "Firebase",
        parentId: 7,
    },{
        id: 42,
        value: "AWS & Amazon AWS",
        label: "AWS & Amazon AWS",
        parentId: 7,
    },{
        id: 43,
        value: "Big Data",
        label: "Big Data",
        parentId: 7,
    },{
        id: 44,
        value: "Git",
        label: "Git",
        parentId: 7,
    },
    {
        id: 45,
        value: "DevOps",
        label: "DevOps",
        parentId: 7,
    },{
        id: 46,
        value: "Cisco CCNA",
        label: "Cisco CCNA",
        parentId: 7,
    },{
        id: 47,
        value: "Microsoft Azure",
        label: "Microsoft Azure",
        parentId: 7,
    },{
        id: 48,
        value: "AWS Certification",
        label: "AWS Certification",
        parentId: 7,
    },{
        id: 49,
        value: "Microsoft Certification",
        label: "Microsoft Certification",
        parentId: 7,
    },{
        id: 50,
        value: "CompTIA A+",
        label: "CompTIA A+",
        parentId: 7,    
    },{
        id: 51,
        value: "compTIA Security+",
        label: "compTIA Security+",
        parentId: 7,
    },{
        id: 52,
        value: "compTIA Network+",
        label: "compTIA Network+",
        parentId: 7,
    },{
        id: 53,
        value: "Ethical Hacking",
        label: "Ethical Hacking",
        parentId: 7,
    },{
        id: 54,
        value: "Cyber Security",
        label: "Cyber Security",
        parentId: 7,
    },{
        id: 55,
        value: "Network Security",
        label: "Network Security",
        parentId: 7,
    },{
        id: 56,
        value: "IT Networking Fundamentals",
        label: "IT Networking Fundamentals",
        parentId: 7,
    },{
        id: 57,
        value: "PLC",
        label: "PLC",
        parentId: 7,
    },{
        id: 58,
        value: "Arduino",
        label: "Arduino",
        parentId: 7,
    },{
        id: 59,
        value: "Microcontroller",
        label: "Microcontroller",
        parentId: 7,
    },{
        id: 60,
        value: "Raspberry Pi",
        label: "Raspberry Pi",
        parentId: 7,
    },{
        id: 61,
        value: "Embeded Systems",
        label: "Embeded Systems",
        parentId: 7,
    },{
        id: 62,
        value: "Linux",
        label: "Linux",
        parentId: 7,
    },{
        id: 63,
        value: "Windows Server",
        label: "Windows Server",
        parentId: 7,
    },{
        id: 64,
        value: "Shell Scipting",
        label: "Shell Scipting",
        parentId: 7,
    },{
        id: 65,
        value: "PowerShell",
        label: "PowerShell",
        parentId: 7,
    },{
        id: 66,
        value: "MacOS",
        label: "MacOS",
        parentId: 7,
    },{
        id: 67,
        value: "SAP",
        label: "SAP",
        parentId: 7,
    },{
        id: 68,
        value: "Microsoft Excel",
        label: "Microsoft Excel",
        parentId: 7,
    },{
        id: 69,
        value: "Microsoft Word",
        label: "Microsoft Word",
        parentId: 7,
    },{
        id: 70,
        value: "Microsoft PowerPoint",
        label: "Microsoft PowerPoint",
        parentId: 7,
    },{
        id: 71,
        value: "Microsoft Publisher",
        label: "Microsoft Publisher",
        parentId: 7,
    },{
        id: 72,
        value: "Microsoft Access",
        label: "Microsoft Access",
        parentId: 7,
    },{
       id: 73,
       value: "VBA",
       label: "VBA",
       parentId: 7,
   },{
       id: 74,
       value: "AutoCAD",
       label: "AutoCAD",
       parentId: 7,
   },
   {
       id: 74,
       value: "CAD",
       label: "CAD",
       parentId: 7,
   },
   {
       id: 74,
       value: "SolidWork",
       label: "SolidWork",
       parentId: 7,
   },{
       id: 75,
       value: "Pearl",
       label: "Pearl",
       parentId: 7,
   },
   {
       id: 76,
       value: "Wordpress",
       label: "Wordpress",
       parentId: 7,
   },
   {
       id: 76,
       value: "Gaming",
       label: "Gaming",
       parentId: 7,
   },
   //Business
   {
        id: 1,
        value: "Financial Analysis",
        label: "Financial Analysis",
        parentId: 8,
    },{
        id: 2,
        value: "Investing",
        label: "Investing",
        parentId: 8,
    },{
        id: 3,
        value: "Finance",
        label: "Finance",
        parentId: 8,
    },{
        id: 4,
        value: "Stock Trading",
        label: "Stock Trading",
        parentId: 8,
    },{
        id: 5,
        value: "Accounting",
        label: "Accounting",
        parentId: 8,
    },{
        id: 6,
        value: "Forex",
        label: "Forex",
        parentId: 8,
    },{
        id: 7,
        value: "Investment Banking",
        label: "Investment Banking",
        parentId: 8,
    },{
        id: 8,
        value: "Business",
        label: "Business",
        parentId: 8,
    },{
        id: 9,
        value: "Entrepreneurship",
        label: "Entrepreneurship",
        parentId: 8,
    },{
        id: 10,
        value: "Dropshipping",
        label: "Dropshipping",
        parentId: 8,
    },{
        id: 11,
        value: "Business Strategy",
        label: "Business Strategy",
        parentId: 8,
    },{
        id: 12,
        value: "Business Plan",
        label: "Business Plan",
        parentId: 8,
    },{
        id: 13,
        value: "Blogging",
        label: "Blogging",
        parentId: 8,
    },{
        id: 14,
        value: "Business English",
        label: "Business English",
        parentId: 8,
    },{
        id: 15,
        value: "Marketing",
        label: "Marketing",
        parentId: 8,
    },{
        id: 16,
        value: "Managment",
        label: "Managment",
        parentId: 8,
    },{
        id: 17,
        value: "Sales",
        label: "Sales",
        parentId: 8,
    },{
        id: 18,
        value: "Business Law",
        label: "Business Law",
        parentId: 8,
    },{
        id: 19,
        value: "Intellectual Property",
        label: "Intellectual Property",
        parentId: 8,
    },{
        id: 20,
        value: "Human Resources",
        label: "Human Resources",
        parentId: 8,
    },{
        id: 21,
        value: "Interview Prep",
        label: "Interview Prep",
        parentId: 8,
    },{
        id: 22,
        value: "Resume Editing",
        label: "Resume Editing",
        parentId: 8,
    },{
        id: 23,
        value: "Real Estate",
        label: "Real Estate",
        parentId: 8,
    },{
        id: 24,
        value: "Airbnb Hosting",
        label: "Airbnb Hosting",
        parentId: 8,
    },{
        id: 25,
        value: "Six Sigma",
        label: "Six Sigma",
        parentId: 8,
    },{
        id: 26,
        value: "Supply Chain Management",
        label: "Supply Chain Management",
        parentId: 8,
    },{
        id: 27,
        value: "Quality Management",
        label: "Quality Management",
        parentId: 8,
    },{
       id: 28,
       value: "Project Management",
       label: "Project Management",
       parentId: 8,
   },
   //Personal Development
    {
        id: 1,
        value: "Leadership",
        label: "Leadership",
        parentId: 9,
    },{
        id: 2,
        value: "Life Coaching",
        label: "Life Coaching",
        parentId: 9,
    },{
        id: 3,
        value: "Time Management",
        label: "Time Management",
        parentId: 9,
    },{
        id: 4,
        value: "Personal Productivity",
        label: "Personal Productivity",
        parentId: 9,
    },{
        id: 5,
        value: "Goal Setting",
        label: "Goal Setting",
        parentId: 9,
    },{
        id: 6,
        value: "Productivity",
        label: "Productivity",
        parentId: 9,
    },{
        id: 7,
        value: "Organization",
        label: "Organization",
        parentId: 9,
    },{
        id: 8,
        value: "Motivation",
        label: "Motivation",
        parentId: 9,
    },{
        id: 9,
        value: "Management Skills",
        label: "Management Skills",
        parentId: 9,
    },{
        id: 10,
        value: "Manager Training",
        label: "Manager Training",
        parentId: 9,
    },{
        id: 11,
        value: "Public Speaking",
        label: "Public Speaking",
        parentId: 9,
    },{
        id: 12,
        value: "Communication Skills",
        label: "Communication Skills",
        parentId: 9,
    },{
        id: 13,
        value: "Conflict Management",
        label: "Conflict Management",
        parentId: 9,
    },{
        id: 14,
        value: "Emotional Intelligence",
        label: "Emotional Intelligence",
        parentId: 9,
    },{
        id: 15,
        value: "Neuro-Linguistic Programming",
        label: "Neuro-Linguistic Programming",
        parentId: 9,
    },{
        id: 16,
        value: "Career Development",
        label: "Career Development",
        parentId: 9,
    },{
        id: 17,
        value: "Resume",
        label: "Resume",
        parentId: 9,
    },{
        id: 18,
        value: "Interviewing Skills",
        label: "Interviewing Skills",
        parentId: 9,
    },{
        id: 19,
        value: "Job Search",
        label: "Job Search",
        parentId: 9,
    },{
        id: 20,
        value: "Personal Networking",
        label: "Personal Networking",
        parentId: 9,
    },{
        id: 21,
        value: "Perosnal Branding",
        label: "Perosnal Branding",
        parentId: 9,
    },{
        id: 22,
        value: "Social Skills",
        label: "Social Skills",
        parentId: 9,
    },{
        id: 23,
        value: "Presentation Skills",
        label: "Presentation Skills",
        parentId: 9,
    },{
        id: 24,
        value: "Influence",
        label: "Influence",
        parentId: 9,
    },{
        id: 25,
        value: "Negotiation Skills",
        label: "Negotiation Skills",
        parentId: 9,
    },{
        id: 26,
        value: "Persuation Skills",
        label: "Persuation Skills",
        parentId: 9,
    },{
        id: 27,
        value: "Speech Coaching",
        label: "Speech Coaching",
        parentId: 9,
    },{
        id: 28,
        value: "Self-Esteem",
        label: "Self-Esteem",
        parentId: 9,
    },{
        id: 29,
        value: "Speed Reading",
        label: "Speed Reading",
        parentId: 9,
    },{
        id: 30,
        value: "Study Skills",
        label: "Study Skills",
        parentId: 9,
    },{
        id: 31,
        value: "Learning Disability",
        label: "Learning Disability",
        parentId: 9,
    },{
        id: 32,
        value: "Learning Strategies",
        label: "Learning Strategies",
        parentId: 9,
    },{
        id: 33,
        value: "Memorization Skills",
        label: "Memorization Skills",
        parentId: 9,
    },{
        id: 34,
        value: "Focus Mastery",
        label: "Focus Mastery",
        parentId: 9,
    },
    //Life Style
    {
        id: 1,
        value: "Cooking",
        label: "Cooking",
        parentId: 10,
    },{
        id: 2,
        value: "Wine",
        label: "Wine",
        parentId: 10,
    },{
        id: 3,
        value: "Baking",
        label: "Baking",
        parentId: 10,
    },{
        id: 4,
        value: "Cosmetics",
        label: "Cosmetics",
        parentId: 10,
    },{
        id: 5,
        value: "Makeup Artistry",
        label: "Makeup Artistry",
        parentId: 10,
    },{
        id: 6,
        value: "Hair Styling",
        label: "Hair Styling",
        parentId: 10,
    },{
        id: 7,
        value: "Nair Art",
        label: "Nair Art",
        parentId: 10,
    },{
        id: 8,
        value: "Travel Tips",
        label: "Travel Tips",
        parentId: 10,
    },{
        id: 9,
        value: "Airbnb Hosting",
        label: "Airbnb Hosting",
        parentId: 10,
    },{
        id: 10,
        value: "Home Improvement",
        label: "Home Improvement",
        parentId: 10,
    },{
        id: 11,
        value: "Farming",
        label: "Farming",
        parentId: 10,
    },{
        id: 12,
        value: "Gardening",
        label: "Gardening",
        parentId: 10,
    },{
        id: 13,
        value: "Renovation",
        label: "Renovation",
        parentId: 10,
    },{
        id: 14,
        value: "Parenting",
        label: "Parenting",
        parentId: 10,
    },{
        id: 15,
        value: "Aquaculture",
        label: "Aquaculture",
        parentId: 10,
    },{
        id: 16,
        value: "Pet Training",
        label: "Pet Training",
        parentId: 10,
    },{
        id: 17,
        value: "Pet Care",
        label: "Pet Care",
        parentId: 10,
    },{
        id: 18,
        value: "Anger Management",
        label: "Anger Management",
        parentId: 10,
    },
]

export const levels = [
    {
        id: 1,
        label: "K-8",
        value: "k8",
    },
    {
        id: 2,
        label: "Grade 9",
        value: "gr9",
    },
    {
        id: 3,
        label: "Grade 10",
        value: "gr10",
    },
    {
        id: 4,
        label: "Grade 11",
        value: "gr11",
    },
    {
        id: 5,
        label: "Grade 12 - Advanced Functions",
        value: "af",
    },
    {
        id: 6,
        label: "Grade 12 - Calculus",
        value: "cal",
    },
    {
        id: 7,
        label: "Grade 12 -Data Management",
        value: "dm",
    },
]