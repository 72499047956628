import React from 'react'
import Add from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import Select from 'react-select'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';


import { educations as educationData } from '../../../app/common/educations'
import { withStyles } from '@material-ui/core/styles';


const style = (theme) => ({
  subjectTitle: {
    fontSize: '1.5rem',
    marginLeft: 40,
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 20
    }
  },
  cardStyle: {
    marginTop: 20,
    overflow: 'hidden',
    width: '95%',
    marginLeft: 'auto',
    marginRight: 'auto',
    boxShadow: 'none',
    border: '1px solid #e1e1e6',
    paddingTop: 8,
    paddingBottom: 8,
    color: '#585c60',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: 0,
      borderRadius: 0,
      boxShadow: 'none'
    }
  },
  phoneSize: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      marginBottom: 17,
      marginLeft: 20,
      marginRight: 20,
      paddingTop: 17,
      paddingLeft: 20,
      paddingBottom: 0,
      borderTop: '1px solid #e1e1e6'
    },
  },
  desktopSize: {
    width: '100%',
    borderBottom: '1px solid #e1e1e6',
    paddingBottom: 15,
    display: 'block',
    marginBottom: 15,
    paddingLeft: 20,
    color: '#585c60',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },

  },
  desktopSize1: {
    width: '100%',
    display: 'block',
    paddingLeft: 20,
    margin: 0,
    color: '#585c60',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },

  },


  selectStyle: {
    marginBottom: 20
  },
  card: {
    overflow: 'visible'
  },
  dialBtnStyle1: {
    padding: '8px 16px',
    backgroundColor: '#fff',
    borderRadius: 5,
    color: '#FF5D5D',
    cursor: 'pointer',
    border: '1px solid #FF5D5D',
    '&:hover': {
      backgroundColor: '#FF5D5D',
      color: '#fff'
    },
  },
  dialBtnStyle2: {
    padding: '8px 16px',
    backgroundColor: '#4ca7ab',
    borderRadius: 5,
    borderStyle: 'none',
    color: 'white',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#0A4065',
    },
  },
  cardHeaderStyle: {
    paddingBottom: 0
  }
})
const TutorProfileEducation = ({
  tutorProfile,
  openResumeUserEdu,
  handleResumeUserEduClickOpen,
  handleResumeUserEduClickClose,
  handleSubmitEducation,
  handleRemoveEducation,
  handleChangeSelectEducation,
  handleChange,
  isOwner,
  classes
}) => {
  // console.log(tutorProfile)
  return (
    <React.Fragment>
      <Card className={classes.card}>
        <Dialog
          fullWidth
          open={openResumeUserEdu}
          onClose={() => handleResumeUserEduClickClose()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title"></DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <TextField
                fullWidth
                id="outlined-name"
                label="school"
                name="where"
                onChange={(data) => handleChangeSelectEducation(data)}
                margin="normal"
                variant="outlined"
              />
              <TextField
                fullWidth
                id="outlined-name"
                label="Major"
                name="what"
                className={classes.textfieldStyle}
                onChange={(e) => handleChange(e)}
                margin="normal"
                variant="outlined"
              />
              <Grid container >
                <Grid item xs={12} md={4}>
                  <TextField
                    id="outlined-name"
                    label="from"
                    name="from"
                    className={classes.textfieldStyle}
                    onChange={(e) => handleChange(e)}
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    id="outlined-name"
                    label="to"
                    name="to"
                    className={classes.textfieldStyle}
                    onChange={(e) => handleChange(e)}
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
              </Grid>



            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button className={classes.dialBtnStyle1} onClick={() => handleResumeUserEduClickClose()} >CANCEL</button>
            <button className={classes.dialBtnStyle2} onClick={() => handleSubmitEducation()} >SAVE</button>
          </DialogActions>
        </Dialog>
      </Card>


      {isOwner ?
        <Card className={classes.cardStyle}>
          <CardContent
            className={classes.cardHeaderStyle}
          >
            <Grid container>
              <Grid item xs={11} md={11}>
                <Typography style={{ color: '#4ca7ab' }} className={classes.subjectTitle}>School
                </Typography>
              </Grid>
              <Grid item xs={1} md={1}>
                <Add style={{
                  display: 'inlineBlock',
                  cursor: 'pointer',
                  float: 'right',
                  color: '#4ca7ab'

                }}
                  onClick={() => handleResumeUserEduClickOpen()}
                />
              </Grid>
            </Grid>
          </CardContent>

          <CardContent >
            {tutorProfile.educations && tutorProfile.educations.map((education, i) => (
              <React.Fragment key={i}>

                <div className={classes.desktopSize}>
                  <Grid container>
                    <Grid item xs={6}>
                      <p >{education.where}</p>
                      <p style={{ marginTop: -8, color: '#585c60' }}>{education.what}</p>
                    </Grid>
                    <Grid item xs={3}>
                      <p >{education.from}&nbsp;&#8764;&nbsp;{education.to}</p>
                    </Grid>
                    <Grid item xs={3}>
                      <DeleteIcon style={{ float: 'right', marginRight: '22px', color: '#FF5D5D', fontSize: '18px' }} onClick={(e) => handleRemoveEducation(e, i)} />
                    </Grid>
                  </Grid>

                </div>
                <div className={classes.phoneSize}>
                  <Grid container>

                    <Grid item md={5}>
                      <p >{education.where}</p>
                    </Grid>
                    <Grid item md={4}>
                      <p>{education.what}</p>
                    </Grid>
                    <Grid item md={2}>
                      <span>{education.from}&nbsp;&#8764;&nbsp;{education.to}</span>
                    </Grid>
                    <Grid item md={1}>
                      <DeleteIcon style={{ marginLeft: '55%', color: '#FF5D5D', fontSize: '18px', cursor: 'pointer' }} onClick={(e) => handleRemoveEducation(e, i)} />
                    </Grid>
                  </Grid>
                </div>
              </React.Fragment>
            ))
            }
          </CardContent>
        </Card>
        :
        <Card className={classes.cardStyle}>
          <CardContent
            className={classes.cardHeaderStyle}
          >
            <Typography style={{ color: '#4ca7ab' }} className={classes.subjectTitle}>School</Typography>
          </CardContent>
          <CardContent >
            {tutorProfile.educations && tutorProfile.educations.map((education, i) => (

              <React.Fragment key={i}>
                <div className={(i === tutorProfile.educations.length - 1) ? classes.desktopSize1 :
                  classes.desktopSize
                }>
                  <Grid container>
                    <Grid item xs={8}>
                      <p >{education.where}</p>
                      <p style={{ marginTop: -8, color: '#585c60' }}>{education.what}</p>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={2}>
                      <p >{education.from}&nbsp;&#8764;&nbsp;{education.to}</p>
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.phoneSize}>
                  <Grid container>

                    <Grid item md={5}>
                      <p >{education.where}</p>
                    </Grid>
                    <Grid item md={5}>
                      <p>{education.what}</p>
                    </Grid>
                    <Grid item md={2}>
                      <span>{education.from}&nbsp;&#8764;&nbsp;{education.to}</span>
                    </Grid>

                  </Grid>
                </div>
              </React.Fragment>
            ))
            }
          </CardContent>
        </Card>
      }
    </React.Fragment>
  )
}

export default withStyles(style)(TutorProfileEducation)