import React, { Component } from 'react'
import firebase from '../../app/config/firebase'
import TutorSearchForm from '../tutors/TutorSearchForm/TutorSearchForm'
import _ from 'lodash'
import { Link } from "react-scroll"
import { Link as LinkTo } from "react-router-dom";
import { connect } from 'react-redux';
import { compose } from 'recompose'
import { getCategoryRequest } from '../../app/common/categoryActions'
import { withStyles } from '@material-ui/core/styles';
import { getUserRequest } from '../../features/user/userAction'
import { toastr } from "react-redux-toastr";
import { categories, levels, subjects } from "../../app/common/categories";
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CourseList from '../TestPrep/CourseList';
import StudentPassBanner from './StudentPassBanner/StudentPassBanner';
import TutorVideoDemo from './StudentPassBanner/TutorVideoDemo';
import TutorQuiz from './StudentPassBanner/TutorQuiz';
import AboutMidtermPrep from './StudentPassBanner/AboutMidtermPrep';
import { courseOptions } from "../../app/common/courseOptions";
import { openModal } from '../modals/modalActions'

const styles = theme => ({
    backgroundStyle: {
        backgroundImage: 'url("/assets/BackgroundImage/Material_Page_Banner.png") ',
        backgroundPosition: 'center',
        width: '100vw',
        height: '90vh',
        backgroundSize: 'cover',
        borderRadius: '0px',
        marginTop: 90,
        [theme.breakpoints.down('xs')]: {
            marginBottom: 40,
            height: '50vh',
            backgroundImage: 'linear-gradient(#669fff, #ccdfff)'
        }
    },
    gridStyle: {
        maxWidth: '1180px',
        marginRight: 'auto',
        marginLeft: 'auto'
    },
    girdFirst: {
        marginTop: 150,
        letterSpacing: '2px',
        display: 'block',
        [theme.breakpoints.down('xs')]: {
            marginTop: 60,
            paddingLeft: 40
        }
    },
    fontStyle1: {
        fontSize: '3.2rem',
        color: '#585c60',
        fontWeight: 900,
        marginRight: 80,
        marginBottom: 0,
        marginTop: 0,
        [theme.breakpoints.down('xs')]: {
            fontWeight: 600,
            fontSize: '2rem',
            color: 'white'
        }

    },
    fontStyle2: {
        fontSize: '1.9rem',
        color: '#585c60',
        marginRight: 80,
        fontWeight: 300,
        marginTop: 20,
        [theme.breakpoints.down('xs')]: {
            color: 'white'
        }
    },
    homeBtn: {
        width: '130px',
        backgroundColor: 'none',
        color: '#585c60',
        fontWeight: 600,
        fontSize: '1.1rem',
        letterSpacing: '1.7px',
        border: 'none',
        cursor: 'pointer',
        marginTop: 85,
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            marginLeft: 40,
            display: 'none'
        },
        '&:hover': {
            color: '#585c60'
        }
    },
    downloadBtn: {
        backgroundColor: '#4ca7ab',
        color: '#fff',
        fontWeight: 600,
        fontSize: '1.1rem',
        padding: '14px 42px',
        borderRadius: 5,
        letterSpacing: '1.7px',
        border: 'none',
        cursor: 'pointer',
        marginTop: 70,
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        },
        '&:hover': {
            borderBottom: '1px solid #4ca7ab',
        }
    },
    homeBtn1: {
        backgroundColor: 'Transparent',
        color: '#669fff',
        fontWeight: 600,
        fontSize: '1.1rem',
        letterSpacing: '1.7px',
        border: 'none',
        textDecoration: 'underline',
        marginTop: 40,
        display: 'flex',
        width: '80%',
        marginRight: 'auto',
        marginLeft: 'auto',
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        }
    },
    secondhead: {
        zIndex: -1,
        fontSize: '3rem',
        fontWeight: 600,
        color: '#585c60',
        marginTop: 110,
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '1180px',
        marginBottom: 40,
        fontFamily: 'proxima-nova, sans-serif ',
        [theme.breakpoints.down('xs')]: {
            width: '80%',
            fontSize: '2em',
            marginTop: 'auto',
            paddingTop: 80,

        },
    },


    seneca: {
        width: 400,
        height: 'auto',

        marginLeft: 'auto',
        marginRight: 'auto',
        borderRadius: '0px',
        [theme.breakpoints.down('xs')]: {
            width: 300,
            float: 'center'
        },
    },
    senecabox: {
        display: 'block',
        marginTop: '100px',
        paddingTop: '40px',
        backgroundColor: 'white',
        height: 140,
        width: '100vw',
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.down('xs')]: {
            height: 80,
            float: 'center',
            marginTop: '150px',

        },
    },
    verifiedStyle: {
        marginTop: 100,
        marginBottom: -80,
        display: 'flex',
        textAlign: 'center',
        width: '80%',
        marginRight: 'auto',
        marginLeft: 'auto',
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        }
    },
    midtermDescription: {
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '1180px',
        marginTop: -30,
        color: '#585c60',
        [theme.breakpoints.down('xs')]: {
            width: '80%',
            marginRight: 'auto',
            marginLeft: 'auto',
            fontSize: '1.2rem'
        }
    }




});

const getSuggestions = value => {

    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 ? [] : subjects.filter(lang =>
        lang.value.toLowerCase().slice(0, inputLength) === inputValue
    );
};

const getSuggestionValue = suggestion => suggestion.value;

const renderSuggestion = suggestion => (
    <div style={{ listStyle: 'none' }}>
        {suggestion.value}
    </div>
);

class HomePage02 extends Component {

    state = {
        testSubjectOptions: {},
        lat: '',
        lng: '',
        formattedAddress: '',
        subject: '',
        lang: ['English'],
        value: '',
        suggestions: [],
        topTutorsList: [],
        recentTutorsList: [],

    }

    getTutors = () => {
        let loadedTopTutors = [];
        let loaddedRecentTutors = [];
        const profileRef = firebase.firestore().collection('profiles');

        profileRef.where("top", ">", 0).get()
            .then(snap => {
                snap.forEach(function (doc) {
                    // console.log(doc.id, " => ", doc.data());
                    loadedTopTutors.push(doc.data())
                }
                )
            })
        profileRef.orderBy('created', 'desc').limit(10).get()
            .then(snap => {
                snap.forEach(function (doc) {
                    // console.log(doc.id, " => ", doc.data());
                    loaddedRecentTutors.push(doc.data())
                }
                )
                this.setState({
                    topTutorsList: loadedTopTutors,
                    recentTutorsList: loaddedRecentTutors,
                })
                // console.log('loadedTopTutors', loadedTopTutors);
                // console.log('loaddedRecentTutors', loaddedRecentTutors)
            })
            .catch(err => console.log(err));
    }


    componentDidMount() {

        this.props.getUserRequest()
        this.props.getCategoryRequest()
        this.getTutors()
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    onChangeSubject = (item) => {
        this.setState({
            level: item.value,
            levelLabel: item.label
        })
    }

    onChange = (event, { newValue }) => {
        this.setState({
            value: newValue,
            level: newValue
        });
        // console.log(this.state.subject)
    };

    onChangeLang = (options) => {
        let langList = [];
        _.forEach(options, (option) => {
            langList.push(option.value);
        });
        this.setState(state => {
            // console.log(langList)
            return { lang: langList };
        });
    }

    handleScriptLoad = () => {
        var options = {
            types: ['geocode'],
        };

        // Initialize Google Autocomplete
        /*global google*/ // To disable any eslint 'google not defined' errors
        this.autocomplete = new google.maps.places.Autocomplete(
            document.getElementById('autocomplete'),
            options,
        );

        // Fire Event when a suggested name is selected
        this.autocomplete.addListener('place_changed', this.handlePlaceSelect);
    }

    handlePlaceSelect = async () => {
        // Extract City From Address Object
        let addressObject = this.autocomplete.getPlace();
        let address = addressObject.address_components;

        // Check if address is valid
        if (address) {
            // Set State
            this.setState({
                addressComponents: addressObject.address_components,
                lat: addressObject.geometry.location.lat(),
                lng: addressObject.geometry.location.lng(),
                locationName: addressObject.name,
                formattedAddress: addressObject.formatted_address,
                placeId: addressObject.place_id,
                icon: addressObject.icon,
                plusCode: addressObject.plus_code,
                vicinity: addressObject.vicinity,
                utc_offset: addressObject.utc_offset,
                url: addressObject.url
            });
        }
    }

    handleSearch = (e) => {
        const { level, levelLabel, lang, formattedAddress, lat, lng } = this.state;
        const distance = [25];

        if (level === '' || lang.length === 0 || formattedAddress === '') {
            this.props.history.push({
                pathname: '/',
            })
            return toastr.error("Oops", "Please fill the form correctly")
        }

        const levelValue = { label: levelLabel, value: level };
        const langValue = [];

        _.forEach(lang, (item) => {
            langValue.push({
                label: item,
                value: item
            })
        })

        this.props.searchBy(
            langValue,
            levelValue,
            formattedAddress,
            lat,
            lng,
            distance,
            1,
            0,
            100,
            {
                university: true,
                college: true,
                high: true,
                middle: true,
                elementary: true,
                early: true,
                casual: true,
            }
        )
        this.props.history.push({
            pathname: '/tutors',
        })

        // console.log(Date.now())

        // if(this.props.auth.uid !== '25TdcSZIqmQzzysNs9Jy2roIZSF2' && this.props.auth.uid !== '3N7ClcLcUsegmIiFnLi6dF7XmjI2' && this.props.auth.uid !== 'vCsTGN3ZVsNRLRkD5h5uueyndO12' && this.props.auth.uid !== 'CiPm4mUV7SQtsEAE6wnDAMEjvYr1' ){
        if (!this.props.auth.isEmpty) {
            if (!this.props.admin) {
                firebase.firestore().collection('mainSearches').doc(`${Date.now()}`).set({
                    a_name: this.props.auth.displayName || `${this.props.profile.firstName} ${this.props.profile.lastName}`,
                    b_level: levelValue.value,
                    c_formattedAddress: formattedAddress,
                    langValue,
                    uid: this.props.auth.uid,
                    a_date: new Date(Date.now())
                })
                // .then(() => {
                //   return firebase.firestore().collection('users').doc(this.props.auth.uid).collection('searches').doc(`${uuid()}`).update({
                //     langValue,
                //     subjectValue,
                //     formattedAddress,
                //     date: new Date(Date.now())
                //   })
                // }).catch(err => console.log(err));
            }
        } else {
            return firebase.firestore().collection('mainSearches').doc(`${Date.now()}`).set({
                a_name: 'Not LoggedIn',
                b_level: levelValue.value,
                c_formattedAddress: formattedAddress,
                langValue,
                a_date: new Date(Date.now())
            })
        }
        // }


    }

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: getSuggestions(value)
        });
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    reSendVerificationEmail = () => {
        this.props.openModal("EmailVerificationModal")
    }







    render() {
        // const {history} = this.props;
        // const { classes } = this.props;
        const { value, suggestions, topTutorsList, recentTutorsList } = this.state;
        const { classes, subjectOptions, auth, openModal, closeModal, profile } = this.props;

        return (

            <div>
                <div className={classes.backgroundStyle}>
                    <Grid
                        className={classes.gridStyle}
                        container
                    >

                        <Grid item xs={12} md={7}>
                            <div className={classes.girdFirst} >
                                <h1
                                    className={classes.fontStyle1}
                                >Complete Test Prep</h1>
                                <h1
                                    className={classes.fontStyle1}
                                >with Tossmath</h1>
                                <h3
                                    className={classes.fontStyle2}
                                >
                                    Gr9 - Gr12 Prep Materials available
                            </h3>
                            </div>
                            <Grid container>
                                <Grid item md={4}>
                                    <Link
                                        activeClass='active'
                                        to='midtermPrep'
                                        spy={true}
                                        smooth={true}
                                        offset={-120}
                                        duration={500}
                                    >
                                        <button className={classes.downloadBtn}  >DOWNLOAD</button>
                                    </Link>

                                </Grid>
                                <Grid item md={6}>
                                    <a
                                        href="/"
                                        className={classes.homeBtn}
                                    >
                                        Find Tutor &#62;
                                    </a>
                                </Grid>
                            </Grid>



                        </Grid>
                        <Grid item xs={12} md={5}>

                        </Grid>
                    </Grid>
                </div>
                <div>
                    <a
                        href="/"
                        className={classes.homeBtn1}
                    >
                        Find Tutor &#62;
              </a>
                </div>


                <h2 className={classes.secondhead} id="midtermPrep">Test Preps- Part A</h2>
                <div className={classes.midtermDescription}>
                    <p
                    >
                        100% Follwing the Ontario Curriculum and designed to teach students from concepts to competition level problems. Part A includes the first four chapters of the course.
          </p>
                </div>


                <CourseList
                    courseOptions={courseOptions}
                    auth={auth}
                    firebase={firebase}
                    admin={this.props.admin}
                    profile={profile}
                />

                <AboutMidtermPrep />
                <StudentPassBanner />

                <div className={classes.senecabox}>
                    <Avatar className={classes.seneca} src="/assets/SenecaHelix.png" alt="helix"  >
                    </Avatar>
                </div>

            </div >



        );
    }
};



const mapDispatchToProps = (dispatch) => {
    return {
        getUserRequest: () => dispatch(getUserRequest()),
        searchBy: (lang, level, formattedAddress, lat, lng, distance, searchPage, priceLow, priceHigh) => dispatch({
            type: 'SEARCH_BY',
            langs: lang,
            level: level,
            location: formattedAddress,
            lat: lat,
            lng: lng,
            distance: distance,
            searchPage: searchPage,
            priceLow,
            priceHigh,
        }),
        getCategoryRequest: () => dispatch(getCategoryRequest()),
        openModal: (payload) => dispatch(openModal(payload)),
    }
}

const mapState = (state) => ({
    subjectOptions: state.categories.subjects,
    auth: state.firebase.auth,
    admin: state.firebase.profile.admin,
    profile: state.firebase.profile,
})

export default withStyles(styles)(
    compose(
        connect(
            mapState, mapDispatchToProps)
    )(
        HomePage02)
);
