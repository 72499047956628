import React from "react";
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card';
import { courseOptions } from '../../../app/common/courseOptions'

const styles = theme => ({
    cardStyle: {
        width: '90%',
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 20,
        minHeight: '200px',
        maxHeight: '200px',
        boxShadow: 'none',
        color: '#585c60',
        border: '1px solid #e1e1e6',
        fontFamily: 'proxima-nova, sans-serif ',
        [theme.breakpoints.down('xs')]: {
            minHeight: '270px',
            maxHeight: '270px',
        }
    },
    courseOptionlist: {
        borderRadius: 0,
        width: '100%',
        height: '170px'
    },
    nameStyle: {
        fontSize: '1.3rem',
        fontWeight: 600,
        marginBottom: 0,
        display: 'block',
        color: '#fff',
        fontFamily: 'proxima-nova, sans-serif ',
        width: '90%',
        paddingTop: 15,
        marginRight: 'auto',
        marginLeft: 'auto'
    },
    levelStyle: {
        color: '#fff',
        fontSize: '1.1rem',
        fontFamily: 'proxima-nova, sans-serif ',
        width: '90%',
        marginRight: 'auto',
        marginLeft: 'auto'
    },
    avatarStyle: {
        borderRadius: 0,
        width: '100%',
        cursor: 'pointer',
        height: '100px',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        [theme.breakpoints.down('xs')]: {
            height: '170px'
        }
    },
    infoStyle1: {
        width: '80%',
        marginTop: 15,
        marginLeft: 'auto',
        marginRight: 'auto',

    },
    cityStyle: {
        marginLeft: 0
    },
    background: {
        height: '100px',
        color: 'rgba(0, 0, 0, 0.5)',
        width: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    midtermPrep: {
        textAlign: 'center',
        paddingTop: 5,
        marginBottom: 5,
        fontFamily: 'proxima-nova, sans-serif ',
        fontWeight: 600,
        fontSize: '1.1rem',
        letterSpacing: '1px'
    },

})
const MyCoursesList = ({
    myCourse,
    classes
}) => {

    var theCourse;
    courseOptions.map(courseOption => {
        if(courseOption.buyTitle === myCourse.id){
            theCourse = courseOption;
        }
    })
    
    
    return (<div>
        <Link to={`/courses/${theCourse.id}`}>
            <Card
                className={classes.cardStyle}
            >
                <p
                    className={classes.midtermPrep}
                >Midterm Prep</p>
                <div
                    style={{
                        backgroundImage: `url(${theCourse.avatar})`,
                    }}
                    className={classes.avatarStyle}
                >
                    {/* <div
                                style={nameStyle}
                            >
                                {grade}
                            </div>
                            <div
                                style={levelStyle}
                            >
                                {level}
                            </div> */}
                </div>
                <div className={classes.infoStyle1}>
                    <p style={{
                        fontFamily: 'proxima-nova, sans-serif ',
                        fontSize: '1rem',
                        fontWeight: 300
                    }}>
                        <div>{theCourse.title}</div>
                        {/* <div>{courselist.level.length > 10 ? `${courselist.level.substring(0, 12)}....` : courselist.level}</div> */}

                    </p>
                </div>
            </Card>
        </Link>
    </div>
    )
}

export default withStyles(styles)(MyCoursesList);
