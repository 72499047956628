import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { courseOptions } from "../../app/common/courseOptions";

//Material UI
import { Grid } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';

const styles = theme => ({
    cardStyle: {
        width: '90%',
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 20,
        minHeight: '320px',
        maxHeight: '320px',
        boxShadow: 'none',
        color: '#585c60',
        border: '1px solid #e1e1e6',
        borderRadius: 10,
        fontFamily: 'proxima-nova, sans-serif ',
        [theme.breakpoints.down('xs')]: {
            minHeight: '320px',
            maxHeight: '320px',
        }
    },
    midtermPrep: {
        textAlign: 'center',
        paddingTop: 5,
        paddingBottom: 5,
        marginBottom: 5,
        backgroundColor: 'white',
        zIndex: 5,
        fontFamily: 'proxima-nova, sans-serif ',
        fontWeight: 'bold',
        fontSize: '1.3rem',
        letterSpacing: '1px',
        borderBottom: '1px solid #e1e1e6'
    },
    courseOptionlist: {
        borderRadius: 0,
        width: '100%',
        height: '170px'
    },

    nameStyle: {
        fontSize: '1.3rem',
        fontWeight: 600,
        marginBottom: 0,
        display: 'block',
        color: '#fff',
        fontFamily: 'proxima-nova, sans-serif ',
        width: '90%',
        paddingTop: 15,
        marginRight: 'auto',
        marginLeft: 'auto'
    },
    levelStyle: {
        color: '#fff',
        fontSize: '1.1rem',
        fontFamily: 'proxima-nova, sans-serif ',
        width: '90%',
        marginRight: 'auto',
        marginLeft: 'auto'
    },
    avatarStyle: {
        borderRadius: 0,
        width: '100%',
        cursor: 'pointer'
    },
    infoStyle: {
        width: '100%',
        height: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    infoStyle1: {
        width: '90%',
        marginTop: 15,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    cityStyle: {
        marginLeft: 0
    },
    btnStyle: {
        display: 'block',
        color: '#fff',
        backgroundColor: '#4ca7ab',
        borderRadius: 5,
        border: 'none',
        fontWeight: 600,
        width: '80%',
        marginRight: 'auto',
        marginLeft: 'auto',
        marginTop: 10,
        textAlign: 'center',
        fontSize: '1rem',
        cursor: 'pointer',
        fontFamily: 'proxima-nova, sans-serif ',
        '&:hover': {
            color: '#fff',
            backgroundColor: '#4ca7ab',
        }
    },
    gradeStyle: {
        textAlign: 'center',
        marginBottom: 5,
        fontFamily: 'proxima-nova, sans-serif ',
        fontWeight: 'bold',
        color: '#585c60',
        fontSize: '1.3rem',
        letterSpacing: '1px'
    },
    courseNameStyle: {
        textAlign: 'center',
        marginBottom: 5,
        fontFamily: 'proxima-nova, sans-serif ',
        color: '#ADB2BA',
        fontSize: '0.8rem',
        letterSpacing: '1px',
    }
})


const CourseListItems = ({
    classes,
    courseOption,
    auth,
    firebase,
    admin,
    profile
}) => {

    const saveToDatabase = async () => {

        // console.log('auth', auth)
        // console.log('admin', admin)

        if (!auth.isEmpty) {
            if (!admin) {
                firebase.firestore().collection('courseClick').doc(`${Date.now()}`).set({
                    a_name: auth.displayName || `${profile.firstName} ${profile.lastName}`,
                    b_level: courseOption.grade,
                    uid: auth.uid,
                    a_date: new Date(Date.now())
                })
            }
        } else {
            return firebase.firestore().collection('courseClick').doc(`${Date.now()}`).set({
                a_name: 'Not LoggedIn',
                b_level: courseOption.grade,
                a_date: new Date(Date.now())
            })
        }

    }

    return (
        <Card
            className={classes.cardStyle}
        >
            <p
                className={classes.midtermPrep}
            >Test Prep</p>
            <div
                style={{
                    backgroundImage: `url(${courseOption.avatar})`,
                    width: '100%',
                    height: '150px',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    zIndex: -1

                }}
                className={classes.infoStyle}>
                {/* <div
                    className={classes.nameStyle}
                >
                    {courseOption.grade}
                </div>
                <div
                    className={classes.levelStyle}
                >
                    {courseOption.level || <br />}
                </div> */}
            </div>
            <div className={classes.infoStyle1}>


                <Typography
                    className={classes.gradeStyle}
                >
                    {courseOption.grade}
                </Typography>
                <Typography
                    className={classes.courseNameStyle}>
                    {courseOption.courseName}
                </Typography>
                <Button
                    onClick={saveToDatabase}
                    className={classes.btnStyle}
                    component={Link}
                    to={`/courses/${courseOption.id}`}
                >View the Course</Button>
            </div>
        </Card>
    )
}

export default withStyles(styles)(CourseListItems)