import * as types from './profileActions';

const initState = {
    isReviewFetching: false,
    isGetReviewFetching: false,
    isFetching: false,
    me: '',
    current: '',
    hasProfile: false,
    reviews: [],
    error: ''
}

const profileReducer = (state = initState, action) => {
    switch (action.type) {
        // GET PROFILE
        case types.GET_PROFILE_REQUEST:
            return {
                ...state,
                isFetching: true
            }
        case types.GET_PROFILE_SUCCESS:
            return {
                ...state,
                current: action.current,
                isFetching: false
            }
        case types.SET_EMPTY_PROFILE:
            return {
                ...state,
                isFetching: false,
                current: ""
            }
        case types.GET_PROFILE_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
            }
        // CREATE PROFILE
        case types.CREATE_PROFILE_REQUEST:
            return {
                ...state,
                isFetching: true,
            }
        case types.CREATE_PROFILE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                hasProfile: true,
            }
        case types.CREATE_PROFILE_ERROR:
            return {
                ...state,
                isFetching: false,
            }
        // CREATE REVIEW
        case types.CREATE_REVIEW_REQUEST:
            return {
                ...state,
                isReviewFetching: true,
            }
        case types.CREATE_REVIEW_SUCCESS:
            return {
                ...state,
                isReviewFetching: false,
            }
        case types.CREATE_REVIEW_ERROR:
            return {
                ...state,
                isReviewFetching: false,
            }
        // GET REVIEWS
        case types.GET_REVIEWS_REQUEST:
            return {
                ...state,
                isGetReviewFetching: true,
            }
        case types.GET_REVIEWS_SUCCESS:
            return {
                ...state,
                isGetReviewFetching: false,
                reviews: action.reviews
            }
        case types.GET_REVIEWS_ERROR:
            return {
                ...state,
                isGetReviewFetching: false,
            }
        default:
            return {
                ...state,
                error: action.error
            }
    }
}

export default profileReducer