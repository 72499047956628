import React from 'react';
import { NavLink, Link } from 'react-router-dom'
import Typography from '@material-ui/core/Typography';
import { Segment, Header, Form, Divider, Label, Button, Icon } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import { combineValidators, matchesField, isRequired, composeValidators } from 'revalidate'
import TextInput from '../../../app/common/form/TextInput';
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  basicbox: {
    marginTop: 150,
    width: "95%",
    marginLeft: "auto",
    marginRight: "auto",
    boxShadow: 'none',
    [theme.breakpoints.down("xs")]: {
      paddingTop: 10,
      width: "100%",
      marginTop: 100

    },
  },
  headerbg: {
    margin: '5% 5% 3% 5%',


  },
  displayname: {
    marginTop: '2%',


  },
  bttn: {
    backgroundColor: '#4ca7ab'
  },
  whitebar: {
    width: 50,
    backgroundColor: 'white',
  },

})


const validate = combineValidators({
  newPassword1: isRequired({ message: 'please enter a password' }),
  newPassword2: composeValidators(
    isRequired({ message: 'Please confirm your new password' }),
    matchesField('newPassword1')({ message: 'Passwords do not match' })
  )()
})

const AccountPage = ({ error, invalid, submitting, handleSubmit, updatePassword, providerId, classes, initialValues }) => {

  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <Card className={classes.basicbox}>
          <div >
          <Link style={{ float: 'left', marginLeft: 20, marginRight: 20 }} as={NavLink} to='/settings' ><Typography className={classes.basic}>Basics</Typography></Link>
            <span style={{ float: 'left', marginRight: 20 }}> > </span>
            <b className={classes.basic}>Account </b>
          </div>
          <div className={classes.headerbg}>
            <Header style={{ color: '#585c60', marginBottom: 40 }} size="large" content="Account" />
            {providerId && providerId === 'password' &&
              <div>
                <Header color="#585c60" sub content="Change password" />
                <p>Use this form to update your account settings</p>
                <Form onSubmit={handleSubmit(updatePassword)}>
                  <Field
                    width={8}
                    name="newPassword1"
                    type="password"
                    pointing="left"
                    inline={true}
                    component={TextInput}
                    basic={true}
                    placeholder="New Password"
                  />
                  <Field
                    width={8}
                    name="newPassword2"
                    type="password"
                    inline={true}
                    basic={true}
                    pointing="left"
                    component={TextInput}
                    placeholder="Confirm Password"
                  />
                  {error && (
                    <Label basic color="red">
                      {error}
                    </Label>
                  )}
                  <Divider />
                  <Button disabled={invalid || submitting} size="large" positive content="Update Password" />

                  <Divider />
                  {/* <Header color="#585c60" sub content="Change password" />
                  <p>Please click this button if you have not recieved a confirmation email</p>
                  <Button size="large" positive content="Send Verification Email" /> */}
                </Form>
              </div>}

            {providerId && providerId === 'facebook.com' &&
              <div>
                <Header color="teal" sub content="Facebook Account" />
                <p>Please visit Facebook to update your account settings</p>
                <a href='https://www.facebook.com/'>
                  <Button type="button" color="facebook">
                    <Icon name="facebook" />
                    Go to Facebook
                  </Button>
                </a>
              </div>}

            {providerId && providerId === 'google.com' &&
              <div>
                <Header color="teal" sub content="Google Account" />
                <p>Please visit Google to update your account settings</p>
                <a href='https://myaccount.google.com/'>
                  <Button type="button" color="google plus">
                    <Icon name="google plus" />
                    Go to Google
                </Button>
                </a>
              </div>}
          </div>
        </Card>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(reduxForm({ form: 'account', validate })(AccountPage));