import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

//Material UI
import { Grid } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';

const styles = theme => ({
    cardStyle: {
        textAlign: 'left',
        padding: '30px',
        color: '#585c60',
        marginTop: 20,
        marginLeft: '40px',
        maxWidth: '400px',
        display: 'block',
        border: '1px solid #e1e1e6',
        borderRadius: 10,
        boxShadow: 'none',
        [theme.breakpoints.down('xs')]: {
            display: 'hidden'
        }
    },
    headerStyle: {
        fontSize: '1.8rem',
        marginBottom: 15,
        fontWeight: 600,
        color: '#585c60',
        fontFamily: 'proxima-nova, sans-serif ',
    },
    descStyle: {
        fontSize: '1.1rem',
        marginBottom: 10,
        color: '#585c60',
        fontFamily: 'proxima-nova, sans-serif ',
    },
    btnStyle: {
        backgroundColor: '#4ca7ab',
        fontWeight: 600,
        width: '100%',
        color: 'white',
        fontSize: '1.1rem',
        letterSpacing: '2px',
        marginTop: 15,
        fontFamily: 'proxima-nova, sans-serif ',
        '&:hover': {
            backgroundColor: '#4ca7ab',
            color: 'white'

        }
    }

})


const LookingTutor = ({
    classes,

}) => {

    return (
        <Card
            className={classes.cardStyle}
        >
            <Typography className={classes.headerStyle}>
                Are you looking for tutor?
          </Typography>
            <Typography className={classes.descStyle}>
                Meet the best math tutors in GTA area. All our tutors went through strict filtering process and we are confident that our tutors can help you achieve your academic goal.
          </Typography>
            <Button
                component={Link}
                to='/'
                className={classes.btnStyle}>
                FIND
          </Button>

        </Card>
    )
}

export default withStyles(styles)(LookingTutor)