import React, { Component } from "react";
import { Button, Divider, Form, Header } from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import { NavLink, Link } from 'react-router-dom'
import RadioInput from "../../../app/common/form/RadioInput";
import TextInput from "../../../app/common/form/TextInput";
import TextArea from "../../../app/common/form/TextArea";
import PlaceInput from "../../../app/common/form/PlaceInput";
import SelectInput from "../../../app/common/form/SelectInput";
import Script from 'react-load-script';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  basicbox: {
    marginTop: 150,
    width: "95%",
    marginLeft: "auto",
    marginRight: "auto",
    boxShadow: 'none',
    [theme.breakpoints.down("xs")]: {
      paddingTop: 10,
      width: "100%",
      marginTop: 100

    },
  },
  headerbg: {
    margin: '3% 5% 3% 5%',


  },
  displayname: {
    marginTop: '2%',


  },
  bttn: {
    backgroundColor: '#4ca7ab'
  },
  whitebar: {
    width: 50,
    backgroundColor: 'white',
  },

})

const interests = [
  { key: "drinks", text: "Drinks", value: "drinks" },
  { key: "culture", text: "Culture", value: "culture" },
  { key: "film", text: "Film", value: "film" },
  { key: "food", text: "Food", value: "food" },
  { key: "music", text: "Music", value: "music" },
  { key: "travel", text: "Travel", value: "travel" }
];

const getSuggestions = value => {

  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  return inputLength === 0 ? [] : interests.filter(interest =>
    interest.value.toLowerCase().slice(0, inputLength) === inputValue
  );
};

const getSuggestionValue = suggestion => suggestion.value;

const renderSuggestion = suggestion => (
  <div>
    {suggestion.value}
  </div>
);

class AboutPage extends Component {

  state = {
    value: '',
    suggestions: [],
    scriptLoaded: false,
  }

  handleScriptLoaded = () => this.setState({ scriptLoaded: true });

  handleCitySelect = selectedCity => {
    const address = selectedCity.formatted_address;
    //const addressArray = selectedCity.address_components;
    const latValue = selectedCity.geometry.location.lat();
    const lngValue = selectedCity.geometry.location.lng();
    this.props.change("origin", address);
    this.setState({ lat: latValue, lng: lngValue });
    // console.log(address, this.state.cityLatLng);
  };


  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: getSuggestions(value)
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };


  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,

    });

  };


  render() {
    const { onChange, pristine, submitting, handleSubmit, updateProfile, classes } = this.props;
    const { value, suggestions, scriptLoaded } = this.state;

    const inputProps = {
      placeholder: "Select your interests",
      value,
      onChange: onChange
    };
    return (

      <React.Fragment>
        <Script
          url="https://maps.googleapis.com/maps/api/js?key=AIzaSyC_B3o6e4mHhWjdZij2lYWPqK_Tw5O_jC8&libraries=places&language=en"
          onLoad={this.handleScriptLoad}
        />
        <Grid container>
          <Grid item xs={12} md={12}>

            <Card className={classes.basicbox}>
              <div >
              <Link style={{ float: 'left', marginLeft: 20, marginRight: 20 }} as={NavLink} to='/settings' ><Typography className={classes.basic}>Basics</Typography></Link>
                <span style={{float: 'left', marginRight: 20}}> > </span>
                <b className={classes.basic}>About Me</b>
              </div>
              <div className={classes.headerbg}>
                <Header style={{ color: '#585c60' }} dividing size="large" content="About Me" />
                <p>Complete your profile to get the most out of this site</p>
                <Form onSubmit={handleSubmit(updateProfile)}>

                  <label>Tell us about yourself</label>
                  <Field name="about" component={TextArea} placeholder="About Me" />

                  <label >Occupation</label>
                  <Field className={classes.lable}
                    width={8}
                    name="occupation"
                    type="text"
                    component={TextInput}
                    placeholder="Occupation"
                  />
                  {/* <label className={classes.lable}>Country of origin</label>
                  <Field className={classes.lable}
                    width={8}
                    name="origin"
                    scriptLoaded={scriptLoaded}
                    component={PlaceInput}
                    onSelect={this.handleCitySelect}
                    placeholder="Country of Origin"
                  /> */}
                  <Divider />
                  <Button
                    disabled={pristine || submitting}
                    size="large"
                    positive
                    content="Update Profile"
                  />
                </Form>
              </div>
            </Card>
          </Grid>
        </Grid>
      </React.Fragment>


    );
  }
};

export default withStyles(styles)(reduxForm({
  form: "userProfile",
  enableReinitialize: true,
  destroyOnUnmount: false
})(AboutPage));
