import React from "react";
import { Item, List, Image } from "semantic-ui-react";
import { Link } from "react-router-dom";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';


const style = (theme) => ({
  subjectTitle: {
    fontSize: '1.5rem',
    marginLeft: 20,
    marginBottom: 10,
    paddingBottom: 20,
    borderBottom: '1px solid #e1e1e6'
  },
  cardStyle: {
    paddingTop: 20,
    paddingRight: 20,
    borderTop: '5px solid #4ca7ab',
    marginTop: 20,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      borderRadius: 0,
      boxShadow: 'none'
    }
  },
  icons1: {
    borderRadius: 0,
    marginBottom: '40px'

  },
  icons2: {
    borderRadius: 0,
    marginBottom: '55px',
    [theme.breakpoints.up('xs')]: {
      marginBottom: '10px'
    },

  },
  items: {
    marginBottom: '20px'
  },
  veri: {
    marginTop: 60,
    [theme.breakpoints.down('sm')]: {
      marginTop: 40
    }
  }

})

const TutorProfileRecommendation = ({
  tutorProfile,
  attendees,
  authenticated,
  openModal,
  loading,
  classes
}) => {
  return (
    <Card className={classes.cardStyle}>
      <Typography className={classes.subjectTitle} style={{ color: '#585c60' }}>There are more badges you can have
      </Typography>

      <CardContent style={{ marginLeft: 10 }}>
        <List relaxed divided>

          <div>
            <Grid container >
              <Grid className={classes.items} item xs={3} md={3} >
                <Avatar className={classes.icons1} src="/assets/Education.png" alt="Education"  >
                </Avatar>
                <Avatar className={classes.icons2} src="/assets/Teacher.png" alt="Education"  >
                </Avatar>
                <Avatar className={classes.veri} src="/assets/Verified.png" alt="Education"  >
                </Avatar>
              </Grid>

              <Grid className={classes.items} item xs={9} md={9}>
                <lable style={{ fontWeight: 600, color: '#585c60', fontSize: '1em' }}>Education Badge</lable>
                <div className={classes.items} style={{ color: '#585c60',fontSize: '0.8em' }}>Are You a Student or Graduated? Send us a proof!</div>

                <lable style={{ fontWeight: 600, color: '#585c60' }}>Professional Badge</lable>
                <div className={classes.items} style={{ color: '#585c60', fontSize: '0.8em' }}>Are You a Professional Teacher? This badge is for you</div>

                <lable style={{ fontWeight: 600, color: '#585c60' }}>Basic Badge</lable>
                <div style={{ color: '#585c60', fontSize: '0.8em' }}>The Easiest Way to be Recognized. All you need is 5 minutes phone call!</div>
              </Grid>
            </Grid>
          </div>
        </List>
      </CardContent>
    </Card>
  );
};

export default withStyles(style)(TutorProfileRecommendation);
