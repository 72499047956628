import React, { Component } from "react";
import firebase from "../../../../app/config/firebase";
import { Form, Segment, Button, Input } from "semantic-ui-react";
import { withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";

const styles = theme => ({
  buttonStyle: {
    padding: '8px 20px',
    fontSize: '1.2rem',
    borderRadius: 10,
    backgroundColor: '#4ca7ab',
    color: '#fff',
    display: 'inline',
    border: 'none',
    cursor: 'pointer',
    "&:hover": {
      backgroundColor: "#0A4065",
      color: "#ffffff"
    }

  }
});

var rootRef = firebase.database().ref();



class ChatMessagesForm extends Component {
  state = {
    message: "",
    loading: false,
    user: this.props.currentUser,
    errors: []
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  createMessage = () => {
    const { user } = this.state;
    const { userProfile } = this.props;
    const message = {
      timestamp: firebase.database.ServerValue.TIMESTAMP,
      user: {
        id: user.auth.uid,
        name: userProfile.displayName || userProfile.firstName,
        avatar:
          userProfile.photoURL || userProfile.avatarUrl|| "/assets/user.png"
      },
      content: this.state.message,
      read: false
    };

    return message;
  };

  sendMessage = (event) => {
    const { chatsRef, currentChannel } = this.props;
    const { message, user } = this.state;

    if (message) {
      this.setState({ loading: true });

      chatsRef
        .child(currentChannel)
        .child("messages")
        .push()
        .set(this.createMessage())
        .then(() => {
          
          this.setState({
            loading: false,
            message: "",
            errors: []
          });
        })
        .catch(err => {
          console.error(err);
          this.setState({
            loading: false,
            errors: this.state.errors.concat(err)
          });
        });
        event.preventDefault();
    } else {
      this.setState({
        errors: this.state.errors.concat({ message: "Add a Message" })
      });
    }
  };

  render() {
    const { classes } = this.props;
    const { errors, message, loading } = this.state;

    return (
      <div>
        {/* <Segment className={classes.message__form}> */}
        {/* Error Message ClassName can be found in video28 */}
        <Divider style={{ marginBottom: 20 }} />
        <Form onSubmit={this.sendMessage}  >
          <Input
            fluid
            name='message'
            onChange={this.handleChange}
            value={message}
            style={{ width: '90%', marginRight: 'auto', marginLeft: 'auto' }}
            label={
              <button
                disabled={loading}
                onClick={this.sendMessage}
                className={classes.buttonStyle}
              >SEND
                </button>}
            labelPosition='right'
            placeholder='Write Your Message'
            onSubmit={this.sendMessage}
          />
        </Form>
      </div>
    );
  }
}

export default withStyles(styles)(ChatMessagesForm);
