import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles'
import StarRatings from 'react-star-ratings';
import ReviewList from '../../../features/tutors/TutorProfile/TutorProfileReview';

const styles = theme => ({
    card: {
        fontFamily: 'proxima-nova, sans-serif ',
        maxWidth: 800,
        maxHeight: 800,
        // borderTop: '5px solid #4ca7ab',
        [theme.breakpoints.down('xs')]: {
            marginRight: 10,
            marginLeft: 10,
            padding: 0
        }
    },
    ResumeUserInfoReview: {
        display: 'block',
        fontSize: '1.7rem',
        marginLeft: '6%',
        marginTop: 10,
        marginBottom: 20,
        textTransform: 'uppercase',
        float: 'left',
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '10%'
        }
    },
    btnStyle: {
        float: 'right',
        width: 120,
        borderRadius: 5,
        backgroundColor: "#4ca7ab",
        color: 'white',
        float: 'right',
        marginRight: '6%',
        marginTop: 10,
        "&:hover": {
            backgroundColor: '#57AAE3'
        }
    },
    textField: {
        marginTop: 10,
        marginLeft: '6%',
        width: '40%',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        }

    },
    rateStars: {
        marginLeft: '6%',
    },
    reviewTitle: {
        marginTop: 10,
        margin: 0,
        fontSize: '1.4rem'
    },
    textFielddesc: {
        width: '88%',
        marginLeft: '6%',
        marginRight: 'auto'
    }
})

const ResumeUserReviews = ({
    classes,
    rating,
    changeRating,
    handleChange,
    handleReviewSubmit
}) => {

    return (
        <Card className={classes.card}>
            <CardContent>
                <div className={classes.hrStyle}>
                    <div>
                        <Typography variant="h5" component="h2" className={classes.ResumeUserInfoReview}>
                            Reviews
                    </Typography>
                    </div>
                    <div>
                        <div className={classes.rateStars}>
                            <StarRatings
                                rating={rating}
                                starRatedColor="yellow"
                                starHoverColor="yellow"
                                starDimension='25px'
                                changeRating={changeRating}
                                numberOfStars={5}
                                name='reviewRating'
                            />
                        </div>
                        <div>
                            <TextField
                                fullWidth
                                id="reviewTitle"
                                name="reviewTitle"
                                className={classes.textField}
                                margin="normal"
                                variant="outlined"
                                onChange={(e) => handleChange(e)}
                                placeholder='Summarize your review in one sentence'
                            />
                        </div>
                    </div>

                    <TextField
                        multiline
                        rows="4"
                        id="reviewContent"
                        name="reviewContent"
                        className={classes.textFielddesc}
                        margin="normal"
                        variant="outlined"
                        onChange={(e) => handleChange(e)}
                        placeholder='Summarize your review in one sentence'

                    />
                    <Button
                        variant="contained"
                        className={classes.btnStyle}
                        onClick={handleReviewSubmit}
                    >
                        SUBMIT
                        </Button>
                </div>


                <Typography className={classes.ResumeUserInfoReview}></Typography>
            </CardContent>
        </Card>
    );
}

export default withStyles(styles)(ResumeUserReviews);