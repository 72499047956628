import firebase from "../../../app/config/firebase";
import { toastr } from "react-redux-toastr";
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError
} from "../../async/asyncActions";
import _ from "lodash";
import Datasort from 'react-data-sort'

export const SEARCH_BY_REQUEST = "SEARCH_BY_REQUEST";
export const SEARCH_BY_SUCCESS = "SEARCH_BY_SUCCESS";
export const SEARCH_BY_ERROR = "SEARCH_BY_ERROR";

export const POST_SEARCH_REQUEST = "POST_SEARCH_REQUEST";
export const POST_SEARCH_SUCCESS = "POST_SEARCH_SUCCESS";
export const POST_SEARCH_ERROR = "POST_SEARCH_ERROR";

export const SEARCH_BY = "SEARCH_BY";

export const SEARCH_PAGINATE = "SEARCH_PAGINATE"
export const FILTER_SEARCH_LEVELS = "FILTER_SEARCH_LEVELS"

/*
 * Search data by
 * - multiple languages
 * - one subject
 * - one location
 */

function distance(lat1, lon1, lat2, lon2, unit) {
  if ((lat1 == lat2) && (lon1 == lon2)) {
    return 0;
  }
  else {
    var radlat1 = Math.PI * lat1 / 180;
    var radlat2 = Math.PI * lat2 / 180;
    var theta = lon1 - lon2;
    var radtheta = Math.PI * theta / 180;
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;
    dist = dist * 60 * 1.1515;
    //DEFAULT UNIT IS M (MILE)
    if (unit == "K") { dist = dist * 1.609344 }
    if (unit == "N") { dist = dist * 0.8684 }
    return dist;
  }
}

export const searchByRequest = (payload) => {

  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch(asyncActionStart());
    dispatch({ type: POST_SEARCH_REQUEST });
    const { langList, level, location } = payload;
    // console.log("SearchByRequest", payload);
    const firestore = getFirestore();
    // console.log('searchAction',level)
    try {
      // query languages
      let result = [];
      let docIds = [];
      let dbPromises = [];

      //reference to the documents that matches the subject and language query
      _.forEach(langList, (lang) => {
        let profileRef = firestore
          .collection("profiles")
          .where(`levelList`, "array-contains", `${level}`)
          .where(`languages.${lang}`, "==", true)
          .where('cancelled', "==", false)

        
        dbPromises.push(
          profileRef.get().then(snapshot => {
            snapshot.docs.forEach(async (doc) => {
              // console.log("Doc in SearchAction",doc.data())
              const dist = distance(doc.data().location.latitude, doc.data().location.longitude, location.lat, location.lng, "K")
              // console.log("The distance: ",dist)
              // const dist = 25
              let merged;
              if(payload.distance >= 0){
                if (dist <= payload.distance) {
                  
                  if(!docIds.includes(doc.id)){
                    // console.log(payload.price)
                    if(payload.price){
                      let count = 1;



                      for (let [key, value] of Object.entries(doc.data().levels)) {
                        // console.log("key",key)
                        // console.log("value",value)
                        if(value.id === level && value.price >= payload.price.priceLow && value.price  <= payload.price.priceHigh){
                          // console.log(`${key}: ${value.price}`);
                          // console.log("im not going to appear")
                          if(count ===1){
                            await docIds.push(doc.id)
                            merged = { ...doc.data(), searchedLevel: `${level}` };
                            await result.push(merged)
                          }
                          count++
                        }
                      }



                    }else {
                      await docIds.push(doc.id)
                      merged = { ...doc.data(), searchedLevel: `${level}` };
                      await result.push(merged)
                    }
                    // console.log(merged)
                  }
                }
              }
            })
          })
        )
      })

      Promise.all(dbPromises)
        .then(() => {
          dispatch({
            type: POST_SEARCH_SUCCESS,
            result,
            level
          });
        })
        .catch(error => {
          const { message } = error;
          dispatch({ type: POST_SEARCH_ERROR, message });
        });
      dispatch(asyncActionFinish());

    } catch (error) {
      console.log(error);
      dispatch(asyncActionError());
      toastr.error("Oops", "Something went wrong");
      this.props.history.goBack();
    }
  };
};

export const setSearchPageNumber = (state, pageNumber) => {
  return {
    type: SEARCH_PAGINATE,
    state,
    payload: {
      searchPage: pageNumber
    }
  }
}

export const filterLevels = (state, levels, filteredList) => {
  return {
    type: FILTER_SEARCH_LEVELS,
    state,
    payload: {
      levels,
      filteredList
    }
  }
}