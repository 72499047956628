import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux'
import { NavLink, Link } from 'react-router-dom'
import Typography from '@material-ui/core/Typography';
import {
  Image,

  Header,
  Divider,
  Button,

  Icon
} from "semantic-ui-react";
import Dropzone from "react-dropzone";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { toastr } from "react-redux-toastr";
import { uploadProfileImage, deletePhoto, setMainPhoto } from "../userAction";
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';


const styles = theme => ({
  basicbox: {
    marginTop: 150,
    width: "95%",
    marginLeft: "auto",
    marginRight: "auto",
    boxShadow: 'none',
    [theme.breakpoints.down("xs")]: {
      paddingTop: 10,
      width: "100%",
      marginTop: 100

    },
  },
  headerbg: {
    margin: '5% 5% 3% 5%',


  },
  displayname: {
    marginTop: '2%',


  },
  bttn: {
    backgroundColor: '#4ca7ab'
  },
  whitebar: {
    width: 50,
    backgroundColor: 'white',
  },
  imageStyle: {
    maxHeight: "180px",
    maxWidth: "180px",
    marginBottom: 20,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 'auto',
      marginRight: 'auto'
    }

  },
  headerStyle:{
    fontSize: '1.6rem',
    color: '#585c60'
  },
  descStyle: {
    marginTop: 40,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center'
    }
  },
  btnStyle: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center'
    }
  }


})

const query = ({ auth }) => {
  return [{
    collection: 'users',
    doc: auth.uid,
    subcollections: [{ collection: 'photos' }],
    storeAs: 'photos'
  }]
}

const actions = {
  uploadProfileImage,
  deletePhoto,
  setMainPhoto
};

const mapState = (state) => ({
  auth: state.firebase.auth,
  profile: state.firebase.profile,
  photos: state.firestore.ordered.photos,
  loading: state.async.loading
})

class PhotosPage extends Component {
  state = {
    files: [],
    fileName: "",
    cropResult: null,
    image: {}
  };

  uploadImage = async () => {
    try {
      await this.props.uploadProfileImage(
        this.state.image,
        this.state.fileName
      );
      this.cancelCrop();
      toastr.success("Success!", "Photo has been uploaded");
    } catch (error) {
      toastr.error("Oops", error.message);
    }
  };

  handleSetMainPhoto = photo =>
    async () => {
      try {
        await this.props.setMainPhoto(photo)
        toastr.success("Success!", "Photo has been set as a main");
      } catch (error) {
        toastr.error('Oops', error.message)
      }
    }

  cancelCrop = () => {
    this.setState({
      files: [],
      image: {}
    });
  };

  handlePhotoDelete = (photo) =>
    async () => {
      try {
        toastr.success('Success!', 'Photo successfully deleted')
        this.props.deletePhoto(photo);
      } catch (error) {
        toastr.error('Oops', error.message)
      }
    }

  cropImage = () => {
    if (typeof this.refs.cropper.getCroppedCanvas() === "undefined") {
      return;
    }

    this.refs.cropper.getCroppedCanvas().toBlob(blob => {
      let imageUrl = URL.createObjectURL(blob);
      this.setState({
        cropResult: imageUrl,
        image: blob
      });
    }, "image/jpeg");
  };

  onDrop = files => {
    this.setState({
      files,
      fileName: files[0].name
    });
  };

  render() {
    const { photos, profile, loading, classes } = this.props;
    let filteredPhotos;
    if (photos) {
      filteredPhotos = photos.filter(photo => {
        return photo.url !== profile.photoURL;
      })
    }
    return (
      <Grid container>
        <Grid item xs={12} md={12}>
          <Card className={classes.basicbox}>
            <div >
              <Link style={{ float: 'left', marginLeft: 20, marginRight: 20 }} as={NavLink} to='/settings' ><Typography className={classes.basic}>Basics</Typography></Link>
              <span style={{ float: 'left', marginRight: 20 }}> > </span>
              <b className={classes.basic}>Your Photos </b>
            </div>
            <div className={classes.headerbg}>
            <Header style={{ color: '#585c60', marginBottom: 30 }} size="large" content="My Photo" />
              <Grid container spacing={24}>
                <Grid item xs={12} md={6}>
                  <p className={classes.descStyle}  >Step 1 - Add Photo</p>
                  <Dropzone className={classes.imageStyle} onDrop={this.onDrop} multiple={false}>
                    <div style={{ paddingTop: "20px", textAlign: "center" }}>
                      <Icon name="upload" size="huge" />
                    </div>
                  </Dropzone>

                </Grid>
                <Grid item xs={12} md={6}>
                  <p className={classes.descStyle}  >Step 2 - Resize image</p>
                  {this.state.files[0] && (
                    <Cropper
                      className={classes.imageStyle}
                      ref="cropper"
                      src={this.state.files[0].preview}
                      aspectRatio={1}
                      viewMode={0}
                      dragMode={"move"}
                      guides={false}
                      scalable={true}
                      cropBoxMovable={true}
                      cropBoxResizable={true}
                      crop={this.cropImage}
                    />
                  )}
                </Grid>
                {/* <Grid item xs={12} md={4}>
                  <Header className={classes.descStyle} sub color="#585c60" content="Step 3 - Preview and Upload" />
                  {this.state.files[0] && (
                    <div className={classes.btnStyle}>
                      <Image
                        className={classes.imageStyle}
                        src={this.state.cropResult}
                      />
                    </div>
                  )}

                </Grid> */}

              </Grid>
              {this.state.files[0] && (
                <div className={classes.btnStyle}>
                  <Button.Group>
                    <Button loading={loading} onClick={this.uploadImage} style={{ width: '100px', backgroundColor: '#4ca7ab', color: 'white' }} icon='check' />
                    <Button disabled={loading} onClick={this.cancelCrop} style={{ width: '100px', backgroundColor: '#fff', border: '1px solid #ff5d5d', color: '#ff5d5d' }} icon='close' />
                  </Button.Group>
                </div>
              )}

              <p className={classes.descStyle}  >Your Photo</p>

              <Image className={classes.imageStyle} src={profile.photoURL || '/assets/user.png'} />
              <div className={classes.btnStyle}>
                <Button positive>Main Photo</Button>
              </div>

              {photos && filteredPhotos.map((photo) => (
                <div style={{ marginTop: 20 }} key={photo.id}>
                  <Image className={classes.imageStyle} src={photo.url} />
                  <div className="ui two buttons">
                    <Button loading={loading} onClick={this.handleSetMainPhoto(photo)} basic color="green">
                      Main
                </Button>
                    <Button onClick={this.handlePhotoDelete(photo)} basic icon="trash" color="red" />
                  </div>
                </div>
              ))}
            </div>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(compose(
  connect(mapState, actions),
  firestoreConnect(auth => query(auth))
)(PhotosPage));
