import React from 'react';
import firebase from '../../../../app/config/firebase';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux'
import ChatMessages from './ChatMessages';

const mapState = (state) => ({
  currentUser: state.firebase,
  userProfile: state.firebase.profile,
})

const notMyId = async (currentUser, match) => {
  const firestore = firebase.firestore();
  let id = match.params.id;
  const ids = id.split('_');
  let result;
  let name = ''
  for (let i = 0; i < 2; i++) {
    if (ids[i] !== currentUser.auth.uid) {
      const id = ids[i];
      await firebase.firestore().collection('users').doc(id).get().then(snapshot => {
        result = {
          ...snapshot.data(),
          id: id
        };
      })
    }
  }

  return result;
}

const theme = {
  vars: {
    'primary-color': '#427fe1',
    'secondary-color': '#fbfbfb',
    'tertiary-color': '#fff',
    'avatar-border-color': 'blue',
  },
  AgentBar: {
    Avatar: {
      size: '42px',
    },
    css: {
      backgroundColor: 'var(--secondary-color)',
      borderColor: 'var(--avatar-border-color)',
    }
  },
  Message: {
    css: {
      fontWeight: 'bold',
    },
  },
}

const ChatPage = ({ currentUser, userProfile, match, setCurrentChannel }) => {
  const receiver = notMyId(currentUser, match);
  const regex = new RegExp(currentUser.auth.uid, 'gi');

  if (match.params.id.match(regex)) {
    return (
      <ChatMessages
        receiver={receiver}
        currentUser={currentUser}
        userProfile={userProfile}
        currentChannel={match.params.id}
      />
    )
  } else {
    return <Redirect to='/' />
  }

}

export default connect(mapState)(ChatPage)