import * as actionTypes from './tabFocusConstants';

const initialTabState = {
    isTabFocused: true,
}

const tabFocusReducer = (state = initialTabState, action) => {
    switch(action.type) {
      case actionTypes.FOCUS_TAB:
        return {
          ...state,
          isTabFocused:action.payload.isTabFocused
        }
      case actionTypes.BLUR_TAB:
        return {
          ...state,
          isTabFocused:action.payload.isTabFocused
        }
      
      default:
        return state;
    }
  }

  export default tabFocusReducer