import React, { Component } from 'react';
import { Modal } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withStyles } from "@material-ui/core/styles";

import { closeModal, openModal } from "./modalActions";



const actions = { closeModal, openModal };

const styles = theme => ({
    modalStyle: {

    },
    congFont: {
        fontSize: '2em',
        marginBottom: '10px'
    },
    nowTutorFont: {
        fontSize: '1.1em',
        color: '#47a7ac',
    },
    descHeader: {
        fontSize: '1.2rem',
      
    },
    headerStyle: {
        textAlign: 'center',
        paddingTop: '40px',
        paddingBottom: '40px',
    },
    emailStyle: {
        color: '#4ca7ab',
        fontWeight: 600,
    },
    lineStyle: {
        marginTop: '20px',
        fontSize: '1.2rem'

    },

    nextBtn: {
        float: 'right',
        display: 'inlineBlock',
        fontWeight: 600,
        color: 'white',
        backgroundColor: '#47a7ac',
        padding: '10px 40px',
        borderRadius: '5px',
        cursor: 'pointer',
        border: '1px solid #47a7ac',
        margin: '20px 80px',
        [theme.breakpoints.down('xs')]: {
            margin: '20px 20px'
        }
    },
    prevBtn: {
        float: 'left',
        display: 'inlineBlock',
        fontWeight: 600,
        color: '#47a7ac',
        backgroundColor: '#fff',
        padding: '10px 25px 10px 25px',
        borderRadius: '5px',
        cursor: 'pointer',
        border: '1px solid #47a7ac',
        margin: '20px 80px',
        [theme.breakpoints.down('xs')]: {
            margin: '20px 20px'
        }
    },
    stepStyle: {
        marginTop: '-22px',
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            marginTop: '-10px'
        }
    },
    numberStyle: {
        width: '6%'
    },
    numLineStyle: {
        width: '30%'
    }

})


class ReceiveBadgesModal extends Component {
    state = {
        isOpen: true,
        step: 1,
    }

    nextStep = (e) => {
        const { step } = this.state;
        this.setState({
            step: step + 1
        });
    };

    // Go back to prev step
    prevStep = () => {
        const { step } = this.state;
        this.setState({
            step: step - 1
        });
    };

    render() {
        const { closeModal, openModal, classes } = this.props;
        const { step } = this.state;
        switch (step) {
            case 1:
                return (
                    <Modal
                        size='small'
                        open={true}
                        onClose={closeModal}
                        isOpen={true}
                        className={classes.modalStyle}
                    >
                        <Modal.Header
                            style={{ backgroundColor: '#f4f5f7', paddingBottom: '40px', borderBottom: 'none' }}
                            className={classes.headerStyle}>
                            <p className={classes.congFont}>Congratulations !</p>
                            <p className={classes.nowTutorFont}>You are now a Tutor!</p>

                        </Modal.Header>
                        <div className={classes.stepStyle}>
                            <img alt='modal_1_color' className={classes.numberStyle} src="/assets/modal_1_color.png " />
                            <img alt='modal_line_none.png' className={classes.numLineStyle} src="/assets/modal_line_none.png " />
                            <img alt='modal_2_none.png' className={classes.numberStyle} src="/assets/modal_2_none.png " />
                            <img alt='modal_line_no_color.png' className={classes.numLineStyle} src="/assets/modal_line_no_color.png " />
                            <img alt='modal_3_none.png' className={classes.numberStyle} src="/assets/modal_3_none.png " />
                        </div>

                        <Modal.Content>
                            <Modal.Description
                                style={{ padding: '10px 40px' }}
                            >
                                <b className={classes.descHeader}>Obtain CrownTutor Badges</b>
                                <p className={classes.lineStyle}>
                                    Contact us at
                                    <span className={classes.emailStyle}><a href="mailto:Support@crowntutor.com?Subject=Interview%20Request" target="_top"> Support@crowntutor.com</a> </span>
                                    to schedule for a short Skype Interview
                                </p>
                            </Modal.Description>
                        </Modal.Content>
                        <p><button className={classes.nextBtn} onClick={this.nextStep}>Next</button></p>

                    </Modal>
                )
            case 2:
                return (
                    <Modal
                        size='small'
                        open={true}
                        onClose={closeModal}
                        isOpen={true}
                        className={classes.modalStyle}
                    >


                        <Modal.Header
                            style={{ backgroundColor: '#f4f5f7', paddingBottom: '40px', borderBottom: 'none' }}
                            className={classes.headerStyle}>
                            <p className={classes.congFont}> Congratulations !</p>
                            <p className={classes.nowTutorFont}>You are now a Tutor!</p>
                        </Modal.Header>
                        <div className={classes.stepStyle}>
                        <img alt='modal_1_color' className={classes.numberStyle} src="/assets/modal_1_color.png " />
                            <img alt='modal_line_none.png' className={classes.numLineStyle} src="/assets/modal_line_none.png " />
                            <img alt='modal_2_none.png' className={classes.numberStyle} src="/assets/modal_2_none.png " />
                            <img alt='modal_line_no_color.png' className={classes.numLineStyle} src="/assets/modal_line_no_color.png " />
                            <img alt='modal_3_none.png' className={classes.numberStyle} src="/assets/modal_3_none.png " />
                        </div>
                        <Modal.Content>
                            <Modal.Description
                                style={{ padding: '10px 40px' }}
                            >
                                <b className={classes.descHeader}>Check Your Email Regularly</b>

                                <p className={classes.lineStyle} >
                                    You will get an email notification when students message you
                                </p>
                                <p className={classes.lineStyle} style={{ marginTop: 0 }}>
                                    When meeting students, make sure to meet at a public space for first few sessions.
                                </p>
                            </Modal.Description>
                        </Modal.Content>
                        <button onClick={this.prevStep} className={classes.prevBtn}>Previous</button>
                        <button onClick={this.nextStep} className={classes.nextBtn}>Next</button>

                    </Modal>
                )
            case 3:
                return (
                    <Modal
                        size='small'
                        open={true}
                        onClose={closeModal}
                        isOpen={true}
                        className={classes.modalStyle}
                    >
                        <Modal.Header
                            style={{ backgroundColor: '#f4f5f7', paddingBottom: '40px', borderBottom: 'none' }}
                            className={classes.headerStyle}>
                            <p className={classes.congFont}>Congratulations !</p>
                            <p className={classes.nowTutorFont}>You are now a Tutor!</p>
                        </Modal.Header>
                        <div className={classes.stepStyle}>
                        <img alt='modal_1_color' className={classes.numberStyle} src="/assets/modal_1_color.png " />
                            <img alt='modal_line_none.png' className={classes.numLineStyle} src="/assets/modal_line_none.png " />
                            <img alt='modal_2_none.png' className={classes.numberStyle} src="/assets/modal_2_none.png " />
                            <img alt='modal_line_no_color.png' className={classes.numLineStyle} src="/assets/modal_line_no_color.png " />
                            <img alt='modal_3_none.png' className={classes.numberStyle} src="/assets/modal_3_none.png " />
                        </div>
                        <Modal.Content>
                            <Modal.Description
                                style={{ padding: '10px 40px' }}
                            >
                                <b className={classes.descHeader}>Ask for a Good Review</b>
                                <p className={classes.lineStyle}>
                                    Review is important! Good reviews and our badges will boost the page views
                                </p>
                            </Modal.Description>
                        </Modal.Content>
                        <button onClick={this.prevStep} className={classes.prevBtn} >Previous</button>

                        <button className={classes.nextBtn} onClick={closeModal}>Close</button>

                    </Modal>
                )
        };



    }
}

export default connect(null, actions)(withStyles(styles)(ReceiveBadgesModal));



