import React from 'react'
import { Segment, Icon, Header } from 'semantic-ui-react'
import Add from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  categories as categoryOptions,
  subjects as subjectOptions,
  levels as levelOptions,
} from '../../../app/common/categories'
import _ from 'lodash'
import Select from 'react-select'
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';




import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import './TutorProfile.css'


const style = (theme) => ({
  subjectTitle: {
    fontSize: '1.5rem',
    marginLeft: 40,
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
    marginLeft: 20
    }
  },
  isOwnerStyle: {
    marginTop: 20,
    overflow: 'hidden',
    width: '95%',
    marginLeft: 'auto',
    marginRight: 'auto',
    boxShadow: 'none',
    border: '1px solid #e1e1e6',
    paddingTop: 8,
    paddingBottom: 8,
    color: '#585c60',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: 0,
      borderRadius: 0,
      boxShadow: 'none'
    }
  },
  videoURLStyle: {
    marginTop: 20,
    overflow: 'hidden',
    width: '95%',
    marginLeft: 'auto',
    marginRight: 'auto',
    boxShadow: 'none',
    border: '1px solid #e1e1e6',
    paddingTop: 8,
    paddingBottom: 8,
    color: '#585c60',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: 0,
      borderRadius: 0,
      boxShadow: 'none'
    }
  },
  noVideo: {
    marginTop: -40,
    overflow: 'hidden',
    width: '95%',
    marginLeft: 'auto',
    marginRight: 'auto',
    boxShadow: 'none',
    border: '1px solid #e1e1e6',
    paddingTop: 8,
    paddingBottom: 8,
    color: '#585c60',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: 0,
      borderRadius: 0,
      boxShadow: 'none'
    }
  },
  phoneSize: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      marginBottom: 17,
      marginLeft: 20,
      marginRight: 20,
      paddingTop: 17,
      paddingLeft: 20,
      paddingBottom: 0,
      borderTop: '1px solid #e1e1e6'
    },
  },
  desktopSize: {
    width: '100%',
    borderBottom: '1px solid #e1e1e6',
    paddingBottom: 15,
    display: 'block',
    marginBottom: 15,
    paddingLeft: 20,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },

  },
  desktopSize1: {
    width: '100%',
    display: 'block',
    paddingLeft: 20,
    margin: 0,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },

  },


  selectStyle: {
    marginBottom: 20
  },
  card: {
    overflow: 'visible'
  },
  dialBtnStyle1: {
    padding: '8px 16px',
    backgroundColor: '#fff',
    borderRadius: 5,
    color: '#FF5D5D',
    cursor: 'pointer',
    border: '1px solid #FF5D5D',
    '&:hover': {
      backgroundColor: '#FF5D5D',
      color: '#fff'
    },
  },
  dialBtnStyle2: {
    padding: '8px 16px',
    backgroundColor: '#4ca7ab',
    borderRadius: 5,
    borderStyle: 'none',
    color: 'white',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#0A4065',
    },
  },
  cardHeaderStyle: {
    paddingBottom: 0
  }
})

const getSubjectsByCategory = (categoryId) => {
  let result = []
  subjectOptions.map(subject => {
    if (subject.parentId === categoryId) {
      result.push(subject)
    }
  })
  return result
}


const TutorProfileInfo = ({
  tutorProfile,
  openResumeUserSubject,
  handleChangeSelect,
  handleRemoveSubject,
  handleResumeUserSubjectClickOpen,
  handleResumeUserSubjectClickClose,
  handleChange,
  handleSubmitSubject,
  categoryId,
  isOwner,
  classes
}) => {

  const levelHash = tutorProfile.levels === undefined ? {} : tutorProfile.levels
  let levelList = []
  _.forEach(levelHash, (item) => {
    levelList.push(item)
  })

  let subjectSelectOptions = []
  if (categoryId !== '') {
    subjectSelectOptions = getSubjectsByCategory(categoryId)
  }
  const setCardStyle = () => {
    if (isOwner) {
      return classes.isOwnerStyle
    } else if (tutorProfile.videoURL) {
      return classes.videoURLStyle
    } else {
      return classes.noVideo
    }
  }
  let settingCardStyle = setCardStyle();
  
  
  
  return (


    <React.Fragment >
      {/* <Card className={classes.card}>
        <Dialog
          fullWidth
          open={openResumeUserSubject}
          onClose={() => handleResumeUserSubjectClickClose()}
          aria-labelledby="al ert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
        
          
          <DialogActions style={{ zIndex: 0 }}>
            <button className={classes.dialBtnStyle1} onClick={() => handleResumeUserSubjectClickClose()} >CANCEL</button>
            <button className={classes.dialBtnStyle2} onClick={() => handleSubmitSubject()} >SAVE</button>
          </DialogActions>
        </Dialog>
      </Card> */}

        <Card className={settingCardStyle}>
          <CardContent
            className={classes.cardHeaderStyle}

          >
            <Grid item xs={11} md={11}>
              <Typography style={{ color: '#4ca7ab' }} className={classes.subjectTitle}>Levels
                </Typography>
            </Grid>
          </CardContent>
          <CardContent >
            <Grid >
              {tutorProfile.levels.map((level, i) => (
                <React.Fragment key={i}>
                  <div
                    className={(i === levelList.length - 1) ? classes.desktopSize1 :
                      classes.desktopSize
                    }>
                    <Grid container>
                      <Grid item xs={6}>
                        <p >{level.levelLabel}</p>
                      </Grid>
                      <Grid item xs={4}></Grid>
                      <Grid item xs={2}>
                        <p >{level.price}/hr</p>
                      </Grid>

                    </Grid>

                  </div>
                  <div className={classes.phoneSize}>
                    <Grid container>

                      <Grid item md={5}>
                        {/* <p>{subject.name}</p> */}
                        <p>{level.levelLabel}</p>
                      </Grid>
                      <Grid item md={5}>
                        {/* <p>{subject.level}</p> */}
                      </Grid>
                      <Grid item md={2}>
                        <span>{level.price}/hr</span>
                      </Grid>

                    </Grid>
                  </div>

                </React.Fragment>
              ))
              }
            </Grid>
          </CardContent>
        </Card>
    </React.Fragment>
  )
}

export default withStyles(style)(TutorProfileInfo)