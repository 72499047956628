import React from 'react';
import { Segment, Header, Form, Divider, Label, Button, Icon } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import { combineValidators, matchesField, isRequired, composeValidators } from 'revalidate'
import TextInput from '../../../app/common/form/TextInput';
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Switch from "react-switch";

const styles = theme => ({
  basicbox: {
    borderTop: '3px solid #4ca7ab',
    marginTop: 150,

  },
  headerbg: {
    margin: '3% 5% 3% 5%',


  },
  displayname: {
    marginTop: '2%',


  },
  bttn: {
    backgroundColor: '#4ca7ab'
  },
  whitebar: {
    width: 50,
    backgroundColor: 'white',
  },

})


const validate = combineValidators({
  newPassword1: isRequired({ message: 'please enter a password' }),
  newPassword2: composeValidators(
    isRequired({ message: 'Please confirm your new password' }),
    matchesField('newPassword1')({ message: 'Passwords do not match' })
  )()
})

const handleChange = (checked) => {
  this.setState({ checked });
}

const PrivacySettingsPage = ({ error, invalid, submitting, handleSubmit, updatePassword, providerId, classes, initialValues }) => {
  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <Card className={classes.basicbox}>
          <div className={classes.headerbg}>
            <Header className={classes.hdfont} dividing size="large" content="Privacy Settings" />
            
              <div>
                <Header color="#585c60" sub content="Contact Information" />
                <p>Change the Visibility of Your Contact Info on your Tutor Profile</p>
                <Form>
                  <Field
                    width={8}
                    name="newPassword1"
                    type="password"
                    pointing="left"
                    inline={true}
                    component={TextInput}
                    basic={true}
                    placeholder="New Password"
                  />
                  
                </Form>
              </div>
          </div>
        </Card>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(reduxForm({ form: 'account', validate })(PrivacySettingsPage));