import React from 'react'
import { Form } from 'semantic-ui-react'

const RadioInput = ({input, type, label, style}) => {
  return (
    <Form.Field>
        <div className='ui radio'>
            <input {...input} type={type} style={style}/> {' '}
            <label>{label}</label>
        </div>
    </Form.Field>
  )
}

export default RadioInput
