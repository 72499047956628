import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "../TestPrep.css";
import Introduction from './Introduction';
import PreviewPage from './PreviewPage';
import TutorProfileChat from '../../tutors/TutorProfile/TutorProfileChat';
import QuestionAndAnswer from './QuestionAndAnswer';
import { withStyles } from '@material-ui/core/styles';
import AnswerFromQA from './AnswerFromQA';
import PDFfile from './PDFfile';

const styles = theme => ({


})

const SimpleTabs = ({
  addTutorProfileComment,
  deleteTutorProfileComment,
  tutorProfileId,
  tutorProfileChat,
  authenticated,
  openModal,
  loading,
  userId,
  classes,
  auth,
  tutorProfile,
  courseInfo,
  questions,
  buttonType,
  userProfile
}) => {

  // const isOwner = tutorProfile.tutorUid === auth.uid;
  return (
    <div style={{ display: 'block', }}>
      <Tabs defaultIndex={0} >
        <TabList >
          <Tab style={{ paddingTop: 20 }}>Course</Tab>
          <Tab style={{ paddingTop: 20 }}>Q&#38;A</Tab>
          <Tab style={{ paddingTop: 20 }}>Preview</Tab>
        </TabList>
        <TabPanel style={{ paddingBottom: 50, }} >
          <Introduction
            courseInfo={courseInfo}
          />

          {/* Review */}
          {/* <TutorProfileChat
            userId={userId}
            tutorProfileChat={tutorProfileChat}
            deleteTutorProfileComment={deleteTutorProfileComment}
            addTutorProfileComment={addTutorProfileComment}
            authenticated={authenticated}
            openModal={openModal}
          /> */}

        </TabPanel>
        <TabPanel >
          <QuestionAndAnswer
            courseInfo={courseInfo}
            buttonType={buttonType}
            userProfile={userProfile}
            userId={userId}
          />
          {questions && questions.map(question => (
            <AnswerFromQA
              question={question}
              courseInfo={courseInfo}
              buttonType={buttonType}
              userProfile={userProfile}
              userId={userId}
            />

          ))}

        </TabPanel>
        <TabPanel >
          <PreviewPage
            courseInfo={courseInfo}
          />


        </TabPanel>

      </Tabs>
    </div>
  );
}

export default withStyles(styles)(SimpleTabs)
