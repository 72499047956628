import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

//Material UI
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

const styles = theme => ({
    registerStyle: {
        marginTop: 160,
        fontSize: '2.2rem',
        maxWidth: '1180px',
        marginRight: 'auto',
        marginLeft: 'auto',
        fontWeight: 900,
        color: '#585c60',
        letterSpacing: '2px',
        [theme.breakpoints.down('xs')]: {
            width: '90%',
            fontSize: '1.5rem',
            marginTop: 120
        }
    },
    registrationStyle: {
        marginTop: 160,
        fontSize: '3rem',
        maxWidth: '1180px',
        marginRight: 'auto',
        marginLeft: 'auto',
        fontWeight: 600,
        color: '#585c60',
        letterSpacing: '2px',
        [theme.breakpoints.down('xs')]: {
            width: '90%',
            fontSize: '2rem',
            marginLeft: 0
        }
    },
    paragraphStyle: {
        margin: 0,
        padding: 0,
        fontWeight: 600,
        fontSize: '2rem',
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.5rem'
        }


    },
    continuebtn: {
        color: '#fff',
        fontWeight: 600,
        padding: '10px 42px',
        fontSize: '1.1rem',
        letterSpacing: '1.7px',
        float: 'right',
        marginTop: '60px',
        backgroundColor: '#669fff',
        borderRadius: '5px',
        cursor: 'pointer',
        border: '1px solid #669fff',
        [theme.breakpoints.down('xs')]: {
            width: '100%',

        }
    },

})
const Circle = () => {
    const style = {
        display: 'block',
        width: '5px',
        marginTop: '10px',
        marginBottom: 0
    }

    var circles = [
        <img style={style} src='./assets/Dot.png' />,
        <img style={style} src='./assets/Dot.png' />,
        <img style={style} src='./assets/Dot.png' />];

    for (var i = 0; i < 4; i++) {
        return <p style={{ margin: 0 }}>{circles}</p>
    }
}
const RegistrationSteps = ({
    classes,
    nextStep,

}) => {

    return (
        <div className={classes.registerStyle}>
            <Grid container>
                <Grid item md={1}></Grid>

                <Grid item xs={12} md={6}>
                    <p className={classes.registrationStyle}>Registration</p>
                    <p
                        className={classes.paragraphStyle}> Step
                    <span style={{ color: '#4ca7ab' }}> 01. </span>
                        Fill Your Information
                     </p>
                    <Circle />
                    <p
                        style={{ marginTop: '10px' }}
                        className={classes.paragraphStyle}>
                        Step
                     <span style={{ color: '#4ca7ab' }}> 02. </span>
                        Upload Profile Video (Optional)
                      </p>
                    <Circle />
                    <p
                        style={{ marginTop: '10px' }}
                        className={classes.paragraphStyle}
                    > Step
                     <span style={{ color: '#4ca7ab' }}> 03. </span>
                        Submit Solutions
                      </p>
                    <p
                    >
                        <button
                            className={classes.continuebtn}
                            onClick={nextStep}
                        >
                            NEXT
                      </button>
                    </p>

                </Grid>
            </Grid>

        </div>
    )
}

export default withStyles(styles)(RegistrationSteps)