import { MODAL_CLOSE, MODAL_OPEN } from './modalConstants';

export const openModal = (modalType, modalProps, data) => {
    return {
        type: MODAL_OPEN,
        payload: {
            modalType,
            modalProps,
            data
        }
    }
}

export const closeModal = () => {
    return {
        type: MODAL_CLOSE,
    }
}