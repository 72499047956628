import firebase from 'firebase';
import 'firebase/firestore';
import 'firebase/storage'

// Initialize Firebase

var config = {
    apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
    authDomain: "crown-tutor-fb.firebaseapp.com",
    databaseURL: "https://crown-tutor-fb.firebaseio.com",
    projectId: "crown-tutor-fb",
    storageBucket: "crown-tutor-fb.appspot.com",
    messagingSenderId: "497827499164"
};

firebase.initializeApp(config);
firebase.firestore().settings({ timestampsInSnapshots: true });
const firestore = firebase.firestore();
const storage = firebase.storage();



export {
    storage, firebase as default
} 

