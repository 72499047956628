import React, { Component, Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles';

import styled from 'styled-components'
import Slider from 'react-slick'
import CourseListItems from './CourseListItems';

const styles = theme => ({
    wrapper: {
        dispaly: 'flex',
        maxWidth: '1180px',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 30,
        marginBottom: 120,
        [theme.breakpoints.down('xs')]: {
            padding: 0,
            maxWidth: '85%',
            marginBottom: 40,

        }
    }
})

function SampleNextArrow(props) {
    const { className, style, onClick } = props;

    return (
        <div
            className={className}
            style={{ ...style }}
            onClick={onClick}
        />
    );
}

const Page = styled.div`
width: 100%;
`

class CourseList extends Component {

    render() {
        const settings = {
            dots: false,
            infinite: false,
            slidesToShow: 5,
            speed: 1000,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SampleNextArrow />,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 5,
                        infinite: false,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        // const levelLength = () => {
        //     if (this.courseOption.level.length < 0) {
        //         return <div>haha</div>
        //     } else {
        //         return <div>lala</div>
        //     }
        // }
        // let levelBlock = levelLength();

        const { classes, courseOptions, admin, auth, firebase, profile } = this.props;

        return (
            <div className={classes.wrapper} id="testPrep">
                <Slider
                    {...settings}
                >
                    {courseOptions && courseOptions.map(courseOption => (
                        <Page
                        >
                            <CourseListItems
                                courseOption={courseOption}
                            // levelBlock={levelBlock}
                            admin={admin}
                            auth={auth}
                            firebase={firebase}
                            profile={profile}
                            />
                        </Page>
                    ))}
                </Slider>

            </div >

        )
    }
}

export default withStyles(styles)(CourseList);