import React from "react";
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';


const styles = theme => ({
  haha: {
    backgroundColor: '#red',
    margin: '5px',
    '&:hover': {
      color: '#4ca7ab',
      backgroundColor: '#ffffff',
  },
},
})
const SignedOutMenu = ({ signIn, register, classes }) => {
  return (
    <Typography position="right">
      <Button onClick={signIn}  content="Login" className={classes.haha}>Login</Button>
      <Button
        onClick={register}
        content="Register"
        className={classes.haha}
      >
        Register
        </Button>
    </Typography>
  );
};

export default withStyles(styles)(SignedOutMenu);
