import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { openModal } from '../../modals/modalActions'
import { connect } from "react-redux";

//Material UI
import { Grid } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';

const styles = theme => ({
    btnStyle: {
        backgroundColor: '#ff5d5d',
        color: '#fff',
        width: '100%',
        marginTop: 20,
        letterSpacing: '1px',
        fontSize: '1.3rem',
        fontFamily: 'proxima-nova, sans-serif ',
        '&:hover': {
            backgroundColor: '#ff5d5d',
            color: '#fff',
        },
        [theme.breakpoints.down('xs')]: {
            width: '95%'
        }
    },
    avatarStyle: {
        borderRadius: 5,
        width: '100%',
        height: '100%',
        marginRight: 'auto',
        marginLeft: 'auto',
        paddingBottom: 20,
        borderBttom: 'none',
        [theme.breakpoints.down('xs')]: {
            borderBottom: '1px solid #c2c7d1',
            paddingBottom: 20,
            borderRadius: 0,
            width: '95%',
            display: 'none'
        }
    },
    avatarStudentPass:{
        display: 'block',
        borderRadius: 5,
        width: '100%',
        height: '100%',
        marginRight: 'auto',
        marginLeft: 'auto',
        paddingBottom: 20,
        borderBttom: 'none',
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        }
    },
    cardStyle: {
        maxWidth: '400px',
        marginTop: 100,
        textAlign: 'center',
        padding: '30px',
        marginLeft: '40px',
        boxShadow: 'none',
        border: '1px solid #e1e1e6',
        borderRadius: 10,
        [theme.breakpoints.down('xs')]: {
            marginTop: 0,
            marginLeft: 0,
            padding: '10px',
            border: 'none',
        }

    },
    regularPriceStyle: {
        fontSize: '1.4rem',
        textDecoration: 'line-through',
        textAlign: 'left',
        fontWeight: 100,
        fontFamily: 'proxima-nova, sans-serif ',
        color: '#c2c7d1',
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.2rem',
            width: '95%',
            marginRight: 'auto',
            marginLeft: 'auto',
            marginTop: 10,
        }
    },
    discountPriceStyle: {
        fontSize: '2.2rem',
        textAlign: 'left',
        fontFamily: 'proxima-nova, sans-serif ',
        color: '#585c60',
        fontWeight: 600,
        [theme.breakpoints.down('xs')]: {
            fontSize: '2rem',
            width: '95%',
            marginRight: 'auto',
            marginLeft: 'auto'
        }
    },


})
const actions = {
    openModal,
}

const BuyContent = ({
    classes,
    thumbnail,
    handleStripePayment,
    userProfile,
    contentType,
    buttonType,
    courseInfo,
    openModal,
    authenticated,
    studentPass
}) => {
    // var theButton = <div>{authenticated ?
    // <Button
    //     className={classes.btnStyle}
    //     onClick={handleStripePayment}
    // >Purchase
    //     </Button>
    //     :
    //     <Button
    //         className={classes.btnStyle}
    //         onClick={() => openModal("LoginModal")}
    //     >Login
    //     </Button>
    // }

    // </div>
    var theButton = ''
    var price = "CA $ 9.99"

    if (authenticated) {
        switch (true) {
            case buttonType === 'download':
                theButton = <Button
                    className={classes.btnStyle}
                    download
                    href={courseInfo.pdfFile}
                >
                    DOWNLOAD
                </Button>

                break;
            case buttonType === 'discount':
                price = "CA $ 9.99"
                theButton = <Button
                    className={classes.btnStyle}
                    onClick={handleStripePayment}
                >Check Out
                </Button>
                break;
            case buttonType === 'none':
                theButton = <Button disabled style={{ opacity: '0.3', color: 'white' }} className={classes.btnStyle}>PURCHASED</Button>
                break
            case buttonType === 'pass':
                    theButton = <Button
                    className={classes.btnStyle}
                    onClick={handleStripePayment}
                >Check Out
                </Button>
                break
            default:
                theButton = <Button
                    className={classes.btnStyle}
                    onClick={handleStripePayment}
                >Check Out
                </Button>
        }

    }
    else {
        theButton = <Button
            className={classes.btnStyle}
            onClick={() => openModal("LoginModal")}>Purchase</Button>
    }

    return (
        <Card
            className={classes.cardStyle}
        >

            <div style={{ maxHeight: '300px' }}>
                <img
                    src={thumbnail || '/assets/Thumnail/StudentPass.png'}
                    className={thumbnail ? classes.avatarStyle : classes.avatarStudentPass}
                />
            </div>


            <Typography className={classes.regularPriceStyle}>{studentPass === true ? 'CA $9.99' : 'CA $ 19.99' }</Typography>
            <Typography className={classes.discountPriceStyle}>{studentPass ? 'CA $4.99' : price}</Typography>

            {theButton}
            {/* <Button style={{ color: 'white' }} className={classes.btnStyle}>Ready this Week</Button> */}

        </Card>
    )
}


export default withStyles(styles)(connect(null, actions)(BuyContent))
