import moment from 'moment';

export const objectToArray = (object) => {
    if (object) {
      return Object.entries(object).map(e => Object.assign({}, e[1], {id: e[0]}))
    }
  }
  

export const createNewTutorProfile = (id, rating, user, photoURL, displayName, tutorProfile, rank) => {
    // tutor.date = moment(tutor.date).toDate();

    return {
        ...tutorProfile,
        cancelled: true,
        rating: rating,
        tutorUid: user.uid,
        tutorDisplayName: displayName,
        tutorPhotoURL: photoURL || '/assets/user.png',
        created: new Date(Date.now()),
        badges: {
            basic: false,
            education: false,
            professional: false
        },
        photoUpdated: false,
        rank: rank
    }
}


export const createDataTree = dataset => {
    let hashTable = Object.create(null);
    dataset.forEach(a => hashTable[a.id] = {...a, childNodes: []});
    let dataTree = [];
    dataset.forEach(a => {
        if (a.parentId) hashTable[a.parentId].childNodes.push(hashTable[a.id]);
        else dataTree.push(hashTable[a.id])
    });
    return dataTree
};