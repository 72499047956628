import React from 'react';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
// import AboutUs from '../../components/AboutUs';
import { NavLink } from "react-router-dom";
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';

const styles = theme => ({
  LayoutBody: {
    float: 'left',
    textTransform: 'none',
    [theme.breakpoints.down('xs')]: {
      float: 'left',
      textAlign: 'left',
      alignItem: 'left',
      display: 'flex'
    }
  },
  LayoutBody1: {
    float: 'right',
    position: 'static',
    textTransform: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    '&:hover': {
      backgroundColor: '#f4f5f7',
      cursor: 'context-menu'
    }
  },
  layout: {
    display: 'block',
    width: 'auto',
    marginLeft: 'auto',
    zIndex: 0,
    marginRight: 'auto',
    [theme.breakpoints.up('xs')]: {
      maxWidth: 1200,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  footer: {
    width: '100%',
    zIndex: 0,
    backgroundColor: 'rgba(229, 230, 235, 0)',
    marginTop: '40px',
    marginBottom: '40px',
    [theme.breakpoints.down('xs')]: {
      marginTop: 50,
    }
  },

  logo: {
    textAlign: 'center',
    float: 'left',
    margin: 20,
    width: 30,
    height: 30,
    display: 'block'

  },
  CTlogo: {
    width: 30,
    height: 'auto',
    float: 'left',
    display: 'inline',

  },

  logosPosition: {
    display: 'block',
    maxWidth: 300,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 40,
    [theme.breakpoints.down('xs')]: {
      display: 'center',
      marginLeft: '10%',

    }
  },
  split: {
    float: 'right'
  },
  copyright1: {
    alignItems: 'center',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  copyright2: {

    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex'
    }
  },
  responsive: {
    itemAlign: 'center',
    display: 'flex',
    fontSize: '0.7rem',
    marginLeft: 20,
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  resposiveS:{
    itemAlign: 'center',
    display: 'inline',
    marginTop: -200,
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  resposiveL:{
    itemAlign: 'center',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  LayoutBody2:{
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'inline',
      fontSize: '0.7rem',

    }
  }


});

// const footers = [
//     {
//       title: 'Company',
//       description: ['Team', 'History', 'Contact us', 'Locations'],
//     },
//     {
//       title: 'Features',
//       description: ['Cool stuff', 'Random feature', 'Team feature', 'Developer stuff', 'Another one'],
//     },
//     {
//       title: 'Resources',
//       description: ['Resource', 'Resource name', 'Another resource', 'Final resource'],
//     },
//     {
//       title: 'Legal',
//       description: ['Privacy policy', 'Terms of use'],
//     },
//   ];

const Footer = (props) => {
  const { classes } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (

    <footer className={classNames(classes.footer, classes.layout)}>
      <span className={classes.resposiveS} >
        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
         <MenuIcon />
      </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}><Button className={classes.LayoutBody} component={NavLink} to="/aboutUs">About Us</Button></MenuItem>
          <MenuItem onClick={handleClose}><Button className={classes.LayoutBody} component={NavLink} to="/contactUs">Contact Us</Button></MenuItem>
          <MenuItem onClick={handleClose}><Button className={classes.LayoutBody} component={NavLink} to="/faq">FAQ</Button></MenuItem>
          <MenuItem onClick={handleClose}><Button className={classes.LayoutBody} component={NavLink} to="/termsAndCondition">Terms &#38; Conditions</Button></MenuItem>
          <MenuItem onClick={handleClose}><Button className={classes.LayoutBody} component={NavLink} to="/privacyAndPolicy">Privacy Policy</Button></MenuItem>
        </Menu>
      </span>
      <p  className={classes.LayoutBody2}> &#9400; 2019 CrownTutor Inc., All Rights Reserved</p>
      <Grid container spacing={32} justify="space-evenly" >
        {/* {footers.map(footer => (
                <Grid item xs={12} md={3} key={footer.title}>
                <Typography variant="h6" color="textPrimary" gutterBottom>
                    {footer.title}
                </Typography>
                {footer.description.map(item => (
                    <Typography key={item} variant="subtitle1" color="textSecondary">
                    {item}
                    </Typography>
                ))}
                </Grid>
            ))} */}
      </Grid>

      <div >
        {/* <AboutUs /> */}
        <div style={{position: 'relative', bottom: 0 }}>
          <Grid container spacing={0} >
            <Grid item xs={1} md={1} className={classes.responsive} />

            <Grid item xs={12} md={8}  className={classes.resposiveL}>
              <Button className={classes.LayoutBody} component={NavLink} to="/aboutUs">About Us</Button>
              <Button className={classes.LayoutBody} component={NavLink} to="/contactUs">Contact Us</Button>
              <Button className={classes.LayoutBody} component={NavLink} to="/faq">FAQ</Button>
              <Button className={classes.LayoutBody} component={NavLink} to="/termsAndCondition">Terms &#38; Conditions</Button>
              <Button className={classes.LayoutBody} component={NavLink} to="/privacyAndPolicy">Privacy Policy</Button>
            </Grid>

            <Grid item xs={5} md={4}>
              <Button  className={classes.LayoutBody1}> &#9400; 2019 CrownTutor Inc., All Rights Reserved </Button>
            </Grid>
          </Grid>







          {/* <img src={require('../../static/CrownTutor_Logo1.png')}  alt="Logo"  className={classes.CTlogo}/> */}

        </div>


        {/* <div className={classes.logosPosition}>
                <div>
                  <img src={require('../../static/Facebook_Logo.png')}  alt="Logo" className={classes.logo}/>
                </div>
                <div>
                  <img src={require('../../static/Instagram_Logo.png')}  alt="Logo"  className={classes.logo}/>
                </div>
                <div>
                  <img src={require('../../static/Twitter_Logo.png')}  alt="Logo"  className={classes.logo}/>
                </div>
              </div> */}

      </div>

    </footer>

  )
}

export default withStyles(styles)(Footer);