import * as actionTypes from "./tabFocusConstants";


export const focusTab = () => {
    return {
      type: actionTypes.FOCUS_TAB,
      payload: {
        isTabFocused: true
      }
    }
  }

export const blurTab = () => {
    return {
      type: actionTypes.BLUR_TAB,
      payload: {
        isTabFocused: false
      }
    }
  }