export const CREATE_TUTORPROFILE = "CREATE_TUTORPROFILE";
export const UPDATE_TUTORPROFILE = "UPDATE_TUTORPROFILE";
export const DELETE_TUTORPROFILE = "DELETE_TUTORPROFILE";
export const FETCH_TUTORPROFILES = "FETCH_TUTORPROFILES";

export const CREATE_TUTORPROFILE_REVIEW = "CREATE_TUTORPROFILE_REVIEW";
export const UPDATE_TUTORPROFILE_REVIEW = "UPDATE_TUTORPROFILE_REVIEW";


export const GET_TUTOR_REQUEST = 'GET_TUTOR_REQUEST';
export const GET_TUTOR_SUCCESS = 'GET_TUTOR_SUCCESS';
export const GET_TUTOR_ERROR = 'GET_TUTOR_ERROR'