export const GET_CATEGORY_REQUEST = 'GET_CATEGORY_REQUEST'
export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS'
export const GET_CATEGORY_ERROR = 'GET_CATEGORY_ERROR'

export const UPDATE_CATEGORY_REQUEST = 'UPDATE_CATEGORY_REQUEST'
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS'
export const UPDATE_CATEGORY_ERROR = 'UPDATE_CATEGORY_ERROR'

export const CREATE_CATEGORY_REQUEST = 'CREATE_CATEGORY_REQUEST'
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS'
export const CREATE_CATEGORY_ERROR = 'CREATE_CATEGORY_ERROR'

export const REMOVE_CATEGORY_REQUEST = 'REMOVE_CATEGORY_REQUEST'
export const REMOVE_CATEGORY_SUCCESS = 'REMOVE_CATEGORY_SUCCESS'
export const REMOVE_CATEGORY_ERROR = 'REMOVE_CATEGORY_ERROR'