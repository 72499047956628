import * as actionTypes from "./chatActionConstants";
import firebase from '../../../../app/config/firebase'
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError
} from "../../../async/asyncActions";
// export const setPrivateChannel = isPrivateChannel => {
//   return {
//     type: actionTypes.SET_PRIVATE_CHANNEL,
//     payload: {
//       isPrivateChannel
//     }
//   }
// }

export const setCurrentChannel = channel => {
  return {
    type: actionTypes.SET_CURRENT_CHANNEL,
    payload: {
      currentChannel: channel
    }
  }
}
export const setNotifications = notifications => {
  return {
    type: actionTypes.SET_NOTIFICATIONS,
    payload: {
      notifications: notifications
    }
  }
}


//firebase.database().ref(`/rooms/$roomKey/messages`).orderByChild('createdAt').startAt('15039996197').on(//code);
export const getChatMessages = (channel) =>
  async (dispatch, getState) => {
    const chatRef = firebase.database().ref('chats');
    const chatMessagesQuery = chatRef.child(channel).child('messages').orderByChild('timestamp').limitToFirst(18);
    let loaddedMessages;
    // console.log(chatMessagesQuery)
    
    try {
      dispatch(asyncActionStart());
      await chatMessagesQuery.once('value').then(snap => {
        loaddedMessages = Object.keys(snap.val()).map(function (key) {
          return snap.val()[key];
      })
      })
      // console.log(loaddedMessages)

      dispatch({type:actionTypes.FETCH_CHATMESSAGES, payload:{loaddedMessages, channel}})
      dispatch(asyncActionFinish());
    } catch (error) {
        console.log(error);
        dispatch(asyncActionError());
    }
  }