import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles'

import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    aboutUsImage: {
        maxWidth: '1180px',
        marginRight: 'auto',
        marginLeft: 'auto'
    },
    firstElement: {
        fontFamily: 'proxima-nova, sans-serif ',
        paddingLeft: 40,
        paddingRight: 40,
        textAlign: 'center',
    },
    girdFirst: {
        letterSpacing: '2px',
        fontSize: '2.3rem',
        color: '#585c60',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'left',
            width: '100%',
            marginTop: 40,
            marginRight: 'auto',
            marginLeft: 'auto',
            paddingLeft: 0
        }
    },
    headerStyle: {
        fontSize: '3rem',
        fontWeight: 600,
        color: '#585c60',
        marginTop: 110,
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '1180px',
        marginBottom: 40,
        fontFamily: 'proxima-nova, sans-serif ',
        [theme.breakpoints.down('xs')]: {
            width: '80%',
            fontSize: '2em',
            marginTop: 40,
            paddingTop: 40,

        },
    },
    fontStyle: {
        fontFamily: 'proxima-nova, sans-serif ',
        letterSpacing: '1px',
        fontSize: '1.3rem',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'left'
        }
    },
    iconStyle: {
        borderRadius: 0,
        width: '200px',
        height: 'auto',
        marginRight: 'auto',
        marginLeft: 'auto',
        marginBottom: 40,
    },
    titleStyle: {
        fontFamily: 'proxima-nova, sans-serif ',
        letterSpacing: '1px',
        fontSize: '1.8rem',
        fontWeight: 600,
        marginTop: 20,
        marginBottom: 20,
        [theme.breakpoints.down('xs')]: {
            textAlign: 'left'
        }
    }





});


export const AboutMidtermPrep = ({ classes }) => (
    <div >
        <div className={classes.aboutUsImage}>
            <Typography
                className={classes.headerStyle}
            >
                About Test Prep
            </Typography>
            <Grid container className={classes.girdFirst}>
                <Grid
                    item
                    md={4}
                    className={classes.firstElement}
                >
                    <div >
                        <Avatar
                            src='/assets/smallImage/Ontario_Curriculum.png'
                            className={classes.iconStyle}
                            style={{marginTop: 20}}
                        />
                    </div>
                    <Typography
                        className={classes.titleStyle}
                    >
                        Ontario Curriculum
                    </Typography>
                    <Typography
                        className={classes.fontStyle}
                    >
                       The Grade 9 - Complete Test Prep - Part A covers the first half of the course (MPM1D) and is equivalent to Nelson textbook chapters 1-4
                        </Typography>
                </Grid>
                <Grid
                    item
                    md={4}
                    className={classes.firstElement}
                >
                    <div  >
                        <Avatar
                            src='/assets/QAillust.png'
                            className={classes.iconStyle}
                        />

                    </div>
                    <Typography
                        className={classes.titleStyle}
                    >
                        Q&#38;A
                    </Typography>
                    <Typography
                        className={classes.fontStyle}
                    >
                     Our selected tutors will answer your questions. You can also contact tutors if you need help in person.
                </Typography>
                </Grid>
                <Grid
                    item
                    md={4}
                    className={classes.firstElement}
                >
                    <div >
                        <Avatar
                            src='/assets/Levelillustration.png'
                            className={classes.iconStyle}
                        />

                    </div>
                    <Typography
                        className={classes.titleStyle}
                    >
                        All Levels
                    </Typography>
                    <Typography
                        className={classes.fontStyle}
                    >
                        From concept problems to competition level problems. With the Grade 10 - Complete Test Prep - Part A, students will be able to grasp the concept and understand how to apply it to challenging problems.
</Typography>
                </Grid>
            </Grid>
        </div>


    </div>
)


export default withStyles(styles)(AboutMidtermPrep)
