import React from 'react'
import { connect } from 'react-redux'
import LoginModal from './LoginModal'
import RegisterModal from './RegisterModal'
import UnauthModal from './UnauthModal'
import ResetPasswordModal from './ResetPasswordModal'
import EmailVerificationModal from './EmailVerificationModal'
import ReceiveBadgesModal from './ReceiveBadgesModal'
import StripeModal from './StripeModal'
import PaymentResponseModal from './PaymentResponseModal'
import RecommendInformation from './RecommendInformation'
import closeServiceModal from './closeServiceModal'

const modalLookup = {
    LoginModal,
    RegisterModal,
    UnauthModal,
    ResetPasswordModal,
    EmailVerificationModal,
    ReceiveBadgesModal,
    StripeModal,
    PaymentResponseModal,
    RecommendInformation,
    closeServiceModal  
}

const mapState = (state) => ({
    currentModal: state.modals
})

const ModalManager = ({currentModal}) => {
    let renderedModal;

    if(currentModal) {
        const {modalType, modalProps, data} = currentModal;
        const ModalComponent = modalLookup[modalType];

        renderedModal = <ModalComponent {...modalProps}/>
    }
  return <span>{renderedModal}</span>
}

export default connect(mapState)(ModalManager)
