import React, { Component } from "react";
import { connect } from 'react-redux'
import _ from "lodash";
import { searchByRequest, filterLevels } from '../../TutorSearchForm/searchActions'
import { Form, Button, Sticky } from "semantic-ui-react";
import Card from '@material-ui/core/Card';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    inputs: {
        margin: '5% 2% 3% 2%',
    },
    inputbase: {
        padding: '2% 0 7% 0',
    },
    inputbase1: {
        padding: '2% 0 7% 0',
        marginBottom: 5
    },
    filter: {
        padding: '0px 0 20px 0',
        overflow: 'visible',
        width: '90%',
        marginTop: '20px',
        boxShadow: 'none',
        border: '1px solid #e1e1e6',
        [theme.breakpoints.down('xs')]: {
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '100%',
            borderRadius: 0,
            borderBottom: '1px solid #cec7d1',
            borderRight: '1px solid #cec7d1',
            marginTop: 0,
            height: '100%'
        }
    },
    stickyStyle: {
        position: 'sticky',
        top: 400,
        zIndex: 0,
        [theme.breakpoints.down('xs')]: {
            marginTop: '120px',
            width: '95%',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        subjectbox: {
            marginBottom: '20px'
        }
    },
    slider: {
        padding: '22px 0px',
    },
    headerStyle: {
        backgroundColor: '#4ca7ab',
        borderBottom: '1px solid #e1e1e6',
        [theme.breakpoints.down('xs')]: {
            borderBottom: 'none',
        }
    },
    distanceHeader: {
        color: 'white',
        fontSize: '1.7rem',
        paddingBottom: 10,
        paddingTop: 10,
        margin: 20
    },
    responsiveStyle:{
        margin: 20,
        [theme.breakpoints.down('xs')]: {
        marginTop: 0
        }
    },
})
const mapStateToProps = state => {
    return {
        langs: state.searches.langs,
        subject: state.searches.subject,
        location: state.searches.location,
        lat: state.searches.lat,
        lng: state.searches.lng,
        distance: state.searches.distance,
        searchList: state.searches.list,
        isFetching: state.searches.isFetching,
        priceLow: state.searches.priceLow,
        priceHigh: state.searches.priceHigh,
        levels: state.searches.levels,
        searchState: state.searches
    };
};


const mapDispatchToProps = dispatch => {
    return {
        searchByRequest: payload => dispatch(searchByRequest(payload)),
        filterLevels: (state, levels, filteredList) => dispatch(filterLevels(state, levels, filteredList)),
        searchBy: (lang, subject, formattedAddress, lat, lng, distance, searchPage, priceLow, priceHigh) =>
            dispatch({
                type: "SEARCH_BY",
                langs: lang,
                subject: subject,
                location: formattedAddress,
                lat: lat,
                lng: lng,
                distance: distance,
                searchPage: searchPage,
                priceLow,
                priceHigh
            }),
    };
};


class TutorLevelFilter extends Component {

    state = {
        theLists: this.props.searchList,
        levels: {
            university: this.props.levels.university,
            college: this.props.levels.college,
            high: this.props.levels.high,
            middle: this.props.levels.middle,
            elementary: this.props.levels.elementary,
            early: this.props.levels.early,
            casual: this.props.levels.casual,
        }
    }

    componentDidMount() {
        const { tutorList, searchList, subject, searchState } = this.props;
        const { levels } = this.state;
        let idChecks = [];
        let filteredList = [];

        // console.log("componentDidMount", tutorList);



        tutorList.map(list => {
            for (let [key, value] of Object.entries(levels)) {
                if (value) {
                    switch (key) {
                        case 'university':
                            if (list.subjects.hasOwnProperty(`${subject.label}_University`)) {
                                // console.log('University',list);
                                if (!idChecks.includes(list.tutorUid)) {
                                    filteredList.push(list)
                                    idChecks.push(list.tutorUid)
                                }
                            }
                            break;
                        case 'college':
                            if (list.subjects.hasOwnProperty(`${subject.label}_College`)) {
                                // console.log('College',list);
                                if (!idChecks.includes(list.tutorUid)) {
                                    filteredList.push(list)
                                    idChecks.push(list.tutorUid)
                                }
                            }
                            break;
                        case 'high':
                            if (list.subjects.hasOwnProperty(`${subject.label}_High School`)) {
                                // console.log('High School',list);
                                if (!idChecks.includes(list.tutorUid)) {
                                    filteredList.push(list)
                                    idChecks.push(list.tutorUid)
                                }
                            }
                            break;
                        case 'middle':
                            if (list.subjects.hasOwnProperty(`${subject.label}_Middle School`)) {
                                // console.log('Middle School',list);
                                if (!idChecks.includes(list.tutorUid)) {
                                    filteredList.push(list)
                                    idChecks.push(list.tutorUid)
                                }
                            }
                            break;
                        case 'elementary':
                            if (list.subjects.hasOwnProperty(`${subject.label}_Elementary School`)) {
                                // console.log('Elementary School',list);
                                if (!idChecks.includes(list.tutorUid)) {
                                    filteredList.push(list)
                                    idChecks.push(list.tutorUid)
                                }
                            }
                            break;
                        case 'early':
                            if (list.subjects.hasOwnProperty(`${subject.label}_Early Child`)) {
                                // console.log('Early Child',list);
                                if (!idChecks.includes(list.tutorUid)) {
                                    filteredList.push(list)
                                    idChecks.push(list.tutorUid)
                                }
                            }
                            break;
                        case 'casual':
                            if (list.subjects.hasOwnProperty(`${subject.label}_Casual & Adult`)) {
                                // console.log('Casual',list);
                                if (!idChecks.includes(list.tutorUid)) {
                                    filteredList.push(list)
                                    idChecks.push(list.tutorUid)
                                }
                            }
                            break;
                    }
                }
            }

        })

        // const categories = [
        //     'University',
        //     "College",
        //     "High School",
        //     "Middle School",
        //     "Elementary School",
        //     "Early Child",
        //     "Casual & Adult",
        // ]
        // tutorList.map(list => {
        //     let j = 0;

        //     for(let [key2, value2] of Object.entries(list.subjects)){
        //         const ids = key2.split('_');
        //         if(ids[0] === subject.label){
        //             for(let i = 0; i< categories.length; i++){
        //                 if(categories[i] === ids[1]){
        //                     j++;
        //                 }
        //             }
        //         }
        //     }

        //     if(j === 0) {
        //         console.log("Inthe List Error",list);
        //     }
        // })

        // console.log("THe FilteredList", filteredList)
        this.setState({ theLists: tutorList })
        this.props.filterLevels(searchState, levels, filteredList)
    }


    toggleReverse = () => {
        this.setState(prev => ({ reversed: !prev.reversed }))
    }

    onUpdate = update => {
        this.setState({ update })
    }

    onChange = name => event => {
        const { searchState, subject } = this.props
        const { theLists } = this.state

        const theList = theLists;

        let filteredList = [];
        let idChecks = []

        let theLevels = {
            ...this.state.levels,
            [name]: event.target.checked
        }

        // console.log("In TutorLevel Filter, tutors",tutorList);
        // console.log("In TutorLevel Filter, searchList",this.props.searchList);

        theList.map(list => {
            for (let [key, value] of Object.entries(theLevels)) {
                if (value) {
                    switch (key) {
                        case 'university':
                            if (list.subjects.hasOwnProperty(`${subject.label}_University`)) {
                                // console.log('University',list);
                                if (!idChecks.includes(list.tutorUid)) {
                                    filteredList.push(list)
                                    idChecks.push(list.tutorUid)
                                }
                            }
                            break;
                        case 'college':
                            if (list.subjects.hasOwnProperty(`${subject.label}_College`)) {
                                // console.log('College',list);
                                if (!idChecks.includes(list.tutorUid)) {
                                    filteredList.push(list)
                                    idChecks.push(list.tutorUid)
                                }
                            }
                            break;
                        case 'high':
                            if (list.subjects.hasOwnProperty(`${subject.label}_High School`)) {
                                // console.log('High School',list);
                                if (!idChecks.includes(list.tutorUid)) {
                                    filteredList.push(list)
                                    idChecks.push(list.tutorUid)
                                }
                            }
                            break;
                        case 'middle':
                            if (list.subjects.hasOwnProperty(`${subject.label}_Middle School`)) {
                                // console.log('Middle School',list);
                                if (!idChecks.includes(list.tutorUid)) {
                                    filteredList.push(list)
                                    idChecks.push(list.tutorUid)
                                }
                            }
                            break;
                        case 'elementary':
                            if (list.subjects.hasOwnProperty(`${subject.label}_Elementary School`)) {
                                // console.log('Elementary School',list);
                                if (!idChecks.includes(list.tutorUid)) {
                                    filteredList.push(list)
                                    idChecks.push(list.tutorUid)
                                }
                            }
                            break;
                        case 'early':
                            if (list.subjects.hasOwnProperty(`${subject.label}_Early Child`)) {
                                // console.log('Early Child',list);
                                if (!idChecks.includes(list.tutorUid)) {
                                    filteredList.push(list)
                                    idChecks.push(list.tutorUid)
                                }
                            }
                            break;
                        case 'casual':
                            if (list.subjects.hasOwnProperty(`${subject.label}_Casual & Adult`)) {
                                // console.log('Casual',list);
                                if (!idChecks.includes(list.tutorUid)) {
                                    filteredList.push(list)
                                    idChecks.push(list.tutorUid)
                                }
                            }
                            break;
                    }
                }
            }

        })

        // console.log("THe FilteredList", filteredList)
        this.setState({ levels: theLevels, searchList: theList });
        this.props.filterLevels(searchState, theLevels, filteredList)

    }

    render() {
        const { contextRef, classes } = this.props;
        return (
            <Card className={classes.filter}>
                <div className={classes.headerStyle}>
                    <Typography className={classes.distanceHeader}>Level</Typography>
                </div>
                <div className={classes.responsiveStyle}>
                    <div>
                        <span>University</span>
                        <Checkbox
                            onChange={this.onChange('university')}
                            checked={this.state.levels.university}
                            style={{ color: '#4ca7ab' }}
                        />
                    </div>
                    <div>
                        <span>College</span>
                        <Checkbox
                            onChange={this.onChange('college')}
                            checked={this.state.levels.college}
                            style={{ color: '#4ca7ab' }}
                        />
                    </div>
                    <div>
                        <span>High School</span>
                        <Checkbox
                            onChange={this.onChange('high')}
                            checked={this.state.levels.high}
                            style={{ color: '#4ca7ab' }}
                        />
                    </div>
                    <div>
                        <span>Middle School</span>
                        <Checkbox
                            onChange={this.onChange('middle')}
                            checked={this.state.levels.middle}
                            style={{ color: '#4ca7ab' }}
                        />
                    </div>
                    <div>
                        <span>Elementary School</span>
                        <Checkbox
                            onChange={this.onChange('elementary')}
                            checked={this.state.levels.elementary}
                            style={{ color: '#4ca7ab' }}
                        />
                    </div>
                    <div>
                        <span>Early Child</span>
                        <Checkbox
                            onChange={this.onChange('early')}
                            checked={this.state.levels.early}
                            style={{ color: '#4ca7ab' }}
                        />
                    </div>
                    <div>
                        <span>Casual & Adult</span>
                        <Checkbox
                            onChange={this.onChange('casual')}
                            checked={this.state.levels.casual}
                            style={{ color: '#4ca7ab' }}
                        />
                    </div>
                </div>
            </Card>
        );
    }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(TutorLevelFilter));