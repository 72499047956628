import firebase from "../../../app/config/firebase"
import React, { Component } from 'react';
import { connect } from 'react-redux'
import {
  CardElement,
  CardNameElement,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  PostalCodeElement,
  PaymentRequestButtonElement,
  IbanElement,
  IdealBankElement,
  StripeProvider,
  Elements,
  injectStripe,
} from 'react-stripe-elements';
import { Label, Button, Dimmer, Loader, } from 'semantic-ui-react';
import { closeModal, openModal } from "../../modals/modalActions"
import { withStyles } from '@material-ui/core/styles';
import { toastr } from 'react-redux-toastr'
import { withRouter } from 'react-router-dom';



const styles = theme => ({
  btnStyle: {
    width: '100%'
  },
  labelStyle: {
    fontSize: '0.8rem',
    color: '#585c60',
    display: 'block'
  }
})

const createOptions = (fontSize, padding) => {
  return {
    style: {
      base: {
        fontSize,
        color: '#424770',
        letterSpacing: '0.025em',
        fontFamily: 'proxima-nova, sans-serif ',
        '::placeholder': {
          color: '#aab7c4',
        },
        padding,
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };
};

const actions = {
  closeModal,
  openModal
}

class CheckoutForm extends Component {

  state = {
    complete: false,
    cardComplete: false,
    expComplete: false,
    cvcComplete: false,
    cardError: '',
    expError: '',
    cvcError: '',
    loading: false,
    paymentResult: false,
    paymentLoading: true,
  }

  async componentDidMount() {
    // this.addPurchaseListener();

  }

  addPurchaseListener = () => {
    const { user } = this.props;
    console.log("listenr")
    let paymentResult = [];
    this.state.userRef
      .child(user.uid)
      .child("paymentResult")
      .on("child_changed", snap => {
        // console.log(snap.key)
        console.log(snap.val().error);
        if (snap.val().error === 'none') {
          this.setState({
            paymentLoading: false,
            paymentResult: true,
            loading: false,
          })
        }
        // paymentResult.push(snap.val().error) ;
        // console.log(paymentResult)
      });

  };

  handleSubmit = async (ev) => {
    const { user, contentInfo } = this.props;
    ev.preventDefault();
    await this.setState({ loading: true })
    const theDate = Date.now();
    const userRef = firebase.firestore().collection('users').doc(user.uid)
    const stripeTokenRef = userRef.collection('stripeToken');
    const userDatabaseRef = firebase.database().ref('/users/'+user.uid).child('paymentResult')
    // *******REALTIME DATABASE LOGIC
    // const userRef = firebase.database().ref('users/' + user.uid)
    // const stripeTokenRef = userRef.child('stripeToken')
    // const chargesRef = userRef.child('charges');

    // await chargesRef.child(theDate).set(({
    //   amount: contentInfo.price,
    //   timeStamp: firebase.database.ServerValue.TIMESTAMP,
    //   description: contentInfo.buyTitle,
    //   chargeId: theDate
    // }))
    //UNTIL HERE ***********

    userDatabaseRef.set({
      error:{
        error: 'none',
        id: Date.now()
      }
    })

    await userRef.collection('charges').doc(`${theDate}`).set({
      amount: contentInfo.price,
      created: new Date(Date.now()),
      timeStamp: Date.now(),
      description: contentInfo.buyTitle,
    })

    if (this.props.stripe) {
      this.props.stripe
        .createToken()
        .then(async (payload) => {
          payload.description = contentInfo.buyTitle;
          // await stripeTokenRef.child(theDate).set(payload)
          await stripeTokenRef.doc(`${theDate}`).set(payload)
     
          this.setState({ loading: false })
          this.props.openModal("PaymentResponseModal", null, contentInfo);
        });
    }
    else {
      console.log("Stripe.js hasn't loaded yet.");
    }

  };

  handleCardChange = (change) => {
    const { complete, cvcComplete, expComplete } = this.state;
    // console.log(change)
    this.setState({ cardComplete: false })
    if (change.complete) {
      // console.log('yay Cardcomplete')
      if (cvcComplete === true && expComplete === true) {
        this.setState({
          cardError: '',
          cardComplete: true,
          complete: true,
        })
        // console.log(this.state.complete)
      } else {
        this.setState({
          cardError: '',
          cardComplete: true,
          complete: false,
        })
        // console.log(this.state.complete)
      }

    } else {
      if (change.error) {
        // console.log('yay Carderror')
        this.setState({
          cardError: `${change.error.message}`,
          cardComplete: false,
          complete: false,
        })

        // console.log(this.state.complete)
      }
    }
  }

  handleExpChange = (change) => {
    const { complete, cvcComplete, cardComplete } = this.state;

    // console.log(change)
    if (change.complete) {
      // console.log('yay Expcomplete')
      if (cvcComplete === true && cardComplete === true) {
        this.setState({
          expError: '',
          expComplete: true,
          complete: true,
        })
        // console.log(this.state.complete)
      } else {
        this.setState({
          expError: '',
          expComplete: true,
          complete: false,
        })
        // console.log(this.state.complete)
      }

    } else {
      if (change.error) {
        // console.log('yay Experror')
        this.setState({
          expError: `${change.error.message}`,
          expComplete: false,
          complete: false,
        })
        // console.log(this.state.complete)
      }
    }
  }

  handleCvcChange = (change) => {
    const { complete, cardComplete, expComplete } = this.state;
    if (change.complete) {

      if (expComplete === true && cardComplete === true) {
        this.setState({
          cvcError: '',
          cvcComplete: true,
          complete: true,
        })

      } else {
        this.setState({
          cvcError: '',
          cvcComplete: true,
          complete: false,
        })

      }

    } else {
      if (change.error) {

        this.setState({
          cvcError: `${change.error.message}`,
          cvcComplete: false,
          complete: false,
        })

      }
    }
  }

  render() {
    const { classes, contentInfo } = this.props;
    const { loading, cardError, expError, cvcError, complete } = this.state;

    

    return (
      <div className="checkout">
        <form style={{
          marginBottom: '20px',
        }}>

          <label className={classes.labelStyle}>
            Card number
            <CardNumberElement
              onChange={this.handleCardChange}
              // onBlur={this.handleBlur}
              onFocus={this.handleFocus}
              // onReady={this.handleReady}
              {...createOptions('15px')}
            />
          </label>
          {cardError && <Label sytle={{ display: 'block' }} basic color='red'>{cardError}</Label>}
          <label className={classes.labelStyle}>
            Expiration date
          <CardExpiryElement
              onChange={this.handleExpChange}
              // onBlur={this.handleBlur}
              onFocus={this.handleFocus}
              // onReady={this.handleReady}
              {...createOptions('15px')}
            />
          </label>
          {expError && <Label basic color='red'>{expError}</Label>}
          <label className={classes.labelStyle}>
            CVC
          <CardCVCElement
              onChange={this.handleCvcChange}
              // onBlur={this.handleBlur}
              onFocus={this.handleFocus}
              // onReady={this.handleReady}
              {...createOptions('15px')}
            />

          </label>
          {cvcError && <Label basic color='red'>{cvcError}</Label>}
          {/* <CardElement
            onChange={this.handleChange}
          /> */}

          <Button
            className={classes.btnStyle}
            onClick={this.handleSubmit}
            disabled={!complete || loading}
            id="stripeButton">{loading ? <Dimmer active inverted={false}>
              <Loader inverted>Processing Your Payment</Loader>
            </Dimmer> : `pay CA $${contentInfo.price / 100}`}</Button>
        </form>

      </div>
    );
  }
}

export default connect(null, actions)(withStyles(styles)(withRouter(injectStripe(CheckoutForm))));

// export default connect(null, actions)(
//   reduxForm({ form: "checkoutForm", enableReinitialize: true, validate })(
//     injectStripe(CheckoutForm)
//   )
// )