import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

//Material UI
import { Grid } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';

const styles = theme => ({
    cardStyle: {
        width: '90%',
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 20,
        minHeight: '320px',
        maxHeight: '320px',
        boxShadow: 'none',
        border: '1px solid #e1e1e6',
        [theme.breakpoints.down('xs')]: {
            minHeight: '250px',
            maxHeight: '250px',
        }
    },
    nameStyle: {
        fontSize: '1.3rem',
        fontWeight: 600,
        marginBottom: 0,
        textAlign: 'center',
        color: '#585c60'

    },
    avatarStyle: {
        borderRadius: 0,
        width: '100%',
        height: 'auto',
        cursor: 'pointer'
    },
    infoStyle: {
        width: '90%',
        marginTop: 15,
        marginLeft: 'auto',
        marginRight: 'auto',
        float: 'left'
    },
    infoStyle1: {
        textAlign: 'left',
        marginLeft: 10,
        [theme.breakpoints.down('xs')]: {
            marginLeft: 10
        }
    },
    cityStyle: {
        marginLeft: 0
    }

})


const RecommendedTutorListItem = ({
    tutorList,
    classes
}) => {


    let displayedSubjects = [];

    var subjectList = tutorList.subjectList.filter(function (elem, index, self) {
        return index === self.indexOf(elem);
    })
    // console.log(subjectList)

    // console.log(subjectList)

    if (subjectList.length >= 2) {
        for (let i = 0; i < subjectList.length; i++) {
            if (i > 1) {
                displayedSubjects.push('');
            } else if (i === 1) {
                displayedSubjects.push(subjectList[i], '...');
            } else {
                displayedSubjects.push(subjectList[i], ', ');
            }
        }
    } else {
        displayedSubjects = subjectList[0];
    }

    // <Typography
    //     className={classes.infoStyle1}
    // >
    //     <i
    //         class="material-icons"
    //         className={classes.editStyle}
    //         style={{
    //             color: '#4ca7ab',
    //             display: 'inline',
    //             position: 'relative',
    //             top: '8px',
    //             left: '-5px'
    //         }}>
    //         edit
    //                 </i>
    //     {displayedSubjects}
    // </Typography>

    return (


        <Card
            className={classes.cardStyle}
        >
            <Avatar
                src={tutorList.tutorPhotoURL || '/assets/user.png'}
                className={classes.avatarStyle}
                component={Link}
                to={`/tutor/${tutorList.tutorUid}`}
            />
            <div className={classes.infoStyle}>
                <Typography
                    className={classes.nameStyle}
                    component={Link}
                    to={`/tutor/${tutorList.tutorUid}`}
                >
                    {(tutorList.tutorDisplayName || tutorList.firstName).length > 5
                        ? (tutorList.firstName || tutorList.tutorDisplayName).split(' ')[0]
                        : tutorList.firstName || tutorList.tutorDisplayName}
                </Typography>
                <Typography
                    className={classes.infoStyle1}
                >
                    <Grid container>
                        <Grid item xs={2} md={2}>
                            <i
                                class="material-icons"
                                style={{
                                    color: '#FF5D5D',
                                    display: 'inline',
                                    position: 'relative',
                                    fontSize: '16px'
                                }}
                            >
                                place
                                </i>
                        </Grid>
                        <Grid item xs={10}  md={10}>
                            <span className={classes.cityStyle}>{tutorList.city}</span>
                        </Grid>
                    </Grid>


                </Typography>
                <Typography
                    className={classes.infoStyle1}
                >
                    <Grid container>
                        <Grid item xs={2}  md={2}>
                            <i
                                class="material-icons"
                                className={classes.editStyle}
                                style={{
                                    color: '#4ca7ab',
                                    display: 'inline',
                                    position: 'relative',
                                    fontSize: '16px'
                                }}>
                                edit
                            </i>
                        </Grid>
                        <Grid item xs={10} md={10}>
                            <span className={classes.cityStyle}>{displayedSubjects}</span>
                        </Grid>
                    </Grid>


                </Typography>

            </div>

        </Card>



    )
}

export default withStyles(styles)(RecommendedTutorListItem)