import React, { Component } from 'react';
import { Document, Page } from 'react-pdf';
import { Link } from 'react-router-dom';

class PDFfile extends Component {
    render() {
        const { courseInfo } = this.props;

        return (
            <div>

                <div className="Example__container">
                    <div className="Example__container__document">
                        <a
                            href={courseInfo.pdfFile}
                            download>
                            Download
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default PDFfile
