import React from 'react';
import {connect} from 'react-redux'   
import { Form, Segment, Label, Divider } from 'semantic-ui-react';
import Button from '@material-ui/core/Button';
import { Field, reduxForm } from 'redux-form';
import TextInput from '../../../app/common/form/TextInput';
import { login, facebookSocialLogin, googleSocialLogin } from '../authActions'
import SocialLogin from '../SocialLogin/SocialLogin';
import { withStyles } from '@material-ui/core/styles';
import {closeModal, openModal} from "../../modals/modalActions";
const styles = theme => ({
  buttonStyle: {
    backgroundColor: '#4ca7ab',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginBottom: 10,
    width: '100%',
    color: '#fff',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#0A4065',
  },
  },
  cursorStyle:{
    cursor:'pointer',
    fontSize: '0.9rem'
  }


})
const actions = {
    login,
    facebookSocialLogin,
    googleSocialLogin,
    closeModal,
    openModal,
}


const LoginForm = ({login,classes, handleSubmit, error, facebookSocialLogin,
  googleSocialLogin, openModal}) => {
  return (
    <Form error size="large" onSubmit={handleSubmit(login)}>
        <Field
          name="email"
          component={TextInput}
          type="text"
          placeholder="Email Address"
        />
        <Field
          name="password"
          component={TextInput}
          type="password"
          placeholder="password"
        />
        {error && <Label basic color='red'>{error}</Label>}
        <Button type="submit"  className={classes.buttonStyle}>
            Login
          </Button>
        <a className={classes.cursorStyle} onClick={() => openModal("RegisterModal")}>Don't have an Account?</a>
        <br/>
        <a  className={classes.cursorStyle} onClick={() => openModal("ResetPasswordModal")}>Forgot Password?</a>
        <br/>
        <Divider horizontal>
          Or
        </Divider>
        <SocialLogin facebookSocialLogin={facebookSocialLogin} googleSocialLogin={googleSocialLogin}/>
    </Form>
  );
};

export default withStyles(styles)(connect(null, actions)(reduxForm({form: 'loginForm'})(LoginForm)));