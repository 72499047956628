import React, { Component, Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles';

import styled from 'styled-components'
import Slider from 'react-slick'
import RecommendedTutorListItem from './RecommendedTutorListItem';
import './RecommendedTutor.css';


const styles = theme => ({
    wrapper: {
        dispaly: 'flex',
        maxWidth: '1200px',
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.down('xs')]: {
            padding: 0,
            maxWidth: '85%',
        }
    }
})

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style  }}
            onClick={onClick}
        />
    );
}



const Page = styled.div`
width: 100%;
`

class RecentlyTutorList extends Component {
    render() {
        const settings = {
            dots: false,
            infinite: false,
            slidesToShow: 5,
            slidesToScroll: 5,
            speed: 1000,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SampleNextArrow />,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                    infinite: false,
                    dots: true
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    initialSlide: 2
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                  }
                }
              ]
        };
        const { tutorLists, classes } = this.props;
        return (
            <div className={classes.wrapper}>
                <Slider
                    {...settings}
                >
                    {tutorLists && tutorLists.map(tutorList => (
                        <Page
                            key={tutorList.tutorUid}

                        >
                            <RecommendedTutorListItem
                                tutorList={tutorList}
                            />
                        </Page>

                    ))}

                </Slider>

            </div >

        )
    }
}

export default withStyles(styles)(RecentlyTutorList);