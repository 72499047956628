import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';

import { Segment, Item, Icon, List, Label, Button } from "semantic-ui-react";
import StudentListAttendee from "./StudentListAttendee";

const styles = theme => ({


});



class StudentListItem extends Component {


    render() {
        const { student, deleteEvent } = this.props;
        return (
            <Segment.Group>
                <Segment>
                    <Item.Group>
                        <Item.Image size='tiny' circular src={student.hostPhotoURL} />
                        <Item.Content>
                            <Item.Header as="a">{student.title}</Item.Header>
                            <Item.Description>
                                Hosted by {student.hostedBy}
                            </Item.Description>

                        </Item.Content>
                    </Item.Group>
                </Segment>
                <Segment>
                    <span>
                        <Icon name="clock"> {student.date} |</Icon>
                        <Icon name="marker"> {student.venue} |</Icon>
                    </span>
                </Segment>
                {/* <Segment secondary>
                    <List horizontal>
                        {student.attendees && student.attendees.map(attendee => (
                            <StudentListAttendee key={attendee.id} attendee={attendee} />
                        ))}
                    </List>
                </Segment> */}
                <Segment clearing>
                    <span>{student.description}</span>
                    <Button
                        as="a"
                        color="red"
                        floated="right"
                        content="DELETE"
                        onClick={() => deleteEvent(student.id)} />
                    <Button
                        as={Link}
                        to={`/students/${student.id}`}
                        color="teal"
                        floated="right"
                        content="VIEW" 
                        />
                </Segment>
            </Segment.Group>

        );
    }
}

export default StudentListItem;
