import React, { Component } from "react";
import {
  Segment,
  Form,
  Button,
} from "semantic-ui-react";
import { Field } from "redux-form";
import TextInput from "../../../app/common/form/TextInput";
import PlaceInput from "../../../app/common/form/PlaceInput";
import PlaceInput2 from "../../../app/common/form/PlaceInput.1.js";
import { withStyles } from '@material-ui/core/styles';
import Script from 'react-load-script';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import InputBase from "@material-ui/core/InputBase";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';

const styles = theme => ({
  cardStyle: {
    borderTop: '3px solid #4ca7ab',
    padding: '3%',
    boxShadow: 'none',
    [theme.breakpoints.down('xs')]: {
    paddingRight: 30,
    paddingLeft: 30,
    borderRadius: 0

    }
  },
  box: {
    padding: '3%',
    marginTop: '120px',
    marginBottom: '20%',
    marginRight: 'auto',
    marginLeft: 'auto',
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      marginTop: '90px',

    }
  },
  basicinfo: {
    color: '#585c60',
    margin: '10% 0 5% 0',
  },
  inputs: {

  },
  location: {
    border: '1px solid #585c60',

  },
  addressStyle: {
    border: '1px solid #e1e1e6',
    borderRadius: '3px',
    padding: '4px 12px 4px 12px',
    color: '#808080',

    marginBottom: '15px',

  },
  typo: {
    marginTop: '12px',
    color: '#696969',
  },
  continuebtn: {
    fontWeight: 600,
    color: 'white',
    backgroundColor: '#4ca7ab',
    padding: '10px 18px 10px 18px',
    borderRadius: '5px',
    cursor: 'pointer',
    border: '1px solid #4ca7ab',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#0A4065',


    }
  }
})

class TutorBasicDetails extends Component {
  state = {
    scriptLoaded: false
  }

  continue = e => {
    e.preventDefault();
    if (this.props.formattedAddress === '') {
      window.alert("Please Click or Enter the Address from the Selection Box");

    } else {

      this.props.nextStep();
    }

  };

  onKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); //<===== This stops the form from being submitted
      event.stopPropagation();
      alert("enter")
    } else {
      // alert("not enter")
    }
  }


  render() {
    const {
      disabled,
      onSelect,
      classes,
    } = this.props;
    const { scriptLoaded } = this.state;
    return (
      <React.Fragment>
        <Grid container>
          <Grid item xs={12} md={6} className={classes.box}>
            <Card className={classes.cardStyle}>
              <Form autoComplete="off">
                <h1 className={classes.basicinfo}>Basic Info</h1>
                <label className={classes.typo}>First Name</label>
                <Field
                  label="first name"
                  name="firstName"
                  type="text"
                  component={TextInput}
                />
                <label className={classes.typo}>Last Name</label>

                <Field
                  name="lastName"
                  type="text"
                  component={TextInput}
                />
                <label className={classes.typo}>Phone Number</label>

                <Field
                  name="phone"
                  type="text"
                  component={TextInput}
                />
                <label className={classes.typo}>Email</label>

                <Field
                  name="email"
                  type="text"
                  component={TextInput}
                />
              </Form>
              <Typography className={classes.typo}><span style={{ color: 'red' }}>&#42;</span> Enter your address, only location will be diplayed on profile</Typography>
              <Field className={classes.location}
                onKeyPress={this.onKeyPress}
                scriptLoaded={scriptLoaded}
                name="city"
                className={classes.addressStyle}
                placeholder='Enter your address'
                component={PlaceInput2}
              // onSubmit={(e) => e.preventDefault()}
              />
              {/* <InputBase
                  id="autocomplete"
                  type="text"
                  autoComplete='new-password'
                  label="Where"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                /> */}
              <p style={{ float: 'right' }}><button className={classes.continuebtn} disabled={disabled} onClick={this.continue}>CONTINUE</button></p>
            </Card>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(TutorBasicDetails);

