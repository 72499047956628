import React, { Component } from 'react';
import firebase from '../../app/config/firebase'
import { Modal } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { closeModal, openModal } from "./modalActions";
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from "@material-ui/core/styles";
import PaymentSuccess from '../payment/stripe/PaymentSuccess'
import PaymentFailure from '../payment/stripe/PaymentFailure'
import { Label, Button, Dimmer, Loader, } from 'semantic-ui-react';
import cuid from 'cuid'
const styles = theme => ({
    badge: {
        width: '100px',
        borderRadius: 0,
        textAlign: 'center'
    }
})


const actions = { closeModal, openModal };

const mapState = (state) => ({
    contentInfo: state.modals.data,
    user: state.firebase.auth,
    paymentError: false,
})

class PaymentResponseModal extends Component {

    state = {
        loading: true,
        userRef: firebase.database().ref("users"),
    }
    handleStripePayment = (props) => {
        this.props.openModal("StripeModal", null, props);
    };

    addPurchaseListener = () => {
        const { user } = this.props;
        const { userRef } = this.state;
        console.log("listenr")
        let paymentResult = [];

        userRef
            .child(user.uid)
            .child("paymentResult")
            .on("child_changed", snap => {
                // console.log(snap.key)
                // console.log(snap.val());
                if (snap.val().error === 'none') {

                    this.setState({
                        loading: false,
                        paymentError: false
                    });

                } else {
                    this.setState({
                        loading: false,
                        paymentError: true,
                        errorMessage: snap.val().error
                    })
                }
            });

    };


    removeUserChatListener = () => {
        const { userRef } = this.state;
        const { user } = this.props;
        userRef
            .child(user.uid)
            .child("paymentResult")
            .off()
    }

    async componentDidMount() {
        this.addPurchaseListener()
    }

    componentWillUnmount() {
        this.removeUserChatListener();
    }

    render() {
        const { amount, user, classes, price, contentInfo, closeModal } = this.props;
        const { loading, paymentError, errorMessage } = this.state
        // console.log(price);
        const displayResponse = (condition) => {
            if (condition === true) {
                return <PaymentFailure />
            } else {
                return <PaymentSuccess />
            }
        }
        return (
            <Modal
                size='mini'
                open={true}
                onClose={this.props.closeModal}
                style={{ marginTop: 20 }}
            >
                <Modal.Content >
                    {loading ?
                        <Dimmer active inverted={false}>
                            <Loader inverted>Processing Your Payment</Loader>
                        </Dimmer>
                        :
                        <div>
                            {paymentError ?
                                <PaymentFailure
                                    errorMessage={errorMessage}
                                    handleStripePayment={() => this.handleStripePayment(contentInfo)}
                                /> :
                                <PaymentSuccess
                                    contentInfo={contentInfo}
                                    closeModal={closeModal}
                                />}
                        </div>
                    }

                </Modal.Content>
            </Modal>
        );
    }
}

export default connect(mapState, actions)(withStyles(styles)(PaymentResponseModal));


