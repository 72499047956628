import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import firebase from '../../../app/config/firebase'
import cuid from "cuid";
//Material UI
import { Grid } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import { Accordion, Icon, TextArea, Form } from 'semantic-ui-react'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import "../TestPrep.css";

const styles = theme => ({
    boxStyle: {
        border: '1px solid #c2c7d1',
        borderRadius: 5,
        marginBottom: 80,
        color: '#585c60'
    },
    cardStyle: {
        paddingRight: 20,
        paddingLeft: 20,
        paddingBottom: 20,
        marginTop: 10,
        boxShadow: 'none',
        borderRadius: 0,
    },
    avatarStyle: {
        width: '75px',
        height: 'auto',
        [theme.breakpoints.down('xs')]: {
            width: '50px'
        }
    },
    titleStyle: {
        fontSize: '1.3rem',
        paddingTop: 25,
        marginLeft: -10,
        color: '#585c60',
        [theme.breakpoints.down('xs')]: {
            marginLeft: 10,
            paddingTop: 5,
        }

    },
    nameStyle: {
        fontSize: '1.1rem',
        marginLeft: -10,
        color: '#585c60',
        [theme.breakpoints.down('xs')]: {
            marginLeft: 10,
        }

    },
    descStyle: {
        marginTop: 20,
    },
    replyStyle: {
        paddingTop: 35,
        [theme.breakpoints.down('xs')]: {
            paddingTop: 20,

        }
    },
    reply1Style: {
        width: 20,
        height: 20,
        marginTop: 20,
        marginRight: 10

    },
    expansionPanelStyle: {
        paddingLeft: 0,
        paddingRight: '1px',
        color: '#585c60',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 0,
            paddingRight: 0,

        }
    },
    titleNameStyle: {
        marginLeft: -20,
        color: '#585c60',
        [theme.breakpoints.down('xs')]: {
            margin: 0
        }
    },
    questionHeader: {
        fontSize: '1.4rem',
        color: '#585c60',
        marginBottom: 10
    },
    formStyle: {
        marginTop: 50
    },
    inputStyle: {
        width: '100%',
        marginRight: 'auto',
        marginLeft: 'auto',
        itemAlign: 'center',
        borderRadius: 5,
        borderShadow: 'none',
        padding: 5,
        marginBottom: 20,
    },
    btnStyle: {
        backgroundColor: '#4ca7ab',
        color: '#fff',
        padding: '7px 35px',
        '&:hover': {
            backgroundColor: '#4ca7ab',
        }
    },
    answerQA: {
        marginLeft: -30,
        [theme.breakpoints.down('xs')]: {
            marginLeft: '30px'
        }
    }
})


class AnswerFromQA extends React.Component {
    state = {
        body: '',
        title: '',
        loading: false
    }

    handleInputChange = ({ target: { name, value } }) => {
        this.setState({
            [name]: value
        })

    }
    handleSubmit = async (event, data) => {
        const { classes, question, buttonType, userProfile, userId, courseInfo } = this.props
        const { body, title } = this.state;
        this.setState({ loading: true })
        var theQuestion = question
        
        const firestore = firebase.firestore();
        const questionsRef = firestore.collection("questions").doc(courseInfo.id);
        var answerArray = question.answers ? question.answers : [];
        
        let theAnswer = {
            content: body,
            title: title,
            created: new Date(Date.now()),
            answererFirstName: userProfile.firstName,
            answererLastName: userProfile.lastName,
            answererId: userId
        }

    
        answerArray.push(theAnswer)
        
        
        theQuestion.answers = answerArray
        
        await questionsRef.set({ list: [theQuestion] }, { merge: true })

        this.setState({ loading: false, body: '', title: '' })
    }
    render() {
        const { classes, question, buttonType, userProfile, userId } = this.props;
        return (

            < div
                className={classes.boxStyle}

            >
                {/* <Card
                    className={classes.cardStyle}
                > */}


                < ExpansionPanel className={classes.cardStyle} >
                    <ExpansionPanelSummary className={classes.expansionPanelStyle}>
                        <Grid container>
                            <Grid item xs={2} md={2}>
                                <Avatar
                                    className={classes.avatarStyle}
                                    src={'/assets/user.png'} />
                            </Grid>
                            <Grid item xs={9} md={9}>
                                <Typography className={classes.titleStyle}>{question.title}</Typography>
                                <Typography className={classes.nameStyle}>{question.studentFirstName}, {question.studentLastName}</Typography>
                            </Grid>
                            <Grid item xs={1} md={1} className={classes.replyStyle}>
                                <img
                                    src={'/assets/reply.png'}

                                    style={{
                                        color: '#585c60',
                                        width: '16px',
                                        height: 'auto',
                                        textAlign: 'right'
                                    }}
                                />

                            </Grid>

                            <Grid item md={12} className={classes.descStyle}>
                                <Typography >
                                    {question.content}
                                </Typography>
                            </Grid>
                        </Grid>
                    </ExpansionPanelSummary>

                    <ExpansionPanelDetails style={{ padding: 10 }}>

                        <Grid container  >

                            {question.answers && question.answers.map((answer) => (

                                <div>

                                    <Grid item md={3}>
                                        <Grid container>
                                            <Grid item md={3}>
                                                <img
                                                    className={classes.reply1Style}
                                                    src={'/assets/reply_1.png'}
                                                />
                                            </Grid>
                                            <Grid item md={6}>
                                                <Avatar
                                                    className={classes.avatarStyle}
                                                    src={'/assets/user.png'} />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item md={8} className={classes.titleNameStyle}>
                                        <Typography className={classes.titleStyle}>{answer.title}</Typography>
                                        <Typography className={classes.nameStyle}>{answer.answererFirstName}, {answer.answererLastName}</Typography>
                                    </Grid>

                                    <Grid item md={3} />

                                    <Grid item md={8} className={classes.descStyle} >
                                        <Typography className={classes.answerQA}>
                                            {answer.content}
                                </Typography>
                                    </Grid>
                                </div>
                            )
                            )}



                            {/* From here is the comment */}


                            {/* to here is the comment */}

                            <Grid item xs={12}>
                                <Form
                                    className={classes.formStyle}
                                    onSubmit={this.handleSubmit}
                                >

                                    <Typography
                                        className={classes.questionHeader}
                                    >
                                        Ask a new question
                                    </Typography>


                                    <Form.Field>

                                        <TextArea
                                            name='title'
                                            placeholder="Question title"
                                            className={classes.inputStyle}
                                            style={{ marginTop: 20, }}
                                            onChange={this.handleInputChange}
                                            value={this.state.title}
                                            fullWidth
                                            rows='1'
                                            disabled={buttonType === 'download' ? false : true}
                                        />
                                    </Form.Field>
                                    <Form.Field>

                                        <TextArea
                                            name='body'
                                            placeholder="Describe what you are trying to ask..."
                                            className={classes.inputStyle}
                                            value={this.state.body}
                                            rows='7'
                                            onChange={this.handleInputChange}
                                            disabled={buttonType === 'download' ? false : true}
                                        />
                                    </Form.Field>
                                    <Button
                                        className={classes.btnStyle}
                                        type='submit'
                                        disabled={this.state.loading}
                                    >SUBMIT
                    </Button>

                                </Form>
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel >
                {/* </Card> */}
            </div >

        )
    }

}


export default withStyles(styles)(AnswerFromQA)