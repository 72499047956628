import React, { Component } from 'react';
import { Modal } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withStyles } from "@material-ui/core/styles";

import { closeModal, openModal } from "./modalActions";

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';




const actions = { closeModal, openModal };

const styles = theme => ({
    subjectTitle: {
        fontSize: '2rem',
        color: '#fff',
        border: '2px solid #fff',
        paddingRight: 15,
        paddingLeft: 15,
        borderRadius: 50,
        marginRight: 10,
        marginLeft: 30,
        [theme.breakpoints.down('xs')]: {

        }
    },
    subjectStyle: {
        fontSize: '1.4rem',
        color: '#fff'
    },
    header: {
        backgroundColor: '#4ca7ab',
        paddingTop: 20,
        paddingBottom: 20
    },
    cardStyle: {
        paddingBottom: 5,
    },
    listStyle: {
        lineHeight: 1.2,
        paddingLeft: 20
    }
})

class closeServiceModal extends Component {
    state = {
        isOpen: true,
        step: 1,
    }



    render() {
        const { closeModal, openModal, classes } = this.props;

        return (
            <Modal
                size='small'
                open={true}
                onClose={closeModal}
                isOpen={true}
                style={{ marginTop: '50px' }}
            >
                <Card className={classes.cardStyle}>
                    <CardContent className={classes.header}>
                        <p className={classes.subjectStyle} >
                            <span className={classes.subjectTitle}>!</span>
                            Important Service Changes
                        </p>
                    </CardContent>
                    <CardContent style={{ paddingBottom: 10, paddingRight: 50, paddingLeft: 50 }} >
                        <p>Dear Tutors and Students,</p>
                        <br />
                        <p>
                            After September 15th, CrownTutor will be renamed to <b style={{fontSize: '1.3rem'}}>Tossmath</b> and will only accommodate math subjects up to high school level. The levels are divided into seven levels.
                </p>

                        <ol>
                            <li className={classes.listStyle}>K-8</li>
                            <li className={classes.listStyle}>Grade 9</li>
                            <li className={classes.listStyle}>Grade 10</li>
                            <li className={classes.listStyle}>Grade 11</li>
                            <li className={classes.listStyle}>Grade 12 - Advanced Functions</li>
                            <li className={classes.listStyle}>Grade 12 - Calculus and Vectors</li>
                            <li className={classes.listStyle}>Grade 12 Data Management</li>
                        </ol>
                        <p>If you wish to continue using our service as a math tutor, please let us know the levels and rate you are expecting to teach. Otherwise, your tutor profile will be deleted from our system.</p>
                        <p>CrownTutor users will still be able to login to TossMath without registering again.</p>
                        <p>Please let us know if you wish your user information to be removed from our database.</p>
                        <p>You can always contact us at support@crowntutor.com for any inquiry.</p>
                        <p>Thank you,</p>
                        <p>John Nam,</p>
                        <p>CEO of CrownTutor Inc.</p>
                    </CardContent>
                </Card>
            </Modal>
        )

    };




}

export default connect(null, actions)(withStyles(styles)(closeServiceModal));




