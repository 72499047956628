import React from 'react'
import firebase from '../../../app/config/firebase'
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { toastr } from "react-redux-toastr";
import {

    Form,
    Button
} from "semantic-ui-react";

import { connect } from 'react-redux'
import { reduxForm } from "redux-form";
import { withFirestore } from "react-redux-firebase";
import { Field } from "redux-form";
import cuid from "cuid";
//Material UI
import { Grid } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import { Input, TextArea } from 'semantic-ui-react';


const styles = theme => ({
    questionHeader: {
        fontSize: '2rem',
        color: '#585c60',
        textAlign: 'left',
        fontWeight: 600,
        fontFamily: 'proxima-nova, sans-serif ',
        letterSpacing: '1.2px',
        margin: 0,
        [theme.breakpoints.down('xs')]: {
            textAlign: 'left',
        }
    },
    formStyle: {
        borderBottom: '1px solid #e1e1e6',
        paddingBottom: 25,
        marginBottom: 200,
        [theme.breakpoints.down('xs')]: {
            width: '90%',
            marginRight: 'auto',
            marginLeft: 'auto',
            marginTop: 18
        }
    },
    inputStyle: {
        width: '100%',
        marginRight: 'auto',
        marginLeft: 'auto',
        itemAlign: 'center',
        borderRadius: 5,
        borderShadow: 'none',
        padding: 15,
        marginBottom: 20,

    },
    btnStyle: {
        textAlign: 'center',
        color: '#fff',
        border: '1px solid #4ca7ab',
        background: '#4ca7ab',
        padding: '10px 42px',
        letterSpacing: '1.5px',
        fontWeight: 600,
        fontSize: '1.1rem',
        marginTop: 20,
        marginBottom: 25,
        borderRadius: 5,
        cursor: 'pointer',
        '&:hover': {
            background: '#4ca7ab',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    }

})


class QuestionAndAnswer extends React.Component {

    state = {
        body: '',
        title: '',
        loading: false
    }

    handleInputChange = ({ target: { name, value } }) => {
        this.setState({
            [name]: value
        })
    }
    handleSubmit = async (event, data) => {
        const { classes, buttonType, userProfile, userId, courseInfo } = this.props
        const { body, title } = this.state;

        this.setState({ loading: true })

        const firestore = firebase.firestore();
        const questionsRef = firestore.collection("questions").doc(courseInfo.id);
        var questionArray;

        let theQuestion = {
            content: body,
            title: title,
            created: new Date(Date.now()),
            studentFirstName: userProfile.firstName,
            studentLastName: userProfile.lastName,
            studentId: userId,
            questionId: cuid(),
            answers: [],
        }

        await questionsRef.update({
            list: firebase.firestore.FieldValue.arrayUnion(theQuestion)
        })
        // await questionsRef.get().then(doc =>
        //     questionArray = doc.data().list
        // ).catch(err => console.log(err));

        // questionArray.push(theQuestion)

        // questionsRef.set({list:questionArray})

        this.setState({ loading: false, body: '', title: '' })
    }

    render() {

        const { classes, buttonType, courseInfo, userProfile } = this.props

        return (


            <div>
                <Form
                    className={classes.formStyle}
                    onSubmit={this.handleSubmit}
                >
                    <div>
                        <Typography
                            className={classes.questionHeader}
                        >
                            Ask a new question
                        </Typography>

                        <Typography
                            style={{ fontSize: '1rem', letterSpacing: '1px', marginBottom: 20 }}
                        >

                            Purchase the package to access our Q &#38; A. Our Tutors Are Ready to Answer Your Questions!
                        </Typography>
                    </div>
                    <Form.Field>

                        <TextArea
                            name='title'
                            placeholder="Question title"
                            className={classes.inputStyle}
                            style={{ marginTop: 20, }}
                            onChange={this.handleInputChange}
                            value={this.state.title}
                            fullWidth
                            rows='1'
                            disabled={buttonType === 'download' ? false : true}
                        />
                    </Form.Field>
                    <Form.Field>

                        <TextArea
                            name='body'
                            placeholder="Describe what you are trying to ask..."
                            className={classes.inputStyle}
                            value={this.state.body}
                            rows='7'
                            onChange={this.handleInputChange}
                            disabled={buttonType === 'download' ? false : true}
                        />
                    </Form.Field>

                    {buttonType === 'download' ?
                        <Button
                            className={classes.btnStyle}
                            type='submit'
                            disabled={this.state.loading}
                        >SUBMIT
                    </Button>
                        :
                        <p></p>
                    }
                </Form>

            </div>

        )
    }

}

export default withStyles(styles)(QuestionAndAnswer)

// export default withStyles(styles)(withFirestore(
//     connect(
//         null, null
//     )(
//         reduxForm({ form: "questionsForm", enableReinitialize: true})(
//             QuestionAndAnswer
//         )
//     )
// ));
