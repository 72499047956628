import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid';
import home from './assets/aboutUs.png';

const styles = theme => ({
    aboutUsImage: {
        height: '100%',
        width: '100%',
        margin: 0,
        paddingBottom: 80,
        backgroundImage: 'linear-gradient( #669FFF, #4CA7AB)',
        [theme.breakpoints.down('xs')]: {
            maxHeight: 400,
        }

    },
    widthStyle: {
        maxWidth: '1180px',
        marginRight: 'auto',
        marginLeft: 'auto',
        [theme.breakpoints.down('xs')]: {
            maxWidth: '90%',

        }

    },
    aboutUsHeader: {
        display: 'block',
        paddingTop: 150,
        fontSize: '4rem',
        color: 'white',
        paddingBottom: 10,
        letterSpacing: '2px',
        fontFamily: 'proxima-nova, sans-serif ',
        [theme.breakpoints.down('xs')]: {
            paddingTop: 140,
            fontSize: '3rem',

        }

    },
    contactUsQuestion: {
        color: 'white',
        fontSize: '2rem',
        margin: 0,
        padding: 0,
        letterSpacing: '1.4px',
        fontFamily: 'proxima-nova, sans-serif ',
        fontWeight: 600,
        [theme.breakpoints.down('xs')]: {
            textAlign: 'left',

        }
    },
    aboutUsMain: {
        maxWidth: 1180,
        marginRight: 'auto',
        marginLeft: 'auto',
        fontSize: '1.5rem',
        paddingLeft: 40,
        paddingRight: 40,
        paddingTop: 100
    },
    aboutUsH3: {
        color: '#4ca7ab',
        marginTop: 100,
        fontSize: '2rem',
        fontWeight: 600
    },
    tableStyle: {
        border: '1px solid black',
        borderCollapse: 'collapse',
        padding: 10

    },
  

});


export const PrivacyPolicyPage = ({ classes }) => (
    <div className={classes.root}>
        <div className={classes.aboutUsImage}>
            <div className={classes.widthStyle}>
                <Grid container>
                    <Grid item md={4}>
                        <h2 className={classes.aboutUsHeader} >Privacy Policy</h2>
                    </Grid>
                </Grid>
            </div>
        </div>

        <div className={classes.aboutUsMain}>
            <h3 className={classes.aboutUsH3}>1. General</h3>
            <p>
                Tossmath (whose registered office is at 97 Pemberton Ave, Toronto, Ontario, Canada) (“Tossmath”, “we”, “our” or “us”), acting as data controller, is committed to protecting andrespecting your privacy. This notice (“Privacy Notice”) is designed to tell you about our practices regarding the collection, use and disclosure of information that you may provide via this website or the (“Platform“).
            </p>
            <p>
                This Privacy Notice (together with our Terms and Conditions) establishes the basis on which any personal data we collect from you, or that you provide us, will be processed. Please readthe following carefully to understand our policy and practices regarding your personal data and how it will be administered and stored.
            </p>
            <h3 className={classes.aboutUsH3}>2. What information do we collect and how long do we keep it?</h3>
            <p>We may collect and process the following personal data:</p>
            <br />
            <p>2.1. The information you provide us with</p>
            <p>You may provide us with information, including information that can be used to identify you (“Personal Data“) by using our Platform, filling out forms on the Platform (i.e. the sign-up procedure), when you participate in any of our competitions, promotions or surveys, when you correspond with us by phone, e-mail or otherwise, and when you report a problem with the Platform.</p>
            <p>The information you give us may include:</p>
            <p>2.1.1. Mandatory information required to register for the service we provide on our Platform, including your name, email address, date of birth, cell phone number, IP address and password are required. All these fields are mandatory. Tossmath will not be able to provide you with the services offered on our Platforms if the required information is not provided. Consequently, you will not be able to register for a user account on our Platforms</p>
            <p>2.1.2. A photograph;</p>
            <p>2.1.3. A postal address;</p>
            <p>2.1.4. Details of your qualifications;</p>
            <p>2.1.5.Your mini-biography;</p>
            <p>2.1.6. A record of any correspondence between you and us;</p>
            <p>2.1.7. A record of any requests you have made or related ads you have placed with or through our Platform;</p>
            <p>2.1.8. Accounting details or financial transactions including transactions carried out through our Platform or otherwise. This may include information such as your payment card or bank account details, details of lessons that you have offered through our Platforms;</p>
            <p>2.1.9. Details of your visits to our Platforms and the resources that you access;</p>
            <p>2.1.10. Your replies to any surveys or questionnaires, such as your reviews on the tutors you have shared with other members of our community. Such information may be used for analytic & user understanding purposes;</p>
            <p>2.1.11. Information we may require from you when you report a problem with our Platform orour service, such as the subject of your request for support;</p>
            <p>2.1.12. Location information when you have given consent to the collection and processing of this data;</p>
            <p>2.1.13. Certificates and other documents that you have agreed to provide to us.</p>
            <p>2.1.14. Intro video that you have agreed to provide to us.</p>
            <p>2.2. Information we collect automatically</p>
            <p>2.2.1. If you sign up via the social media authentication method, Tossmath will access certain Personal Data (e.g. first name, last name, picture, email, number of Facebook friends, etc.) on your social media account pursuant to the applicable terms and conditions of these social media platforms. We may also collect some of your Personal Data when you interact with third-party social media features, such as the “Like” functions.</p>
            <p>2.2.2. With regard to each of your visits to our Platforms we may collect, in concordance withthe applicable laws, and your consent where required, information related to the devices you
use and the networks you are connected to when using our services. This may include the following information: your IP address, log-in information, browser type and version, browser plug-in types and versions, operating system and platform, advertising identifier, information about your visit including the URL clickstream to, through, and from our Platform, products you viewed or searched for, download errors, length of visits to certain pages, page interaction and any phone number used to call our customer service department. We collect this information through the use of various technologies including cookies.</p>
            <p>2.2.3. We also collect aggregated information regarding your activity on our Platform (such as the number of users you connected to, your message response rate, etc.). Such information may be published on your public profile on our Platform.</p>
            <p>2.3. Data retention</p>
            <p>Your data will be retained and secured indefinitely unless you explicitly request the completedeletion of all your data. To do so, please send an email to support@Tossmath.ca entitled Data Deletion.</p>
            <h3 className={classes.aboutUsH3}>3. How do we use the information we collect from you?</h3>
            <p>We will use the information we collect:</p>
            <table className={classes.tableStyle}>
                <tr>
                    <th className={classes.tableStyle}>PURPOSE</th>
                    <th className={classes.tableStyle}>LEGAL BASIS</th>
                </tr>
                <tr>
                    <td className={classes.tableStyle}>3.1. To carry out our obligationsarising from any contracts entered intobetween you and Tossmath and toprovide you with the information andservices that you requested from us;</td>
                    <td className={classes.tableStyle}>This processing is necessary for theperformance of our mutual contractualobligations.</td>
                </tr>
                <tr>
                    <td className={classes.tableStyle}>
                        3.2. To send you service-relatedinformation by email and/or textmessage and/or any othercommunication means (e.g. yourrequest confirmation)
                    </td>
                    <td className={classes.tableStyle}>
                        This processing is necessary for theperformance of our mutual obligationsand/or carried out with your consent.
                    </td>
                </tr>
                <tr>
                    <td className={classes.tableStyle}>
                        3.3. To enable you to personalise youruser profile on our Platform
                    </td>
                    <td className={classes.tableStyle}>
                        This processing is carried out withyour consent.
                    </td>
                </tr>
                <tr>
                    <td className={classes.tableStyle}>
                        3.4. To enable you to communicateand interact with other members aboutour services or about the lessons youhave/will share with other membersand/or to organise such lessons
                    </td>
                    <td className={classes.tableStyle}>
                        This processing is necessary for theperformance of our mutual contractualobligations and/or carried out withyour consent.
                    </td>
                </tr>
                <tr>
                    <td className={classes.tableStyle}>
                        3.5. To give you access to our supportservices and to enable you tocommunicate with our memberrelations team
                    </td>
                    <td className={classes.tableStyle}>
                        This processing is (i) necessary forthe performance of our mutualcontractual obligations, (ii) carried outwith your consent and/or (iii)necessary for the establishment,exercise or defence of legal claims.
                    </td>
                </tr>
                <tr>
                    <td className={classes.tableStyle}>
                        3.6. To ensure compliance with (i)applicable laws, (ii) our Terms andConditions, and (iii) our PrivacyNotice. Certain breaches that weregard as inappropriate may lead tothe suspension of your account
                    </td>
                    <td className={classes.tableStyle}>
                        This processing is necessary (i) forthe performance of our mutualcontractual obligations, (ii) forcompliance with our legal obligationsand/or (iii) for the establishment,exercise or defence of legal claims.
                    </td>
                </tr>
                <tr>
                    <td className={classes.tableStyle}>
                        3.7.To send you, in accordance withapplicable laws and where required,with your consent, marketing materialsand information necessary to facilitatethe service or the lesson requestprocess and to make yoursuggestions and recommendationsabout goods or services related to ourservices that may interest you. Wealso use your data to target you inorder to serve our ads on social mediaplatforms. You can learn more abouthow these features work, and the datathat we obtain about you, by visitingthe relevant sections of the third partysocial media platforms and
                    </td>
                    <td className={classes.tableStyle}>
                        This processing is based on yourconsent and/or our legitimate interest(i.e. providing you with meaningfuladvertising).
                    </td>
                </tr>
                <tr>
                    <td className={classes.tableStyle}>
                        3.8. To notify you about changes toour services
                    </td>
                    <td className={classes.tableStyle}>
                        This processing is necessary (i) forthe performance of our mutualcontractual obligations, (ii) forcompliance with our legal obligationsand/or (iii) the establishment, exerciseor defence of legal claims.
                    </td>
                </tr>
                <tr>
                    <td className={classes.tableStyle}>
                        3.9. To verify the informationcontained on yourcertificates/diplomas and otherdocuments that may be collected fromyou at the time of registration or at anyother time during your use of ourPlatforms
                    </td>
                    <td className={classes.tableStyle}>
                        This processing is based on (i) yourconsent, (ii) carried out for theestablishment, exercise or defence oflegal claims and/or (iii) for compliancewith our legal obligations.
                    </td>
                </tr>
                <tr>
                    <td className={classes.tableStyle}>
                        3.10. To administer our Platform andfor internal operations, includingtroubleshooting, data analysis, testing,research, analytic and surveypurposes
                    </td>
                    <td className={classes.tableStyle}>
                        This processing is based on ourlegitimate interest (i.e. ensuring thesecurity of our Platform and improvingits features)
                    </td>
                </tr>
                <tr>
                    <td className={classes.tableStyle}>
                        3.11. To improve our Platforms toensure that content is presented in themost effective manner for you and foryour device
                    </td>
                    <td className={classes.tableStyle}>
                        This processing is based on ourlegitimate interest (i.e. providing youwith meaningful content).
                    </td>
                </tr>
                <tr>
                    <td className={classes.tableStyle}>
                        3.12. To allow you to participate ininteractive features of our service,when you choose to do so
                    </td>
                    <td className={classes.tableStyle}>
                        This processing is necessary (i) forthe performance of our mutualcontractual obligations and/or (ii)based on your consent.
                    </td>
                </tr>
                <tr>
                    <td className={classes.tableStyle}>
                        3.14. As part of our efforts to keep ourPlatforms safe and secure and
                    </td>
                    <td className={classes.tableStyle}>
                        This processing is based on (i) ourlegitimate interest (ensuring thesecurity of our Platforms), (ii) carriedout for the establishment, exercise ordefence of legal claims and/or (iii) forcompliance with our legal obligations.
                    </td>
                </tr>
                <tr>
                    <td className={classes.tableStyle}>
                        3.15 To measure or understand theeffectiveness of advertising we serveto you and others, and to deliverrelevant advertising to you.
                    </td>
                    <td className={classes.tableStyle}>
                        This processing is based on (i) ourlegitimate interest (i.e. measuring andoptimizing the efficiency of ouradvertising campaigns) and/or (ii) yourconsent.
                    </td>
                </tr>
            </table>

            <h3 className={classes.aboutUsH3}>4. Who are the recipients of the Information we collect from you and for which purposes?</h3>
            <p>4.1. When you use our services, some information about you is shared with the members of our communities, either on your public profile or during the request process (e.g. we give your telephone number to the members with whom you will have a lesson with).</p>
            <p>4.2. We may receive and send information about you, including your Personal Data, if you use any of the platforms we operate or from other Tossmath entities and affiliates, for the purposes outlined in this Privacy Notice.</p>
            <p>4.3. We are also working closely with third parties which may be the recipients of your Personal Data such as:</p>

            <ul>
                <li>Our business partners who are social media platforms and which may provide you with connecting services, such as the connection of the information of your profile, from their social media platforms to our Platforms;</li>
                <li>Our business partners who may advertise their services on our Platforms and to which youmay decide to sign up for: these services may be any service related to our services such as network services, banking services, etc;</li>
                <li>Our business partners who may advertise our services on their websites;</li>
                <li>Our sub-contractors in technical, payment services, or analytics providers.</li>
            </ul>
            <p>4.4. We only share your Personal Data with any of these third parties in the following cases:</p>
            <p>4.4.1. Where it is necessary to involve a third party service provider, for the performance of any contract we enter into with you in order to facilitate or extend our services (e.g. if we charge you any fees or collect any money from you in relation to any services on the Platforms);</p>
            <p>4.4.2. As part of the request process and in order to provide the services, information such as your name, photography, mobile phone number and/or email address, may be displayed on the Platforms and/or passed on to tutors (if you are a student) or a students (if you are a tutor);</p>
            <p>4.4.3. As part of our rating system, the reviews you write will be published on the Platforms. The reviews including your abbreviated name and photography are visible to all visitors of the Platforms;</p>
            <p>4.4.4. We use analytics and search engine providers to assist us in the improvement and optimization of our Platform;</p>
            <p>4.4.5. It is explicitly requested by you (e.g. with when using social media authentication methods);</p>
            <p>4.4.6. We may distribute parts of our Platforms (including the profile you have posted) for display on our business partners’ websites through API or widgets. In these cases, some information from your public profile may be displayed on these websites;</p>
            <p>4.4.7. Tossmath may also disclose your information if required to do so by law or in a good faith belief that such access, preservation or disclosure is reasonably necessary to (i) respond to claims asserted against Tossmath, (ii) to comply with legal proceedings, (iii) to enforce any agreement with our users such as our Terms and Conditions and our Privacy Policy, (iv) in the event of an emergency involving the danger of public health, death or physical injury to a person (v) in the framework of investigation or (vi) to protect the rights, property or personal safety of Tossmath, its members or others ;</p>
            <p>4.4.8. In the event that we sell or buy any business or assets, in which case we may discloseyour Personal Data to the prospective seller or buyer of such business or assets; and</p>
            <p>4.4.9. If Tossmath or all or part of its assets are acquired by a third party, in which case Personal Data held by it about its members will be one of the transferred assets.</p>
            <p>4.5. In accordance with applicable laws and where required with your consent, we may combine information about you, including your Personal Data and cookie information, we send to and receive from our business partners. We may use this information and the combined information for the purposes set out above.</p>
            <p>4.6. We draw your attention on the fact that if you decide to share your information, including Personal Data, with us through our business partners’ connecting services, any of our business partners’ privacy policies and/or notices may also be applicable to you, in addition to this Privacy Policy. We do not control the collection and/or the processing of your information eventually made by our business partners on their own platforms.</p>
            <h3 className={classes.aboutUsH3}>5. How do we use and moderate your messages?</h3>
            <p>5.1. We may review, scan, or analyse the messages you exchange with other members of our community through our Platforms for fraud prevention, service improvement, customer support purposes, enforcement of the contracts entered into with our members (such as our Terms and Conditions). For example, in order to prevent the circumventing of our online search engine, we may scan and analyse messages sent through our Platforms to check thatthey do not include any contact details or references to other websites.</p>
            <p>5.2. We will never scan or analyse your messages with other members of our community for commercial and advertising purposes. We may use automated methods to carry out the moderation of these messages, but no automated individual decision-making is performed inthis regard.</p>
            <h3 className={classes.aboutUsH3}>6. Targeted ads on social media platforms and our communications sent by e-mail and/or text messages</h3>
            <p>In accordance with applicable laws and where required with your consent, we may use the information you give us on our Platforms for electronic direct marketing purposes (e.g. (i) receiving our newsletters, invitations to our events or other communications that we think may interest you or (ii) serving you with targeted advertising on social media platforms or third parties websites).</p>
            <p>For electronic marketing communications: You can withdraw your consent at any time by (i) unticking the relevant box in your member account, (ii) clicking on the unsubscribe link we provide in each Tossmath communication sent to your attention or (iii) contacting us using the contact details provided in section 12 below.</p>
            <p>For targeted ads and content:</p>
            <p>On social media (e.g. Facebook and Twitter): you can object at any time by configuring your settings regarding advertisement via your social media account;</p>
            <h3 className={classes.aboutUsH3}>7. Is your Information being transferred? How and where?</h3>
            <p>In principle, we store the Personal Data that we hold about you in the Canada. However, since for example some of our service providers are based in countries outside of the Canada (“third countries”), we also transfer some of your data to third countries. This may include third countries where the Government of Canada has not taken the decision that such third country ensures an adequate level of protection (for example the European Union). In that case, we ensure that the transfer is performed in accordance with the applicable legislation and that appropriate safeguards have been put in place (especially standard contractual clauses as issued by the Government of Canada) in order to guarantee a sufficient level of protection of individuals’ private life and fundamental rights.</p>
            <h3 className={classes.aboutUsH3}>8. What are your rights in respect of your personal data?</h3>
            <p>8.1. You are entitled to receive a copy of your personal data that is in our possession (your right of data access).</p>
            <p>8.2 You may request the deletion of personal data or the correction of inaccurate personal data (your right to erasure and rectification). Please note that we may keep certain information concerning you, as required by law, or when we have a legal basis to do so (e.g.,our legitimate interest to keep the platform safe and secure for other users).</p>
            <p>8.3 You have the right to object at any time (i) to the processing of your personal data for the purpose of direct marketing, or (ii) to the processing of your personal data for other purposes on grounds relating to your particular situation (your right to object to processing). Please note that in the latter case, this right only applies if the processing of your personal data is based on our legitimate interest.</p>
            <p>8.4 You have the right to restrict the processing of your personal data (your right to restriction of processing). Please note that this only applies if (i) you contested the accuracy of your personal data and we are verifying the accuracy of the personal data, (ii) you exercised your right to object and we are still considering, as foreseen by the applicable law,whether our legitimate grounds to process your personal data in that case override your interests, rights and freedoms; or (iii) your personal data has been processed by us in an unlawful way but you either oppose the erasure of the personal data or want us to keep yourpersonal data in order to establish, exercise or defend a legal claim.</p>
            <p>8.5 You have the right to receive and/or have us transfer to another data controller a subset of personal data, that concern you and that you provided us with, and which we process for the performance of our contract or because you previously consented to it, in a structured, commonly used and machine-readable format (your right to data portability).</p>
            <p>8.6 To exercise your rights, please contact the Group Data Protection Officer (see under Article 12).</p>
            <p>8.7.You also have the right to make a complaint to the relevant data protection supervisory authority or to seek a remedy through the courts if you believe that your rights have been
breached.</p>
            <h3 className={classes.aboutUsH3}>9. Confidentiality of your password</h3>
            <p>Where you have chosen a password which enables you to access certain parts of our Platforms, you are responsible for keeping this password confidential. We ask you not to share a password with anyone.</p>
            <h3 className={classes.aboutUsH3}>10. Links to other websites and social media</h3>
            <p>Our Platforms may, from time to time, contain links to and from the websites of our partner networks, advertisers and affiliates. If you follow a link to any of these websites, please note that these websites have their own privacy practices and that we do not accept any responsibility or liability for these policies. Please check these policies before you submit anyPersonal Data to these websites.</p>
            <h3 className={classes.aboutUsH3}>11. Changes to our Privacy Policy</h3>
            <p>Any changes we may make to our Privacy Notice in the future will be posted on this page. Where appropriate, we will notify you or seek your consent. Please check back frequently to see any updates or changes to our Privacy Policy.</p>
            <h3 className={classes.aboutUsH3}>12. Contact &#38; Data Protection Officer</h3>
            <p>If at any time you would like to contact us with your views about our privacy practices, or with any enquiry relating to your Personal Data, please use one of the following means:</p>
            <ul>
                <li>via our contact page; or</li>
                <li>via email to our Data Protection Officer under support@Tossmath.ca;</li>
            </ul>

        </div>
    </div>
)
PrivacyPolicyPage.propTypes = {
    classes: PropTypes.object // from enhancer (withStyles)
}

export default withStyles(styles)(PrivacyPolicyPage)