import React from "react";
import firebase from '../../../app/config/firebase'
import { connect } from 'react-redux'
import { Item, List, Form, Button, TextArea } from "semantic-ui-react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import cuid from "cuid";
import { toastr } from 'react-redux-toastr';
import Grid from '@material-ui/core/Grid';

const style = (theme) => ({
    subjectTitle: {
        fontSize: '1.5rem',
        marginLeft: 20,
        color: '#585c60',
        width: '100%'
    },
    cardStyle: {
        display: 'none',
        [theme.breakpoints.down('xs')]: {
            display: 'block',
            marginTop: -30,
            width: '100%',
            position: 'sticky',
            borderRadius: 0,
            zIndex: 3,
            boxShadow: 'none'
        }
    },
    revealButton: {
        margin: '5px 15px 5px 30px',
        backgroundColor: '#4ca7ab',
        color: '#ffffff',
        padding: '5px 20px',
        borderRadius: '10px',
        padding: '5px 20px',
        '&:hover': {
            backgroundColor: '#0A4065',
            color: '#ffffff'
        },
    },

    phoneSize: {
        display: 'none',
        [theme.breakpoints.up('xs')]: {
            display: 'block',
            marginBottom: 15,
            marginLeft: 20
        },
    },
    desktopSize: {
        width: '100%',
        borderBottom: '1px solid #e1e1e6',
        paddingBottom: 15,
        display: 'block',
        marginBottom: 15,
        marginLeft: 20,
        [theme.breakpoints.up('xs')]: {
            display: 'none',

        },
    },
    btnstyle: {
        fontWeight: 600,
        color: 'white',
        backgroundColor: '#ff5d5d',
        padding: '8px 20px',
        borderRadius: 5,
        borderStyle: 'none',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#ff5d5d',
            color: '#ffffff'
        },
    },
    btnstyle1: {
        width: '100%'
    },
    messageInputStyle: {
        width: '100%',
        minHeight: 100,
        maxHeight: 200,
    }
})

const mapState = (state) => ({
    searches: state.searches,
})



class TutorProfileDirectMessage2 extends React.Component {

    state = {
        revealed: false,
        message: '',
        channelId: '',
        error: [],
        loading: false,
    }

    // componentDidMount() {
    //     this.getChannelId();
    // }

    // getChannelId = () => {
    //     const { auth, tutorProfile } = this.props;
    //     const currentUserId = auth.uid;
    //     const channelId = tutorProfile.tutorUid < currentUserId ? `${tutorProfile.tutorUid}_${currentUserId}` : `${currentUserId}_${tutorProfile.tutorUid}`
    //     return this.setState({ channelId: channelId });
    // }

    componentWillUnmount() {
        this.props.setCurrentChannel({ id: "noChannel" });
    }


    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    createMessage = () => {
        const { userProfile, auth } = this.props;

        const message = {
            timestamp: firebase.database.ServerValue.TIMESTAMP,
            user: {
                id: auth.uid,
                name: userProfile.displayName || userProfile.firstName,
                avatar:
                    userProfile.avatarUrl || userProfile.photoURL || "/assets/user.png"
            },
            content: this.state.message,
            read: false
        };

        return message;
    };

    sendMessage = (event) => {
        const { message,  } = this.state;
        const {channelId} = this.props
        if (message) {
            this.setState({ loading: true });

            firebase.database().ref('chats')
                .child(channelId)
                .child("messages")
                .push()
                .set(this.createMessage())
                .then(() => {

                    firebase.database().ref('chats')
                        .child(channelId)
                        .child("lastMessage")
                        .set(this.createMessage())
                    this.setState({
                        loading: false,
                        message: "",
                        errors: [],

                    });
                    toastr.success("Success", "Message is Sucessfully Sent")
                })
                .catch(err => {
                    console.error(err);
                    this.setState({
                        loading: false,
                        errors: this.state.errors.concat(err)
                    });
                });
                event.preventDefault();
        } else {
            this.setState({
                errors: this.state.errors.concat({ message: "Add a Message" })
            });
        }
    };

    render() {
        const {
            tutorProfile,
            authenticated,
            openModal,
            auth,
            classes
        } = this.props
        const { loading, message } = this.state
        return (
            <Card className={classes.cardStyle}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography className={classes.subjectTitle}>Message {tutorProfile.firstName}</Typography>
                    </Grid>


                    <Grid item xs={12}>

                        <CardContent >
                            <List relaxed divided>
                                {authenticated ?
                                    <div>
                                        <Form onSubmit={this.sendMessage}  >
                                            <TextArea
                                                className={classes.messageInputStyle}
                                                fluid
                                                name='message'
                                                onChange={this.handleChange}
                                                value={message}
                                                placeholder={`Write Your Message to ${tutorProfile.firstName}`}
                                                onSubmit={this.sendMessage}
                                            />
                                            <Item.Content style={{ textAlign: 'right' }}>
                                                <button
                                                    className={classes.btnstyle1 + ' ' + classes.btnstyle}
                                                    style={{ marginTop: '10px' }}
                                                    disabled={loading}
                                                    onClick={this.sendMessage}
                                                >SEND
                                                </button>
                                            </Item.Content>
                                        </Form>
                                    </div>
                                    :
                                    <div>
                                        <Item.Content>
                                            <button
                                                className={classes.btnstyle}
                                                onClick={() => openModal('UnauthModal')}
                                                loading={loading}  >
                                                LogIn to View</button>
                                        </Item.Content>
                                    </div>
                                }

                            </List>
                        </CardContent>
                    </Grid>
                </Grid>

            </Card>
        );
    }
}


export default withStyles(style)(connect(mapState)(TutorProfileDirectMessage2));
