import React, { Component } from "react";
import { Form, Header, Divider, Button } from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import { NavLink, Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import MyCoursesList from "./MyCoursesList";
import Slider from 'react-slick'
import styled from 'styled-components'
import ChatUserInfo from './Chat/ChatUserInfo'

const styles = theme => ({
    basicbox: {
        marginTop: 130,
        width: "95%",
        marginLeft: "auto",
        marginRight: "auto",
        boxShadow: 'none',
        [theme.breakpoints.down("xs")]: {
            paddingTop: 10,
            width: "100%",
            marginTop: 100

        },
    },
    headerbg: {
        margin: '5% 5% 3% 5%',


    },
    displayname: {
        marginTop: '2%',


    },
    bttn: {
        backgroundColor: '#4ca7ab',
        marginBottom: 20
    },
    whitebar: {
        width: 50,
        backgroundColor: 'white',
    },
})



class SettingMainPage extends Component {

    render() {
        const { classes, currentUser, currentUserId } = this.props;

        return (
            <div>

                <Grid container >
                    <Grid item xs={12} md={12}>
                        <Card className={classes.basicbox}>
                            <Link style={{ float: 'left', marginLeft: 20, marginRight: 20 }} as={NavLink} to='/settings' >
                                <Typography className={classes.basic}>Setting</Typography>
                            </Link>
                            <span style={{ float: 'left', marginRight: 20 }}> > </span>
                        </Card>
                        <ChatUserInfo
                            currentUser={currentUser}
                            currentUserId={currentUserId}
                        />
                    </Grid>
                </Grid>
            </div >

        );
    }
};

export default withStyles(styles)(SettingMainPage);