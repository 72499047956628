import React, { Component } from "react";
import AdminUsersListItem from "./AdminUsersListItem";
import InfiniteScroll from 'react-infinite-scroller'
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash'

const styles = theme => ({

});

class AdminUsersList extends Component {


  render() {
    const { users, getNextTutorProfiles, loading, moreTutorProfiles, classes } = this.props;

    return (
      <div >
 
        {users && users.map(user => (
          <AdminUsersListItem
            key={user.id}
            user={user}
          />
        ))}
      </div>
    );
  }
}

export default withStyles(styles)(AdminUsersList);

