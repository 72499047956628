import { SubmissionError, reset } from 'redux-form';
import { closeModal } from '../modals/modalActions';
import { toastr } from 'react-redux-toastr'
import { LOGIN_USER, SIGN_OUT_USER } from './authConstants'


export const sendResetPasswordEmail = (emailAddress) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        //console.log("Eamil Address", emailAddress.email)
        var auth = firebase.auth();
        auth.sendPasswordResetEmail(emailAddress.email).then(function () {
            // Email sent.
            dispatch(closeModal());
            toastr.success("Success", "Password Reset Link Sent. Please Check Your Email")
        }).catch(function (error) {
            // An error happened.
            console.log(error);
            window.alert(error.message)
        });
    }

}

export const sendVerificationEmail = () => {
    return (dispatch, getState, { getFirebase }) => {

        const firebase = getFirebase();
        var user = firebase.auth().currentUser;

        console.log(user)

        user.sendEmailVerification().then(function () {
            // Email sent.
            dispatch(closeModal());
            toastr.success("Success", "Verification Email Sent. Please Check Your Email")
        }).catch(function (error) {
            // An error happened.
            console.log(error);
            window.alert(error.message)
        });

    }

}

export const login = (creds) => {
    return async (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        try {
            await firebase.auth().signInWithEmailAndPassword(creds.email, creds.password);
            dispatch(closeModal())
            window.location.reload();
        } catch (error) {
            console.log(error)
            window.alert(error.message)
            throw new SubmissionError({
                _error: 'Login Failed'
            })
        }
    }
}

// export const signIn = (credentials, showError) => {
//     return (dispatch, getState, {getFirebase, getFirestore}) => {
//         const firebase = getFirebase()
//         const userRef = getFirestore().collection('users')
//         firebase.auth().signInWithEmailAndPassword(
//             credentials.email,
//             credentials.password
//         ).then(() => {
//             dispatch({ type: LOGIN_SUCCESS })
//         }).catch((err) => {
//             const { message } = err
//             showError(message) // flash message
//             dispatch({ type: LOGIN_ERROR, message });
//         });
//     }
// }
export const logout = () => {
    return {
        type: SIGN_OUT_USER
    }
}

export const registerUser = (payload, showError) =>
    async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase();
        const firestore = getFirestore();
        const { email, firstName, lastName } = payload

        const user = firebase.auth();

        await user.createUserWithEmailAndPassword(
            payload.email,
            payload.password
        ).then(res => {
            return firestore.collection('users').doc(res.user.uid).set({
                email: email,
                displayName: `${firstName} ${lastName}`,
                firstName: firstName,
                lastName: lastName,
                createdAt: firestore.FieldValue.serverTimestamp(),
                hasProfile: false

            });
            // send a verification email
        }).then(() => {
            user.currentUser.sendEmailVerification();
            window.alert('Verification email sent. Please Check Your Email. It might take several minutes')
            dispatch(closeModal());
            window.location.reload();
        }).catch((error) => {
            console.log(error)
            window.alert(error.message)
            throw new SubmissionError({
                _error: error.message
            })
        });














        //     try {
        //         //create the user in auth
        //         let createdUser = await firebase
        //             .auth()
        //             .createUserWithEmailAndPassword(user.email, user.password)
        //             .then(() => {
        //                 firebase.auth().currentUser.sendEmailVerification();
        //             }).then(()=> {

        //             })

        //         console.log(createdUser);

        //         let newUser = {
        //             firstName: user.firstName,
        //             lastName: user.lastName,
        //             displayName: user.firstName.concat(' ', user.lastName),
        //             email: user.email,
        //             createdAt: firestore.FieldValue.serverTimestamp(),
        //         };

        //         await firestore.set(`users/${createdUser.user.uid}`, { ...newUser });
        //         var CurrentUser = firebase.auth().currentUser;
        //         CurrentUser.sendEmailVerification().then(function() {
        //             // Email sent.
        //           }).catch(function(error) {
        //             // An error happened.
        //           });

        //         dispatch(closeModal());
        //         window.alert('Verification email sent. Please Check Your')
        //         window.location.reload();
        //     } catch (error) {
        //         console.log(error)
        //         throw new SubmissionError({
        //             _error: error.message
        //         })
        //     }
    }


export const facebookSocialLogin = (selectedProvider) =>
    async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase();
        const firestore = getFirestore();
        try {
            dispatch(closeModal());
            let user = await firebase.login({
                provider: selectedProvider,
                type: 'popup'
            })

            console.log(user)
            if (user.additionalUserInfo.isNewUser) {
                await firestore.set(`users/${user.user.uid}`, {
                    email: user.profile.email,
                    displayName: user.profile.displayName,
                    photoURL: `https://graph.facebook.com/${user.profile.providerData[0].uid}/picture?type=large`,
                    createdAt: firestore.FieldValue.serverTimestamp(),
                    hasProfile: false,
                })
            }
            window.location.reload();
        } catch (error) {
            console.log(error)
        }
    }

export const googleSocialLogin = (selectedProvider) =>
    async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase();
        const firestore = getFirestore();
        try {
            dispatch(closeModal());
            let user = await firebase.login({
                provider: selectedProvider,
                type: 'popup'
            })
            console.log(user);
            if (user.additionalUserInfo.isNewUser) {
                await firestore.set(`users/${user.user.uid}`, {
                    displayName: user.profile.displayName,
                    photoURL: user.profile.avatarUrl,
                    createdAt: firestore.FieldValue.serverTimestamp(),
                    hasProfile: false,
                    email: user.profile.email
                })
            }
            window.location.reload();
        } catch (error) {
            console.log(error)
        }
    }


export const updatePassword = (creds) =>
    async (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        const user = firebase.auth().currentUser;
        try {
            await user.updatePassword(creds.newPassword1);
            await dispatch(reset('account'));
            toastr.success('Success', 'Your password has been updated')
        } catch (error) {
            throw new SubmissionError({
                _error: error.message
            })
        }
    }