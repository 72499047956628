import * as actionTypes from './searchActions';

const initState = {
    isFetching: false,
    langs: [],
    level: '',
    location: '',
    lat: '',
    lng: '',
    list: [],
    distance: [25],
    searchPage: 1,
    priceLow: 1,
    priceHigh: 100,
}

const searchReducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.POST_SEARCH_REQUEST:
            return {
                ...state,
                isFetching: true,
            }
        case actionTypes.POST_SEARCH_SUCCESS:
            return {
                ...state,
                isFetching: false,
                list: action.result,
            }
        case actionTypes.POST_SEARCH_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
            }
        case actionTypes.SEARCH_BY:
            return {
                ...state,
                langs: action.langs,
                level: action.level,
                location: action.location,
                lat: action.lat,
                lng: action.lng,
                distance: action.distance,
                searchPage: action.searchPage,
                priceLow: action.priceLow,
                priceHigh: action.priceHigh,
            }
        case actionTypes.SEARCH_PAGINATE:
            return {
                ...action.state,
               searchPage: action.payload.searchPage,
            }
        case actionTypes.FILTER_SEARCH_LEVELS:
            return {
                ...action.state,
               levels: action.payload.levels,
               filteredList: action.payload.filteredList,
            }
        default:
            return state
    }
}

export default searchReducer;