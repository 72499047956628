import React from "react";
import firebase from '../../../app/config/firebase'
import { connect } from 'react-redux'
import { Item, List } from "semantic-ui-react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Switch from "react-switch";
import { toastr } from "react-redux-toastr";

const style = (theme) => ({
  subjectTitle: {
    fontSize: '1.5rem',
    marginLeft: 20,
    fontWeight: 600
  },
  cardStyle: {
    marginTop: 20,
    overflow: 'visible',
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    boxShadow: 'none',
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      marginTop: 40,
      borderRadius: 0,
      borderRight: 'white',
      borderLeft: 'white',

    }
  },
  // cardStyle: {
  //   paddingTop: 20,
  //   marginTop: '220px',
  //   borderTop: '5px solid #4ca7ab',
  //   [theme.breakpoints.down('xs')]: {
  //     display: 'none'
  //     // marginTop: 20,
  //     // overflow: 'visible',
  //     // width: '95%',
  //     // marginLeft: 'auto',
  //     // marginRight: 'auto'
  //   }
  // },
  cardNotVisible: {
    display: 'none',
  },
  revealButton: {
    margin: '5px 15px 5px 30px',
    backgroundColor: '#4ca7ab',
    color: '#ffffff',
    padding: '5px 20px',
    borderRadius: '10px',
    padding: '5px 20px',
    '&:hover': {
      backgroundColor: '#0A4065',
      color: '#ffffff'
    },
  },
  phoneSize: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      marginBottom: 15,
      marginLeft: 20
    },
  },
  desktopSize: {
    width: '100%',
    borderBottom: '1px solid #e1e1e6',
    paddingBottom: 15,
    display: 'block',
    marginBottom: 15,
    paddingLeft: 20,
    [theme.breakpoints.up('sm')]: {
      display: 'none',

    },
  },
  btnstyle: {
    fontWeight: 600,
    color: 'white',
    backgroundColor: '#4ca7ab',
    padding: '8px 20px',
    borderRadius: 5,
    borderStyle: 'none',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#0A4065',
      color: '#ffffff'
    },
  },
  switchStyle: {
    marginLeft: 10,
    marginBottom: '-4px',
  },




  card: {
    overflow: 'visible'
  },

  textfieldStyle: {
    zIndex: 0
  },
  dialBtnStyle1: {
    padding: '8px 16px',
    backgroundColor: '#fff',
    borderRadius: 5,
    color: '#FF5D5D',
    cursor: 'pointer',
    border: '1px solid #FF5D5D',
    '&:hover': {
      backgroundColor: '#FF5D5D',
      color: '#fff'
    },
  },
  dialBtnStyle2: {
    padding: '8px 16px',
    backgroundColor: '#4ca7ab',
    borderRadius: 5,
    borderStyle: 'none',
    color: 'white',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#0A4065',
    },
  },
  cardHeaderStyle: {
    backgroundColor: '#fff',
    border: '1px solid #e1e1e6',
    [theme.breakpoints.down('xs')]: {
      borderRight: '1px solid #fff',
      borderLeft: '1px solid #fff',
      backgroundColor: '#f8f8f8',

    }
  }

})

const mapState = (state) => ({
  searches: state.searches,
})



class TutorProfileSidebar extends React.Component {

  state = {
    revealed: false,
    visible: false,
    isOpen: false
  }


  handleToggle = () => {
    this.setState({ isOpen: !this.state.isOpen });

  }


  componentDidMount() {
    const { tutorProfile } = this.props;

    if (tutorProfile.contactVisible === undefined) {
      this.setState({ visible: false })
    } else {
      this.setState({ visible: tutorProfile.contactVisible })
    }
  }

  handleChange = (checked) => {
    const { tutorProfile } = this.props;
    firebase.firestore().collection(`profiles`)
      .doc(tutorProfile.tutorUid)
      .update({
        contactVisible: checked,
      })
      .then(() => toastr.success('Success', `${checked ? 'Your Contact Info is Now Visible to Other Users' : 'Your Contact Info is Now Hidden from Other Users'}`))
      .catch(err => console.log(err));
    // console.log(checked)
    this.setState({ visible: checked });
  }

  displayContacts = (phone, email) => {
    const { classes, isOwner } = this.props;



    if (!isOwner && !this.state.revealed) {
      return (
        <div>
          <Item.Content>
            <Item.Description style={{ marginLeft: '30px', fontSize: '1.1rem' }} onClick={this.revealContacts} > {phone.substr(0, 5)}XXXXX <span> <Button className={classes.revealButton}>REVEAL</Button></span></Item.Description>
          </Item.Content>
          <br />
          <Item.Content>
            <p style={{ color: '#585c60', fontSize: '1.5rem' }}>email</p>
            <Item.Description style={{ marginLeft: '30px', fontSize: '1.1rem' }} onClick={this.revealContacts}>{email.substr(0, 5)}XXXXX <span> <Button className={classes.revealButton}>REVEAL</Button></span> </Item.Description>
          </Item.Content>
          <br />
        </div>
      )
    } else {
      return (
        <div>
          <Item.Content>
            <p style={{ color: '#585c60', fontSize: '1.5rem' }}>Phone</p>
            <Item.Description style={{fontSize: '1.1rem'}}>{phone && phone} </Item.Description>
          </Item.Content>
          <br />
          <Item.Content>
            <p style={{ color: '#585c60', fontSize: '1.5rem' }}>email</p>
            <Item.Description style={{fontSize: '1.1rem'}}>{email}</Item.Description>
          </Item.Content>
          <br />
        </div>
      )
    }

  }

  revealContacts = () => {
    const info = [];
    const { tutorProfileId, tutorProfile, userProfile, searches, userAuth } = this.props;
    this.setState({ revealed: true });

    const possibleMatchingsRef = firebase.firestore().collection('possibleMatchings').doc(`${userAuth.uid}_${tutorProfileId}`)
    let searchInfo = {
      searchedSubject: searches.subject,
      searchedLanguages: searches.langs,
      searchedLocation: searches.location,
    }

    info.push(searchInfo);

    possibleMatchingsRef.get().then(snap => {
      if (snap.data() === undefined) {
        possibleMatchingsRef
          .set({
            senderId: userAuth.uid,
            senderName: userProfile.displayName || userProfile.firstName || 'No Name Found',
            receiverId: tutorProfileId,
            receiverName: tutorProfile.displayName || tutorProfile.firstName || 'No Name Found',
            detail: info
          });
      } else {
        snap.data().detail.map(detail => info.push(detail));
        possibleMatchingsRef.update({ detail: info });
      }
      // console.log("In tutoraction newPeopleListFOrSchool", newList)
    })
      .catch(error => console.log(error));
  }

  render() {
    const {
      tutorProfile,
      attendees,
      authenticated,
      openModal,
      loading,
      classes,
      isOwner,
      visibilityClass,
      isOpen
    } = this.props

    return (
      <Card
        className={visibilityClass + ' ' + classes.cardStyle}
      // className={classes.cardStyle}
      ><CardContent
        className={classes.cardHeaderStyle}
        onClick={this.handleToggle}
      >
          <Typography style={{ color: '#585c60', display: 'inline' }} className={classes.subjectTitle}>
            Contact Information
          </Typography>
          {this.state.isOpen ? <span style={{ float: 'right', marginTop: 5 }}><i
            class="material-icons"
            style={{ color: '#585c60' }}
          >
            keyboard_arrow_up
            </i></span> : <span style={{ float: 'right', marginTop: 5 }}><i
            class="material-icons"
            style={{ color: '#585c60' }}
          >
            keyboard_arrow_down
            </i></span>}
          {isOwner && <div style={{ display: 'inline' }} >
            <Switch
              className={classes.switchStyle}
              onChange={this.handleChange.bind(this)}
              checked={this.state.visible}
              onColor="#c9e9ff"
              onHandleColor="#4ca7ab"
              handleDiameter={20}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={13}
              width={35}
            />
          </div>
          }
        </CardContent>
        {this.state.isOpen ? <CardContent style={{ marginLeft: 10 }}>
          <List relaxed divided>
            {authenticated ?
              (this.displayContacts(tutorProfile.phone, tutorProfile.email)) 
              :
              <div>
                <Item.Content>
                  <button
                    className={classes.btnstyle}
                    onClick={() => openModal('UnauthModal')}
                    loading={loading}  >
                    LogIn to View</button>
                </Item.Content>
              </div>
            }

          </List>
        </CardContent> : " "}



      </Card>
    );
  }
}


export default withStyles(style)(connect(mapState)(TutorProfileSidebar));
