import firebase from '../../app/config/firebase'

export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_ERROR = 'USER_UPDATE_ERROR'

export const GET_USER_REQUEST= 'GET_USER_REQUEST'
export const GET_USER_SUCCESS= 'GET_USER_SUCCESS'
export const GET_USER_ERROR= 'GET_USER_ERROR'

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR'

export const UPDATE_USER_IMG_REQUEST = 'UPDATE_USER_IMG_REQUEST'
export const UPDATE_USER_IMG_SUCCESS = 'UPDATE_USER_IMG_SUCCESS'
export const UPDATE_USER_IMG_ERROR = 'UPDATE_USER_IMG_ERROR'

export const GET_USER_FOLLOWERS_REQUEST = 'GET_USER_FOLLOWERS_REQUEST'
export const GET_USER_FOLLOWERS_SUCCESS = 'GET_USER_FOLLOWERS_SUCCESS'
export const GET_USER_FOLLOWERS_ERROR = 'GET_USER_FOLLOWERS_ERROR'

export const UPDATE_HAS_PROFILE = 'UPDATE_HAS_PROFILE'

export const getUserRequest = () => {
    return (dispatch, _, { getFirebase, getFirestore }) => {
        const currentUser = getFirebase().auth().currentUser
        if (currentUser !== null) {
            dispatch({ type: GET_USER_REQUEST });
            
            const uid = currentUser.uid
            const userRef = getFirestore().collection('users').doc(uid)
            
            userRef.get().then((doc) => {
                dispatch({ 
                    type: GET_USER_SUCCESS,
                    me: doc.data(),
                })
            }).catch(error => {
                console.log("Error getting document:", error)
                dispatch({ 
                    type: GET_USER_ERROR,
                    error: error
                })
            })
        }
    }
}

export const updateUserRequest = (payload, showSuccess, showError, history) => {
    return (dispatch, _, { getFirebase, getFirestore }) => {
        dispatch({ type: UPDATE_USER_REQUEST });
        const { 
            firstName,
            lastName,
        } = payload

        const uid = getFirebase().auth().currentUser.uid
        const firestore = getFirestore()
        const batch = firestore.batch()
        const userDoc = firestore.collection('users').doc(uid)
        const profileDoc = firestore.collection('profiles').doc(uid)

        // update displayName
        batch.update(userDoc, {
            firstName,
            lastName,
            displayName: `${firstName} ${lastName}`
        })
        batch.update(profileDoc, {
            "user.firstName": firstName,
            "user.lastName": lastName,
        })
        // commit
        batch.commit().then(() => {
            showSuccess("Successfully updated!")
            dispatch({ 
                type: UPDATE_USER_SUCCESS,
            });
        }).catch(error => {
            const { message } = error
            showError(message)
            dispatch({ type: UPDATE_USER_ERROR, error });
        })
    }
}

/*
 * Update current user's avatarUrl
 * Also, update user's profile avatarUrl
 */
export const updateUserImgRequest = (payload, showSuccess, showError) => {
    return (dispatch, _, { getFirebase, getFirestore }) => {
        dispatch({ type: UPDATE_USER_IMG_REQUEST });

        const uid = getFirebase().auth().currentUser.uid
        const firestore = getFirestore()
        
        const batch = firestore.batch();
        const userDoc = firestore.collection('users').doc(uid)
        const profileDoc = firestore.collection('profiles').doc(uid)

        // update user's avatarUrl
        batch.update(userDoc, "avatarUrl", payload) 
        // update user's profile avatarUrl
        batch.update(profileDoc, "user.avatarUrl", payload) 

        // Commit the batch
        batch.commit().then(() => {
            dispatch({ 
                type: UPDATE_USER_IMG_SUCCESS,
                avatarUrl: payload
            });
            showSuccess("Successfully updated!")
        }).catch(error => {
            const { message } = error
            showError(message)
            dispatch({ type: UPDATE_USER_IMG_ERROR, error });
        })
    }
}

export const getFollowersRequest = (payload) => {
    return (dispatch, _, {getFirebase, getFirestore}) => {
        const uid = getFirebase().auth().currentUser.uid
        const ref = getFirestore().collection('followers').doc(uid).collection('userFollowers')
        const followers = []
        ref.get().then(querySnapshot => {
            querySnapshot.forEach(doc => {
                // doc.data() is never undefined for query doc snapshots
                followers.push(doc.data())
            });
            dispatch({ 
                type: GET_USER_FOLLOWERS_SUCCESS, 
                followers: followers 
            });
        }).catch(error => {
            dispatch({ type: GET_USER_FOLLOWERS_ERROR, error });
        })
    }
}