import firebase from "../../../app/config/firebase";
import React, { Component } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { withFirestore, firestoreConnect, isEmpty } from "react-redux-firebase";
import { compose } from "redux";
//Utils
import Script from "react-load-script";
import { toastr } from "react-redux-toastr";
import { Link } from 'react-router-dom'

//Components
import TutorProfileChat from "./TutorProfileChat";
import TutorProfileHeader from "./TutorProfileHeader";
import TutorProfileInfo from "./TutorProfileInfo";
import TutorProfileEducation from "./TutorProfileEducation";
import TutorProfileExperience from "./TutorProfileExperience";
import TutorProfileDescription from "./TutorProfileDescription";
import TutorProfileSidebar from "./TutorProfileSidebar";
import TutorProfileSidebar1 from "./TutorProfileSidebar1";
import TutorProfileDirectMessage from "./TutorProfileDirectMessage";
import TutorProfileRecommendation from "./TutorProfileRecommendation";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import Rating from "../../../app/common/form/Rating";
//helpers && actions
import {
  objectToArray,
  createDataTree
} from "../../../app/common/util/helpers";
import { goingToEvent, cancelGoingToEvent } from "../../user/userAction";
import {
  addTutorProfileComment,
  deleteTutorProfileComment
} from "../tutorActions";
import { openModal, closeModal } from "../../modals/modalActions";
import { getUserRequest } from "../../user/userActions";
import {
  getProfileRequest,
  getReviewsRequest,
  updateProfileEducationRequest,
  removeProfileEducationRequest,
  updateProfileLanguageRequest,
  removeProfileLanguageRequest,
  updateProfileDescriptionRequest,
  updateProfileSubjectRequest,
  removeProfileSubjectRequest,
  updateProfileExperienceRequest,
  removeProfileExperienceRequest,
  createReviewRequest,
  updateResumeUserInfoRequest,
  setEmptyProfile
} from "../TutorSearchForm/profileActions";
import _ from "lodash";
import { withStyles } from "@material-ui/core/styles";
import Background from "../TutorForm/assets/resume_BG.png";
import TutorProfileDirectMessage2 from "./TutorProfileDirectMessage2";
import { setCurrentChannel } from '../../user/Settings/Chat/chatActions'
import Button from '@material-ui/core/Button';
import Typography from "material-ui/styles/typography";
import TutorPlayVideo from "./TutorPlayVideo";
import TutorProfileVideoUpload from "./TutorProfileVideoUpload";
import BuyContent from '../../TestPrep/testPrepContents/BuyContent'

const styles = theme => ({
  filterDash: {
    maxWidth: "1240px",
    marginLeft: "auto",
    marginRight: "auto"
  },
  isOwnerStyle: {
    marginTop: -40,
    [theme.breakpoints.down('xs')]: {
      marginTop: -20,
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  agreed: {
    marginTop: '20px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: 0
    }
  },
  notVisible: {
    display: 'none'
  },
  msgStyle: {
    borderRadius: 5,
    backgroundColor: "#ff5d5d",
    color: 'white',
    marginTop: 15,
    padding: '9px 22px',
    marginBottom: 7,
    position: 'fixed',
    zIndex: 5,
    float: 'right',
    "&:hover": {
      backgroundColor: '#ff5d5d'
    }
  },
  msgStyle2: {
    backgroundColor: "white",
    color: '#585c60',
    marginTop: 14,
    zIndex: 5,
    float: 'right',
    fontSize: '1.1rem'

  },
  responsiveStyle: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      zIndex: 2,
      position: 'fixed',
      bottom: 0,
      height: '70px',
      width: '100%',
      backgroundColor: 'white',
      color: '#585c60',
      boxShadow: '1px 2px 5px 3px #e1e1e1'
    }
  },
  responsiveStyle2: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      zIndex: 2,
      position: 'fixed',
      bottom: 0,
      maxHeight: '400px',
      width: '100%',
      backgroundColor: 'white',
      color: '#585c60',
      boxShadow: '1px 2px 5px 3px #e1e1e1'
    }
  },
  msgToTr: {
    display: 'flex',
    marginLeft: 20,
    marginTop: '22px',
    fontWeight: 600
  },
  msgToTr2: {
    display: 'none'
  },
  xBtnStyle: {
    width: '20px',
    float: 'right',
    "&:hover": {
      backgroundColor: 'white'
    }
  },
  mobileContact: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    }
  },
  desktopContact: {
    display: 'block',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  btnStyle: {
    display: 'block',
    color: '#fff',
    backgroundColor: '#ff5d5d',
    borderRadius: 5,
    border: 'none',
    fontWeight: 600,
    width: '80%',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: 10,
    marginBottom: 20,
    textAlign: 'center',
    fontSize: '1rem',
    cursor: 'pointer',
    fontFamily: 'proxima-nova, sans-serif ',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#ff5d5d',
    }
  },
});


const query = ({ tutorProfile }) => {
  return [
    {
      collection: "profiles",
      doc: `${tutorProfile.tutorUid}`,
      subcollections: [{ collection: "reviews" }],
      storeAs: "reviews"
    }
  ];
};

const mapDispatchToProps = dispatch => {
  return {
    setEmptyProfile: payload => dispatch(setEmptyProfile()),
    getProfileRequest: payload => dispatch(getProfileRequest(payload)),
    getReviewsRequest: payload => dispatch(getReviewsRequest(payload)),
    getUserRequest: () => dispatch(getUserRequest()),
    updateProfileEducationRequest: (payload, showSuccess, showError) => {
      dispatch(updateProfileEducationRequest(payload, showSuccess, showError));
    },
    removeProfileEducationRequest: (payload, showSuccess, showError) => {
      dispatch(removeProfileEducationRequest(payload, showSuccess, showError));
    },
    updateProfileLanguageRequest: (payload, showSuccess, showError) => {
      dispatch(updateProfileLanguageRequest(payload, showSuccess, showError));
    },
    removeProfileLanguageRequest: (payload, showSuccess, showError) => {
      dispatch(removeProfileLanguageRequest(payload, showSuccess, showError));
    },
    updateProfileDescriptionRequest: (payload, showSuccess, showError) => {
      dispatch(
        updateProfileDescriptionRequest(payload, showSuccess, showError)
      );
    },
    updateProfileSubjectRequest: (payload, showSuccess, showError) => {
      dispatch(updateProfileSubjectRequest(payload, showSuccess, showError));
    },
    removeProfileSubjectRequest: (payload, showSuccess, showError) => {
      dispatch(removeProfileSubjectRequest(payload, showSuccess, showError));
    },
    updateProfileExperienceRequest: (payload, showSuccess, showError) => {
      dispatch(updateProfileExperienceRequest(payload, showSuccess, showError));
    },
    removeProfileExperienceRequest: (payload, showSuccess, showError) => {
      dispatch(removeProfileExperienceRequest(payload, showSuccess, showError));
    },
    updateResumeUserInfoRequest: (payload, showSuccess, showError) => {
      dispatch(updateResumeUserInfoRequest(payload, showSuccess, showError));
    },
    createReviewRequest: (payload, showSuccess, showError) => {
      dispatch(createReviewRequest(payload, showSuccess, showError));
    },
    goingToEvent: payload => {
      dispatch(goingToEvent(payload));
    },
    cancelGoingToEvent: payload => {
      dispatch(cancelGoingToEvent(payload));
    },
    addTutorProfileComment: payload => {
      dispatch(addTutorProfileComment(payload));
    },
    deleteTutorProfileComment: payload => {
      dispatch(deleteTutorProfileComment(payload));
    },
    openModal: payload => {
      dispatch(openModal(payload));
    },
    setCurrentChannel: payload => dispatch(setCurrentChannel(payload))
  };
};

const mapState = state => {
  return {
    auth: state.firebase.auth,
    userProfile: state.firebase.profile,
    requesting: state.firestore.status.requesting,
    profiles: state.profiles,
    tutorProfile: state.profiles.current,
    loading: state.async.loading,
    tutorProfileChat: state.firestore.ordered.reviews,
  };
};

const action = {
  openModal,
  closeModal
}

class TutorProfilePage extends Component {


  constructor(props) {
    super(props);
    if (this.props.requesting.reviews) {
      this.state = {
        loaded: false,
      }
    } else {
      this.state = {
        loaded: true
      }
    }
  }

  state = {
    initialLoading: true,
    reviewContent: "",
    openResumeUserInfo: false,
    openResumeUserEdu: false,
    openResumeUserLanguage: false,
    openResumeUserSubject: false,
    openResumeUserExperience: false,
    openResumeUserDescription: false,
    //Languages
    languages: "",
    // subject
    category: "",
    categoryId: "",
    name: "",
    level: "",
    price: "",
    // education
    where: "",
    what: "",
    from: "",
    to: "",
    // Experience
    what: "",
    where: "",
    from: "",
    to: "",
    //Description
    Description: "",
    // review
    reviewRating: 0,
    reviewTitle: "",
    reviewContent: "",
    // google data
    addressComponents: "",
    lat: "",
    lng: "",
    locationName: "",
    formattedAddress: "",
    placeId: "",
    icon: "",
    plusCode: "",
    vicinity: "",
    utc_offset: "",
    url: "",
    channelId: '',
    //Responsive Chat Button
    opened: false,
    show: false,
    theTitle: `${this.props.tutorProfile.firstName}`
  };

  toggleShow = () => {
    this.setState({
      opened: !this.state.opened,
    });
  }


  getChannelId = () => {
    const { auth, match } = this.props;
    const currentUserId = auth.uid;
    const channelId = match.params.id < currentUserId ? `${match.params.id}_${currentUserId}` : `${currentUserId}_${match.params.id}`
    this.setState({ channelId: channelId });
  }

  // async componentWillMount(){
  //   const { firestore, match, auth } = this.props;
  //   const uid = this.props.match.params.id;
  //   await this.props.getProfileRequest(uid).then(()=> {
  //     console.log('WILLMOUNT')
  //   }).catch(err => console.log(err)); // request a profile data
  //   // this.props.getReviewsRequest(uid) // request a profile's review data
  //   this.props.getUserRequest();
  // }

  async componentDidMount() {
    const { firestore, match, auth, profiles, history } = this.props;


    const uid = this.props.match.params.id;
    this.props.getProfileRequest(uid); // request a profile data
    // this.props.getReviewsRequest(uid) // request a profile's review data
    this.props.getUserRequest();
    //Getting the tutor profile page from the database
    await firestore.setListener(`tutorProfiles/${match.params.id}`);

    await this.getChannelId()
    this.props.setCurrentChannel(this.state.channelId);
    this.setState({
      initialLoading: false
    });

  }

  componentWillUnmount() {
    this.props.setCurrentChannel({ id: "noChannel" });
    const theTitle = 'CrownTutor'
    this.setState({ theTitle: theTitle })
  }

  //General Functions *************************

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleScriptLoad = () => {
    var options = {
      types: ["geocode"]
    }; // To disable any eslint 'google not defined' errors

    // Initialize Google Autocomplete
    /*global google*/ this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById("autocomplete"),
      options
    );

    // Fire Event when a suggested name is selected
    this.autocomplete.addListener("place_changed", this.handlePlaceSelect);
  };
  handlePlaceSelect = () => {
    // Extract City From Address Object
    let addressObject = this.autocomplete.getPlace();
    let address = addressObject.address_components;

    // Check if address is valid
    if (address) {
      // Set State
      this.setState({
        addressComponents: addressObject.address_components,
        lat: addressObject.geometry.location.lat(),
        lng: addressObject.geometry.location.lng(),
        locationName: addressObject.name,
        formattedAddress: addressObject.formatted_address,
        placeId: addressObject.place_id,
        icon: addressObject.icon,
        plusCode: addressObject.plus_code,
        vicinity: addressObject.vicinity,
        utc_offset: addressObject.utc_offset,
        url: addressObject.url
      });
    }
  };
  //******************************************************

  //Subject Functions ************************************
  handleRemoveSubject = (e, subjectName) => {
    e.preventDefault();
    if (window.confirm("Are you sure?")) {
      const { showSuccess, showError } = this.props;
      this.props.removeProfileSubjectRequest(
        subjectName,
        showSuccess,
        showError
      );
    }
  };
  // open a dialog

  handleResumeUserSubjectClickOpen = () => {
    this.setState({ openResumeUserSubject: true });
  };
  // close a dialog
  handleResumeUserSubjectClickClose = () => {
    this.setState({ openResumeUserSubject: false });
  };
  handleChangeSelect = (target, data) => {
    if (target == "category") {
      this.setState({
        category: data.value,
        categoryId: data.id
      });
    } else if (target == "subject") {
      this.setState({ name: data.value });
    } else if (target == "level") {
      this.setState({ level: data.value });
    }
  };
  handleSubmitSubject = () => {
    const { showSuccess, showError } = this.props;
    const { category, name, level, price } = this.state;
    let isValidated = false;
    let error = "";
    // validate
    if (category === "") {
      isValidated = true;
      error = "Please select a category!";
    } else if (name === "") {
      isValidated = true;
      error = "Please select a subject!";
    } else if (level === "") {
      isValidated = true;
      error = "Please select a level!";
    } else if (price === "") {
      isValidated = true;
      error = "Please complete a price!";
    }

    if (isValidated) {
      window.alert(error);
      //showError(error)
      return;
    }

    this.props.updateProfileSubjectRequest(
      {
        category,
        name,
        level,
        price
      },
      showSuccess,
      showError
    );
  };

  //*********************************************

  handleResumeUserDescriptionClickOpen = () => {
    this.setState({ openResumeUserDescription: true });
  };

  handleResumeUserDescriptionClickClose = () => {
    this.setState({ openResumeUserDescription: false });
  };
  //*********************************************

  handleResumeUserExperienceClickOpen = () => {
    this.setState({ openResumeUserExperience: true });
  };
  // close a dialog
  handleResumeUserExperienceClickClose = () => {
    this.setState({ openResumeUserExperience: false });
  };

  handleResumeUserLanguageClickOpen = () => {
    this.setState({ openResumeUserLanguage: true });
  };
  // close a dialog
  handleResumeUserLanguageClickClose = () => {
    this.setState({ openResumeUserLanguage: false });
  };

  //*********************************************
  //Education and Experiences Functions ***************************
  handleRemoveEducation = (e, i) => {
    e.preventDefault();
    if (window.confirm("Are you sure?")) {
      const { showSuccess, showError } = this.props;
      this.props.removeProfileEducationRequest(i, showSuccess, showError);
    }
  };

  //*********************************************
  // Experiences Functions ***************************
  handleRemoveExperience = (e, i) => {
    e.preventDefault();
    if (window.confirm("Are you sure?")) {
      const { showSuccess, showError } = this.props;
      this.props.removeProfileExperienceRequest(i, showSuccess, showError);
    }
  };

  // open a handleResumeUserEdu dialog
  handleResumeUserEduClickOpen = () => {
    this.setState({ openResumeUserEdu: true });
  };

  // close a handleResumeUserEdu dialog
  handleResumeUserEduClickClose = () => {
    this.setState({ openResumeUserEdu: false });
  };

  handleChangeSelectEducation = data => {
    this.setState({ where: data.target.value }, () => {
      // console.log()
    });
  };

  // submit an education form
  handleSubmitEducation = () => {
    const { showSuccess, showError } = this.props;
    const { where, what, from, to } = this.state;

    let isValidated = false;
    let error = "";
    // validate
    if (where === "") {
      isValidated = true;
      error = "Please complete your school!";
    } else if (what === "") {
      isValidated = true;
      error = "Please complete your program/major!";
    } else if (from === "") {
      isValidated = true;
      error = "Please complete the year you started";
    } else if (to === "") {
      isValidated = true;
      error = "Please complete the year you finished!";
    }

    if (isValidated) {
      window.alert(error);
      //showError(error)
      return;
    }

    this.props.updateProfileEducationRequest(
      {
        where,
        what,
        from,
        to
      },
      showSuccess,
      showError
    );
  };

  handleRemoveLanguage = (e, i) => {
    e.preventDefault();
    if (window.confirm("Are you sure?")) {
      const { showSuccess, showError } = this.props;
      this.props.removeProfileLanguageRequest(i, showSuccess, showError);
    }
  };

  handleSubmitLanguage = () => {
    const { showSuccess, showError } = this.props;
    this.props.updateProfileLanguageRequest(
      {
        languages: this.state.languages
      },
      showSuccess,
      showError
    );
  };

  handleSelectLanguages = (data, e) => {
    let languages = {};
    _.forEach(data, item => {
      _.assign(languages, {
        [item.value]: true
      });
    });
    this.setState(
      {
        languages: languages
      },
      () => {
        // console.log(this.state.languages);
      }
    );
  };

  handleSubmitDescription = () => {
    const { showSuccess, showError } = this.props;
    this.props.updateProfileDescriptionRequest(
      {
        description: this.state.description
      },
      showSuccess,
      showError
    );
  };

  handleSubmitExperience = () => {
    const { showSuccess, showError } = this.props;
    this.props.updateProfileExperienceRequest(
      {
        what: this.state.what,
        where: this.state.where,
        from: this.state.from,
        to: this.state.to
      },
      showSuccess,
      showError
    );
  };

  countBadges = (badges) => {
    let badgeCount = 0;

    for (let [key, value] of Object.entries(badges)) {
      if (value === true) {
        badgeCount++;
      }
    }
    return badgeCount
  }

  render() {
    const {
      openModal,
      closeModal,
      tutorProfile,
      auth,
      goingToEvent,
      cancelGoingToEvent,
      addTutorProfileComment,
      deleteTutorProfileComment,
      tutorProfileChat,
      loading,
      requesting,
      match,
      classes,
      userProfile
    } = this.props;
    const { authLoaded } = this.state;
    const isOwner = tutorProfile.tutorUid === auth.uid;
    const authenticated = auth.isLoaded && !auth.isEmpty;
    const loadingTutorProfile = requesting[`tutorProfiles/${match.params.id}`];
    const haveStudentPass = userProfile.studentPass;
    const badgeCount = 0;

    const setVisibility = () => {
      if (isOwner) {
        return classes.isOwnerStyle
      } else if (tutorProfile.contactVisible) {
        return classes.agreed
      } else {
        return classes.notVisible
      }
    }

    let visibilityClass = setVisibility();

    if (loadingTutorProfile || this.state.initialLoading)
      return <LoadingComponent inverted={true} />;


    // const openedModal = () => {
    //   if (isOwner && this.countBadges(tutorProfile.badges) === 0) {
    //     return openModal('ReceiveBadgesModal')
    //   } else {
    //     return ''
    //   }
    // }
    if (!this.state.loaded) {
      return <LoadingComponent inverted={true} />;
    } else {
      if (this.props.profiles.current === undefined || this.props.profiles.current === '') {
        return <div><p>Nope</p><p>Nope</p><p>Nope</p><p>Nope</p><p>Nope</p><p>Nope</p></div>
      } else {
        return (
          <div >
            <Grid container>
              <Grid item xs={12} md={12}>
                <TutorProfileHeader
                  user={auth}
                  loading={loading}
                  tutorProfile={tutorProfile}
                  cancelGoingToEvent={cancelGoingToEvent}
                  isOwner={isOwner}
                  goingToEvent={goingToEvent}
                  openResumeUserLanguage={this.state.openResumeUserLanguage}
                  handleResumeUserLanguageClickOpen={
                    this.handleResumeUserLanguageClickOpen
                  }
                  handleResumeUserLanguageClickClose={
                    this.handleResumeUserLanguageClickClose
                  }
                  handleSubmitLanguage={this.handleSubmitLanguage}
                  handleRemoveLanguage={this.handleRemoveLanguage}
                  handleChangeSelectEducation={this.handleChangeSelectEducation}
                  handleChange={this.handleChange}
                  handleSelectLanguages={this.handleSelectLanguages}
                  openModal={openModal}
                />
              </Grid>
            </Grid>
            <Grid container className={classes.filterDash}>
              <Grid item xs={12} md={8}>



                {/* <TutorProfileDirectMessage2
                  tutorProfile={tutorProfile}
                  authenticated={authenticated}
                  tutorProfileId={tutorProfile.tutorUid}
                  auth={auth}
                  userProfile={this.props.userProfile}
                  openModal={openModal}
                  loading={loading}
                  isOwner={isOwner}
                  channelId={this.state.channelId}
                  setCurrentChannel={this.props.setCurrentChannel}
                /> */}
                {!tutorProfile.videoURL && isOwner ?
                  <TutorProfileVideoUpload
                    isOwner={isOwner}
                    tutorProfile={tutorProfile}
                  />
                  :
                  <div>
                    {tutorProfile.videoURL ?
                    <TutorPlayVideo
                      tutorProfile={tutorProfile}
                    />
                    :
                    ''
                    }
                  </div>
                }
                <TutorProfileInfo
                  isOwner={isOwner}
                  tutorProfile={tutorProfile}
                  categoryId={this.state.categoryId}
                  openResumeUserSubject={this.state.openResumeUserSubject}
                  handleChangeSelect={this.handleChangeSelect}
                  handleRemoveSubject={this.handleRemoveSubject}
                  handleResumeUserSubjectClickOpen={
                    this.handleResumeUserSubjectClickOpen
                  }
                  handleResumeUserSubjectClickClose={
                    this.handleResumeUserSubjectClickClose
                  }
                  handleChange={this.handleChange}
                  handleSubmitSubject={this.handleSubmitSubject}
                />
                <TutorProfileEducation
                  isOwner={isOwner}
                  tutorProfile={tutorProfile}
                  openResumeUserEdu={this.state.openResumeUserEdu}
                  handleResumeUserEduClickOpen={this.handleResumeUserEduClickOpen}
                  handleResumeUserEduClickClose={this.handleResumeUserEduClickClose}
                  handleSubmitEducation={this.handleSubmitEducation}
                  handleRemoveEducation={this.handleRemoveEducation}
                  handleChangeSelectEducation={this.handleChangeSelectEducation}
                  handleChange={this.handleChange}
                />

                <TutorProfileExperience
                  tutorProfile={tutorProfile}
                  isOwner={isOwner}
                  openResumeUserExperience={this.state.openResumeUserExperience}
                  handleResumeUserExperienceClickOpen={
                    this.handleResumeUserExperienceClickOpen
                  }
                  handleResumeUserExperienceClickClose={
                    this.handleResumeUserExperienceClickClose
                  }
                  handleSubmitExperience={this.handleSubmitExperience}
                  handleRemoveExperience={this.handleRemoveExperience}
                  handleChangeSelectExperience={this.handleChangeSelectExperience}
                  handleChange={this.handleChange}
                />
                <TutorProfileDescription
                  tutorProfile={tutorProfile}
                  isOwner={isOwner}
                  openResumeUserDescription={this.state.openResumeUserDescription}
                  handleResumeUserDescriptionClickOpen={
                    this.handleResumeUserDescriptionClickOpen
                  }
                  handleResumeUserDescriptionClickClose={
                    this.handleResumeUserDescriptionClickClose
                  }
                  handleSubmitDescription={this.handleSubmitDescription}
                  handleChangeSelectDescription={this.handleChangeSelectDescription}
                  handleChange={this.handleChange}
                />
                <div >
                  {haveStudentPass ?
                    <TutorProfileSidebar
                      visibilityClass={visibilityClass}
                      tutorProfile={tutorProfile}
                      authenticated={authenticated}
                      tutorProfileId={tutorProfile.tutorUid}
                      userAuth={auth}
                      userProfile={this.props.userProfile}
                      openModal={openModal}
                      loading={loading}
                      isOwner={isOwner}
                    />
                    :
                    ' '
                  }

                </div>

              </Grid>

              <Grid item xs={12} md={4}>

                {isOwner ? '' :
                  <div>
                    <TutorProfileDirectMessage
                      haveStudentPass={haveStudentPass}
                      tutorProfile={tutorProfile}
                      authenticated={authenticated}
                      tutorProfileId={tutorProfile.tutorUid}
                      auth={auth}
                      userProfile={this.props.userProfile}
                      openModal={openModal}
                      loading={loading}
                      isOwner={isOwner}
                      channelId={this.state.channelId}
                      setCurrentChannel={this.props.setCurrentChannel}
                    />
                  </div>
                }
                <div className={classes.desktopContact}>
                  {(isOwner || haveStudentPass) ?
                    <TutorProfileSidebar1
                      visibilityClass={visibilityClass}
                      tutorProfile={tutorProfile}
                      authenticated={authenticated}
                      tutorProfileId={tutorProfile.tutorUid}
                      auth={auth}
                      userProfile={this.props.userProfile}
                      openModal={openModal}
                      loading={loading}
                      isOwner={isOwner}
                      channelId={this.state.channelId}
                      setCurrentChannel={this.props.setCurrentChannel}
                    />
                    :
                    ''
                  }

                </div>


                {/* <TutorProfileRecommendation
                  tutorProfile={tutorProfile}
                  openModal={openModal}
                  loading={loading}
                /> */}
              </Grid>


              {isOwner ? '' :
                <div className={this.state.opened ? classes.responsiveStyle2 : classes.responsiveStyle}>
                  <Grid container>
                    <Grid item xs={8}>
                      {this.state.opened ?
                        <span className={classes.msgToTr}>
                          {haveStudentPass ? `Message ${tutorProfile.firstName || tutorProfile.lastName || tutorProfile.displayName} now.`
                            :
                            `Student Pass 1 Year Unlimited Access`
                          }
                        </span>
                        :
                        <span className={classes.msgToTr}>
                          {haveStudentPass ? `Message ${tutorProfile.firstName || tutorProfile.lastName || tutorProfile.displayName} now.`
                            :
                            `Buy Student Pass to Message`
                          }
                        </span>
                      }
                    </Grid>
                    <Grid item xs={4}>
                      <Button
                        className={classes.msgStyle}
                        component={Link}
                        to='/studentpass'
                      >
                        PURCHASE
                    </Button>
                    </Grid>
                  </Grid>


                </div>}

              {/* </div>
        );
                  <br />
                  <TutorProfileRecommendation
                    tutorProfile={tutorProfile}
                    openModal={openModal}
                    loading={loading}
                  />
                  <br />
                </Grid> */}


              {/* <div className={classes.responsiveStyle}>
                    <h2 style={shown}>
                      <TutorProfileDirectMessage2
                        tutorProfile={tutorProfile}
                        authenticated={authenticated}
                        tutorProfileId={tutorProfile.tutorUid}
                        auth={auth}
                        userProfile={this.props.userProfile}
                        openModal={openModal}
                        loading={loading}
                        isOwner={isOwner}
                        channelId={this.state.channelId}
                        setCurrentChannel={this.props.setCurrentChannel}
                      />
                    </h2>
                    <Button
                      className={classes.msgStyle}
                      onClick={this.toggle.bind(this)}
                    >
                      Message
                    </Button>
        
                  </div> */}
            </Grid>
            <Grid container className={classes.filterDash}>
              <Grid item xs={12} md={8} >
                <TutorProfileChat
                  userId={auth.uid}
                  tutorProfileChat={tutorProfileChat}
                  deleteTutorProfileComment={deleteTutorProfileComment}
                  addTutorProfileComment={addTutorProfileComment}
                  tutorProfileId={tutorProfile.tutorUid}
                  authenticated={authenticated}
                  openModal={openModal}
                />
              </Grid>
            </Grid>
          </div>
        );
      }


    }

  }
}

export default withStyles(styles)(
  compose(
    withFirestore,
    connect(
      mapState,
      mapDispatchToProps
    ),
    firestoreConnect(tutorProfile => query(tutorProfile))
  )(TutorProfilePage)
);
