import React, { Component } from "react";
import { Form, Label } from "semantic-ui-react";
import InputBase from "@material-ui/core/InputBase";



class PlaceInput2 extends Component {

  render() {
    const {
      input,
      width,
      onSelect,
      placeholder,
      autoComplete,
      className,
      meta: { touched, error }
    } = this.props;
    return (
      <Form.Field autoComplete={false} onSubmit={(e) => e.preventDefault()} error={touched && !!error} width={width} >
        <InputBase
          {...input}
          id='autocomplete'
          label="Where"
          type="text"
          className={className}
          placeholder={placeholder}
          fullWidth
          className={className}
          placeholder={placeholder}
          // InputLabelProps={{
          //   shrink: true,
          // }}
        />
        {touched && error && <Label basic color='red'>{error}</Label>}
      </Form.Field>
    );
  }
}
export default PlaceInput2;
