import React from 'react';
import { Header, Menu } from 'semantic-ui-react'
import { NavLink, Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';


const styles = theme => ({
  navMain: {
    marginTop: 150,
    marginRight: 'auto',
    marginLeft: 'auto',
    fontSize: '1.2rem',
    width: '95%',
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
    }
  },
  smallHeader: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      marginLeft: 10,
      marginBottom: 20
    }
  },
  largeHeader: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      display: 'none',

    }
  },
  threemenus: {
    marginLeft: '7%',
    marginRight: '7%',
    fontFamily: 'proxima-nova, sans-serif ',

  },
  basic: {
    color: '#585c60',
    paddingBottom: '5%',
    paddingTop: '10%',
    borderBottom: '1px solid #E1E1E6',
    fontFamily: 'proxima-nova, sans-serif ',
  },
  basic2: {
    color: '#585c60',
    paddingBottom: '12px',
    paddingTop: '12px',
    fontSize: '0.9rem',
    fontFamily: 'proxima-nova, sans-serif ',

  },
  basic3: {
    color: '#585c60',
    paddingBottom: '20px',
    paddingTop: '20px',
    marginLeft: '20px',
    borderBottom: '1px solid #E1E1E6',
    fontFamily: 'proxima-nova, sans-serif ',
  },
  basic4: {
    color: '#585c60',
    paddingBottom: '12px',
    paddingTop: '12px',
    width: '100%',
    borderBottom: '1px solid #E1E1E6',
    fontSize: '0.9rem',
    fontFamily: 'proxima-nova, sans-serif ',
  },
  firstBox: {
    boxShadow: 'none',
    marginTop: 20,
    border: '1px solid #e1e1e6',
    [theme.breakpoints.down('xs')]: {
      border: 'none'
    }

  },
  sndbox: {
    marginTop: '10%',
    boxShadow: 'none'
  },
  profile: {
    margin: 0,
    padding: '5px 10px 8px 10px',
    color: '#4ca7ab',
    backgroundColor: '#4ca7ab',
    border: '1px solid #4ca7ab',
    [theme.breakpoints.down('xs')]: {
      backgroundColor: '#fff',
      border: 'none'

    }

  },
  headerStyle: {
    color: 'white',
    marginLeft: '10px',
    fontSize: '1.5rem',
    fontWeight: 600,
    marginTop: 10,
    [theme.breakpoints.down('xs')]: {
      color: '#4ca7ab',

    }
  }


})

const SettingsNav = ({ classes }) => {


  return (

    <Grid container className={classes.navMain}>
      <Grid item xs={12} md={12}>
        <Card className={classes.firstBox}>
          <CardContent className={classes.profile}>
            <div className={classes.smallHeader}>
              <i class="material-icons" style={{ color: '#4ca7ab', position: 'relative', top: 5, }}>
                person
              </i>
              <span className={classes.headerStyle}>Profile</span>

            </div>
            <div className={classes.largeHeader}>
              <i class="material-icons" style={{ color: 'white', position: 'relative', top: 5, }}>
                person
              </i>
              <span className={classes.headerStyle}>Profile</span>

            </div>
          </CardContent>
          <div className={classes.threemenus}>
            <Link as={NavLink} to='/settings/basic' ><Typography className={classes.basic4}>Basics</Typography></Link>
            {/* <Link as={NavLink} to='/settings/about'><Typography className={classes.basic4}>About Me</Typography></Link> */}
            <Link as={NavLink} to='/settings/photos'><Typography className={classes.basic4}>My Photos</Typography></Link>
            <Link as={NavLink} to='/settings/chats'><Typography className={classes.basic4}>Chats</Typography></Link>
            <Link as={NavLink} to='/settings/MyCourses'><Typography className={classes.basic2}>My Courses</Typography></Link>

          </div>
        </Card>
        <Card className={classes.firstBox}>
          <CardContent className={classes.profile}>
          <div className={classes.smallHeader}>
              <i class="material-icons" style={{ color: '#4ca7ab', position: 'relative', top: 5, }}>
                settings
              </i>
              <span className={classes.headerStyle}>Account</span>

            </div>
            <div className={classes.largeHeader}>
              <i class="material-icons" style={{ color: 'white', position: 'relative', top: 5, }}>
                settings
              </i>
              <span className={classes.headerStyle}>Account</span>

            </div>


          </CardContent  >
          <div className={classes.threemenus}>
            <Link as={NavLink} to='/settings/account' ><Typography className={classes.basic4}>My Account</Typography></Link>
            {/* <Link as={NavLink} to='/settings/privacysettings' ><Typography className={classes.basic2}>Privacy Settings</Typography></Link> */}
          </div>
        </Card>

      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(SettingsNav)
