import React, { Component } from "react";
import firebase from '../../../app/config/firebase'
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { withFirebase } from "react-redux-firebase";
// import { Menu, Container, Button, ModalActions } from "semantic-ui-react";
import { NavLink, Link, withRouter } from "react-router-dom";
import {  } from "react-scroll"
import SignedOutMenu from "../Menus/SignedOutMenu";
import SignedInMenu from "../Menus/SignedInMenu";
import { openModal } from "../../modals/modalActions";
import { logout } from "../../auth/authActions";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { objectToArray } from "../../../app/common/util/helpers";
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import { fade } from '@material-ui/core/styles/colorManipulator';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import { setNotifications } from '../../user/Settings/Chat/chatActions'

const styles = theme => ({
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    float: 'left',
    [theme.breakpoints.up('md')]: {
      display: 'none',

    },
  },
  fullNav: {
    background: '#fff',
    height: '90px',
    lineHeight: '90px',
    position: 'sticky',
    top: '0px',
    paddingTop: '40px',
    paddingBottom: '40px',
    borderBottom: '1px solid #e1e1e6',
    maxWidth: '1240px',
    marginRight: 'auto',
    marginLeft: 'auto'
  },
  grow: {
    flexGrow: 1,
  },
  img: {

    width: '150px',
    borderRadius: '0px',

  },
  appbar: {
    backgroundColor: 'rgba(255, 255, 255, 5)',
    height: 90,
    display: 'block',
    boxShadow: 'none',
    borderBottom: '1px solid #e1e1e6'

  },
  threemenu: {
    float: 'right',
    marginLeft: '43%',
  },

  testPrepBtn:{
    margin: '5px 7px 5px 2px',
    backgroundColor: '#fff',
    color: '#4ca7ab',
    padding: '5px 20px',
    border: '1px solid #4ca7ab',
    borderRadius: '20px',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#4ca7ab'
    },
  },
  tutor: {
    margin: '5px',
    borderRadius: '0px',
    borderbottom: 'solid 3px #4ca7ab',
    '&:hover': {
      backgroundColor: '#ffffff',
    },
  },

  student: {
    '&:hover': {
      backgroundColor: '#ffffff',
    },
  },
  navlogo: {
    display: 'flex',
    marginTop: -10,
    [theme.breakpoints.down('md')]: {
      width: '20px',
      marginLeft: 40
    },
    '&:hover': {
      backgroundColor: '#ffffff',
    },
  },
  signin: {
    marginLeft: '-20px',

    '&:hover': {
      backgroundColor: '#ffffff',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  verifiedStyle: {
    cursor: 'pointer',
    color: '#4ca7ab',
    marginTop: 16
  },
  verifyDesktop: {
    marginRight: 20,
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
});

const actions = {
  openModal,
  logout,
  setNotifications
};

const mapState = state => ({
  auth: state.firebase.auth,
  profile: state.firebase.profile,
  user: state.user,
  tutorProfiles: state.firestore.data.tutorProfiles,
  currentChannel: state.chat.currentChannel,
  notifications: state.chat.notifications,
});


class NavBar extends Component {
  state = {
    anchorEl: null,
    mobileMoreAnchorEl: null,
    userChat: this.props.currentChannel,
    userChats: [],
    userChatsRef: firebase.database().ref('users'),
    chatsRef: firebase.database().ref('chats'),
    chatNotifications: [],
    notiOpen: false,
    menuOpen: false,
    notificationCount: 0,
  }

  componentDidMount() {
    this.addListener();
  }

  addListener = () => {
    let loadedUserChatNotifications = [];
    this.props.auth.uid &&
      this.state.userChatsRef
        .child(this.props.auth.uid)
        .child('chatNotifications')
        .on('child_added', snap => {
          loadedUserChatNotifications.push(snap.val());
          // this.setState({ chatNotifications: loadedUserChatNotifications });
          this.props.setNotifications(loadedUserChatNotifications)
          loadedUserChatNotifications = [];
        });
    //console.log(loadedUserChatNotifications)
  };

  handleNotifications = (chatNotifications) => {
    let lastTotal = 0;
    chatNotifications && chatNotifications.map(chatNotification => {
      if (!chatNotification.read) {
        lastTotal++;
      }
    })
    // console.log(lastTotal);
    return lastTotal;
  }

  reSendVerificationEmail = () => {
    this.props.openModal("EmailVerificationModal")
  }

  handleProfileMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
  };

  handleMobileMenuOpen = event => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  handleClick = (e) => {
    this.setState({ anchorEl: e.currentTarget, menuOpen: true });
  };

  onNotificationOpen = (e) => {
    this.setState({ anchorEl: e.currentTarget, notiOpen: true });
  };

  onNotificationClose = (e) => {
    this.setState({ anchorEl: null, notiOpen: null });
  };

  handleSignIn = () => {
    this.props.openModal("LoginModal");
  };

  handleClose = () => {
    this.setState({ anchorEl: null, menuOpen: false });
  };

  handleRegister = () => {
    this.props.openModal("RegisterModal");
  };

  handleSignOut = () => {
    this.props.firebase.logout();
    this.props.history.push("/");
  };
  

  render() {
    const { auth, user, profile, tutorProfiles, notifications, testPrepBtn } = this.props;
    const authenticated = auth.isLoaded && !auth.isEmpty;
    const { classes } = this.props;
    const { anchorEl, mobileMoreAnchorEl, chatNotifications } = this.state;
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);


    const notificationCount = this.handleNotifications(notifications)
    const renderMobileMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMobileMenuOpen}
        onClose={this.handleClose}
        style={{ display: 'block' }}
      >

        {(!authenticated || !user.me.hasProfile) &&
          <Button className={classes.testPrepBtn}
            component={Link}
            to="/createtutor"
            content="Become a Tutor"
          >
            Become a Tutor
            </Button>}

        {authenticated ? (
          <div>
            <SignedInMenu
              anchorEl={anchorEl}
              auth={auth}
              profile={profile}
              signOut={this.handleSignOut}
              onOpen={this.handleClick}
              open={this.isMobileMenuOpen}
              onClose={this.handleClose}
              onNotificationOpen={this.onNotificationOpen}
              onNotificationClose={this.onNotificationClose}
              notiOpen={this.state.notiOpen}
              menuOpen={this.state.menuOpen}
              notificationCount={notificationCount}
              notifications={notifications}
              setNotifications={this.props.setNotifications}
            >
              sign in
            </SignedInMenu>
          </div>
        ) : (
            <SignedOutMenu className={classes.twonav}
              signIn={this.handleSignIn}
              register={this.handleRegister}
              open={this.isMobileMenuOpen}
              onClose={this.handleClose}
            >
              sign out
            </SignedOutMenu>
          )}

      </Menu>
    );

    return (


      <AppBar className={classes.appbar}>
        <Toolbar className={classes.fullNav}>
          <div >
            <Button className={classes.navlogo} component={Link} to="/" >
              <img className={classes.img} src="/assets/Tossmath_logo.png" alt="Toronto's #1 Tutor NetWork" />
            </Button>
          </div>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            {(authenticated && !auth.emailVerified && auth.providerData[0].providerId !== "facebook.com") &&
              <p className={classes.verifyDesktop}>
                <a className={classes.verifiedStyle} onClick={() => {
                  this.reSendVerificationEmail();
                }}>
                  If You Haven't Received the Verification Email, Plesae Click this Link.
              </a>
              </p>
            }
            {/* <Link
              activeClass='active'
              to='midtermPrep'
              spy={true}
              smooth={true}
              offset={-120}
              duration={500}
            >
              <Button className={classes.testPrepBtn}
                component={Link}
                to="/"
                content="testPrep"

              >
                Test Prep
            </Button>
            </Link> */}
            {/* <Link> */}
            {
              (!authenticated || !user.me.hasProfile) &&
              <Button className={classes.testPrepBtn}
                component={Link}
                to="/createtutor"
                content="Become a Tutor"
                onClick={testPrepBtn}
              >
                Become a Tutor
            </Button>
            }
            {/* </Link> */}
          </div>
          {authenticated ? (
            <SignedInMenu
              anchorEl={anchorEl}
              auth={auth}
              profile={profile}
              signOut={this.handleSignOut}
              onOpen={this.handleClick}
              handleClose={this.handleClose}
              onNotificationOpen={this.onNotificationOpen}
              onNotificationClose={this.onNotificationClose}
              notiOpen={this.state.notiOpen}
              menuOpen={this.state.menuOpen}
              notificationCount={notificationCount}
              notifications={notifications}
              setNotifications={this.props.setNotifications}
            >
              sign in
            </SignedInMenu>
          ) : (
              <SignedOutMenu
                signIn={this.handleSignIn}
                register={this.handleRegister}
                onClose={this.handleClose}
                onClick={this.handleClose}

              >
                sign out
              </SignedOutMenu>
            )}
        </Toolbar>
        {renderMobileMenu}

      </AppBar >
    );
  }
}

export default withStyles(styles)(
  withRouter(
    withFirebase(
      connect(
        mapState,
        actions
      )(NavBar))
  )
);