import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';

import ReactPlayer from 'react-player'

const styles = theme => ({
    videoStyle: {
        width: '95%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: -40,
        [theme.breakpoints.down('xs')]: {
            marginTop: 20,
            width: '100%'
        }
    },
    cardStyle: {
        boxShadow: 'none',
        border: '1px solid #e1e1e6'
    }
})

const TutorPlayVideo = ({
    classes,
    tutorProfile
}) => {
    return (
        <div className={classes.videoStyle} >
            <Card className={classes.cardStyle}>
                <ReactPlayer
                    width="100%"
                    controls
                    volume
                    url={tutorProfile.videoURL}
                />
            </Card>
        </div>
    )
}

export default withStyles(styles)(TutorPlayVideo);