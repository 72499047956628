import { toastr } from "react-redux-toastr";
import { FETCH_TUTORPROFILES } from "./tutorConstants";
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError
} from "../async/asyncActions";
import { createNewTutorProfile, objectToArray } from "../../app/common/util/helpers";
import moment from "moment";
import firebase from "../../app/config/firebase";
import compareAsc from 'date-fns/compare_asc';
import cuid from "cuid";

export const createTutorProfile = tutorProfile => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();
    const user = firebase.auth().currentUser;
    //const id = cuid();
    const id = user.uid;
    const rating = 0;
    const rank = 5;
    const displayName = getState().firebase.profile.displayName;
    const photoURL = getState().firebase.profile.photoURL;
    const newTutorProfile = createNewTutorProfile(
      id,
      rating,
      user,
      photoURL,
      displayName,
      tutorProfile,
      rank
    );
    // console.log(newTutorProfile);

    try {
      await firestore.collection('profiles').doc(`${id}`).set(newTutorProfile)
      await firestore.collection('users').doc(`${id}`).update({ hasProfile: true })

      await tutorProfile.educations.forEach(education => {
        const schoolRef = firestore.collection('schools').doc(`${education.where.trim().toLowerCase()}`)

        schoolRef.get().then(list => {
          let newList = {};
          if (list.data() === undefined) {
            // console.log('Document does not exist!')
            newList[id] = { "id": id };
            schoolRef.set({ list: newList });
          } else {
            newList = list.data().list
            newList[id] = { "id": id };
            schoolRef.update({ list: newList });
          }
          // console.log("In tutoraction newPeopleListFOrSchool", newList)
        });
      })

      toastr.success("Success!", "Tutor Profile has been created");
    } catch (error) {
      console.log(error);
      toastr.error("Oops", "Something went wrong");
    }
  };
};

export const updateTutorProfile = tutorProfile => {
  return async (dispatch, getState) => {
    dispatch(asyncActionStart());
    const user = firebase.auth().currentUser
    const firestore = firebase.firestore();
    // if (tutorProfile.created !== getState().firestore.ordered.tutorProfiles[0].created) {
    //   tutorProfile.created = moment(tutorProfile.created).toDate();
    // }
    tutorProfile.updated = moment().toDate();
    // console.log("In updatedTutorProfile", tutorProfile);

    try {
      let tutorProfileDocRef = firestore.collection('profiles').doc(user.uid);
      await tutorProfileDocRef.update(tutorProfile);

      dispatch(asyncActionFinish());
      toastr.success('Success', 'Event has been updated');
    } catch (error) {
      // console.log(error);
      dispatch(asyncActionError());
      toastr.error('Oops', 'Something went wrong');
    }
  };
};

export const cancelToggle = (cancelled, tutor, tutorProfileId) =>
  async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const message = cancelled ? 'Are you sure you want to deactivate the event?' : 'This will reactivate your profile'
    try {
      toastr.confirm(message, {
        onOk: () =>
          firestore.update(`profiles/${tutorProfileId}`, {
            cancelled: cancelled,
            tutor: tutor,
          })
      })
    } catch (error) {
      // console.log(error)
    }
  }



export const getTutorProfilesForDashboard = lastTutorProfile => async (
  dispatch,
  getState
) => {
  let today = new Date(Date.now());
  const firestore = firebase.firestore();
  const tutorProfilesRef = firestore.collection("profiles");

  try {
    dispatch(asyncActionStart());
    let startAfter =
      lastTutorProfile &&
      (await firestore
        .collection("profiles")
        .doc(lastTutorProfile.id)
        .get());
    let query;

    lastTutorProfile
      ? (query = tutorProfilesRef
        .where("cancelled", '==', false)
        .orderBy("created")
        .startAfter(startAfter)
        .limit(50))
      : (query = tutorProfilesRef
        .where("cancelled", '==', false)
        .orderBy("created")
        .limit(50));

    let querySnap = await query.get();

    if (querySnap.docs.length === 0) {
      dispatch(asyncActionFinish())
      return;
    }

    let tutorProfiles = [];
    for (let i = 0; i < querySnap.docs.length; i++) {
      let tut = { ...querySnap.docs[i].data(), id: querySnap.docs[i].id };
      tutorProfiles.push(tut);
    }
    dispatch({ type: FETCH_TUTORPROFILES, payload: { tutorProfiles } });
    dispatch(asyncActionFinish());
    return querySnap
  } catch (error) {
    // console.log(error);
    dispatch(asyncActionError());
  }
};

export const addTutorProfileComment = (payload) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const profile = getState().firebase.profile;
    const user = firebase.auth().currentUser;

    let newComment = {
      rating: payload.rating,
      displayName: profile.displayName,
      photoURL: profile.photoURL || '/assets/user.png',
      uid: user.uid,
      text: payload.values.comment,
      date: Date.now(),
    }
    try {

      //console.log("In tutorAction payload",payload);
      //console.log("In tutorAction user",user.uid);

      await firestore.collection('profiles').doc(`${payload.tutorProfileId}`).
        collection('reviews').doc(`${user.uid}`).set(newComment);

      await firestore.collection('users').doc(`${user.uid}`).
        collection('myReviews').doc(`${payload.tutorProfileId}`).set(newComment);


      // console.log("The Comment Added is ", newComment);
      toastr.success('Thank You!', 'Your Review Will be Used to Improve our Community')
    } catch (error) {
      // console.log(error);
      toastr.error('Oops', 'Problem adding comment')
    }
  }

export const deleteTutorProfileComment = (payload) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const user = firebase.auth().currentUser;

    try {
      await firestore.collection('profiles').doc(`${payload.tutorProfileId}`).
        collection('reviews').doc(`${payload.userId}`).delete().then(() => {
          // console.log("The Comment deleted", payload.tutorProfileId, payload.userId);
          toastr.success('Success', 'Your Review is Successfullly Deleted')
        })
    } catch (error) {
      // console.log(error);
      toastr.error('Oops', 'Problem deleting comment')
    }
  }
