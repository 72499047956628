import React from "react";
import firebase from '../../../app/config/firebase'
import { connect } from 'react-redux'
import { Item, List, Form, TextArea } from "semantic-ui-react";
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import cuid from "cuid";
import { Link, withRouter } from 'react-router-dom'
import { toastr } from 'react-redux-toastr';
import LookingTutor from '../../TestPrep/testPrepContents/LookingTutor'

const style = (theme) => ({
  subjectTitle: {
    fontSize: '1.5rem',
    marginLeft: 20
  },
  headerStyle: {
    fontSize: '1.8rem',
    marginBottom: 15,
    marginLeft: 5,
    fontWeight: 600,
    padding: '0px 20px',
    color: '#4CA7AB',
    fontFamily: 'proxima-nova, sans-serif ',
  },
  cardStyle: {
    paddingTop: 20,
    marginTop: -40,
    boxShadow: 'none',
    border: '1px solid #e1e1e6',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
      // marginTop: 20,
      // overflow: 'visible',
      // width: '95%',
      // marginLeft: 'auto',
      // marginRight: 'auto'
    }
  },
  revealButton: {
    margin: '5px 15px 5px 30px',
    backgroundColor: '#4ca7ab',
    color: '#ffffff',
    padding: '5px 20px',
    borderRadius: '10px',
    padding: '5px 20px',
    '&:hover': {
      backgroundColor: '#0A4065',
      color: '#ffffff'
    },
  },

  phoneSize: {
    display: 'none',
    [theme.breakpoints.up('xs')]: {
      display: 'block',
      marginBottom: 15,
      marginLeft: 20
    },
  },
  desktopSize: {
    width: '100%',
    borderBottom: '1px solid #e1e1e6',
    paddingBottom: 15,
    display: 'block',
    marginBottom: 15,
    marginLeft: 20,
    [theme.breakpoints.up('xs')]: {
      display: 'none',

    },
  },
  btnstyle: {
    fontWeight: 600,
    color: 'white',
    backgroundColor: '#4ca7ab',
    padding: '8px 20px',
    borderRadius: 5,
    borderStyle: 'none',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#0A4065',
      color: '#ffffff'
    },
  },
  messageInputStyle: {
    width: '100%',
    minHeight: 100,
    maxHeight: 200,
  },
  purchaseBtnStyle: {
    backgroundColor: '#669fff',
    color: '#fff',
    width: '100%',
    marginTop: 20,
    marginBottom: 7,
    letterSpacing: '1px',
    fontSize: '1.3rem',
    fontFamily: 'proxima-nova, sans-serif ',
    '&:hover': {
      backgroundColor: '#669fff',
    },
    [theme.breakpoints.down('xs')]: {
      width: '95%'
    }
  },
})

const mapState = (state) => ({
  searches: state.searches,
})



class TutorProfileDirectMessage extends React.Component {

  state = {
    revealed: false,
    message: '',
    channelId: '',
    error: [],
    loading: false,
  }

  // componentDidMount() {
  //   this.getChannelId();

  // }

  componentWillUnmount() {
    this.props.setCurrentChannel({ id: "noChannel" });
  }

  // getChannelId = () => {
  //   const { auth, tutorProfile } = this.props;
  //   const currentUserId = auth.uid;
  //   const channelId = tutorProfile.tutorUid < currentUserId ? `${tutorProfile.tutorUid}_${currentUserId}` : `${currentUserId}_${tutorProfile.tutorUid}`
  //   return this.setState({ channelId: channelId });
  // }


  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  createMessage = () => {
    const { userProfile, auth } = this.props;

    const message = {
      timestamp: firebase.database.ServerValue.TIMESTAMP,
      user: {
        id: auth.uid,
        name: userProfile.displayName || userProfile.firstName,
        avatar:
          userProfile.photoURL || userProfile.avatarUrl || "/assets/user.png"
      },
      content: this.state.message,
      read: false
    };
    return message;
  };

  sendMessage = (event) => {
    const { message, } = this.state;
    const { channelId } = this.props;

    if (message) {
      this.setState({ loading: true });

      firebase.database().ref('chats')
        .child(channelId)
        .child("messages")
        .push()
        .set(this.createMessage())
        .then(() => {

          firebase.database().ref('chats')
            .child(channelId)
            .child("lastMessage")
            .set(this.createMessage())
          this.setState({
            loading: false,
            message: "",
            errors: []
          });
          toastr.success("Success", "Message is Sucessfully Sent")
        })
        .catch(err => {
          console.error(err);
          this.setState({
            loading: false,
            errors: this.state.errors.concat(err)
          });
        });
      event.preventDefault();
    } else {
      this.setState({
        errors: this.state.errors.concat({ message: "Add a Message" })
      });
    }
  };

  render() {
    const {
      tutorProfile,
      authenticated,
      openModal,
      haveStudentPass,
      auth,
      classes
    } = this.props
    const { loading, message } = this.state
    
    return (
      <React.Fragment>
        <Card className={classes.cardStyle}>
          {haveStudentPass ?
            <Typography className={classes.headerStyle}>Message {tutorProfile.firstName}</Typography>
            :
            <Typography className={classes.headerStyle}>Purchase Student Pass to Contact {tutorProfile.firstName}</Typography>
          }
          <CardContent>
            {haveStudentPass?
            ''
            :
            <p style={{ marginTop: '-15px', padding: '0 10px', color: '#585c60' }}>For only $9.99/year, Student Pass will allow you to contact as many tutors as you want and get 50% discount on all of our course packages.</p>
          }
            <List relaxed divided>
              {authenticated ?
                <div>
                  {haveStudentPass ?
                    <Form onSubmit={this.sendMessage}  >
                      <TextArea
                        className={classes.messageInputStyle}
                        fluid
                        name='message'
                        onChange={this.handleChange}
                        value={message}
                        placeholder={`Write Your Message to ${tutorProfile.firstName}`}
                        onSubmit={this.sendMessage}
                      />
                      <Item.Content style={{ textAlign: 'right' }}>
                        <button
                          className={classes.btnstyle}
                          style={{ marginTop: '10px' }}
                          disabled={loading}
                          onClick={this.sendMessage}
                        >SEND
                    </button>
                      </Item.Content>
                    </Form>
                    : <div>
                      <Link to='/studentPass'>
                        <Button
                          className={classes.purchaseBtnStyle}
                        >
                          PURCHASE
                    </Button>
                      </Link>

                    </div>
                  }

                </div>
                :
                <div>
                  <Item.Content>
                    <button
                      className={classes.btnstyle}
                      onClick={() => openModal('UnauthModal')}
                      loading={loading}  >
                      LogIn to View</button>
                  </Item.Content>
                </div>
              }
            </List>
          </CardContent>
        </Card>
      </React.Fragment>
    );
  }
}


export default withStyles(style)(connect(mapState)(TutorProfileDirectMessage));