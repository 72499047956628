import React from 'react';
import firebase from '../../../app/config/firebase'
import { connect } from 'react-redux'
import { Form, Segment, Label, Divider } from 'semantic-ui-react';
import Button from '@material-ui/core/Button';
import { Field, reduxForm } from 'redux-form';
import TextInput from '../../../app/common/form/TextInput';
import { withStyles } from '@material-ui/core/styles';
import { closeModal, openModal } from "../../modals/modalActions";
import { sendVerificationEmail} from '../authActions'

const styles = theme => ({
  haha: {
    backgroundColor: '#4ca7ab',
    marginRight: 'auto',
    marginLeft: 'auto',
    width: '100%',
    color: '#fff',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#0A4065',
    },
  },


})
const actions = {
  closeModal,
  openModal,
  sendVerificationEmail,
}

// const sendResetPasswordEmail = (emailAddress) => {
//   console.log("Eamil Address", emailAddress.email)
//   var auth = firebase.auth();
//   auth.sendPasswordResetEmail(emailAddress.email).then(function () {
//     // Email sent.
//   }).catch(function (error) {
//     // An error happened.
//   });
// }

const EmailVerificationForm = ({ sendVerificationEmail, classes, handleSubmit, error }) => {
  return (
    <Form error size="large" onSubmit={handleSubmit(sendVerificationEmail)}>
      <Segment>
        <Button type="submit" className={classes.haha}>
          Resend Verification Email
        </Button>
      </Segment>
    </Form>
  );
};

export default withStyles(styles)(connect(null, actions)(reduxForm({ form: 'EmailVerificationForm' })(EmailVerificationForm)));