import React, { Component } from "react";
import {

  Form,
  Button,
  Header,
  Dropdown
} from "semantic-ui-react";
import { reduxForm, Field } from "redux-form";
import TextInput from "../../../app/common/form/TextInput";
import TextField from '@material-ui/core/TextField'
import TextArea from "../../../app/common/form/TextArea";
import SelectInput from "../../../app/common/form/SelectInput";
import MultipleSelectInput from "../../../app/common/form/MultipleSelectInput";
import DateInput from "../../../app/common/form/DateInput";
import PlaceInput from "../../../app/common/form/PlaceInput";
import { categories, levels, subjects } from "../../../app/common/categories";
import Select from "react-select";

import InputBase from "@material-ui/core/InputBase";
import Script from 'react-load-script';
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  box: {
    padding: '3%',
    marginTop: '120px',
    marginBottom: '20%',
    marginRight: 'auto',
    marginLeft: 'auto',
    [theme.breakpoints.down('xs')]: {
      marginTop: '90px',
      padding: 0
    }
  },
  boxes: {
    marginBottom: '2%',

  },
  category: {
    maxWidth: 400,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '2%',
    marginBottom: '2%',
  },

  price: {
    maxWidth: 200,
    display: 'block',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: '2%',
    marginBottom: '2%',
  },

  hoho: {
    marginLeft: '10%',
  },
  frstbox: {
    borderTop: '3px solid #4ca7ab',
    marginBottom: '5%',
    padding: '3%',
    [theme.breakpoints.down('xs')]: {
      borderRadius: 0,
      paddingRight: 30,
      paddingLeft: 30,
      boxShadow: 'none'
    }

  },
  sndbox: {
    borderTop: '3px solid #4ca7ab',

  },
  twoboxes: {
    maxWidth: 400,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '2%',
    marginBottom: '2%',

  },
  btn2: {
    margin: '5%',
  },
  continuebtn: {
    fontWeight: 600,
    color: 'white',
    backgroundColor: '#4ca7ab',
    padding: '10px 18px 10px 18px',
    borderRadius: '5px',
    border: '1px solid #4ca7ab',
    cursor: 'pointer',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#0A4065',

    }
  },
  backbtn: {
    marginRight: 10,
    fontWeight: 600,
    color: '#f68484',
    backgroundColor: '#fff',
    border: '1px solid #ff7474',
    padding: '10px 18px 10px 18px',
    borderRadius: '5px',
    cursor: 'pointer',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#ff7474',
    },

  }

})

class TutorBasicDetails extends Component {
  state = {
    scriptLoaded: false
  }
  continue = e => {
    e.preventDefault();
    this.props.nextStep();
  };
  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };
  handleScriptLoaded = () => this.setState({ scriptLoaded: true });

  getSubjectsByCategory = categoryId => {
    let result = [];
    subjects.map(subject => {
      if (subject.parentId === categoryId) {
        result.push(subject);
      }
    });
    return result;
  };


  render() {
    const {
      onClick,
      disabled,
      tutorProfile,
      handleChange,
      onSelect,
      handleChangeSelectCategory,
      handleChangeSelectSubject,
      handleChangeSelectLevel,
      handleChangePrice,
      handleChangeFrom,
      handleChangeTo,
      handleChangeWhat,
      handleChangeSelectEducation,
      handleSelectLanguages,
      langOptions,
      subjects,
      educations,
      handleSelectAddTeaching,
      handleSelectRemoveTeaching,
      handleSelectAddEducation,
      handleSelectRemoveEducation,
      nextPage,
      previousPage,
      classes
    } = this.props;
    const { scriptLoaded } = this.state;
    return (
      <React.Fragment>
        <Script
          url="https://maps.googleapis.com/maps/api/js?key=AIzaSyC_B3o6e4mHhWjdZij2lYWPqK_Tw5O_jC8&libraries=places&language=en"
          onLoad={this.handleScriptLoad}
        />
        <Grid container>
          <Grid item xs={12} md={6} className={classes.box}>
            <Card className={classes.frstbox}>
              <Form autoComplete="off">
                <h1 className={classes.basicinfo}>About You</h1>
                <Field
                  name="description"
                  type="text"
                  component={TextArea}
                  placeholder="Briefly Introduce Yourself!"
                />
                <div style={{ float: 'right' }}>
                  <button className={classes.backbtn} label="Back" onClick={this.back}>BACK</button>

                  <button className={classes.continuebtn}
                    disabled={disabled} label="Submit" onClick={onClick}
                  >SUBMIT</button>
                </div>

                {/* <button  disabled={disabled} label="Continue" onClick={this.continue} /> */}

                {/* <Button label="Back" onClick={this.back} />
                <Button disabled={disabled} label="Submit" onClick={onClick} /> */}
              </Form>
            </Card>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(TutorBasicDetails);
