import React, { Component } from "react";
import { Form, Header, Divider, Button } from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import { NavLink, Link } from 'react-router-dom'
import DateInput from "../../../app/common/form/DateInput";
import DateInput2 from "../../../app/common/form/DateInput2";
import PlaceInput from "../../../app/common/form/PlaceInput";
import TextInput from "../../../app/common/form/TextInput";
import RadioInput from "../../../app/common/form/RadioInput";
import InputBase from "@material-ui/core/InputBase";
import Script from 'react-load-script';
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({

  basicbox: {
    marginTop: 150,
    width: "95%",
    marginLeft: "auto",
    marginRight: "auto",
    boxShadow: 'none',
    [theme.breakpoints.down("xs")]: {
      paddingTop: 10,
      width: "100%",
      marginTop: 100

    },
  },
  headerbg: {
    margin: '5% 5% 3% 5%',


  },
  displayname: {
    marginTop: '2%',


  },
  bttn: {
    backgroundColor: '#4ca7ab',
    marginBottom: 20
  },
  whitebar: {
    width: 50,
    backgroundColor: 'white',
  },



})

class BasicPage extends Component {
  state = {
    cityLatLng: {}
  };
  getCity = addressArray => {
    let city = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        "administrative_area_level_2" === addressArray[i].types[0]
      ) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };
  /**
   * Get the area and set the area input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getArea = addressArray => {
    let area = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if (
            "sublocality_level_1" === addressArray[i].types[j] ||
            "locality" === addressArray[i].types[j]
          ) {
            area = addressArray[i].long_name;
            return area;
          }
        }
      }
    }
  };
  /**
   * Get the address and set the address input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getState = addressArray => {
    let state = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "administrative_area_level_1" === addressArray[i].types[0]
        ) {
          state = addressArray[i].long_name;
          return state;
        }
      }
    }
  };

  onPlaceSelected = place => {
    const address = place.formatted_address,
      addressArray = place.address_components,
      city = this.getCity(addressArray),
      area = this.getArea(addressArray),
      state = this.getState(addressArray),
      latValue = place.geometry.location.lat(),
      lngValue = place.geometry.location.lng();

    // Set these values in the state.
    this.setState({
      address: address ? address : "",
      area: area ? area : "",
      city: city ? city : "",
      state: state ? state : "",
      lat: latValue ? latValue : "",
      lng: lngValue ? lngValue : ""
    });
  };

  handleCitySelect = selectedCity => {
    const address = selectedCity.formatted_address,
      addressArray = selectedCity.address_components;
    const latValue = selectedCity.geometry.location.lat();
    const lngValue = selectedCity.geometry.location.lng();
    this.props.change("location", address);
    this.setState({ cityLatLng: { lat: latValue, lng: lngValue } })
    // console.log(address, this.state.cityLatLng);
  };

  handleScriptLoad = () => {
    var options = {
      types: ['geocode'],
    };

    // Initialize Google Autocomplete
    /*global google*/ // To disable any eslint 'google not defined' errors
    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById('autocomplete'),
      options,
    );

    // Fire Event when a suggested name is selected
    this.autocomplete.addListener('place_changed', this.handlePlaceSelect);
  }

  render() {
    const { pristine, submitting, handleSubmit, updateProfile, classes } = this.props;
    return (
      <Grid container >
        <Grid item xs={12} md={12}>
          <Card className={classes.basicbox}>
            <div >
              <Link style={{ float: 'left', marginLeft: 20, marginRight: 20 }} as={NavLink} to='/settings' ><Typography className={classes.basic}>Basics</Typography></Link>
              <span style={{ float: 'left', marginRight: 20 }}> > </span>
              <b className={classes.basic}>Basic Information </b>
            </div>
            <div className={classes.headerbg}>
              <Header style={{ color: '#585c60', marginBottom: 30 }} size="large" content="Basic Information" />

              <Form autoComplete="off" onSubmit={(e) => { e.preventDefault() }}>
                <React.Fragment>
                  <Script
                    url="https://maps.googleapis.com/maps/api/js?key=AIzaSyC_B3o6e4mHhWjdZij2lYWPqK_Tw5O_jC8&libraries=places&language=en"
                    onLoad={this.handleScriptLoad}
                  />
                  <Form autoComplete="off" onSubmit={(e) => { e.preventDefault() }}>
                    <Field
                      width={16}
                      name="displayName"
                      type="text"
                      component={TextInput}
                      placeholder="Known As"
                    />
                    <Field
                      width={16}
                      name="email"
                      type="text"
                      component={TextInput}
                      placeholder="Your Email"
                    />

                    <Field
                      width={16}
                      name="dateOfBirth"
                      type="text"
                      dateFormat="YYYY-MM-DD"
                      component={DateInput2}
                      placeholder="Date of Birth"
                      showYearDropdown={true}
                      showMonthDropdown={true}
                      dropdownMode="select"
                    />
                    <InputBase
                      id="autocomplete"
                      label="Where"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <Form.Group inline >
                      <Field
                        name="gender"
                        type="radio"
                        value="male"
                        component={RadioInput}
                        style={{ marginTop: 20, }}
                      />
                      <span style={{ marginRight: 40 }}>Male</span>
                      <Field
                        name="gender"
                        type="radio"
                        value="female"
                        component={RadioInput}
                        style={{ marginTop: 20, }}
                      />
                      <span>Female</span>
                    </Form.Group>
                  </Form>
                  <Button className={classes.bttn}
                    disabled={pristine || submitting}
                    size="large"
                    positive
                    content="Update Profile"
                    onClick={handleSubmit(updateProfile)}
                  />
                </React.Fragment>
              </Form>
            </div>
          </Card>
        </Grid>
      </Grid>


    );
  }

}

export default withStyles(styles)(reduxForm({
  form: "userProfile",
  enableReinitialize: true,
  destroyOnUnmount: false
})(BasicPage));
