import firebase, { storage } from "../../../app/config/firebase";
import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { withFirestore } from "react-redux-firebase";
import {
  composeValidators,
  combineValidators,
  isRequired,
  hasLengthGreaterThan
} from "revalidate";

import Script from 'react-load-script';
import { uploadProfileImage } from "../../user/userAction";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import {
  createTutorProfile,
  updateTutorProfile,
  cancelToggle
} from "../tutorActions";

import TutorBasicDetails from "./TutorBasicDetails";
import _ from "lodash";
import { toastr } from "react-redux-toastr";
import TutorDescriptionDetails from './TutorDescriptionDetails'
import EducationDetails from "./EducationDetails";
import { langOptions } from "../../../app/common/langOptions";
import { withStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router'
import Background from './assets/tutorform.png'

import { openModal } from "../../modals/modalActions";
import RegistrationSteps from "./RegistrationSteps";
import TutorVideoUpload from "./TutorVideoUpload";
import SubmitSolution from "./SubmitSolution";
import "./TutorForm.css";

const styles = theme => ({

})


const mapState = (state, ownProps) => {
  const tutorProfileId = ownProps.match.params.id;
  let tutorProfile = {};

  if (
    state.firestore.ordered.tutorProfiles &&
    state.firestore.ordered.tutorProfiles.filter(
      profile => profile.id === tutorProfileId
    )[0]
  ) {
    tutorProfile = state.firestore.ordered.tutorProfiles.filter(
      profile => profile.id === tutorProfileId
    )[0];
    // console.log(tutorProfile)
  }

  return {
    initialValues: tutorProfile,
    tutorProfile,
    loading: state.async.loading,
    userProfile: state.firebase.profile,
    user: state.firebase.auth,
  };
};

const actions = {
  createTutorProfile,
  updateTutorProfile,
  cancelToggle,
  openModal,
  uploadProfileImage
};


//Validating the reduxform
const validate = combineValidators({
  firstName: isRequired({ message: "This field is required" }),
  lastName: isRequired({ message: "This field is required" }),
  phone: isRequired({ message: "This field is required" }),
  email: isRequired({ message: "This field is required" }),
  city: isRequired({ message: "This field is required" }),
  school: isRequired({ message: "This field is required" }),
  major: isRequired({ message: "This field is required" }),
  yearStart: isRequired({ message: "This field is required" }),
  yearEnd: isRequired({ message: "This field is required" }),
  // categories: isRequired({ message: "This field is required" }),
  // tutorVideo: isRequired({ message: "This field is required" }),
  // experience: composeValidators(
  //   isRequired({ message: "Please enter a experience" }),
  //   hasLengthGreaterThan(10)({ message: "it needs to be at least 11 characters" })
  // )(),
  description: composeValidators(
    isRequired({ message: "Please enter a description" }),
    hasLengthGreaterThan(30)({ message: "it needs to be at least 31 characters" })
  )()
});

class TutorForm extends Component {
  state = {
    lat: '',
    lng: '',
    formattedAddress: '',
    step: 1,
    levels: [],
    levelList: [],
    languages: {
      English: true
    },
    educations: [],
    experiences: [
      {
        id: 1,
        where: "",
        what: "",
        from: "",
        to: ""
      }
    ],
    suggestions: [],
    value: '',
    // Photo Update
    fileName: "",
    image: null,
    url: '',
    progress: 0,
    files: [],
    fileName: "",
    cropResult: null,
    image: {},
    type: 'tutorForm'
  };


  uploadImage = async () => {
    try {
      await this.props.uploadProfileImage(
        this.state.image,
        this.state.type
      )
      this.cancelCrop();

    } catch (error) {
      toastr.error("Oopsssss", error.message);
    }
  };

  cancelCrop = () => {
    this.setState({
      files: [],
      image: {}
    });
  };


  cropImage = (ref) => {
    // console.log(this.refs)

    if (typeof ref.getCroppedCanvas() === "undefined") {
      return;
    }


    ref.getCroppedCanvas().toBlob(blob => {
      let imageUrl = URL.createObjectURL(blob);
      this.setState({
        cropResult: imageUrl,
        image: blob
      });
    }, "image/jpeg");
  };

  onDrop = files => {
    this.setState({
      files,
      fileName: files[0].name
    });
  };


  reSendVerificationEmail = () => {
    this.props.openModal("EmailVerificationModal")
  }

  handleScriptLoad = () => {
    var options = {
      types: ['geocode'],
    };

    // Initialize Google Autocomplete
    /*global google*/ // To disable any eslint 'google not defined' errors
    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById('autocomplete'),
      options,
    );

    // Fire Event when a suggested name is selected
    this.autocomplete.addListener('place_changed', this.handlePlaceSelect);
  }

  handlePlaceSelect = async () => {
    // Extract City From Address Object
    let addressObject = this.autocomplete.getPlace();

    // Extract lat and lng
    // console.log(addressObject.geometry.location.lat())
    // console.log(addressObject.geometry.location.lng())

    let address = addressObject.address_components;

    let city = "";
    let province = "";



    await _.forEach(address, (addressComponent, i) => {
      if (addressComponent.types.includes("locality")) {
        this.setState({ city: addressComponent.short_name });
      } else if (addressComponent.types.includes("administrative_area_level_1")) {
        this.setState({ province: addressComponent.short_name })
      }
    });
    // console.log("city: in TutorSearchForm", city);
    // console.log("Province/State in TutorSearchForm", province);

    // Check if address is valid
    // console.log(address)
    if (address) {
      // Set State
      this.setState({
        addressComponents: addressObject.address_components,
        lat: addressObject.geometry.location.lat(),
        lng: addressObject.geometry.location.lng(),
        locationName: addressObject.name,
        formattedAddress: addressObject.formatted_address,
        placeId: addressObject.place_id,
        icon: addressObject.icon,
        plusCode: addressObject.plus_code,
        vicinity: addressObject.vicinity,
        utc_offset: addressObject.utc_offset,
        url: addressObject.url
      });
    }
  }

  handleSelectLanguages = (data, e) => {
    let languages = {};
    _.forEach(data, item => {
      _.assign(languages, {
        [item.value]: true
      });
    });
    this.setState(
      {
        languages: languages
      },
      () => {
        // console.log(this.state.languages);
      }
    );
  };

  handleSelectLevels = (e) => {
    let newLevelList = this.state.levelList;
    let levels = this.state.levels;
    let levelLabel = '';
    // console.log(e.target.id)

    switch (true) {
      case e.target.id === 'k8':
        levelLabel = 'K-8'
        break;
      case e.target.id === 'gr9':
        levelLabel = 'Grade 9'
        break;
      case e.target.id === 'gr10':
        levelLabel = 'Grade 10'
        break;
      case e.target.id === 'gr11':
        levelLabel = 'Grade 11'
        break;
      case e.target.id === 'cal':
        levelLabel = 'Grade 12 - Calculus'
        break;
      case e.target.id === 'af':
        levelLabel = 'Grade 12 - Advanced Functions'
        break;
      case e.target.id === 'dm':
        levelLabel = 'Grade 12 - Data Management'
        break;
      default:
        levelLabel = ''
    }

    if (e.target.checked !== true) {
      var i = 0
      _.forEach(levels, level => {
        if (level && level.id === e.target.id) {
          levels.splice(i, 1)
          newLevelList.splice(i, 1);
        }
        // console.log(level)
        i++
      });
    } else {
      levels.push({
        id: e.target.id,
        levelLabel,
        price: 0
      })
      newLevelList.push(e.target.id)
    }

    this.setState({ levels: levels, levelList: newLevelList }, () => {
      // console.log(this.state.levels);
    });
  }

  //level price
  handleChangePrice = (e) => {
    let newLevelList = this.state.levelList;
    let levels = this.state.levels;
    let newLevels = []
    const inputPrice = parseInt(e.target.value);

    levels.map(level => {
      if (level.id === e.target.id) {
        newLevels.push({
          ...level,
          price: inputPrice
        });
      } else {
        newLevels.push(level);
      }
    });
    this.setState({ levels: newLevels }, () => {
      // console.log(this.state.levels);
    });
  };

  //Experiences
  handleChangeSelectExperience = (e, experienceId) => {
    const experiences = this.state.experiences;
    let newExperiences = [];

    experiences.map(experience => {
      if (experience.id === experienceId) {
        newExperiences.push({
          ...experience,
          where: e.target.value
        });
      } else {
        newExperiences.push(experience);
      }
    });
    this.setState({ experiences: newExperiences }, () => {
      // console.log(this.state.experiences);
    });
  };

  handleSelectAddExperience = e => {
    const experiences = this.state.experiences;
    const sizeOfExperiences = _.size(experiences);
    const newId =
      sizeOfExperiences === 0 ? 1 : experiences[_.size(experiences) - 1].id + 1;
    this.setState(
      state => {
        return {
          experiences: [
            ...state.experiences,
            {
              id: newId,
              where: null,
              what: null,
              from: null,
              to: null
            }
          ]
        };
      },
      () => {
        // console.log(this.state.subjects)
      }
    );
  };
  handleSelectRemoveExperience = id => {
    let experiences = this.state.experiences;
    let newExperiences = [];
    _.forEach(experiences, experience => {
      if (experience.id !== id) {
        newExperiences.push(experience);
      }
    });
    this.setState({ experiences: newExperiences }, () => {
      // console.log(this.state.subjects)
    });
  };

  handleChangeFrom = (e, experienceId) => {
    const experiences = this.state.experiences;
    let newExperiences = [];

    experiences.map(experience => {
      if (experience.id === experienceId) {
        newExperiences.push({
          ...experience,
          from: e.target.value
        });
      } else {
        newExperiences.push(experience);
      }
    });
    this.setState({ experiences: newExperiences }, () => {
      // console.log(this.state.experiences);
    });
  };
  handleChangeTo = (e, experienceId) => {
    const experiences = this.state.experiences;
    let newExperiences = [];

    experiences.map(experience => {
      if (experience.id === experienceId) {
        newExperiences.push({
          ...experience,
          to: e.target.value
        });
      } else {
        newExperiences.push(experience);
      }
    });
    this.setState({ experiences: newExperiences }, () => {
      // console.log(this.state.experiences);
    });
  };
  handleChangeWhat = (e, experienceId) => {
    const experiences = this.state.experiences;
    let newExperiences = [];

    experiences.map(experience => {
      if (experience.id === experienceId) {
        newExperiences.push({
          ...experience,
          what: e.target.value
        });
      } else {
        newExperiences.push(experience);
      }
    });

    // console.log(e.target.value)
    this.setState({ experiences: newExperiences }, () => {
      // console.log(this.state.experiences);
    });
  };

  //Description
  handleChangeDescription = (data, e) => {

    this.setState({
      description: e
    })
  }

  // handleSelectAddEducation = e => {
  //   const educations = this.state.educations;
  //   const sizeOfEducations = _.size(educations);
  //   const newId =
  //     sizeOfEducations === 0 ? 1 : educations[_.size(educations) - 1].id + 1;
  //   this.setState(
  //     state => {
  //       return {
  //         educations: [
  //           ...state.educations,
  //           {
  //             id: newId,
  //             where: null,
  //             what: null,
  //             from: null,
  //             to: null
  //           }
  //         ]
  //       };
  //     },
  //     () => {
  //       // console.log(this.state.subjects)
  //     }
  //   );
  // };
  // handleSelectRemoveEducation = id => {
  //   let educations = this.state.educations;
  //   let newEducations = [];
  //   _.forEach(educations, education => {
  //     if (education.id !== id) {
  //       newEducations.push(education);
  //     }
  //   });
  //   this.setState({ educations: newEducations }, () => {
  //     // console.log(this.state.subjects)
  //   });
  // };






  // Proceed to next step


  nextStep = (e) => {
    const { step } = this.state;
    this.setState({
      step: step + 1
    });
  };

  // Go back to prev step
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1
    });
  };

  async componentDidMount() {
    const { firebase, firestore, match, tutorProfile } = this.props;
    //await firestore.get(`profiles/${match.params.id}`);
    await firestore.setListener(`tutorProfiles/${match.params.id}`);

  }

  onFormSubmit = async (values) => {
    const { showSuccess, showError, history } = this.props
    const { step } = this.state;
    // values.date = moment(values.date).format();
    const levels = this.state.levels;
    const levelList = this.state.levelList;

    // let newLevelList = [];

    // await levels.map(level => {
    //   newLevelList.push(...levelList, level.id);
    // });

    // if(this.state.files.length === 0) {
    //   return window.alert('Upload the profile photo')
    // }
    values.levels = levels;
    values.levelList = levelList;
    const newLocation = new firebase.firestore.GeoPoint(this.state.lat, this.state.lng);
    values.languages = this.state.languages;
    values.experiences = this.state.experiences;
    values.educations = [
      {
        id: 1,
        where: values.school,
        what: values.major,
        from: values.yearStart,
        to: values.yearEnd,
      }
    ]
    values.city = this.state.city;
    values.province = this.state.province;
    values.location = newLocation;
    values.formattedAddress = this.state.formattedAddress;

    delete values.school;
    delete values.major;
    delete values.yearStart;
    delete values.yearEnd;



    let text = this.state.description
    // text = text.replace(/ /g, "[sp][sp]");
    text = text.replace(/\n/g, "[n1]");

    values.description = text;

    // console.log('values',values)
    // console.log('Id',this.props.initialValues.id)

    if (this.props.initialValues.id) {
      this.props.updateTutorProfile(values);
      this.props.history.goBack();
    } else {
      this.props.createTutorProfile(values);
      // this.props.history.push(`/tutor/${this.props.user.uid}`);
      this.setState({
        step: step + 1
      });
    }
  };

  goToMyProfile = () => {
    this.props.history.push(`/tutor/${this.props.user.uid}`);
  }

  // Handle fields change
  handleChange = input => e => {
    this.setState({ [input]: e.target.value });
  };

  render() {

    const {
      user,
      userProfile,
      invalid,
      submitting,
      pristine,
      tutorProfile,
      cancelToggle,
      classes
    } = this.props;
    const { scriptLoaded, step, value, suggestions, files } = this.state;


    switch (step) {
      case 1:
        if (userProfile.hasProfile) {
          // console.log(userProfile.hasProfile)
          window.alert("You Already Have a Profile Created")
          return <Redirect to="/" />
        } else if (!user.emailVerified && user.providerData[0].providerId !== "facebook.com") {

          window.alert("Your Account is Not Verified Yet. Please Check Your Email")
          this.reSendVerificationEmail();
          return <Redirect to="/" />
        } else {
          return (
            <div className={classes.sectionStyleResponsive}
            >
              {/* <Script
                url="https://maps.googleapis.com/maps/api/js?key=AIzaSyC_B3o6e4mHhWjdZij2lYWPqK_Tw5O_jC8&libraries=places&language=en"
                onLoad={this.handleScriptLoad}
              /> */}
              <RegistrationSteps
                id="myForm"
                nextStep={this.nextStep}
                handleChange={this.handleChange}
                tutorProfile={tutorProfile}
                formattedAddress={this.state.formattedAddress}
              />
            </div>
          )
        };
      case 2:
        return (
          <div >
            <Script
              url="https://maps.googleapis.com/maps/api/js?key=AIzaSyC_B3o6e4mHhWjdZij2lYWPqK_Tw5O_jC8&libraries=places&language=en"
              onLoad={this.handleScriptLoad}
            />
            <EducationDetails
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              handleChange={this.handleChange}
              tutorProfile={tutorProfile}

              //Photo Uploading related props
              uploadImage={this.uploadImage}
              onDrop={this.onDrop}
              cancelCrop={this.cancelCrop}
              cropImage={this.cropImage}
              files={files}

              image={this.state.image}
              url={this.state.url}
              progress={this.state.progress}
              handleChangeSelectExperience={this.handleChangeSelectExperience}
              handleChangeSelectCategory={this.handleChangeSelectCategory}
              handleChangeSelectSubject={this.handleChangeSelectSubject}
              handleSelectLevels={this.handleSelectLevels}
              handleSelectAddTeaching={this.handleSelectAddTeaching}
              handleSelectRemoveTeaching={this.handleSelectRemoveTeaching}
              handleSelectAddExperience={this.handleSelectAddExperience}
              handleSelectRemoveExperience={this.handleSelectRemoveExperience}
              handleChangePrice={this.handleChangePrice}
              handleChangeFrom={this.handleChangeFrom}
              handleChangeTo={this.handleChangeTo}
              handleChangeWhat={this.handleChangeWhat}
              handleChangeSelectLevel={this.handleChangeSelectLevel}
              handleSelectLanguages={this.handleSelectLanguages}
              subjects={this.state.subjects}
              experiences={this.state.experiences}
              previousPage={this.previousPage}
              nextPage={this.nextPage}
              langOptions={langOptions}
              languages={this.state.languages}
              onSubmit={this.onFormSubmit}
              onSelect={this.handlePlaceSelect}
              onClick={this.props.handleSubmit(this.onFormSubmit)}
              levelList={this.state.levelList}
              disabled={invalid || submitting || pristine}
              handleChangeDescription={this.handleChangeDescription}
            />
          </div>
        );
      case 3:
        return (
          <div >
            <TutorVideoUpload
              nextStep={this.nextStep}
              prevStep={this.prevStep}
            />
          </div>

        );
      case 4:
        return (

          <div >
            <SubmitSolution
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              goToMyProfile={this.goToMyProfile}
              disabled={invalid || submitting || pristine}
            />
          </div>

        );
    }
  }
}

export default withStyles(styles)(withFirestore(connect(mapState, actions)(
  reduxForm({ form: "tutorForm", enableReinitialize: true, validate })(
    TutorForm
  )
)
));
