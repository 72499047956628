import React, { Component } from "react";
import Autocomplete from "react-google-autocomplete";
import { Form, Label } from "semantic-ui-react";

class PlaceInput extends Component {
  state = {
    scriptLoaded: false
  };

  handleScriptLoaded = () => this.setState({ scriptLoaded: true });
  onKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); //<===== This stops the form from being submitted
      event.stopPropagation();
      alert("enter")
    } else {
      // alert("not enter")
    }
  }
  render() {
    const {
      scriptLoaded,
      input,
      width,
      onSelect,
      placeholder,
      autoComplete,
      meta: { touched, error }
    } = this.props;

    // console.log(scriptLoaded)
    return (

      <Form.Field autoComplete="new-password" error={touched && !!error} width={width} >
        {scriptLoaded &&
          <Autocomplete
            onKeyPress={this.onKeyPress}
            {...input}
            placeholder={placeholder}
            onPlaceSelected={onSelect}
            
            types={[]}
          />
        }

        {touched && error && <Label basic color='red'>{error}</Label>}
      </Form.Field>
    );
  }
}
export default PlaceInput;
