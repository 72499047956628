import React from 'react'

const StudentDetailedSidebar = () => {
    return (
        <div>
        <h1>Sidebar</h1>     
        </div>
    )
}

export default StudentDetailedSidebar
