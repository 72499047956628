import React from 'react'
import { Icon, Button } from 'semantic-ui-react'
import Add from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Create from '@material-ui/icons/Create';
import Select from 'react-select'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import nl2br from 'react-newline-to-break';

const style = (theme) => ({
  subjectTitle: {
    fontSize: '1.5rem',
    marginLeft: 40,
    fontWeight: 600,
    paddingBottom: 15,
    color: '#4ca7ab',
    [theme.breakpoints.down('xs')]: {
      marginLeft: 20
      }
  },
  cardStyle: {
    marginTop: 20,
    overflow: 'hidden',
    width: '95%',
    marginLeft: 'auto',
    marginRight: 'auto',
    boxShadow: 'none',
    border: '1px solid #e1e1e6',
    color: '#585c60',
    paddingTop: 8,
    paddingBottom: 8,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: 0,
      borderRadius: 0,
      boxShadow: 'none',

    }
  },
  phoneSize: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      marginBottom: 17,
      marginLeft: 20,
      marginRight: 20,
      paddingTop: 17,
      paddingLeft: 20,
      paddingBottom: 0,
      borderTop: '1px solid #e1e1e6'
    },
  },
  desktopSize: {
    width: '100%',
    borderBottom: '1px solid #e1e1e6',
    paddingBottom: 15,
    display: 'block',
    marginBottom: 15,
    paddingLeft: 20,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },

  },
  desktopSize1: {
    width: '100%',
    display: 'block',
    paddingLeft: 20,
    margin: 0,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },

  },


  selectStyle: {
    marginBottom: 20
  },
  card: {
    overflow: 'visible'
  },
 
  cardHeaderStyle: {
    paddingBottom: 0
  },
  descriptionContents:{
    display: 'block',
    marginLeft: '20px',
    marginRight: '20px',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      marginBottom: 17,
      marginLeft: '40px',
      marginRight: '40px',
      paddingTop: 17,
      paddingBottom: 0,
      borderTop: '1px solid #e1e1e6'
    },
  }
})
const TutorProfileDescription = ({
  tutorProfile,
  handleResumeUserDescriptionClickClose,
  handleSubmitDescription,
  openResumeUserDescription,
  handleResumeUserDescriptionClickOpen,
  handleChange,
  isOwner,
  classes
}) => {

  // console.log("In TutorProfileDescription, tutorProfile:", tutorProfile.description);

  let text = tutorProfile.description;
  let newText;

  if (tutorProfile.description) {
    text = text.replace(/\[n1\]/g, '\n');
    // text = text.replace(/\[sp\]/g, `&nbsp;`);
    // console.log("In TutorProfileDescription, text:", text);
  }







  return (

    <React.Fragment>
      <div>
        <Dialog
          fullWidth
          open={openResumeUserDescription}
          onClose={() => handleResumeUserDescriptionClickClose()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title"></DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <TextField
                id="outlined-name"
                label="About You"
                name="description"
                onChange={(e) => handleChange(e)}
                margin="normal"
                defaultValue={text}
                multiline={true}
                rows={2}
                rowsMax={10}
                fullWidth
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button className={classes.dialBtnStyle1} onClick={() => handleResumeUserDescriptionClickClose()} >CANCEL</button>
            <button className={classes.dialBtnStyle2} onClick={() => handleSubmitDescription()} >SAVE</button>
          </DialogActions>
        </Dialog>
      </div>
      {isOwner ?
        <Card className={classes.cardStyle}>
          <CardContent
            className={classes.cardHeaderStyle}

          >
            <Grid container>
              <Grid item xs={11} md={11}>
                <Typography className={classes.subjectTitle}>About {tutorProfile.firstName}</Typography>
              </Grid>
              <Grid item xs={1} md={1}>
                <Create style={{
                  display: 'inlineBlock',
                  cursor: 'pointer',
                  float: 'right',
                  color: '#4ca7ab'
                }} onClick={() => handleResumeUserDescriptionClickOpen()} />
              </Grid>
            </Grid>
          </CardContent>

          <CardContent className={classes.descriptionContents} attached>
            {nl2br(text)}
          </CardContent>

        </Card>
        :
        <Card className={classes.cardStyle}>
          <CardContent
            className={classes.cardHeaderStyle}

          >
            <Typography  className={classes.subjectTitle}>About {tutorProfile.firstName}</Typography>
          </CardContent>

          <CardContent className={classes.descriptionContents}>
            {nl2br(text)}
          </CardContent>

        </Card>


      }
    </React.Fragment>


  )
}

export default withStyles(style)(TutorProfileDescription)
