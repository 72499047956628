import firebase from "../../../app/config/firebase";
import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Form } from "redux-form";
import { withFirestore } from "react-redux-firebase";
import {
  composeValidators,
  combineValidators,
  isRequired,
  hasLengthGreaterThan
} from "revalidate";

import {
  updateTutorProfile,
} from "../tutorActions";

import TutorBasicDetails2 from "./TutorBasicDetails2";
import _ from "lodash";
import { langOptions } from "../../../app/common/langOptions";
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  haha: {
    marginTop: 1000
  }
})


const mapState = (state, ownProps) => {
  const tutorProfileId = ownProps.match.params.id;
  let tutorProfile = {};


    if(state.profiles.current && state.profiles.current.tutorUid === tutorProfileId ){
      tutorProfile.firstName = state.profiles.current.firstName;
      tutorProfile.lastName = state.profiles.current.lastName;
      tutorProfile.phone = state.profiles.current.phone;
      tutorProfile.email = state.profiles.current.email;
      tutorProfile.city = state.profiles.current.city;

    }

  return {
    initialValues: tutorProfile,
    tutorProfile,
    loading: state.async.loading
  };
};

const actions = {
  updateTutorProfile,
};

const validate = combineValidators({
  firstName: isRequired({ message: "This field is required" }),
  lastName: isRequired({ message: "This field is required" }),
  phone: isRequired({ message: "This field is required" }),
  email: isRequired({ message: "This field is required" }),
  city: isRequired({ message: "This field is required" }),
  categories: isRequired({ message: "This field is required" }),
  // description: composeValidators(
  //   isRequired({ message: "Please enter a description" }),
  //   hasLengthGreaterThan(50)({ message: "it needs to be at least 51 characters" })
  // )()
});

class TutorForm2 extends Component {
  state = {
  };

  handleCitySelect = async selectedCity => {
    const address = selectedCity.formatted_address,
      addressArray = selectedCity.address_components;
    const latValue = selectedCity.geometry.location.lat();
    const lngValue = selectedCity.geometry.location.lng();
    // this.props.change("city", address);

    await _.forEach(selectedCity.address_components, (addressComponent, i) => {
      if (addressComponent.types.includes("locality")) {
        this.setState({ city: addressComponent.short_name });
      } else if (addressComponent.types.includes("administrative_area_level_1")) {
        this.setState({ province: addressComponent.short_name })
      }
    })  

    // console.log("City", this.state.city)
    // console.log("Address",address)
    // console.log("province",this.state.province)

    this.setState({ lat: latValue, lng: lngValue, formattedAddress: address});
  };

  onFormSubmit = async (values) => {
    // console.log(values)
    if (this.state.lat && this.state.lng) {
      const newLocation = new firebase.firestore.GeoPoint(this.state.lat, this.state.lng);
      values.location = newLocation
    }
    values.city = this.state.city;
    values.province = this.state.province
    values.formattedAddress = this.state.formattedAddress;
    this.props.updateTutorProfile(values);
    this.props.history.goBack();
  };

  onFormCancel = () => {
    this.props.history.goBack();
  };

  render() {
    const {
      invalid,
      submitting,
      pristine,
      tutorProfile,
    } = this.props;

    return (
        <TutorBasicDetails2
          tutorProfile={tutorProfile}
          onSelect={this.handleCitySelect}
          disabled={invalid || submitting || pristine}
          handleSelectLanguages={this.handleSelectLanguages}
          langOptions={langOptions}
          onClick={this.props.handleSubmit(this.onFormSubmit)}
          onFormCancel={this.onFormCancel}
        />
    )
  }
}

export default withStyles(styles)(withFirestore(
  connect(
    mapState,
    actions
  )(
    reduxForm({ form: "tutorForm", enableReinitialize: true, validate })(
      TutorForm2
    )
  )
));
