import React, { useEffect } from "react";
import { connect } from "react-redux";
import firebase from "../../../../app/config/firebase";
import moment from "moment";
import { Comment } from "semantic-ui-react";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";

const styles = theme => ({
  message__self: {}
});

const OtherMsg = styled.div`
  border-radius: 15px;
  border: 1px solid #ccc;
  background-color: #4ca7ab;
  text-align: center;
  font-weight: 300;
  color: white;
  font-family: arial;
  position: relative;
  width:fit-content;
  margin-top: 1px;
  margin-right: 'auto';
  margin-bottom: 1px;
  margin-left: 'auto';
  max-width: 425px;
  min-width: 40px;
  min-height: 40px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 14px;
  padding-right: 14px;

  
`;

const MyMsg = styled.div`
border-radius: 15px;
border: 1px solid #4ca7ab;
background-color: #fff;
text-align: center;
font-weight: 300;
color: #585c60;
font-family: arial;
width:fit-content;
float: right;
margin-top: 1px;
margin-right: 'auto';
margin-bottom: 1px;
margin-left: 'auto';
max-width: 100%;
min-width: 40px;
min-height: 40px;
padding-top: 8px;
padding-bottom: 8px;
padding-left: 14px;
padding-right: 14px;

`;

// border-radius: 20px;
// border: 1px solid #c9eaff;
// background-color: #c9eaff;
// text-align: center;
// font-weight: 300;
// color: black;
// font-family: arial;
// display: block;
// width:fit-content;
// float: left;
// margin-top: 1px;
// margin-right: 'auto';
// margin-bottom: 1px;
// margin-left: 'auto';
// max-width: 425px;
// padding-top: 8px;
// padding-bottom: 8px;
// padding-left: 14px;
// padding-right: 14px;
const mapState = state => ({
  isTabFocused: state.tabFocus.isTabFocused
});

const isOwnMessage = (message, user) => {
  return message.user.id === user.auth.uid ? true : false;
};

const timeFromNow = timestamp => {
  return moment(timestamp).fromNow();
};

const isRead = (message, user, currentChannel, isTabFocused) => {
  //console.log(message)
  const messagesRef = firebase
    .database()
    .ref("chats")
    .child(currentChannel)
    .child("messages")
    .child(message.id);
  if (isTabFocused && message.user.id !== user.auth.uid) {
    return messagesRef.update({
      ...message,
      read: true
    });
  }
  return;
};

const ChatMessage = ({
  classes,
  message,
  user,
  currentChannel,
  isTabFocused,
  time
}) => {

  message && isRead(message, user, currentChannel, isTabFocused);
  return (
    <Comment>
      <Comment.Content
        className={isOwnMessage(message, user) ? classes.message__self : ""}
      >
        <Comment.Author as='a'>
          {isOwnMessage(message, user) ? (
            <Grid container>
              <Grid item xs={4} md={8} />

              <Grid style={{ textAlign: 'right' }} item xs={8} md={4}>

                <Grid container>
                  <Grid item xs={12} md={12} >
                    <MyMsg>

                      <Comment.Text>
                        {" "}
                        <div style={{ color: "#4ca7ab", display: 'block', fontSize: '16px' }}>
                          {message.content}
                        </div>
                        {" "}
                      </Comment.Text>
                    </MyMsg>
                  </Grid>

                  {time && <Grid item xs={12} md={12}>
                    <p>
                      <Comment.Metadata style={{ fontSize: '13px' }}>
                        {moment(message.timestamp).format('h:mma MMM/DD/YYYY')}
                      </Comment.Metadata>
                    </p >
                  </Grid>}
                </Grid>

              </Grid>
            </Grid>
          ) : (
              <Grid container>
                <Grid item xs={8} md={8}>
                  <OtherMsg>
                    <Comment.Text>
                      {" "}
                      <div style={{ color: "#fff", display: 'block', fontSize: '16px' }}>
                        {message.content}
                      </div>
                      {" "}
                    </Comment.Text>
                  </OtherMsg>
                  {time && <span style={{ float: 'left' }}>
                    <Comment.Metadata style={{ fontSize: '13px' }}>
                      {moment(message.timestamp).format('h:mma MMM/DD/YYYY')}
                    </Comment.Metadata>
                  </span>}
                </Grid>
              </Grid>
            )}
        </Comment.Author>
      </Comment.Content>
    </Comment>
  );

};

export default withStyles(styles)(connect(mapState)(ChatMessage));