import React, { Component } from "react";
import firebase from "../../../../app/config/firebase";
import { connect } from "react-redux";
import { Button, Form, Header, Item, ItemContent } from "semantic-ui-react";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import ChatUserInfo from "./ChatUserInfo";
import { NavLink, Link } from 'react-router-dom'

const styles = theme => ({
  marginStyle: {
    marginTop: 130,
    [theme.breakpoints.down("xs")]: {
      marginTop: 110
    }

  },
  basicbox: {
    marginTop: 20,
    width: "95%",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: 0,
    paddingBottom: 10,
    boxShadow: 'none',
    [theme.breakpoints.down("xs")]: {
      paddingTop: 10,
      marginTop: 0,
      marginBottom: 300,
      borderRadius: 0,
      width: '100%',

    },
  },
  unread: {
    backgroundColor: '#edf2fa',
  },
  info: {
    fontSize: "1.5rem",
    fontWeight: 500,
    color: "#585c60",
    marginTop: 8,
    marginBottom: 5,
    paddingTop: 10,
    paddingLeft: 5,
    [theme.breakpoints.down("xs")]: {

    }
  },
  headerbg: {
    margin: 0,

  },
  displayname: {
    marginTop: "2%"
  },
  bttn: {
    backgroundColor: "#4ca7ab"
  },
  whitebar: {
    width: 50,
    backgroundColor: "white"
  },
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60
  },
  hdfont: {
    display: "inlineBlock",
    color: "#585c60",
    marginTop: 200,
    marginLeft: 25
  },

  chatIconStyle: {
    display: "inlineBlock",
    marginBottom: 20,
    marginTop: 20,
  },
  checkboxStyle: {
    marginTop: 20,
    marginLeft: -20,
    marginRight: 20
  },
  descriptionStyle: {
    width: '40px',
    marginLeft: 20,
    marginTop: 0,
    [theme.breakpoints.down("xs")]: {
      marginLeft: 25,
      marginTop: 5
    }
  },
  avatarStyle: {
    width: 80,
    height: "auto",
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 25,
    [theme.breakpoints.down("xs")]: {
      width: 50,
      marginTop: 15,
      marginBottom: 15,
      height: 'auto',
    }
  },
  newMsgStyle: {
    backgroundColor: '#edf2fa',
    borderRadius: 3
  },
  dividerStyle: {
    width: '90%',
    marginRight: 'auto',
    marginLeft: 'auto',


  },
  emptyMsg: {
    display: 'block',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: '70px'
  },
  msgStyle: {
    paddingLeft: '7px',

    [theme.breakpoints.down("xs")]: {
      marginTop: -10
    }
  }
});

class ChatsList extends Component {
  state = {
    chats: [],
    modal: false,
    chatsRef: firebase.database().ref("chats"),
    usersRef: firebase.database().ref("users"),
    currentUser: this.props.currentUser,
    currentUserId: this.props.currentUserId,
    chatsLoading: true,
  };

  componentDidMount() {
    this.addUserChatListener();
  }

  componentWillUnmount() {
    this.removeUserChatListener();
  }

  addUserChatListener = () => {
    const { usersRef, currentUserId } = this.state;
    let loaddedChats = [];

    usersRef
      .child(currentUserId)
      .child("chats")
      .on("value", snap => {
        for (var property1 in snap.val()) {
          loaddedChats.push(snap.val()[property1]);
        }
        // console.log(loaddedChats)
        loaddedChats.sort(function (a, b) {
          return b.lastMessage.messageSent - a.lastMessage.messageSent;
        });
        this.setState({
          chats: loaddedChats,
          chatsLoading: false
        });

        loaddedChats = [];
      })
  };

  removeUserChatListener = () => {
    const { usersRef, currentUserId } = this.state;
    usersRef
      .child(currentUserId)
      .child("chats")
      .off()
  }


  displayChats = chats => {
    const { classes } = this.props;
    return (
      chats.length > 0 &&
      chats.map(chat => {

        return (
          <div className={chat.lastMessage.read ? classes.read : classes.unread}>
            <ItemContent as={Link} to={`/chat/${chat.channel}`}>
              <Grid container>
                <Grid item xs={2} md={2}>
                  <Avatar
                    className={classes.avatarStyle}
                    src={chat.photoURL || "/assets/user.png"}
                  />
                </Grid>
                <Grid item xs={7} md={9} className={classes.descriptionStyle} >
                  <Typography
                    className={classes.info}
                  >
                    {chat.recipientName}
                  </Typography>
                  <Typography className={classes.msgStyle} >
                    {chat.lastMessage.content.length > 70 ? `${chat.lastMessage.content.substring(0, 70)}.....` : chat.lastMessage.content}
                  </Typography>
                </Grid>

              </Grid>

            </ItemContent>
            <Divider className={classes.dividerStyle} />
          </div >
        );


      })
    );
  };

  noOne = noneChat => {
    const { classes } = this.props;

    return (
      <div style={{ textAlign: 'center', minHeight: '150px' }} >
        <Avatar className={classes.emptyMsg} src={"/assets/emptyMsg.png"}
        />
        <Typography style={{ fontSize: '1.3rem' }} >Your Inbox is Empty</Typography>
      </div >
    )

  }

  render() {
    const { classes, currentUserId, currentUser } = this.props;
    const { chats } = this.state;

    return (
      <React.Fragment>
        <div className={classes.marginStyle}>
          <Link style={{ float: 'left', marginLeft: 20, marginRight: 20 }} as={NavLink} to='/settings' ><Typography className={classes.basic}>Basics</Typography></Link>
          <span style={{ float: 'left', marginRight: 20 }}> > </span>
          <b className={classes.basic}>Chats </b>
        </div>
        <ChatUserInfo currentUser={currentUser} currentUserId={currentUserId} />

        <Grid container >
          <Grid item xs={12} md={12}>
            <Card className={classes.basicbox}>
              <div className={classes.headerbg}>
                <div>
                  <h2 className={classes.hdfont}>
                    {/* <i
                      class='material-icons'
                      style={{
                        color: "#585c60",
                        marginRight: 13,
                        marginTop: 15
                      }}
                    >
                      question_answer
                    </i> */}
                    <div className={classes.chatIconStyle}>Chats</div>
                  </h2>
                </div>
                <Divider className={classes.dividerStyle} />
                {chats.length > 0 ? <div>{this.displayChats(chats)}</div> : <div>{this.noOne()}</div>}

              </div>
            </Card>
          </Grid>
        </Grid>

      </React.Fragment>
    );
  }
}

export default withStyles(styles)(connect(null)(ChatsList));
