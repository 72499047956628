import React, { Component } from 'react';
import { Form  } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import TextArea from '../../../app/common/form/TextArea'
import StarRatings from 'react-star-ratings';
import {
  composeValidators,
  combineValidators,
  isRequired,
  hasLengthGreaterThan,
  hasLengthLessThan
} from "revalidate";
import DeleteIcon from '@material-ui/icons/Delete';
import { withStyles } from '@material-ui/core/styles';


const styles = (theme) => ({
  dialBtnStyle1: {
    padding: '8px 16px',
    backgroundColor: '#fff',
    borderRadius: 5,
    color: '#FF5D5D',
    cursor: 'pointer',
    border: '1px solid #FF5D5D',
    '&:hover': {
      backgroundColor: '#FF5D5D',
      color: '#fff'
    },
  },
  dialBtnStyle2: {
    padding: '8px 16px',
    backgroundColor: '#4ca7ab',
    borderRadius: 5,
    borderStyle: 'none',
    color: 'white',
    cursor: 'pointer',
    marginRight: 10,
    '&:hover': {
      backgroundColor: '#0A4065',
    },
  }
})

const validate = combineValidators({
  comment: composeValidators(
    isRequired({ message: "Please enter a description" }),
    hasLengthGreaterThan(4)({ message: "it needs to be at least 5 characters" }),
    hasLengthLessThan(300)({ message: "it needs to be less than 301 characters" })
  )()
});

class TutorProfileChatForm extends Component {

  handleCommentSubmit = values => {
    const { resetRatingToZero, addTutorProfileComment, reset, tutorProfileId, closeForm, rating, closeReviewAfterSubmit } = this.props;
    addTutorProfileComment({ tutorProfileId, values, rating });
    resetRatingToZero();
    reset();
  }

  render() {
    const {
      defaultText,
      changeRating,
      rating,
      tutorProfileId,
      userId,
      resetRatingToZero,
      enableDelete,
      invalid,
      submitting,
      pristine,
      classes
    } = this.props;
    return (
      <Form>
        <StarRatings
          starDimension="25px"
          starSpacing="1px"
          rating={rating}
          starRatedColor="#ff5d5d"
          starHoverColor="#ff5d5d"
          starEmptyColor="lightgrey"
          changeRating={changeRating}
          numberOfStars={5}
          name='rating'
        />
        {enableDelete &&
          <span>
            <DeleteIcon
              style={{
                marginLeft: '55%',
                color: '#FF5D5D',
                fontSize: '18px',
                cursor: 'pointer',
                float: 'right'
              }}
              onClick={() => {
                this.props.deleteTutorProfileComment({ tutorProfileId, userId });
                resetRatingToZero();
              }} fontSize="small" />
          </span>

        }
        <Field
          name='comment'
          type='text'
          component={TextArea}
          placeholder={defaultText}
          rows={2}
          default="Leave comment"
        >
        </Field>
        <button
          variant="contained"
          labelPosition="left"
          icon="edit"
          primary
          className={classes.dialBtnStyle2}
          onClick={this.props.handleSubmit(this.handleCommentSubmit)}
          disabled={invalid || submitting || pristine}
        >SUBMIT
        </button>
        <span>
          <button className={classes.dialBtnStyle1} onClick={this.props.hanldeToggleReview}>CANCEL</button>
        </span>
      </Form>
    )
  }
}

export default withStyles(styles)(reduxForm({ Fields: 'comment', enableReinitialize: true, validate })(TutorProfileChatForm));

