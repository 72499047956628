import React, { Component } from "react";
import firebase from "../../../../app/config/firebase";
import { connect } from "react-redux";
import { Comment, Header, Input } from "semantic-ui-react";
import { Link } from "react-router-dom";
import ChatMessagesForm from "./ChatMessagesForm";
import ChatMessage from "./ChatMessage";
import { withStyles } from "@material-ui/core/styles";
import { setCurrentChannel, getChatMessages } from "./chatActions";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import InfiniteScroll from 'react-infinite-scroller';
import LoadingComponent from '../../../../app/layout/LoadingComponent'

const actions = {
  setCurrentChannel,
  getChatMessages
}

const mapState = (state) => ({
  chatMessages: state.chat.chatMessages,
  loading: state.async.loading,
})

//<LoadingComponent inverted />

const styles = theme => ({
  messages: {
    minHeight: "340px",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      height: "340px",

    }
  },
  searchMessages: {
    float: 'right',
    borderRadius: '5px',
    border: '1px solid #c2c7d1',
    boxShadow: 'none',
    fontSize: '0.7rem',
    padding: '7px 10px',
    marginTop: '10px',
    marginRight: 20

  },
  headerStyle: {
    border: '1px solid #e1e1e6',
    paddingTop: 20,
    borderRadius: '5px',
    [theme.breakpoints.down("sm")]: {
      border: 'none',
      borderBottom: '1px solid #e1e1e6',
      paddingBottom: 20,
      marginBottom: 20,
    }
  },
  SearchSty: {
    [theme.breakpoints.down("sm")]: {
      display: 'none'
    }
  },
  messageStyle: {
    width: "70%",
    maxHeight: '700px',
    marginRight: "auto",
    marginLeft: "auto",
    marginTop: '120px',
    padding: 30,
    boxShadow: 'none',
    [theme.breakpoints.down("xs")]: {
      width: '100%',
      marginTop: '90px',
      padding: '15px 5px 10px 10px',
      borderRadius: 0

    }
  },
  dsp: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "flex"
    }
  },
  backToChat: {
    padding: '7px 15px',
    border: '1px solid #4ca7ab',
    borderRadius: 10,
    backgroundColor: '#f4f5f7',
    marginTop: 150,
    marginLeft: '16%',
    fontSize: '1rem',
    fontWeight: 400,
    display: 'flex',
    cursor: 'pointer',
    [theme.breakpoints.down("xs")]: {
      marginTop: 100,
      marginLeft: 0
    },
    "&:hover": {
      backgroundColor: "#4ca7ab",
    }
  },
  txtStyle: {
    color: "#4ca7ab",

  },
  nameStyle: {
    color: '#262d33',
    fontWeight: 400,
    marginTop: '5px',
    paddingLeft: 20,
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
      paddingLeft: 0
    }
  },
  myMsgDisplay: {
    marginRight: '40px',
    [theme.breakpoints.down("xs")]: {
      marginRight: 10
    }
  },
  cardResponsive: {
    display: 'flex'
  },
  arrowStyle: {
    fontSize: '35px',
    color: '#262d33',
    marginRight: 10,
    border: 'none',
    backgroundColor: 'white',
  }

});

class ChatMessages extends Component {
  state = {
    privateChatRef: firebase.database().ref("privateChats"),
    chatsRef: firebase.database().ref("chats"),
    usersRef: firebase.database().ref("users"),
    messages: [],
    messagesLoading: false,
    user: this.props.currentUser,
    searchTerm: "",
    searchLoading: false,
    searchResults: [],
    currentChannel: this.props.currentChannel,
    messageReceipientName: "",
    messageReceipientId: "",
    theName: "",
    city: "",
    subjects: [],
    chats: [],
    hasMoreItems: true,
    chatSize: 0,
    start: 0,
    end: 20,
    displayedMessage: [],

  };

  componentDidMount() {
    const { currentChannel } = this.state;
    const { receiver } = this.props;

    this.addListeners(currentChannel);

    this.props.setCurrentChannel(currentChannel);

    receiver.then(async value => {
      if (!value.hasProfile) {
        return this.setState({
          theName: (value.firstName + value.lastName) || value.displayName,
          thePhoto: value.avatarUrl || value.photoURL || "/assets/user.png",
          receiverHasProfile: false,
        });
      } else {
        await firebase
          .firestore()
          .collection("profiles")
          .doc(value.id)
          .get()
          .then(snap => {
            let newSubjectList = snap.data().subjectList;

            return this.setState({
              theName: snap.data().displayName || snap.data().firstName,
              thePhoto: snap.data().tutorPhotoURL || "/assets/user.png",
              subjects: newSubjectList,
              receiverHasProfile: true,
            });
          })
          .catch(err => console.log(err));
      }
    });


  }

  componentWillUnmount() {
    this.props.setCurrentChannel({ id: "noChannel" });
  }

  componentDidUpdate() {
    if (this.messagesEnd) {
      this.scrollToBottom();
    }
  }

  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: 'smooth' });
  }

  addListeners = channelId => {
    this.addMessageListener(channelId);
  };

  addMessageListener = channelId => {
    let loaddedMessages = [];
    this.state.chatsRef
      .child(channelId)
      .child("messages")
      .on("child_added", snap => {
        // console.log(snap.key)
        loaddedMessages.push({ ...snap.val(), id: snap.key });
        this.setState({
          messages: loaddedMessages,
          messagesLoading: false,
          chatSize: loaddedMessages.length,
        });
      });

  };

  handleSearchChange = event => {
    this.setState(
      {
        searchTerm: event.target.value,
        searchLoading: true
      },
      () => this.handleSearchMessages()
    );
  };

  handleSearchMessages = () => {
    const channelMessages = [...this.state.messages];
    const regex = new RegExp(this.state.searchTerm, "gi");
    const searchResults = channelMessages.reduce((acc, message) => {
      if (message.content && message.content.match(regex)) {
        acc.push(message);
      }
      return acc;
    }, []);
    this.setState({ searchResults });
    setTimeout(() => this.setState({ searchLoading: false }), 500);
  };

  displayMessages = (messages) => {
    const { currentChannel } = this.props;
    let theArray = []
    let theSize = messages && messages.length

    for (let i = 0; i < messages.length; i++) {
      if (i !== 0) {
        if ((moment(messages[i].timestamp).toDate().getMinutes() === moment(messages[i - 1].timestamp).toDate().getMinutes()) && (messages[i].user.id === messages[i - 1].user.id)) {
          theArray.push(
            <div style={{ marginTop: '5px' }}>
              <ChatMessage
                currentChannel={currentChannel}
                key={messages[i].timestamp}
                message={messages[i]}
                user={this.state.user}
              />
            </div>
          )
        } else {
          theArray.splice(-1, 1, (
            <div style={{ marginTop: '2px', marginBottom: "10px" }}>
              <ChatMessage
                currentChannel={currentChannel}
                key={messages[i - 1].timestamp}
                message={messages[i - 1]}
                user={this.state.user}
                time={true}
              />
            </div>
          ))

          theArray.push(
            <ChatMessage
              currentChannel={currentChannel}
              key={messages[i].timestamp}
              message={messages[i]}
              user={this.state.user}
              time={false}
            />
          )
        }
      } else {
        theArray.push(
          <div>
            <ChatMessage
              currentChannel={currentChannel}
              key={messages[i].timestamp}
              message={messages[i]}
              user={this.state.user}
            />
          </div>
        )
      }
    }

    messages[theSize - 1] && theArray.splice(-1, 1, (
      <div style={{ marginTop: '2px', marginBottom: "10px" }}>
        <ChatMessage
          currentChannel={currentChannel}
          key={messages[theSize - 1].timestamp}
          message={messages[theSize - 1]}
          user={this.state.user}
          time={true}
        />
      </div>
    ))

    return theArray
  };

  render() {
    const {
      classes,
      userProfile,
      chatMessages,
      loading
    } = this.props;
    const {
      currentChannel,
      chatsRef,
      user,
      messages,
      searchTerm,
      searchResults,
      searchLoading,
      messagesLoading,
      receiverHasProfile,
    } = this.state;

    const ids = currentChannel.split('_');
    let sender;
    let receiver;

    if (ids[0] === user.auth.uid) {
      sender = ids[0];
      receiver = ids[1]
    } else {
      sender = ids[1];
      receiver = ids[0];
    }

    return (
      <React.Fragment >
        {/* <button className={classes.backToChat}
        >
          <Link to='/settings/chats' className={classes.txtStyle} >
            &#60; Go Back to List
          </Link>
        </button> */}
        <Card
          className={classes.messageStyle}
          clearing
        >
          <div
            className={classes.headerStyle} >
            <Grid container >

              <Grid item xs={3} md={1} className={classes.cardResponsive}>
                <Link to='/settings/chats' style={{ marginTop: 1 }}>
                  <button className={classes.arrowStyle}>
                    <i class="material-icons">
                      keyboard_arrow_left
                  </i>
                  </button>
                </Link>
                <Avatar src={this.state.thePhoto} />
              </Grid>
              <Grid item xs={7} md={8} className={classes.nameStyle} >
                {receiverHasProfile ? <Link style={{ color: '#585c60' }} to={'/tutor/' + receiver}>{this.state.theName}</Link> : <span style={{ color: '#585c60' }}>{this.state.theName}</span>}
              </Grid>
              <Grid item xs={4} className={classes.dsp} />

              <Grid item xs={0} md={3} className={classes.SearchSty}>
                <input
                  loading={searchLoading}
                  onChange={this.handleSearchChange}
                  name='searchTerm'
                  placeholder='Search Messages'
                  className={classes.searchMessages}
                />
              </Grid>
            </Grid>
          </div>
          {messagesLoading ? <LoadingComponent inverted /> : <Comment.Group
            className={classes.messages}
            style={{ maxWidth: "95%", maxHeight: '400px', marginLeft: 'auto' }}
          >
            {searchTerm ? (
              <div>{this.displayMessages(searchResults)}</div>
            ) : (
                <div className={classes.myMsgDisplay}>{this.displayMessages(messages)}</div>
              )}

            <div ref={node => (this.messagesEnd = node)}>

            </div>
          </Comment.Group>
          }
          <ChatMessagesForm
            currentChannel={currentChannel}
            chatsRef={chatsRef}
            currentUser={user}
            userProfile={userProfile}
            sender={sender}
            receiver={receiver}
          />
        </Card>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(
  connect(
    mapState,
    actions
  )(ChatMessages)
);
