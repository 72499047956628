import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { firestoreConnect, isEmpty } from 'react-redux-firebase';
import { compose } from 'redux'
import { toastr } from "react-redux-toastr";
import UserDetailedHeader from './UserDetailedHeader'
import UserDetailedDescription from './UserDetailedDescription'
import UserDetailedPhotos from './UserDetailedPhotos'
import UserDetailedSidebar from './UserDetailedSidebar'
import UserDetailedEvents from './UserDetailedEvents'
import { userDetailedQuery } from '../userQueries'
import LoadingComponent from '../../../app/layout/LoadingComponent'
import { getUserTutorProfile, followUser, unfollowUser } from '../userAction'
import { withStyles } from '@material-ui/core/styles'


const styles = theme => ({


}) 

const mapState = (state, ownProps) => {
  let userUid = null;
  let profile = {};

  if (ownProps.match.params.id === state.auth.uid) {
    profile = state.firebase.profile
  } else {
    profile = !isEmpty(state.firestore.ordered.profile) && state.firestore.ordered.profile[0];
    userUid = ownProps.match.params.id;
  }
  return {
    profile,
    userUid,
    tutorProfiles: state.tutors,
    tutorProfileLoading: state.async.loading,
    auth: state.firebase.auth,
    photos: state.firestore.ordered.photos,
    requesting: state.firestore.status.requesting,
    following: state.firestore.ordered.following
  }
}

const actions = {
  getUserTutorProfile,
  followUser,
  unfollowUser
}

class UserDetailedPage extends Component {

  async componentDidMount() {
    let user = await this.props.firestore.get(`users/${this.props.match.params.id}`);
    if(!user.exists) {
      toastr.error('Not Found', 'Sorry, we could not find the user you were looking for')
      this.props.history.push('/error');
    }
    let tutorProfiles = await this.props.getUserTutorProfile(this.props.userUid);
    //console.log("HELLOOOOOOO: ",tutorProfiles)
  }

  changeTab = (e, data) => {
    this.props.getUserTutorProfile(this.props.userUid, data.activeIndex)
  }

  render() {
    const {profile, photos, auth, match, requesting, tutorProfiles, tutorProfileLoading, followUser, following, unfollowUser, classes} = this.props;
    const isCurrentUser = auth.uid === match.params.id;
    const loading = requesting[`users/${match.params.id}`]
    const isFollowing = !isEmpty(following);
    //console.log(profile);
    
    if (loading) return <LoadingComponent inverted={true}/>
    return (
      <Grid>
        <UserDetailedHeader  profile={profile}/>
        <UserDetailedDescription profile={profile}/>
        <UserDetailedSidebar isFollowing={isFollowing} profile={profile} unfollowUser={unfollowUser} followUser={followUser} isCurrentUser={isCurrentUser}/>
        {photos && photos.length > 0 &&
        <UserDetailedPhotos photos={photos}/>}
        <UserDetailedEvents changeTab={this.changeTab} tutorProfiles={tutorProfiles} tutorProfileLoading={tutorProfileLoading}/>
      </Grid>
    );
  }
}

export default withStyles(styles)(compose(
  connect(mapState, actions),
  firestoreConnect((auth, userUid, match) => userDetailedQuery(auth, userUid, match)),
)(UserDetailedPage));
