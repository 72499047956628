import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

const styles = {
}

const ReviewList = ({ 
    classes,
    reviews,
}) => {
    return (
        <div>
            {reviews.map(review => (
                <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                        <Avatar alt="Remy Sharp" src={review.user.avatarUrl} />
                    </ListItemAvatar>
                    <ListItemText
                        primary={review.content}
                        secondary={
                            <React.Fragment>
                                <Typography component="span" className={classes.inline} color="textPrimary">
                                    {review.user.displayName}
                                </Typography>
                            </React.Fragment>
                        }
                    />
                </ListItem>
            ))}
        </div>
    )
}

export default withStyles(styles)(ReviewList);