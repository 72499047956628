export const courseOptions = [
  {
    id: 'grade9_midterm',
    price: 999,
    avatar: '/assets/Thumnail/Thumnail_G09.png',
    grade: 'Grade. 09',
    questionNumbers: '(100 Questions)',
    title: 'Complete Test Prep - Part A ',
    buyTitle: 'Gr9 Midterm Prep',
    courseName: 'Principles of Mathematics',
    level: '',
    preview: '/assets/Preview/Midterm_G.09_Sample-page-001.jpg',
    preview2: '/assets/Preview/Midterm_G.09_Sample-page-002.jpg',
    previewSolution: '/assets/Preview/Solution_TN_G09.png',  
    thumbnail: '/assets/Thumnail/Thumnail_G09.png',
    description: "Our Complete Test Prep - Part A covers the first half of the course. \ 100 handpicked problems encompass the chapters including Rational Numbers, Powers and Polynomial, Linear Relations and Linear Equations",
    curriculum: 'Say goodbye to US curriculum materials. Tossmath\'s prep material is based on Ontario curriculum and designed to help Ontario students to achieve academic success in mathmatics. The Grade 9 - Complete Test Prep - Part A covers the first half of the course (MPM1D) and is equivalent to Nelson textbook chapters 1-4',
    allLevels: 'From concept problems to competition level problems. With the Grade 9 - Complete Test Prep - Part A, students will be able to grasp the concept and understand how to apply it to challenging problems. Only solve the problems that are going to be on the test',
    pdfFile: '/docs/Gr9_Midterm.zip',
  },
  {
    id: 'grade10_midterm',
    price: 999,
    buyTitle: 'Gr10 Midterm Prep',
    avatar: '/assets/Thumnail/Thumnail_G10.png',
    grade: 'Grade. 10',
    questionNumbers: '(100 Questions)',
    title: 'Complete Test Prep - Part A ',
    courseName: 'Principles of Mathematics',
    level: '',
    preview: '/assets/Preview/Midterm_G.10_Sample-page-001.jpg',
    preview2: '/assets/Preview/Midterm_G.10_Sample-page-002.jpg',
    previewSolution: '/assets/Preview/Solution_TN_10.png',  
    thumbnail: '/assets/Thumnail/Thumnail_G10.png',
    description: "Our Complete Test Prep - Part A covers the first half of the course. \ 100 handpicked problems encompass the chapters including Systems of Linear Equations, Analytic Geometry, Graphs of Quadratic Relations and Factoring Algebraic Expressions",
    curriculum: 'Say goodbye to US curriculum materials. Tossmath\'s prep material is based on Ontario curriculum and designed to help Ontario students to achieve academic success in mathmatics. The Grade 9 - Complete Test Prep - Part A covers the first half of the course (MPM1D) and is equivalent to Nelson textbook chapters 1-4',
    allLevels: 'From concept problems to competition level problems. With the Grade 10 - Complete Test Prep - Part A, students will be able to grasp the concept and understand how to apply it to challenging problems. Only solve the problems that are going to be on the test',
    pdfFile: '/docs/Gr10_Midterm.zip',
  },
  {
    id: 'grade11_midterm',
    price: 999,
    buyTitle: 'Gr11 Midterm Prep',
    avatar: '/assets/Thumnail/Thumnail_G11.png',
    title: 'Complete Test Prep - Part A ',
    grade: 'Grade. 11',
    questionNumbers: '(100 Questions)',
    courseName: 'Functions',
    level: '',
    preview: '/assets/Preview/Midterm_G.11_Sample-page-001.jpg',
    preview2: '/assets/Preview/Midterm_G.11_Sample-page-002.jpg',
    previewSolution: '/assets/Preview/Solution_TN_G11.png',  
    thumbnail: '/assets/Thumnail/Thumnail_G11.png',
    description: "Our Complete Test Prep - Part A covers the first half of the course. \ 100 handpicked problems encompass the chapters including Introduction to Functions, Equivalent Algebraic Expressions, Quadratic Functions and Exponential Functions",
    curriculum: 'Say goodbye to US curriculum materials. Tossmath\'s prep material is based on Ontario curriculum and designed to help Ontario students to achieve academic success in mathmatics. The Grade 9 - Complete Test Prep - Part A covers the first half of the course (MPM1D) and is equivalent to Nelson textbook chapters 1-4',
    allLevels: 'From concept problems to competition level problems. With the Grade 11 - Complete Test Prep - Part A, students will be able to grasp the concept and understand how to apply it to challenging problems. Only solve the problems that are going to be on the test',
    pdfFile: '/docs/Gr11_Midterm.zip',
  },
  {
    id: 'gr12_functions_midterm',
    price: 999,
    buyTitle: 'Advanced Functions Midterm Prep',
    avatar: '/assets/Thumnail/Thumnail_AD.png',
    grade: 'Grade. 12',
    questionNumbers: '(50 Questions)',
    title: 'Complete Test Prep - Part A ',
    courseName: 'Advanced Functions',
    level: 'Advanced Functions',
    preview: '/assets/Preview/Midterm_G.12_Ad_Sample-page-001.jpg',
    preview2: '/assets/Preview/Midterm_G.12_Ad_Sample-page-002.jpg',
    previewSolution: '/assets/Preview/Solution_TN_G12_AD.png',  
    thumbnail: '/assets/Thumnail/Thumnail_AD.png',
    description: "Our Complete Test Prep - Part A covers the first half of the course. \ 100 handpicked problems encompass the chapters including Functions: Characteristics and Properties, Functions: Understanding Rates of Change, Polynomial Functions and Polynomial Equations and Inequalities",
    curriculum: 'Say goodbye to US curriculum materials. Tossmath\'s prep material is based on Ontario curriculum and designed to help Ontario students to achieve academic success in mathmatics. The Advanced Functions - Complete Test Prep - Part A covers the first half of the course (MHF4U) and is equivalent to Nelson textbook chapters 1-4',
    allLevels: 'From concept problems to competition level problems. With the Advanced Functions - Complete Test Prep - Part A, students will be able to grasp the concept and understand how to apply it to challenging problems. Only solve the problems that are going to be on the test',
    pdfFile: '/docs/AF_Midterm.zip',
  },
  {
    id: 'gr12_calculus_midterm',
    price: 999,
    buyTitle: 'Calculus Midterm Prep',
    avatar: '/assets/Thumnail/Thumnail_Calculus.png',
    grade: 'Grade. 12',
    questionNumbers: '(50 Questions)',
    title: 'Complete Test Prep - Part A ',
    courseName: 'Calculus and Vectors',
    level: 'Calculus',
    preview: '/assets/Preview/Midterm_G.12_Cal_Sample-page-001.jpg',
    preview2: '/assets/Preview/Midterm_G.12_Cal_Sample-page-002.jpg',
    previewSolution: '/assets/Preview/Solution_TN_G12_Cal.png',  
    thumbnail: '/assets/Thumnail/Thumnail_Calculus.png',
    description: "Our Complete Test Prep - Part A covers the first half of the course. \ 100 handpicked problems encompass the chapters including Introduction to Calculus, Derivatives, Derivatives and Applications and Curve Sketching",
    curriculum: 'Say goodbye to US curriculum materials. Tossmath\'s prep material is based on Ontario curriculum and designed to help Ontario students to achieve academic success in mathmatics. The Calculus and Vectors - Complete Test Prep - Part A covers the first half of the course (MCV4U) and is equivalent to Nelson textbook chapters 1-4',
    allLevels: 'From concept problems to competition level problems. With the Calculus and Vectors - Complete Test Prep - Part A, students will be able to grasp the concept and understand how to apply it to challenging problems. Only solve the problems that are going to be on the test',
    pdfFile: '/docs/Calc_Midterm.zip',
  },
]