import React from "react";
import { Grid, Header, Item, Segment, Label } from "semantic-ui-react";
import differenceInYears from "date-fns/difference_in_years";
import {objectToArray} from '../../../app/common/util/helpers'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({

haha:{
  marginTop: 1000
}
})

const UserDetailedHeader = ({ profile, classes}) => {
  let age;
  if (profile.dateOfBirth) {
    age = differenceInYears(Date.now(), profile.dateOfBirth.toDate());
  } else {
    age = "unknown age";
  }

  return (
    <Grid.Column width={16} className={classes.haha}>
      <Segment>
        <Item.Group>
          <Item>
            <Item.Image
              avatar
              size="small"
              src={profile.photoURL || "/assets/user.png"}
            />
            <Item.Content verticalAlign="bottom">
              <Header as="h1">{profile.displayName}</Header>
              <br />
              <Header as="h3">{profile.occupation}</Header>
              <br />
              <Header as="h3">
                {age}, Lives in {profile.city || "unknown city"}
              </Header>
            </Item.Content>
          </Item>
        </Item.Group>
      </Segment>
    </Grid.Column>
  );
};

export default withStyles(styles)(UserDetailedHeader);
