import React, { Component } from "react";
import {
  Form,
} from "semantic-ui-react";
import { objectToArray } from '../../../app/common/util/helpers'
import { reduxForm, Field } from "redux-form";
import TextInput from "../../../app/common/form/TextInput";
import Typography from "@material-ui/core/Typography";
import TextArea from "../../../app/common/form/TextArea";
import SelectInput from "../../../app/common/form/SelectInput";
import SelectInput1 from "../../../app/common/form/SelectInput1";
import MultipleSelectInput from "../../../app/common/form/MultipleSelectInput";
import DateInput from "../../../app/common/form/DateInput";
import PlaceInput from "../../../app/common/form/PlaceInput";
import { categories, levels, subjects } from "../../../app/common/categories";
import { educations as educationData } from '../../../app/common/educations'
import Select from "react-select";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import { withStyles } from '@material-ui/core/styles';
import CategoriesInput from './SubjectsDetails/CategoriesInput'
import InputBase from '@material-ui/core/InputBase';
import PlaceInput2 from "../../../app/common/form/PlaceInput.1.js";
import './TutorForm.css'
import Checkbox from '../../../app/common/form/Checkbox';
import _ from "lodash";
import TextField from '@material-ui/core/TextField'
import TutorPhotoUpload from "./TutorPhotoUpload";
import TutorProfilePhotoUpload from "./TutorProfilePhotoUpload";

const styles = theme => ({
  containerStyle: {
    maxWidth: '1180px',
    marginRight: 'auto',
    marginLeft: 'auto',
    color: '#585c60',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '95%'
    }
  },
  box: {
    marginTop: 20,
    marginBottom: 20,
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      marginTop: 80,

    }
  },
  box1: {
    marginTop: '40px',
    marginBottom: 10,
    [theme.breakpoints.down('xs')]: {
      padding: 0,

    }
  },
  registrationStyle: {
    marginTop: 160,
    fontSize: '3rem',
    maxWidth: '1180px',
    marginRight: 'auto',
    marginLeft: 'auto',
    fontWeight: 600,
    color: '#585c60',
    letterSpacing: '2px',
    [theme.breakpoints.down('xs')]: {
      width: '90%',
      fontSize: '2rem',
      marginLeft: 0
    }
  },
  paragraphStyle: {
    margin: 0,
    padding: 0,
    fontWeight: 600,
    fontSize: '2rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.5rem'
    }

  },
  basicinfo: {
    color: '#4ca7ab',
    marginTop: 60,
  },
  boxes: {
    marginBottom: '2%',

  },
  category: {
    marginBottom: 10
  },

  price: {
    maxWidth: 200,
    display: 'block',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: '2%',
    marginBottom: '2%',
  },


  frstbox: {
    marginTop: 80,
    overflow: 'visible',
    boxShadow: 'none',
    [theme.breakpoints.down('xs')]: {
      boxShadow: 'none',
      borderRadius: 0,
      padding: 0,
      marginRight: 'auto',
      marginLeft: 'auto',
      width: '95%'
    }

  },
  secondbox: {
    overflow: 'visible',
    boxShadow: 'none',
    [theme.breakpoints.down('xs')]: {
      boxShadow: 'none',
      borderRadius: 0,
      padding: 0,
      marginRight: 'auto',
      marginLeft: 'auto',
      width: '95%'
    }
  },
  languagebox: {
    marginBottom: '5%',
    padding: '2%',
    overflow: 'visible',
    boxShadow: 'none',
    [theme.breakpoints.down('xs')]: {
      boxShadow: 'none',
      borderTop: 'none',
      paddingRight: 30,
      paddingLeft: 30
    }
  },
  typo: {
    marginTop: 10,
  },
  locationBoxStyle: {
    border: '1px solid #e1e1e6',
    borderRadius: 5,
    padding: '5px 14px',
  },
  howToDesc: {
    marginLeft: '40px',
    paddingTop: 100,
    [theme.breakpoints.down('xs')]: {
      marginTop: 20,
      paddingTop: 0,
      marginLeft: 0
    }
  },
  sndbox: {
    borderTop: '3px solid #4ca7ab',

  },
  twoboxes: {
    maxWidth: 400,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '2%',
    marginBottom: '2%',

  },
  btn2: {
    margin: '5%',
  },
  removebtn: {
    fontWeight: 600,
    color: '#f68484',
    backgroundColor: '#fff',
    border: '1px solid #ff7474',
    padding: '6px 15px 6px 15px',
    borderRadius: '15px',
    cursor: 'pointer',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#ff7474',
    },
  },
  addbtn: {
    fontWeight: 600,
    color: 'white',
    backgroundColor: '#4ca7ab',
    border: 'none',
    padding: '6px 27px 6px 27px',
    marginRight: 10,
    cursor: 'pointer',
    borderRadius: '15px',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#0A4065',
    },

  },
  priceinput: {
    width: '100px',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  detailinfo: {
    color: '#585c60',
    margin: '10% 0 5% 0',
  },
  removeschoolbtn: {
    fontWeight: 600,
    color: '#f68484',
    backgroundColor: '#fff',
    border: '1px solid #ff7474',
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    cursor: 'pointer',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#ff7474',
    },
  },
  addschoolbtn: {
    fontWeight: 600,
    color: 'white',
    marginRight: 10,
    backgroundColor: '#4ca7ab',
    border: 'none',
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    cursor: 'pointer',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#0A4065',
    },
  },
  continuebtn: {
    color: '#fff',
    fontWeight: 600,
    padding: '10px 42px',
    fontSize: '1.1rem',
    letterSpacing: '1.7px',
    float: 'right',
    marginTop: '60px',
    backgroundColor: '#669fff',
    borderRadius: '5px',
    cursor: 'pointer',
    border: '1px solid #669fff',
    [theme.breakpoints.down('xs')]: {
        width: '100%',

    }
  },
  backbtn: {
    fontWeight: 600,
    padding: '10px 42px',
    fontSize: '1.1rem',
    letterSpacing: '1.7px',
    marginTop: '60px',
    borderRadius: '5px',
    cursor: 'pointer',
    float: 'left',
    marginTop: '60px',
    color: '#f68484',
    backgroundColor: '#fff',
    border: '1px solid #f68484',
    marginRight: 20,
    marginBottom: 120,
    '&:hover': {
      color: '#fff',
      backgroundColor: '#f68484',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: 20,
      marginBottom: 20,
    }


  },
  floatStyle: {
    float: 'right',
    [theme.breakpoints.down('xs')]: {
      marginTop: 40
    }
  },


  checkboxStyle: {
    display: 'inline',
    marginTop: 5,
    marginRight: 20,
    boxShadow: 'none',
  },
  checkmark: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '25px',
    width: '25px',
    color: 'white',
    backgroundColor: 'white',
  },
  tipStyle2: {
    width: '95%',
    marginRight: 'auto',
    marginLeft: 'auto',
    padding: 0,
    fontSize: '1.2rem',
    marginTop: 40
  },
  para: {
    width: '95%',
    marginRight: 'auto',
    marginLeft: 'auto'
  },
  bulbStyle: {
    width: '14px',
    marginLeft: 5,
    marginTop: -2,
    verticalAlign: 'middle',
  },
  gradeStyle: {
    marginTop: 7,
    marginLeft: -10,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 5
    }
  },
  inputStyle: {
    textAlign: 'right'
  }
})


const required = value => value ? undefined : 'Required'

class EducationDetails extends Component {

  constructor(props) {
    super(props);

    this.state = {
      scriptLoaded: false,
    }
  }

  continue = e => {
    e.preventDefault();
    const { subjects, educations, formattedAddress } = this.props
    let noWay = true;

    for (let i = 0; i < subjects.length; i++) {
      if (subjects[i].name === '' || subjects[i].category === '' || subjects[i].level === '' || subjects[i].price === '') {
        window.alert("Please Complete the Subject Information")
        noWay = false
      }
    }

    for (let i = 0; i < educations.length; i++) {
      if (educations[i].where === '' || educations[i].what === '' || educations[i].from === '' || educations[i].to === '') {
        window.alert("Please Complete the Education Detail, if not graduated, write an expected graduation year");
        noWay = false
      }
    }

    if (this.props.formattedAddress === '') {
      window.alert("Please Click or Enter the Address from the Selection Box");
    }

    if (noWay) {
      this.props.nextStep();
    }
  };

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };

  // getSubjectsByCategory = categoryId => {
  //   let result = [];
  //   subjects.map(subject => {
  //     if (subject.parentId === categoryId) {
  //       result.push(subject);
  //     }
  //   });
  //   return result;
  // };


  onKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); //<===== This stops the form from being submitted
      event.stopPropagation();
      alert("enter")
    } else {
      // alert("not enter")
    }
  }

  render() {
    const {
      handleChangePrice,
      handleChangeFrom,
      handleChangeTo,
      handleChangeWhat,
      handleChangeSelectExperience,
      handleSelectLanguages,
      langOptions,
      handleChangeDescription,
      handlePhotoUpload,
      handlePhotoChange,
      image,
      url,
      progress,
      experiences,
      educations,
      handleSelectAddExperience,
      handleSelectRemoveExperience,
      classes,
      disabled,
      nextStep,
      handleSelectLevels,
      levelList,
      onClick,
      //Photo upload related props
      uploadImage,
      onDrop,
      cancelCrop,
      cropImage,
      files,
    } = this.props;
    const { scriptLoaded } = this.state;

    return (
      <React.Fragment>
        <Grid container className={classes.containerStyle}>
          <Grid item md={1}></Grid>

          <Grid item xs={12} md={6} className={classes.box}>
            <Card className={classes.frstbox}>
              <Form autoComplete="off" >
                <p className={classes.registrationStyle}>Registration</p>
                <p
                  className={classes.paragraphStyle}> Step
                    <span style={{ color: '#4ca7ab' }}> 01. </span>
                  Fill Your Information
                     </p>


                <h1 className={classes.basicinfo}>Basic Info</h1>
                {/* <label className={classes.typo}>First Name</label> */}
                {/* <TutorPhotoUpload
                  handlePhotoUpload={handlePhotoUpload}
                  handlePhotoChange={handlePhotoChange}
                  image={image}
                  url={url}
                  progress={progress}
                /> */}
                <TutorProfilePhotoUpload
                  uploadImage={uploadImage}
                  onDrop={onDrop}
                  cancelCrop={cancelCrop}
                  cropImage={cropImage}
                  files={files}
                />
                <Field
                  label="first name"
                  name="firstName"
                  type="text"
                  style={{ margin: 0 }}
                  component={TextInput}
                  placeholder='First Name'
                />
                {/* <label className={classes.typo}>Last Name</label> */}

                <Field
                  name="lastName"
                  type="text"
                  component={TextInput}
                  placeholder='Last Name'
                />
                {/* <label className={classes.typo}>Phone Number</label> */}

                <Field
                  name="phone"
                  type="text"
                  component={TextInput}
                  placeholder='Phone Number'
                />

                {/* <label className={classes.typo}>Email</label> */}
                <Field
                  name="email"
                  type="text"
                  component={TextInput}
                  placeholder='E-mail'
                />
              </Form>
              <Typography className={classes.typo}><span style={{ color: 'red' }}>&#42;</span> Enter your address, only location will be diplayed on profile</Typography>
              <Field className={classes.locationBoxStyle}
                onKeyPress={this.onKeyPress}
                scriptLoaded={scriptLoaded}
                name="city"
                placeholder='Enter your address'
                component={PlaceInput2}
                autoComplete="on"
              />
            </Card>
          </Grid>
        </Grid>


        <Grid container className={classes.containerStyle}>
          <Grid item md={1}></Grid>

          <Grid item xs={12} md={6} className={classes.box}>

            <Card className={classes.frstbox}>
              <Form>
                <h1 className={classes.basicinfo} style={{ marginTop: '70px' }}>School</h1>
                <Field
                  name="school"
                  type="text"
                  component={TextInput}
                  placeholder='School'
                  style={{ marginBottom: 0 }}
                />
                <Grid container>
                  <Grid item xs={12} md={12}>
                    <Field
                      name='major'
                      type="text"
                      component={TextInput}
                      placeholder="Highest Degree Major"
                      style={{ marginBottom: 20 }}
                    />
                    {/* <InputBase
                      id="what"
                      type="text"
                      name="what"
                      InputLabelProps={{ shrink: true, }}
                      margin="normal"
                      component={TextField}
                      className={classes.locationStyle}
                      placeholder='Degree'
                    /> */}
                  </Grid>

                  <Grid item xs={5} md={3}>
                    <Field
                      name='yearStart'
                      type="number"
                      component={TextInput}
                      placeholder="Year Start"
                    />
                    {/* <InputBase
                      id="from"
                      type="number"
                      name="from"
                      max="4"
                      InputLabelProps={{ shrink: true, }}
                      margin="normal"
                      component={TextField}
                      className={classes.yearStart}
                      placeholder='Year Start'
                    /> */}
                  </Grid>
                  <Grid item xs={2} md={6} />
                  <Grid item xs={5} md={3} >
                    <Field
                      name='yearEnd'
                      type="number"
                      component={TextInput}
                      placeholder="Year End"
                    />
                  </Grid>
                </Grid>
              </Form>
            </Card>
          </Grid>
          {/* <Grid item xs={12} md={4} className={classes.howToDesc}>
            <p className={classes.tipStyle2}>
              Tip
                <img
                src="/assets/bulb.png"
                className={classes.bulbStyle}
              />
            </p>
            <p className={classes.para}>Make sure to submit proof of education at support@tossmath.ca . Otherwise, your profile will not be visible to students</p>
          </Grid> */}
        </Grid>

        <Grid container className={classes.containerStyle}>
          <Grid item md={1}></Grid>

          <Grid item xs={12} md={6} className={classes.box}>
            <Card className={classes.frstbox}>
              <Form>
                <h1 className={classes.basicinfo} style={{ marginTop: '70px' }}>Level</h1>
                <ul style={{ margin: 0, padding: 0 }}>
                  <Grid container >
                    <Grid item xs={1} md={1} style={{ marginTop: 3 }}>
                      <input
                        id="k8"
                        type="checkbox"
                        checked={levelList.k8}
                        onChange={handleSelectLevels}
                      />
                    </Grid>
                    <Grid
                      item xs={8} md={9}
                      className={classes.gradeStyle}
                    >
                      Grade K - 8
                      </Grid>
                    <Grid item xs={2} md={2}
                      style={{ verticalAlign: 'middle' }}
                    >
                      {levelList.includes("k8") ? <input
                        type={TextInput}
                        id="k8"
                        placeholder=" / hr"
                        // ref={input => input && input.focus()}
                        className={classes.inputStyle}
                        onChange={e => handleChangePrice(e)}
                        style={{ border: '1px solid black' }}

                      />
                        : <input
                          type={TextInput}
                          disabled
                          value="/hr"
                          placeholder=" / hr"
                          className={classes.inputStyle}
                          style={{ color: 'lightGray' }}
                        />
                      }

                    </Grid>
                  </Grid>

                  <Grid container >

                    <Grid item xs={1} md={1} style={{ marginTop: 3 }}>
                      <input
                        id='gr9'
                        type="checkbox"
                        checked={levelList.gr9}
                        onChange={handleSelectLevels}
                      />
                    </Grid>
                    <Grid
                      item xs={8} md={9}
                      className={classes.gradeStyle}
                    >
                      Grade 09 .
                      </Grid>
                    <Grid item xs={2} md={2}
                      style={{ verticalAlign: 'middle' }}
                    >
                      {levelList.includes("gr9") ? <input
                        type={TextInput}
                        id='gr9'
                        placeholder=" / hr"

                        className={classes.inputStyle}
                        onChange={e => handleChangePrice(e)}
                        style={{ border: '1px solid black' }}
                      />
                        : <input
                          type={TextInput}
                          disabled
                          value="/hr"
                          placeholder=" / hr"
                          className={classes.inputStyle}
                          style={{ color: 'lightGray' }}
                        />
                      }

                    </Grid>
                  </Grid>

                  <Grid container >
                    <Grid item xs={1} md={1} style={{ marginTop: 3 }}>
                      <input
                        id='gr10'
                        type="checkbox"
                        checked={levelList.gr10}
                        onChange={handleSelectLevels}
                      />
                    </Grid>
                    <Grid
                      item xs={8} md={9}
                      className={classes.gradeStyle}
                    >
                      Grade 10 .
                      </Grid>
                    <Grid item xs={2} md={2}
                      style={{ verticalAlign: 'middle' }}
                    >
                      {levelList.includes("gr10") ? <input
                        type={TextInput}
                        placeholder=" / hr"

                        className={classes.inputStyle}
                        onChange={e => handleChangePrice(e)}
                        style={{ border: '1px solid black' }}
                        id='gr10'
                      />
                        : <input
                          type={TextInput}
                          disabled
                          value="/hr"
                          placeholder=" / hr"
                          className={classes.inputStyle}
                          style={{ color: 'lightGray' }}
                        />
                      }

                    </Grid>
                  </Grid>

                  <Grid container >
                    <Grid item xs={1} md={1} style={{ marginTop: 3 }}>
                      <input
                        id='gr11'
                        type="checkbox"
                        checked={levelList.gr11}
                        onChange={handleSelectLevels}
                      />
                    </Grid>
                    <Grid
                      item xs={8} md={9}
                      className={classes.gradeStyle}
                    >
                      Grade 11 .
                      </Grid>
                    <Grid item xs={2} md={2}
                      style={{ verticalAlign: 'middle' }}
                    >
                      {levelList.includes("gr11") ? <input
                        type={TextInput}
                        placeholder=" / hr"

                        className={classes.inputStyle}
                        onChange={e => handleChangePrice(e)}
                        style={{ border: '1px solid black' }}
                        id='gr11'
                      />
                        : <input
                          type={TextInput}
                          disabled
                          value="/hr"
                          placeholder=" / hr"
                          className={classes.inputStyle}
                          style={{ color: 'lightGray' }}
                        />
                      }

                    </Grid>
                  </Grid>

                  <Grid container >
                    <Grid item xs={1} md={1} style={{ marginTop: 3 }}>
                      <input
                        id='af'
                        type="checkbox"
                        checked={levelList.af}
                        onChange={handleSelectLevels}
                      />
                    </Grid>
                    <Grid
                      item xs={8} md={9}
                      className={classes.gradeStyle}
                    >
                      Grade 12. AdvancedFunction
                      </Grid>
                    <Grid item xs={2} md={2}
                      style={{ verticalAlign: 'middle' }}
                    >
                      {levelList.includes("af") ? <input
                        type={TextInput}
                        placeholder=" / hr"
                        className={classes.inputStyle}
                        onChange={e => handleChangePrice(e)}
                        style={{ border: '1px solid black' }}
                        id='af'
                      />
                        : <input
                          type={TextInput}
                          disabled
                          value="/hr"
                          placeholder=" / hr"
                          className={classes.inputStyle}
                          style={{ color: 'lightGray' }}
                        />
                      }

                    </Grid>
                  </Grid>

                  <Grid container >
                    <Grid item xs={1} md={1} style={{ marginTop: 3 }}>
                      <input
                        id="cal"
                        type="checkbox"
                        checked={levelList.cal}
                        onChange={handleSelectLevels}
                      />
                    </Grid>
                    <Grid
                      item xs={8} md={9}
                      className={classes.gradeStyle}
                    >
                      Grade 12. Calculus
                      </Grid>
                    <Grid item xs={2} md={2}
                      style={{ verticalAlign: 'middle' }}
                    >
                      {levelList.includes("cal") ? <input
                        type={TextInput}
                        placeholder=" / hr"

                        className={classes.inputStyle}
                        onChange={e => handleChangePrice(e)}
                        style={{ border: '1px solid black' }}
                        id='cal'
                      />
                        : <input
                          type={TextInput}
                          disabled
                          value="/hr"
                          placeholder=" / hr"
                          className={classes.inputStyle}
                          style={{ color: 'lightGray' }}
                        />
                      }

                    </Grid>
                  </Grid>

                  <Grid container >
                    <Grid item xs={1} md={1} style={{ marginTop: 3 }}>
                      <input
                        id='dm'
                        type="checkbox"
                        checked={levelList.dm}
                        onChange={handleSelectLevels}
                      />
                    </Grid>
                    <Grid
                      item xs={8} md={9}
                      className={classes.gradeStyle}
                    >
                      Grade 12. Datamanagement
                      </Grid>
                    <Grid item xs={2} md={2}
                      style={{ verticalAlign: 'middle' }}
                    >
                      {levelList.includes("dm") ? <input
                        type={TextInput}
                        placeholder=" / hr"

                        className={classes.inputStyle}
                        onChange={e => handleChangePrice(e)}
                        style={{ border: '1px solid black' }}
                        id='dm'
                      />
                        : <input
                          type={TextInput}
                          disabled
                          value="/hr"
                          placeholder=" / hr"
                          className={classes.inputStyle}
                          style={{ color: 'lightGray' }}
                        />
                      }

                    </Grid>
                  </Grid>

                </ul>
                <h1 className={classes.basicinfo}>Languages</h1>
                <Select className={classes.category}
                  isMulti
                  name="langs"
                  options={langOptions}
                  defaultValue={{
                    value: "English",
                    label: "English",
                    nativeName: "English",
                    isFixed: true,
                    backgroundColor: "black"
                  }}
                  onChange={(data, e) => handleSelectLanguages(data, e)}
                />


              </Form>
            </Card >
          </Grid >
        </Grid >
        <Grid container className={classes.containerStyle}>
          <Grid item md={1}></Grid>

          <Grid item xs={12} md={6} className={classes.box1}>
            <Form>
              <Card className={classes.secondbox}>
                <h1 className={classes.basicinfo} style={{ marginTop: '10px' }}>Experience</h1>
                {experiences.map((experience) => {
                  return (

                    <React.Fragment key={experience.id}>
                      <InputBase className={classes.boxes}
                        onChange={(data) => handleChangeSelectExperience(data, experience.id)}
                        id="where"
                        type="text"
                        name="experience"
                        InputLabelProps={{ shrink: true, }}
                        placeholder='Company/Organization'
                        margin="normal"
                        component={TextField}
                        fullWidth
                      />
                      <InputBase style={{ color: 'red' }} className={classes.boxes}
                        onChange={(e) => handleChangeWhat(e, experience.id)}
                        placeholder="Position/Activity"
                        id="what"
                        type="text"
                        name="what"
                        InputLabelProps={{ shrink: true, }}
                        margin="normal"
                        component={TextField}
                        fullWidth

                      />
                      <Grid container spacing={24}>
                        <Grid item xs={5} md={3}>
                          <InputBase className={classes.boxes}
                            style={{ marginRight: 10 }}
                            onChange={(e) => handleChangeFrom(e, experience.id)}
                            id="from"
                            type="number"
                            name="from"
                            max="4"
                            InputLabelProps={{ shrink: true, }}
                            margin="dense"
                            component={TextField}
                            placeholder='Year Start'
                          />
                        </Grid>
                        <Grid item xs={2} md={6} />
                        <Grid item xs={5} md={3}>
                          <InputBase className={classes.boxes}
                            onChange={(e) => handleChangeTo(e, experience.id)}
                            id="to"
                            type="number"
                            name="to"
                            InputLabelProps={{ shrink: true, }}
                            margin="dense"
                            component={TextField}
                            placeholder='Year End'
                          />
                        </Grid>
                      </Grid>


                      <div style={{ marginBottom: 20, marginTop: 15, float: 'right' }}>
                        <button className={classes.addschoolbtn}
                          onClick={handleSelectAddExperience}
                        >&#43;</button>
                        <button className={classes.removeschoolbtn}
                          onClick={() => handleSelectRemoveExperience(experience.id)}
                        >&#06;</button>

                      </div>

                      {/* <Button label="Remove School" type="button" onClick={() => handleSelectRemoveEducation(education.id)} /> */}
                    </React.Fragment>
                  );
                })}
                {/* <Field
                  name="experience"
                  type="text"
                  component={TextField}
                  rows={1}
                  placeholder="Company/organization"
                />
                <Field
                  name="experience"
                  type="text"
                  component={TextField}
                  rows={1}
                />
                <Grid container>
                  <Grid item xs={6} md={2}>
                    <InputBase
                      id="from"
                      type="number"
                      name="from"
                      max="4"
                      InputLabelProps={{ shrink: true, }}
                      margin="normal"
                      component={TextField}
                    />
                  </Grid>
                  <Grid item xs={6} md={2} >
                    <InputBase
                      id="to"
                      type="number"
                      name="to"
                      InputLabelProps={{ shrink: true, }}
                      margin="normal"
                      component={TextField}
                    />
                  </Grid>
                </Grid> */}
              </Card>
            </Form>
          </Grid>


        </Grid>

        <Grid container className={classes.containerStyle}>
          <Grid item md={1}></Grid>

          <Grid item xs={12} md={6} className={classes.box1}>
            <Form>
              <Card className={classes.secondbox}>
                <h1 className={classes.basicinfo} style={{ marginTop: '30px' }}>Description</h1>
                <Field
                  name="description"
                  type="text"
                  component={TextArea}
                  rows={5}
                  onChange={handleChangeDescription}
                  onChange={(data, e) => handleChangeDescription(data, e)}
                />
                {/* <button className={classes.backbtn} label="Back" onClick={this.back}>BACK</button> */}
                <button
                  className={classes.continuebtn}
                  onClick={onClick}
                  type='button'
                >
                  NEXT
                </button>
              </Card>
            </Form >
          </Grid >


        </Grid >
      </React.Fragment >

    );
  }
}

export default withStyles(styles)(EducationDetails);
