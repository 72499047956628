import React, { Component } from "react";
import { Form, Header, Divider } from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import { NavLink, Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MyCoursesList from "./MyCoursesList";
import Slider from 'react-slick'
import styled from 'styled-components'

const styles = theme => ({
    wrapper: {
        dispaly: 'flex',
        maxWidth: '1180px',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 30,
        [theme.breakpoints.down('xs')]: {
            padding: 0,
            maxWidth: '85%',
        }
    },
    basicbox: {
        marginTop: 150,
        width: "95%",
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: 100,
        boxShadow: 'none',
        color: '#585c60',
        overflow: 'visible',
        [theme.breakpoints.down("xs")]: {
            paddingTop: 10,
            width: "100%",
            marginTop: 100
        },
    },
    basic: {
        color: '#585c60',
        fontSize: '1.3rem',
        fontWeight: 600,
    },
    headerbg: {
        margin: '5% 5% 3% 5%',


    },
    displayname: {
        marginTop: '2%',


    },
    bttn: {
        backgroundColor: '#4ca7ab',
        marginBottom: 20,
    },
    whitebar: {
        width: 50,
        backgroundColor: 'white',
    },
    paraStyle: {
        marginTop: 5,
        marginBottom: 5
    },
    btnStyle: {
        backgroundColor: '#4ca7ab',
        borderRadius: 5,
        color: '#fff',
        fontWeight: 600,
        padding: '7px 42px',
        marginTop: 10,
        fontSize: '1.1rem',
        letterSpacing: '1.7px',
        border: 'none',
        cursor: 'pointer',
        '&:hover': {
            color: '#fff',
            backgroundColor: '#4ca7ab',
          },
    }

})


// const courseOptions = [
//     {
//         id: 1,
//         avatar: './assets/Thumb_09.png',
//         grade: 'Grade. 09 ',
//         level: '',
//     },
//     {
//         id: 2,
//         avatar: '/assets/Thumb_10.png',
//         grade: 'Grade. 10 ',
//         level: '',

//     },
//     {
//         id: 3,
//         avatar: '/assets/Thumb_11.png',
//         grade: 'Grade. 11 ',
//         level: '',

//     },
//     {
//         id: 4,
//         avatar: '/assets/Thumb_11.png',
//         grade: 'Grade. 12 ',
//         level: 'Advanced Function',
//     },
// ]

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style }}
            onClick={onClick}
        />
    );
}

const Page = styled.div`
width: 100%;
`

class MyCourses extends Component {

    render() {
        const settings = {
            dots: false,
            infinite: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            speed: 1000,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SampleNextArrow />,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: false,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        const { classes, currentUser } = this.props;

        const mycourses = currentUser.myCourses;

        // console.log('@@@@@2', currentUser.studentPass)
        return (
            <div>

                <Grid container >
                    <Grid item xs={12} md={12}>
                        <Card className={classes.basicbox}>
                            <div >
                                <Link style={{ float: 'left', marginLeft: 20, marginRight: 20 }} as={NavLink} to='/settings' >
                                    <Typography >Basics</Typography>
                                </Link>
                                <span style={{ float: 'left', marginRight: 20 }}> > </span>
                                <b className={classes.basic}>Items </b>
                            </div>
                            <div >
                                <div className={classes.headerbg}>
                                    <Header style={{ color: '#585c60', marginBottom: 30 }} size="large" content="Items" />
                                    <Typography style={{ color: '#585c60', marginBottom: 30, fontSize: '1.3rem', fontWeight: 600 }}>
                                        Purchased Prep List
                            </Typography>

                                    {/* List of the courses */}
                                    <div className={classes.wrapper}>
                                        <Slider
                                            {...settings}
                                        >
                                            {mycourses && mycourses.map(myCourse => (
                                                <Page
                                                >
                                                    <MyCoursesList
                                                        myCourse={myCourse}
                                                    // levelBlock={levelBlock}
                                                    />
                                                </Page>
                                            ))}
                                        </Slider>
                                    </div>

                                </div>

                            </div>
                            <div className={classes.headerbg}>
                                <Divider style={{ marginBottom: 30 }} />
                                <Typography className={classes.basic}>
                                    Student Pass
                        </Typography>
                                {/* <p className={classes.paraStyle}>
                                    Showing the duration of Student Pass or the date when students buy this pass
                        </p>
                                <p className={classes.paraStyle}>
                                    Describe conditions of cancellation of Student pass.
                        </p> */}
                                {currentUser.studentPass ?
                                    ' '
                                    :
                                    <div>
                                        <p>
                                            With Student Pass, you can contact as many tutors as possible.
                                        </p>
                                        <Button
                                            className={classes.btnStyle}>
                                            <Link style={{color:'white'}} to='/studentpass'>
                                             Go to Student Pass
                                            </Link>
                                        </Button>
                                    </div>
                                }
                            </div>
                        </Card>
                    </Grid>
                </Grid>
            </div >

        );
    }
};

export default withStyles(styles)(MyCourses);