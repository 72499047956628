import firebase from '../../app/config/firebase'
import {
    asyncActionStart,
    asyncActionFinish,
    asyncActionError
} from "../async/asyncActions";

import { FETCH_USERS } from './adminConstants'

export const getUsersForAdminPage = (lastUser) =>
    async (dispatch, getState) => {
        let today = new Date(Date.now());
        const firestore = firebase.firestore();
        const usersRef = firestore.collection('users')

        try {
            dispatch(asyncActionStart());
            let startAfter = lastUser && await firestore.collection('users').doc(lastUser.id).get();
            let query;

            lastUser ? query = usersRef.startAfter(startAfter).orderBy('hasProfile')
                : query = usersRef.orderBy('createdAt','desc')
            //let querySnap = await usersRef.get()
            let querySnap = await query.get();

            if(querySnap.docs.length === 0 ){
                dispatch(asyncActionFinish());
                return;
            }

            let users = [];

            for (let i = 0; i < querySnap.docs.length; i++) {
                let user = { ...querySnap.docs[i].data(), id: querySnap.docs[i].id };
                users.push(user);
            }
            // console.log(users)
            dispatch({ type: FETCH_USERS, payload: { users } });
            dispatch(asyncActionFinish());
            return querySnap;

        } catch (error) {
            // console.log(error)
            dispatch(asyncActionError())
        }
    }