import React from 'react'
import { withStyles } from '@material-ui/core/styles'

const style = theme => ({
    main:{
        width: '100vw',
        height: 'auto',        
    }
})

const MaintenancePage = ({classes}) => {
  return (
    <div>
      <img className={classes.main} src="https://firebasestorage.googleapis.com/v0/b/crown-tutor-fb.appspot.com/o/users%2Fassets%2FM_page.png?alt=media&token=e621c036-0865-4fae-9168-ceed7f2142ec" alt="maintenance" ></img>
    </div>
  )
}



export default withStyles(style)(MaintenancePage)
