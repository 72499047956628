import React, { Component } from 'react'
import firebase from '../../app/config/firebase'
import CardForm from './CardForm'
import { Elements, StripeProvider, CardElement } from 'react-stripe-elements';
import StripeCheckout from 'react-stripe-checkout';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import cuid from 'cuid'
import { openModal } from "../modals/modalActions";

// import {redirectToCheckout} from 'st'

import { Segment, Item, Icon, List, Label, Button } from "semantic-ui-react";

const actions = {
  openModal,

};

const mapState = state => ({

});



{/* <script>
    var stripe = Stripe('pk_test_CHCATAQOxCqDF393Ta82UpUX00zUUeSZQW');
  
    var checkoutButton = document.getElementById('checkout-button-sku_FXSOL48puHLcxV');
    checkoutButton.addEventListener('click', function () {
      // When the customer clicks on the button, redirect
      // them to Checkout.
      stripe.redirectToCheckout({
        items: [{sku: 'sku_FXSOL48puHLcxV', quantity: 1}],
  
        // Do not rely on the redirect to the successUrl for fulfilling
        // purchases, customers may not always reach the success_url after
        // a successful payment.
        // Instead use one of the strategies described in
        // https://stripe.com/docs/payments/checkout/fulfillment
        successUrl: 'https://your-website.com/success',
        cancelUrl: 'https://your-website.com/canceled',
      })
      .then(function (result) {
        if (result.error) {
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer.
          var displayError = document.getElementById('error-message');
          displayError.textContent = result.error.message;
        }
      });
    });
  </script> */}





class TestPrepPage extends Component {
  handleStripePayment = () => {
    this.props.openModal("StripeModal");
  };

  onToken = (token) => {
    // console.log(token)
    fetch('/save-stripe-token', {
      method: 'POST',
      body: JSON.stringify(token),
    }).then(response => {
      response.json().then(data => {
        alert(`We are in business, ${data.email}`);
        // console.log(data);
      });
    });
  }

  callingProfileData = async () => {

    firebase.firestore().collection('profiles').doc('5tfXYzcWB6bsW7u8eaa6p15G3Oj1').set({
      badges: {
        education: false,
        professional: false,
        basic: false,
      },
      cancelled: false,
      educations: [
        {
          from: '2018',
          to: '2022',
          where: 'University of Toronto',
          what: 'Computer Engineering'
        }
      ],
      city: 'Toronto',
      created: Date.now(),
      contactVisible: true,
      description: 'Hello! [n1]I am currently enrolled in the Computer Engineering program at the University of Toronto (St. George campus). [n1]I have experiences in tutoring students in math related subjects.[n1]Passionate in helping others to improve in their academic skills. [n1]Fluent in both English and Korean.',
      experiences:[
        {
          id: 1,
          from: '2017',
          to: '2018',
          what: 'Private Tutor',
          where: ''
        }
      ],
      languages:{
        English: true,
        Korean: true,
      },
      firstName:"Mingyu (Roy)",
      lastName: "Son",
      tutorUid: "5tfXYzcWB6bsW7u8eaa6p15G3Oj1",
      formattedAddress: '4 Ferris St, Richmond Hill, ON L4B 4K6, Canada',
      email: 'mack_08@naver.com',
      levelList:[
        'k8','gr9','gr10','gr11','af','cal'
      ],
      levels:[
        {
          id:'k8',
          levelLabel: 'K-8',
          price:20
        },
        {
          id:'gr9',
          levelLabel: 'Grade 9',
          price:20
        },
        {
          id:'gr10',
          levelLabel: 'Grade 10',
          price:25
        },
        {
          id:'gr11',
          levelLabel: 'Grade 11',
          price:25
        },
        {
          id:'af',
          levelLabel: 'Grade 12 - Advanced Functions',
          price:30
        },
        {
          id:'cal',
          levelLabel: 'Grade 12 - Calculus',
          price:30
        },
      ],
      location:'',
      phone: '6478894048',
      photoUpdated: true,
      rank:7,
      rating:0,
      tutorDisplayName:'Mingyu(Roy) Son',
      tutorPhotoURL: 'https://firebasestorage.googleapis.com/v0/b/crown-tutor-fb.appspot.com/o/5tfXYzcWB6bsW7u8eaa6p15G3Oj1%2Fuser_images%2Fcjwqtu1j800003a5w77irkq1p?alt=media&token=58c2d548-7327-4254-bfc9-9f3049f9b8c7',
      updated: Date.now(),
    })
  }

  addData = () => {
    firebase.database()
    .ref('/users/'+ '3N7ClcLcUsegmIiFnLi6dF7XmjI2')
    .child('paymentResult')
    .update({
      error: {id: `${cuid()}`,
        error:'Card Declined'}
    })
  }


  open = () => {
    this.props. openModal('PaymentResponseModal');

  }
  render() {
   

    return (
      <div style={{ marginTop: '200px' }}>

        <StripeCheckout
          name="Complete Midterm Prep - Gr9"
          description="Hello Test Pack"
          token={this.onToken}
          stripeKey="pk_test_CHCATAQOxCqDF393Ta82UpUX00zUUeSZQW"
          amount={999} // cents
          currency="CAD"
        />

        <StripeProvider apiKey={process.env.REACT_APP_STRIPE_TESTKEY}>
          <div style={{ width: '500px', backgroundColor: 'white' }} className="example">
            <h1>React Stripe Elements Example</h1>
            <Elements>
              <CardForm />
            </Elements>
          </div>
        </StripeProvider>

        <button onClick={this.callingProfileData}>
          Call tutors
        </button>


        <div id="error-message"></div>
      </div>
    )
  }
}

export default connect(mapState, actions)(TestPrepPage)