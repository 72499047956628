import React from "react";
import firebase from '../../../app/config/firebase'
import { connect } from 'react-redux'
import { Link, NavLink } from "react-router-dom";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Avatar from "@material-ui/core/Avatar";
import MenuList from "@material-ui/core/MenuList";
import Typography from "@material-ui/core/Typography";
import NotificationsNone from "@material-ui/icons/NotificationsNone";
import ChatBubbleOutline from "@material-ui/icons/ChatBubbleOutline";
import ChatBubble from "@material-ui/icons/ChatBubble";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { Image, Icon } from "semantic-ui-react";
import { setNotifications } from '../../user/Settings/Chat/chatActions'


const styles = theme => ({
  haha: {
    backgroundColor: "none",
    "&:hover": {
      backgroundColor: "none"
    }
  },
  becomeatutor: {
    margin: "5px",
    backgroundColor: "#4ca7ab",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#0A4065",
      color: "#ffffff"
    }
  },
  btnStyle: {
    "&:focus": {
      backgroundColor: "white"
    },
    "&:hover": {
      color: "white",
      backgroundColor: "#4ca7ab"
    }
  },
  imgStyle: {
    display: "flex",
    cursor: "pointer",
    width: 200,
    height: "auto",
    marginLeft: 20,
    marginTop: -3,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0
    }
  },
  btnforTutor: {
    display: "flex",

    "&:focus": {
      backgroundColor: "white"
    },
    "&:hover": {
      color: "white",
      backgroundColor: "#4ca7ab"
    },
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  nameStyle: {
    color: "#585c60",
    fontWeight: 900,
    display: "inlineBlock",
    marginLeft: "5px",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  iconStyle1: {
    color: "#4ca7ab",
    width: 25,
    top: "7px",
    cursor: 'pointer',
  },
  iconStyle2: {
    color: "#fdfdfd",
    opacity: 0.8,
    top: "7px",
    cursor: 'pointer',
    width: 25,
    marginTop: '-4px',
    [theme.breakpoints.down("sm")]: {
      float: 'right',
    },

  },
  buttonHover: {
    "&:hover": {
      backgroundColor: '#fff'
    }
  },
  notificationCountStyle: {
    border: '1px solid tomato',
    backgroundColor: 'tomato',
    borderRadius: 50,
    color: 'white',
    marginLeft: -10,
    marginTop: -20,
    paddingRight: 5,
    paddingLeft: 5,
    fontSize: '0.6rem'
  }
});

const handleNotifications = (auth, notifications, setNotification) => {
  setNotification([]);
  notifications && notifications.map(notification => {
    // console.log(notification.channel)
    // console.log(auth.uid)
    firebase.database().ref('users')
      .child(auth.uid)
      .child('chatNotifications')
      .child(notification.channel)
      .once('value')
      .then(snap => {
        if (snap.val() !== null) {
          firebase.database().ref('users')
            .child(auth.uid)
            .child('chatNotifications')
            .child(notification.channel)
            .remove()
        }

      }).catch(err => console.log(err));
  })
}

const SignedInMenu = ({
  signOut,
  profile,
  auth,
  onOpen,
  anchorEl,
  classes,
  handleClose,
  menuOpen,
  notificationCount,
  notifications,
  setNotifications,
}) => {
  return (
    <div>

      {notificationCount > 0 ?
        <Button onClick={() => handleNotifications(auth, notifications, setNotifications)} className={classes.buttonHover} component={NavLink} to='/settings/chats'>
          <img src="/assets/Message_fill.png" className={classes.iconStyle1} name='comment outline' />
          <span className={classes.notificationCountStyle}>{notificationCount}</span>
        </Button> :
        <Button onClick={() => handleNotifications(auth, notifications, setNotifications)} className={classes.buttonHover} component={NavLink} to='/settings/chats'>
          <img src="/assets/Message_non_2.png" className={classes.iconStyle2} name='comment outline' />

        </Button>
      }
      <span className={classes.nameStyle}>
        Hi, {profile.firstName || profile.displayName} !
      </span>
      <Image
        aria-owns={anchorEl ? "simple-menu" : undefined}
        aria-haspopup='true'
        onClick={onOpen}
        avatar
        className={classes.imgStyle}
        src={profile.photoURL || "/assets/user.png"}
      />
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleClose}
      >
        {!profile.hasProfile &&
          <MenuItem
            onClick={handleClose}
            className={classes.btnforTutor}
            component={Link}
            to='/createtutor'
          >
            Become a Tutor
          </MenuItem>
        }

        {profile.hasProfile && (
          <MenuItem
            onClick={handleClose}
            className={classes.btnStyle}
            component={Link}
            to={`/tutor/${auth.uid}`}
            icon='calendar'
          >
            My Tutor Profile
          </MenuItem>
        )}

        {/* <MenuItem onClick={handleClose} className={classes.btnStyle} component={Link} to="/people" icon="users" >My Network</MenuItem>
        <MenuItem onClick={handleClose} className={classes.btnStyle} component={Link} to={`/profile/${auth.uid}`} icon="user">My Account</MenuItem> */}
        {/* <MenuItem
          className={classes.btnStyle}
          component={Link}
          to='/settings/chats'
          icon=''
        >
          Message
        </MenuItem> */}
        <MenuItem
          className={classes.btnStyle}
          component={Link}
          to='/settings'
          icon='settings'
        >
          Settings
        </MenuItem>
        {/* <MenuItem
          exact
          component={NavLink}
          to='/students'
          name='Student'
        >
          student
        </MenuItem> */}
        <MenuItem
          onClick={handleClose}
          className={classes.btnStyle}
          onClick={signOut}
          icon='power'
        >
          Sign Out
        </MenuItem>
      </Menu>
    </div>
  );
};

export default withStyles(styles)(SignedInMenu);
