import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

//Material UI
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

const styles = theme => ({
    registerStyle: {
        marginTop: 160,
        fontSize: '2.2rem',
        maxWidth: '1180px',
        marginRight: 'auto',
        marginLeft: 'auto',
        fontWeight: 900,
        color: '#585c60',
        letterSpacing: '2px',
        [theme.breakpoints.down('xs')]: {
            width: '90%',
            fontSize: '1.5rem',
            marginTop: 120
        }
    },
    registrationStyle:{
        marginTop: 160,
        fontSize: '3rem',
        maxWidth: '1180px',
        marginRight: 'auto',
        marginLeft: 'auto',
        fontWeight: 600,
        color: '#585c60',
        letterSpacing: '2px',
        [theme.breakpoints.down('xs')]: {
            width: '90%',
            fontSize: '2rem',
            marginLeft: 0
        }
    },
    paragraphStyle: {
        margin: 0,
        padding: 0,
        fontWeight: 600,
        fontSize: '2rem',
    },
    paragraph: {
        fontSize: '1rem',
        fontWeight: 300,
        fontFamily: 'proxima-nova, sans-serif ',
    },
    headerStyle: {
        marginTop: 10,
        marginBottom: 10,
        padding: 0,
        fontWeight: 'bold',
        fontSize: '1.5rem',
        fontFamily: 'proxima-nova, sans-serif ',
    },

    imgStyle: {
        width: '80%',
        textAlign: 'center',
        marginTop: 60,
        marginLeft: 80,
        marginBottom: 40
    },
    tipStyle: {
        margin: 0,
        padding: 0,
        fontSize: '1rem'
    },
    tipPara: {
        margin: 0,
        padding: 0,
        fontSize: '1rem'

    },
    continuebtn: {
        color: '#fff',
        fontWeight: 600,
        padding: '10px 42px',
        fontSize: '1.1rem',
        letterSpacing: '1.7px',
        float: 'right',
        marginTop: '60px',
        backgroundColor: '#669fff',
        borderRadius: '5px',
        cursor: 'pointer',
        border: '1px solid #669fff',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
    
        }
    },
    backbtn: {
        fontWeight: 600,
        padding: '10px 42px',
        fontSize: '1.1rem',
        letterSpacing: '1.7px',
        marginTop: '60px',
        borderRadius: '5px',
        cursor: 'pointer',
        float: 'left',
        marginTop: '60px',
        color: '#f68484',
        backgroundColor: '#fff',
        border: '1px solid #f68484',
        marginRight: 20,
        marginBottom: 120,
        '&:hover': {
            color: '#fff',
            backgroundColor: '#f68484',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginTop: 20,
            marginBottom: 20
        }
    },
    bulbStyle: {
        width: '14px',
        marginLeft: 5,
        marginTop: -2,
        verticalAlign: 'middle',
    },
    tipStyle2: {
        padding: 0,
        fontSize: '1.2rem',
        marginTop: 40
    },
    howToDesc: {
        marginLeft: '40px',
        paddingTop: 30,
        [theme.breakpoints.down('xs')]: {
            marginTop: 20,
            paddingTop: 0,
            marginLeft: 0
        }
    },
})



class SubmitSolution extends Component {
    continue = e => {
        e.preventDefault();
        this.props.nextStep();
    };

    back = e => {
        e.preventDefault();
        this.props.prevStep();
    };

    render() {
        const {
            values,
            handleChange,
            onSubmit,
            classes,
            prevStep,
            disabled,
            goToMyProfile
        } = this.props;
        return (
            <div className={classes.registerStyle}>
                <Grid container>
                    <Grid item md={1}></Grid>
                    <Grid item xs={12} md={6}>
                        <p className={classes.registrationStyle}>Registration</p>
                        <p
                            className={classes.paragraphStyle}> Step
                        <span style={{ color: '#4ca7ab' }}> 03. </span>
                            Submit Solution
                        </p>

                        <br />
                        <p className={classes.headerStyle}>Almost Done!</p>
                        <p className={classes.headerStyle}>Time to solve <span style={{ color: '#4ca7ab' }}> Math Problems! </span></p>
                        <br />
                        <p className={classes.paragraph}>
                            Based on the highest level you decided to teach, we will send you an email with math problems once you click the span <span style={{ color: '#4ca7ab' }}> SUBMIT </span> button. You have to solve the problems and submit the solutions at <span style={{ color: '#4ca7ab' }}> support@tossmath.ca </span> within 24 hours. You can either scan it or take pictues of it.
                    </p>
                        <p>
                            <img
                                className={classes.imgStyle}
                                src="./assets/step3img.png"
                            />
                        </p>
                        <p>
                            {/* <button className={classes.backbtn} label="Back" onClick={this.back}>BACK</button> */}
                            <button
                                className={classes.continuebtn}
                                onClick={goToMyProfile}
                            >
                                Go to My Profile
                            </button>
                        </p>

                    </Grid>
                    <Grid item xs={12} md={4} className={classes.howToDesc}>
                        <p className={classes.tipStyle2}>
                            Tip
                            <img
                                src="/assets/bulb.png"
                                className={classes.bulbStyle}
                            />
                        </p>
                        <p className={classes.paragraph}>Make sure to show the steps carefully. Having answers only could disqualify you from becoming a tutor</p>
                    </Grid>
                </Grid>

            </div>


        );
    }
}



export default withStyles(styles)(SubmitSolution)
