import React, { Component, Fragment } from 'react'
import moduleName from 'module'
import StudentListItem from './StudentListItem';
import { connect } from "react-redux";


const mapState = (state) => ({
    students: state.students
  })

class StudentList extends Component {
    render() {
        const { students, deleteEvent } = this.props;
        return (
            <Fragment>
                {students && students.map(student => (
                    <StudentListItem
                        key={student.id}
                        student={student}
                        deleteEvent={deleteEvent}
                    />

                ))}

            </Fragment>
        )
    }
}

export default connect(mapState)(StudentList);