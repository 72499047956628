import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { compose } from "redux";
import { withFirestore, firestoreConnect, isEmpty } from "react-redux-firebase";
import { connect } from "react-redux";
import ReactPlayer from 'react-player'
import { courseOptions } from '../../../app/common/courseOptions'
import { openModal } from '../../modals/modalActions'
import LoginModal from '../../../features/modals/LoginModal'
//Material UI
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import BuyContent from '../../TestPrep/testPrepContents/BuyContent';
import LookingTutor from '../../TestPrep/testPrepContents/LookingTutor';

const styles = theme => ({
    fontStyle: {
        backgroundImage: 'url("/assets/Student_Pass_BG.png")',
        width: '100vw',
        height: '50vh',
        borderRadius: 0,
        marginTop: 90,
        backgroundSize: 'cover',
        display: 'flex',
        overflow: 'hidden',
        [theme.breakpoints.down('xs')]: {
            height: '33vh'
        }
    },
    bannerImage: {

    },
    headerStyle1: {
        marginTop: 90,
        [theme.breakpoints.down('xs')]: {
            marginTop: 50
        }
    },
    headerStyle: {
        color: '#fff',
        fontSize: '3rem',
        textAlign: 'right',
        letterSpacing: '2px',
        marginBottom: 20,
        [theme.breakpoints.down('xs')]: {
            textAlign: 'left',
            marginLeft: '10%'

        }

    },
    maxWidthStyle: {
        filter: 'none',
        color: 'white',
        maxWidth: '1180px',
        marginRight: 'auto',
        marginLeft: 'auto',
        display: 'block',
        float: 'center',
        zIndex: 1
    },
    descStyle: {
        color: '#fff',
        fontSize: '1.1rem',
        textAlign: 'right',
        paddingLeft: '25%',
        display: 'flex',
        fontFamily: 'proxima-nova, sans-serif ',
        letterSpacing: '1px',
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    hiddenStyle: {
        display: 'block',

        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    hiddenStyle1: {
        display: 'none',
        [theme.breakpoints.down('xs')]: {
            display: 'block'
        }
    },
    responsiveStyle: {
        display: 'none',
        [theme.breakpoints.down('xs')]: {
            display: 'block',
            zIndex: 2,
            position: 'fixed',
            bottom: 0,
            height: '70px',
            width: '100%',
            backgroundColor: 'white',
            color: '#585c60',
            boxShadow: '1px 2px 5px 3px #e1e1e1'
        }
    },
    responsiveStyle2: {
        display: 'none',
        [theme.breakpoints.down('xs')]: {
            display: 'block',
            zIndex: 2,
            position: 'fixed',
            bottom: 0,
            maxHeight: '60%',
            width: '100%',
            backgroundColor: 'white',
            color: '#585c60',
            boxShadow: '1px 2px 5px 3px #e1e1e1',
            overflow: 'scroll'
        }
    },
    xBtnStyle: {
        width: '20px',
        float: 'right',
        "&:hover": {
            backgroundColor: 'white'
        }
    },
    msgStyle: {
        borderRadius: 5,
        backgroundColor: "#ff5d5d",
        color: 'white',
        marginTop: 15,
        padding: '9px 22px',
        marginBottom: 7,
        position: 'fixed',
        zIndex: 5,
        float: 'right',
        "&:hover": {
            backgroundColor: '#ff5d5d'
        }
    },
    msgStyle2: {
        backgroundColor: "white",
        color: '#585c60',
        marginTop: 14,
        zIndex: 5,
        float: 'right',
        fontSize: '1.1rem'

    },
    msgToTr: {
        display: 'flex',
        marginLeft: 20,
        marginTop: '22px',
        fontWeight: 600
    },
    msgToTr2: {
        display: 'none'
    },
    studentPassImg: {
        marginTop: 0,
        marginLeft: '-80px',
        zIndex: -2,
        width: '80%',
        height: '100%',
        verticalAlign: 'middle',
        opacity: 0.6,
        filter: 'brightness(50%)',
    },
    secondComp: {
        maxWidth: '1180px',
        marginRight: 'auto',
        marginLeft: 'auto',
        fontSize: '2rem',
        letterSpacing: '2px',
        fontFamily: 'proxima-nova, sans-serif ',
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            width: '90%',
        }
    },
    secondHeader: {
        fontSize: '2rem',
        marginBottom: 20,
        fontWeight: 600,
        letterSpacing: '2px'
    },
    studentpassSheader: {
        color: '#4ca7ab',
    },
    secondDesc: {
        color: '#585c60',
        fontSize: '1rem',
        letterSpacing: '1px',
        marginBottom: 40
    },
    secondFirst: {
        width: '90%',
        marginRight: 'auto',
        marginLeft: 'auto',
        marginTop: 80,
        [theme.breakpoints.down('xs')]: {
            marginTop: 30,

        }
    },
    cardStylehidden: {
        width: '90%',
        marginRight: 'auto',
        marginLeft: 'auto',
        [theme.breakpoints.down('xs')]: {
            boxShadow: 'none'
        }
    },
    reactVideo: {
        marginBottom: 100
    }
})


const mapState = state => {
    return {
        auth: state.firebase.auth,
        userProfile: state.firebase.profile,
        requesting: state.firestore.status.requesting,
        tutorProfile: state.profiles.current,
        loading: state.async.loading,
        tutorProfileChat: state.firestore.ordered.reviews,
    };
};

const actions = {
    openModal
}

const query = ({ tutorProfile }) => {
    return [
        {
            collection: "profiles",
            doc: `${tutorProfile.tutorUid}`,
            subcollections: [{ collection: "reviews" }],
            storeAs: "reviews"
        }
    ];
};

const Information = () => {
    return <div>
    </div>
}

class StudentPassPage extends Component {
    state = {
        opened: false,
    }

    toggleShow = () => {
        this.setState({
            opened: !this.state.opened,
        });
    }

    handleStripePayment = (props) => {
        this.props.openModal("StripeModal", null, props);
    };

    render() {
        const {
            classes,
            auth,
            tutorProfileChat,
            deleteTutorProfileComment,
            addTutorProfileComment,
            tutorProfile,
            openModal,
            userProfile,
        } = this.props;
        const authenticated = auth.isLoaded && !auth.isEmpty;
        const haveStudentPass = userProfile.studentPass;

        const studentPassInfo = {
            price: 999,
            buyTitle: "Student Pass 1 Year Unlimited Access",
            id: 'pass'
        }

        var buttonType = 'checkOut'

        if (userProfile.studentPass) {
            buttonType = 'none'
        }


        return (
            <div>
                <div className={classes.fontStyle}>
                    <Grid container>
                        <Grid item xs={12} md={6} className={classes.maxWidthStyle}>
                            <div className={classes.headerStyle1}>
                                <h3 className={classes.headerStyle}>What is Student Pass?</h3>
                                <Typography
                                    className={classes.descStyle}
                                >
                                    Student Pass gives students an access to tutor's contact information and unlimited messages to tutors.
                                    Start messaging the best tutors in GTA area only at $9.99/year. Also, don't forget to have 50% discount on our test preps.
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={5} className={classes.hiddenStyle} >
                            <img className={classes.studentPassImg} src="./assets/Student_pass_illu1.png" />

                        </Grid>
                    </Grid>
                </div>

                <Grid container className={classes.secondComp}>
                    <Grid item xs={12} md={7} className={classes.secondFirst} >
                        <p className={classes.secondHeader}>
                            What is
                            <span className={classes.studentpassSheader}> Student Pass </span>
                            ?
                        </p>
                        <p className={classes.secondDesc}>
                            Student Pass gives students an access to tutor's contact information and unlimited messages to tutors.
                                        Start messaging the best tutors in GTA area only at $9.99/year. Also, don't forget to have 50% discount on our test preps
                        </p>
                        <ReactPlayer
                            className={classes.reactVideo}
                            controls={true}
                            light={true}
                            playing={true}
                            width="100%"
                            url="https://youtu.be/nI8bLqLRGYI"
                        />
                    </Grid>
                    <Grid item xs={12} md={5} className={classes.hiddenStyle} >
                        <BuyContent
                            courseOption={courseOptions}
                            handleStripePayment={() => this.handleStripePayment(studentPassInfo)}
                            buttonType={buttonType}
                            authenticated={authenticated}
                            studentPass={true}
                        />

                        <LookingTutor />
                    </Grid>
                </Grid>
                {authenticated ?
                    <div>
                        {haveStudentPass ?
                            ' '
                            :
                            <div className={this.state.opened ? classes.responsiveStyle2 : classes.responsiveStyle}>
                                <Grid container>

                                    <Grid item xs={8}>
                                        {this.state.opened ?
                                            <span className={classes.msgToTr}>
                                                Student Pass 1 Year Unlimited Access
                                            </span>
                                            :
                                            <span className={classes.msgToTr}>
                                                Do you need Student Pass?
                                            </span>
                                        }




                                    </Grid>
                                    <Grid item xs={4}>
                                        <Button
                                            className={this.state.opened ? classes.msgStyle2 : classes.msgStyle}
                                            onClick={this.toggleShow}
                                        >
                                            {this.state.opened ? <img
                                                src="/assets/X_button.png"
                                                className={classes.xBtnStyle}
                                            />
                                                : "PURCHASE"}
                                        </Button>
                                    </Grid>
                                    {this.state.opened ? <Grid container>
                                        <Grid item xs={12} md={4} className={classes.hiddenStyle1} >
                                            <Card
                                                className={classes.cardStylehidden}
                                            >
                                                <BuyContent
                                                    handleStripePayment={() => this.handleStripePayment(studentPassInfo)}
                                                    buttonType={buttonType}
                                                    authenticated={authenticated}
                                                    studentPass={true}
                                                />

                                            </Card>
                                        </Grid>
                                    </Grid>
                                        : " "
                                    }
                                </Grid>


                            </div>
                        }
                    </div>
                    :
                    <div className={classes.responsiveStyle}>
                        <Grid container >
                            <Grid item xs={8}>
                                <span className={classes.msgToTr}>
                                    Purchase Student Pass
                                </span>
                            </Grid>
                            <Grid item xs={4}>
                                <Button
                                    className={classes.msgStyle}
                                    onClick={() => openModal("LoginModal")}
                                >

                                    Purchase
                            </Button>
                            </Grid>
                        </Grid>
                    </div>
                }

            </div>
        )
    }



}


export default withStyles(styles)(
    compose(
        withFirestore,
        connect(
            mapState, actions
        ),
        firestoreConnect(tutorProfile => query(tutorProfile))
    )(StudentPassPage)
);
