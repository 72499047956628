import React, { Component, Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';


const styles = theme => ({
    gridStyle: {
        marginTop: 150,
        backgroundImage: 'url("/assets/Student_Pass_BG.png")',
        width: '100%',
        height: '70vh',
        backgroundSize: 'cover',
        borderRadius: '0px',
        color: 'white',
        [theme.breakpoints.down('xs')]: {
            height: '450px',
        }
    },
    cardStyle: {
        width: '90%',
        [theme.breakpoints.down('xs')]: {
            width: '90%',
            marginRight: 'auto',
            marginLeft: 'auto'
        }
    },
    displayNone: {
        display: 'relative',
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    itemStyle: {
        display: 'block',
        maxWidth: '500px',
        height: 'auto',
        marginTop: 80,
        float: 'right',
        borderRadius: '0px',
        [theme.breakpoints.down('xs')]: {
            backgroundImage: 'none',
            display: 'none'
        },
    },
    studentPass: {
        marginTop: 80,
        maxWidth: '500px',
        height: 'auto',
        textAlign: 'left',
        paddingLeft: 40,
        marginRight: 20,
        fontSize: '1.3rem',
        [theme.breakpoints.down('xs')]: {
            marginTop: 40,
            marginLeft: 20,
            paddingLeft: 0,

        }
    },
    detailBtn: {
        textAlign: 'center',
        color: 'white',
        border: '1px solid white',
        width: '150px',
        marginRight: '20px',
        letterSpacing: '1.5px',
        fontWeight: 600,
        padding: '7px 42px',
        fontSize: '1.1rem',
        background: 'none',
        borderRadius: 5,
        cursor: 'pointer',
        display: 'flex',
        float: 'left',
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        },

        '&:hover': {
            backgroundColor: 'none',
            color: 'white'
        }

    },
    h2Style: {
        fontSize: '3rem',
        marginBottom: 40,
        fontWeight: 600,
        fontFamily: 'proxima-nova, sans-serif ',
        letterSpacing: '1.5px',
        [theme.breakpoints.down('xs')]: {
            fontSize: '2rem'
        }
    },
    paraStyle: {
        fontFamily: 'proxima-nova, sans-serif ',
        letterSpacing: '1px',
        marginBottom: 60
    },
    purchaseBtn: {
        textAlign: 'center',
        color: '#fff',
        border: '1px solid #4ca7ab',
        background: '#4ca7ab',
        padding: '7px 42px',
        letterSpacing: '1.5px',
        fontWeight: 600,
        fontSize: '1.1rem',
        borderRadius: 5,
        cursor: 'pointer',
        '&:hover': {
            background: '#4ca7ab',
            color: 'white',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            fontWeight: 600
        }
    },

})


export const StudentPassBanner = ({
    classes
}) => (
        <div className={classes.gridStyle}>
            <Grid container className={classes.cardStyle}>
                <Grid item xs={12} md={6} >

                    <img className={classes.itemStyle} src='/assets/Student_pass_illu1.png' />
                </Grid>
                <Grid xs={12} item md={6}
                    className={classes.studentPass}
                >
                    <p className={classes.h2Style}>
                        <span className={classes.displayNone}>What is </span>
                        Student Pass?
                        </p>
                    <p className={classes.paraStyle}>Purchase Student Pass at only $9.99/year and contact the best math tutors in GTA area.
                    With Student Pass, you can also get 50% discount on all our test preps.</p>
                    <Button
                        component={Link}
                        to="studentpass"
                        className={classes.purchaseBtn}
                    >
                        Learn More
                    </Button>
                </Grid>
            </Grid>
        </div>
    )

export default withStyles(styles)(StudentPassBanner);
