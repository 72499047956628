import React, { Component } from "react";
import { connect } from 'react-redux'
import _ from "lodash";
import { searchByRequest } from '../../TutorSearchForm/searchActions'
import { Form, Button, Sticky } from "semantic-ui-react";
import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider'
import { SliderRail, Handle, Track, Tick } from './sliderComponents'
import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    
    filter: {
        padding: '0 0 70px 0',
        overflow: 'visible',
        width: '90%',
        marginTop: '20px',
        boxShadow: 'none',
        border: '1px solid #e1e1e6',
        borderRadius: 10,
        borderTop: 'none',
        [theme.breakpoints.down('xs')]: {
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 0,
            width: '100%',
            borderRadius: 0,
            borderRight: '1px solid #cec7d1',
            paddingTop: 40,
            paddingBottom: 40
        }
    },
   
    slider: {
        padding: '22px 0px',
    },
    headerStyle: {
        backgroundColor: '#4ca7ab',
        borderBottom: '1px solid #e1e1e6',
        borderTopRightRadius: 10,
        borderTopLeftRadius: 10,
        [theme.breakpoints.down('xs')]: {
            borderBottom: 'none',
        }
    },
    distanceHeader: {
        fontSize: '1.5rem',
        paddingBottom: 10,
        color: 'white',
        margin: '20px',
        paddingTop: 10
    },
    responsiveStyle:{
        margin: 20,
        marginTop: 30,
        [theme.breakpoints.down('xs')]: {
        marginTop: 0
        }
    },
})

const sliderStyle = {
    position: 'relative',
    width: '100%',
    marginTop: '30px'
}
const railStyle = {
    position: 'relative',
    width: '100%',
    height: 1,
    marginTop: 35,
    borderRadius: 5,
    backgroundColor: 'red',
}


const mapStateToProps = state => {
    return {
        langs: state.searches.langs,
        subject: state.searches.subject,
        location: state.searches.location,
        lat: state.searches.lat,
        lng: state.searches.lng,
        distance: state.searches.distance,
        searchList: state.searches.list,
        isFetching: state.searches.isFetching,
        priceLow: state.searches.priceLow,
        priceHigh: state.searches.priceHigh
    };
};


const mapDispatchToProps = dispatch => {
    return {
        searchByRequest: payload => dispatch(searchByRequest(payload)),
        searchBy: (lang, subject, formattedAddress, lat, lng, distance, searchPage, priceLow, priceHigh) =>
            dispatch({
                type: "SEARCH_BY",
                langs: lang,
                subject: subject,
                location: formattedAddress,
                lat: lat,
                lng: lng,
                distance: distance,
                searchPage: searchPage,
                priceLow,
                priceHigh
            }),
    };
};


//const domain = [0, 100]
const defaultValues = [2, 10]

class TutorPriceFilter extends Component {

    state = {
        distance: this.props.distance ? this.props.distance : [25],
        values: this.props.priceHigh ? [this.props.priceLow, this.props.priceHigh] : [0, 100],
        update: this.props.priceHigh ? [this.props.priceLow, this.props.priceHigh] : [0, 100],
        reversed: false,
        domain: [0, 100]
    }


    toggleReverse = () => {
        this.setState(prev => ({ reversed: !prev.reversed }))
    }

    onUpdate = update => {
        this.setState({ update })
    }

    onChange = async values => {
        this.setState({ values })
        let langList = [];

        await _.forEach(this.props.langs, lang => {
            langList.push(lang.value)
        });


        this.props.searchByRequest({
            langList: langList,
            subject: this.props.subject.value,
            location: {
                lng: this.props.lng,
                lat: this.props.lat,
            },
            distance: this.props.distance,
            price: {
                priceLow: values[0],
                priceHigh: values[1]
            },
        })

        this.props.searchBy(
            this.props.langs,
            this.props.subject,
            this.props.location,
            this.props.lat,
            this.props.lng,
            this.props.distance,
            1,
            values[0],
            values[1]
        )
    }

    render() {
        const { contextRef, classes } = this.props;
        //const { distance } = this.state;
        const { domain, values, update, reversed } = this.state;

        return (
            <Card className={classes.filter}>
                <div className={classes.headerStyle}>
                    <Typography className={classes.distanceHeader}>Price</Typography>

                </div>
                <div className={classes.responsiveStyle}>
                    <label style={{ color: '#585c60', fontWeight: 700 }}>Price Range: From<span> ${update[0]}</span> to <span>${update[1]}</span></label>

                    <Slider
                        mode={2}
                        step={1}
                        domain={domain}
                        reversed={reversed}
                        rootStyle={railStyle}
                        onUpdate={this.onUpdate}
                        onChange={this.onChange}
                        values={values}
                    >
                        <Rail >
                            {({ getRailProps }) => <SliderRail rootStyle={{ color: 'red', backgroundColor: 'red' }} getRailProps={getRailProps} />}
                        </Rail>
                        <Handles >
                            {({ handles, getHandleProps }) => (
                                <div className="slider-handles">
                                    {handles.map(handle => (
                                        <Handle
                                            style={{ width: 10 }}
                                            key={handle.id}
                                            handle={handle}
                                            domain={domain}
                                            getHandleProps={getHandleProps}
                                        />
                                    ))}
                                </div>
                            )}
                        </Handles>
                        <Tracks left={false} right={false}>
                            {({ tracks, getTrackProps }) => (
                                <div className="slider-tracks">
                                    {tracks.map(({ id, source, target }) => (
                                        <Track
                                            key={id}
                                            source={source}
                                            target={target}
                                            getTrackProps={getTrackProps}
                                        />
                                    ))}
                                </div>
                            )}
                        </Tracks>
                        <Ticks count={4}>
                            {({ ticks }) => (
                                <div className="slider-ticks">
                                    {ticks.map(tick => (
                                        <Tick key={tick.id} tick={tick} count={ticks.length} />
                                    ))}
                                </div>
                            )}
                        </Ticks>
                    </Slider>

                </div>

            </Card>
        );
    }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(TutorPriceFilter));