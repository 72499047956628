import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles'

import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    root: {
        backgroundImage: 'url("/assets/BackgroundImage/Tutor_quiz_Banner.png")',
        height: '70vh',
        width: '100vw',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    responsive: {
        display: 'none',
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            height: '70%',
            width: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            borderRadius: 0
        }
    },
    aboutUsImage: {
        maxWidth: '1180px',
        marginRight: 'auto',
        marginLeft: 'auto'
    },
    firstElement: {
        fontFamily: 'proxima-nova, sans-serif ',
    },
    girdFirst: {
        marginTop: 160,
        paddingLeft: 40,
        letterSpacing: '2px',
        fontSize: '2.3rem',
        color: '#585c60',
        fontFamily: 'proxima-nova, sans-serif ',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'left',
            width: '80%',
            marginTop: 0,
            marginRight: 'auto',
            marginLeft: 'auto',
            paddingLeft: 0
        }
    },
    headerStyle: {
        fontSize: '3rem',
        marginBottom: 0,
        color: '#585c60',
        fontWeight: 600,
        paddingTop: 0,
        marginBottom: 40,
        fontFamily: 'proxima-nova, sans-serif ',
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.7rem',
            marginBottom: 20,
            paddingTop: 80,
        }
    },
    fontStyle: {
        fontFamily: 'proxima-nova, sans-serif ',
        letterSpacing: '1px',
        fontSize: '1.3rem',
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.1rem'
        }
    }





});


export const TutorQuiz = ({ classes }) => (
    <div className={classes.root}>
        <div className={classes.aboutUsImage}>
            <Grid container className={classes.girdFirst}>
                <Grid
                    item
                    md={6}
                    className={classes.firstElement}
                >
                    <Typography
                        className={classes.headerStyle}
                    >
                        Tutor Evaluation Test
                </Typography>
                    <Typography
                        className={classes.fontStyle}
                    >
                        Not everyone can be a tutor at Tossmath. Only tutors who pass our evaluation test can tutor students. The evaluation test makes sure that the tutors are able to teach students according to the Ontario Curriculum and what level the tutors can teach up to.
                </Typography>
                </Grid>
            </Grid>
            <Avatar className={classes.responsive} src="/assets/BackgroundImage/Tutor_quiz_Banner.png" alt="banner"  >
          </Avatar>
        </div>


    </div>
)


export default withStyles(styles)(TutorQuiz)
