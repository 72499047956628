import React from 'react';
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';


const styles = theme => ({
    aboutUsImage: {
        height: '100%',
        width: '100%',
        margin: 0,
        paddingBottom: 80,
        backgroundImage: 'linear-gradient( #669FFF, #4CA7AB)',
        [theme.breakpoints.down('xs')]: {
            maxHeight: 400,
        }

    },
    widthStyle: {
        maxWidth: '1180px',
        marginRight: 'auto',
        marginLeft: 'auto',
        [theme.breakpoints.down('xs')]: {
            maxWidth: '90%',

        }

    },

    aboutUsHeader: {
        display: 'block',
        paddingTop: 150,
        fontSize: '4rem',
        color: 'white',
        paddingBottom: 10,
        letterSpacing: '2px',
        fontFamily: 'proxima-nova, sans-serif ',
        [theme.breakpoints.down('xs')]: {
            paddingTop: 140,
            fontSize: '3rem',

        }

    },
    contactUsQuestion: {
        color: 'white',
        fontSize: '2rem',
        margin: 0,
        padding: 0,
        letterSpacing: '1.4px',
        fontFamily: 'proxima-nova, sans-serif ',
        fontWeight: 600,
        [theme.breakpoints.down('xs')]: {
            textAlign: 'left',

        }
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',

    },
    card: {
        maxWidth: 1180,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    title: {
        fontSize: '1.5rem',
        textAlign: 'left',
        fontWeight: 400,
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.1rem'
        },
    },


    FAQUsMain: {
        paddingTop: 100,
        maxWidth: 1180,
        marginRight: 'auto',
        marginLeft: 'auto',
        fontSize: '1.5rem',
        paddingLeft: 40,
        paddingRight: 40
    },
    expandIcon: {
        display: 'inline',
        float: 'right'
    },
    blueLine: {
        borderBottom: '3px solid #4ca7ab',
        borderRadius: 5,
        paddingBottom: 5
    },
    yellowLine: {
        borderBottom: '3px solid #f68484',
        borderRadius: 5,
        paddingBottom: 5
    },
    expandStyle: {
        marginBottom: 20,
        boxShadow: 'none'
    },

})

const Generals = [
    {
        title: 'What is Tossmath?',
        description: 'Tossmath is a Toronto-based Math Tutoring platform. We help tutors and students to connect each other',
    },
    {
        title: 'How do you select Tutors? ',
        description: 'We are trying our best to filter out unqualified tutors and recruit only the great ones. First of all, We ask the tutor candiates to submit their proof of educations such as confirmation of enrolment, tranascript, graduation certificate and etc. Then, the candidates need to submit 1-5 minute video to introduce themselves. Lastly, they need to take evaluation test and submit the solution. If they complete all the processes and pass the exam, they can be registered as tutors at Tossmath',
    },
    {
        title: 'Why do I need to choose the ‘Language’ when searching tutors?',
        description: 'It is there to ensure that NO ONE is excluded from using Tossmath. If students from Korea, Japan or any other countries want to learn in their own languages, they can choose their languages when searching tutors. Likewise, tutors can choose the languages they want to teach in when they register for tutors.'
    },
    {
        title: 'What is Student Pass? ',
        description: 'Student Pass allows students to see tutor\'s contact informatio and to message tutors. On top of that, students with a Student Pass can have 50% discounts on our test prep courses. You can purchase the student pass only at $9.99/year',
    },
];
const Students = [
    {
        title: 'Do I need to pay for contacting tutors?',
        description: 'Yes, you need to purchase the Student Pass to contact tutors.',
    },
    {
        title: 'Can I pay tutors through your website?',
        description: 'No. Please discuss with your tutor regarding the payment.'
    },
    {
        title: 'How can I contact tutors?',
        description: 'Once you purchase the Student Pass, you can contact our tutors as many as you want',
    },
    {
        title: 'Do you run a Criminal Record Check on tutors?',
        description: 'We do not do any sort of background check, but we do advise tutors to submit their proof of education and a video introducing themselves. As It does not fully guarantee the credentials, we would suggest students to meet tutors in an open space during the day for the first several meetings and ask them to provide necessary documentations.',
    },
];
const Tutors = [
    {
        title: 'Is it free to become a tutor?',
        description: 'Yes, it is absolutely free and we do not take any commissions unlike other platforms',
    },
    {
        title: 'How do I become a tutor?',
        description: 'Click a \'Become a Tutor\' button on our navigation bar and start creating your profile. You need to submit your photo, an 1-5 minute video introducing who you are, and documents proving your education credentials. On top of that, you need to take an evaluation test to be registered on our platform '
    },
    {
        title: 'I cannot find a subject that I want to be a tutor for. What should I do?',
        description: 'Please contact us at support@crowntutor.com and we will respond within 1-2 business days',
    },
    {
        title: 'Can I teach multiple levels?',
        description: 'YES! You can also set different rates on each level you teach.',
    },
    {
        title: 'How can students contact me?',
        description: 'Once your profile is set up, students can search your profile and contact you. Make sure to check your email regularly and your junk folder as well.',
    },
    {
        title: 'I am not sure if I am qualified to teach someone. Should I?',
        description: 'Don\' worry! If you pass our evaluation test, you are good to go. However, it is the students who will decided to have lessons with you. Make sure to be presentable when you are recording the video!',
    },
];
const Others = [
    {
        title: 'I have something to suggest you.',
        description: 'Thank you. We are always open to suggestions! Please send us feedbacks to support@tossmath.ca',
    },
    {
        title: 'I have a question that is not listed in FAQ',
        description: 'Please contact us at support@tossmath.ca and we will respond within 1-2 business days',
    },
    {
        title: 'I purchased a Test Prep Package. Can I get a refund?',
        description: 'Sorry. Our products are non-refundable as they are in the form of digital copy. Please let us know what is wrong with the product and we will do our best for your satisfaction with our product',
    }
]

class faq extends React.Component {
    state = (
        { expanded: false }
    );
    constructor(props) {
        super(props)
        this.handleExpandClick = this.handleExpandClick.bind(this)
        this.state = {
            showContect: true
        }
    }
    handleExpandClick = () => {
    };

    handleSubmit = (payload) => {
        const { showSuccess, showError } = this.props
        this.props.createVoiceRequest(payload, showSuccess, showError)
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <div className={classes.aboutUsImage}>
                    <div className={classes.widthStyle}>
                        <Grid container>
                            <Grid item md={4}>
                                <h2 className={classes.aboutUsHeader} >FAQ</h2>
                                <p className={classes.contactUsQuestion}>DO YOU HAVE QUESTION?</p>
                            </Grid>
                        </Grid>


                    </div>
                </div>
                <div className={classes.FAQUsMain}>
                    <h2 className={classes.blueLine}>General</h2>
                    {Generals.map(General => (
                        <ExpansionPanel className={classes.expandStyle}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography className={classes.title} title={General.title} >
                                    {General.title}
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails style={{ backgroundColor: '#f4f7fa' }}>
                                <Typography in={this.state.expanded} timeout="auto" unmountOnExit>
                                    {General.description}
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    ))}
                    <h2 className={classes.yellowLine}>Students</h2>
                    {Students.map(Student => (
                        <ExpansionPanel className={classes.expandStyle}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography className={classes.title} title={Student.title}>
                                    {Student.title}
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails >
                                <Typography in={this.state.expanded} timeout="auto" unmountOnExit>
                                    {Student.description}
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>

                    ))}
                    <h2 className={classes.yellowLine}>Tutors</h2>
                    {Tutors.map(Tutor => (
                        <ExpansionPanel className={classes.expandStyle}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography className={classes.title} title={Tutor.title}>
                                    {Tutor.title}
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography in={this.state.expanded} timeout="auto" unmountOnExit>
                                    {Tutor.description}
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>

                    ))}
                    <h2 className={classes.blueLine}>Others</h2>
                    {Others.map(Other => (
                        <ExpansionPanel className={classes.expandStyle}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography className={classes.title} title={Other.title} >
                                    {Other.title}
                                </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography in={this.state.expanded} timeout="auto" unmountOnExit>
                                    {Other.description}
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    ))}
                </div>
            </div>
        );
    }
}



export default compose(
    withStyles(styles),
)(faq)