import React from 'react'
import { Icon, Button, Header } from 'semantic-ui-react'
import Add from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Select from 'react-select'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const style = (theme) => ({
  subjectTitle: {
    fontSize: '1.5rem',
    marginLeft: 40,
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 20
    }
  },
  cardStyle: {
    marginTop: 20,
    overflow: 'hidden',
    width: '95%',
    marginLeft: 'auto',
    marginRight: 'auto',
    boxShadow: 'none',
    border: '1px solid #e1e1e6',
    color: '#585c60',
    paddingTop: 8,
    paddingBottom: 8,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: 0,
      borderRadius: 0,
      boxShadow: 'none'
    }
  },
  phoneSize: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      marginBottom: 17,
      marginLeft: 20,
      marginRight: 20,
      paddingTop: 17,
      paddingLeft: 20,
      paddingBottom: 0,
      borderTop: '1px solid #e1e1e6'
    },
  },
  desktopSize: {
    width: '100%',
    borderBottom: '1px solid #e1e1e6',
    paddingBottom: 15,
    display: 'block',
    marginBottom: 15,
    paddingLeft: 20,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },

  },
  desktopSize1: {
    width: '100%',
    display: 'block',
    paddingLeft: 20,
    margin: 0,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },

  },


  selectStyle: {
    marginBottom: 20
  },
  card: {
    overflow: 'visible'
  },
  dialBtnStyle1: {
    padding: '8px 16px',
    backgroundColor: '#fff',
    borderRadius: 5,
    color: '#FF5D5D',
    cursor: 'pointer',
    border: '1px solid #FF5D5D',
    '&:hover': {
      backgroundColor: '#FF5D5D',
      color: '#fff'
    },
  },
  dialBtnStyle2: {
    padding: '8px 16px',
    backgroundColor: '#4ca7ab',
    borderRadius: 5,
    borderStyle: 'none',
    color: 'white',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#0A4065',
    },
  },
  cardHeaderStyle: {
    paddingBottom: 0
  }
})
const TutorProfileExperience = ({
  tutorProfile,
  openResumeUserExperience,
  handleResumeUserExperienceClickOpen,
  handleResumeUserExperienceClickClose,
  handleSubmitExperience,
  handleRemoveExperience,
  handleChange,
  isOwner,
  classes
}) => {
  // console.log(tutorProfile)
  return (
    <React.Fragment>
      <div>
        <Dialog
          fullWidth
          open={openResumeUserExperience}
          onClose={() => handleResumeUserExperienceClickClose()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" ></DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" style={{ paddingTop: 10 }}>

              <Grid container spacing={24}>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    id="experience"
                    label="experience or certification"
                    name="what"
                    onChange={(e) => handleChange(e)}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    id="outlined-name"
                    label="Organization/Institution"
                    name="where"
                    onChange={(e) => handleChange(e)}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    id="outlined-name"
                    label="year from"
                    name="from"
                    onChange={(e) => handleChange(e)}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    id="outlined-name"
                    label="year to"
                    name="to"
                    onChange={(e) => handleChange(e)}
                    variant="outlined"
                  />
                </Grid>
              </Grid>




            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button className={classes.dialBtnStyle1} onClick={() => handleResumeUserExperienceClickClose()} >CANCEL</button>
            <button className={classes.dialBtnStyle2} onClick={() => handleSubmitExperience()} >SAVE</button>
          </DialogActions>
        </Dialog>
      </div>

      {isOwner ?
        <Card className={classes.cardStyle}>
          <CardContent
            className={classes.cardHeaderStyle}
          >
            <Grid container>
              <Grid item xs={11} md={11}>
                <Typography style={{ color: '#4ca7ab' }} className={classes.subjectTitle}>Experiences/Certifications
                </Typography>
              </Grid>
              <Grid item xs={1} md={1}>
                <Add
                  style={{
                    display: 'inlineBlock',
                    cursor: 'pointer',
                    float: 'right',
                    color: '#4ca7ab'
                  }}
                  onClick={() => handleResumeUserExperienceClickOpen()} />
              </Grid>
            </Grid>
          </CardContent>

          <CardContent >
            <Grid>
              {tutorProfile.experiences && tutorProfile.experiences.map((Experience, i) => (
                <React.Fragment key={i}>
                  {Experience.length > 0 ?
                    
                    ' '
                    :
                    <div>
                      <div className={classes.desktopSize}>
                        <Grid container>
                          <Grid item xs={7}>
                            <p >{Experience.what}</p>
                            <p style={{ marginTop: -8, color: '#585c60' }}>{Experience.where}</p>
                          </Grid>
                          <Grid item xs={2}>
                            <p >{Experience.from}&nbsp;&#8764;&nbsp;{Experience.to}</p>
                          </Grid>
                          <Grid item xs={3}>
                            <DeleteIcon style={{ float: 'right', marginRight: '22px', color: '#FF5D5D', fontSize: '18px' }} onClick={(e) => handleRemoveExperience(e, i)} />
                          </Grid>
                        </Grid>

                      </div>
                      <div className={classes.phoneSize}>
                        <Grid container>

                          <Grid item md={5}>
                            <p >{Experience.what}</p>
                          </Grid>
                          <Grid item md={4}>
                            <p>{Experience.where}</p>
                          </Grid>
                          <Grid item md={2}>
                            <span style={{ paddingLeft: 10 }}>{Experience.from}&nbsp;&#8764;&nbsp;{Experience.to}</span>
                          </Grid>
                          <Grid item md={1}>
                            <DeleteIcon style={{ marginLeft: '55%', color: '#FF5D5D', fontSize: '18px', cursor: 'pointer' }} onClick={(e) => handleRemoveExperience(e, i)} />
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                    
                  }
                </React.Fragment>



              ))
              }
            </Grid>
          </CardContent>
        </Card>
        :
        <Card className={classes.cardStyle}>
          <CardContent
            className={classes.cardHeaderStyle}
          >
            <Typography style={{ color: '#4ca7ab' }} className={classes.subjectTitle}>Experiences/Certifications</Typography>
          </CardContent>
          <CardContent >
            {tutorProfile.experiences && tutorProfile.experiences.map((experience, i) => (

              <React.Fragment key={i}>
                <Grid>
                  <div className={(i === tutorProfile.experiences.length - 1) ? classes.desktopSize1 :
                    classes.desktopSize
                  }>
                    <Grid container>
                      <Grid item xs={6}>
                        <p >{experience.what}</p>
                        <p style={{ marginTop: -8, color: '#585c60' }}>{experience.where}</p>
                      </Grid>
                      <Grid item xs={1}></Grid>
                      <Grid item xs={2}>
                        <p >{experience.from}&nbsp;&#8764;&nbsp;{experience.to}</p>
                      </Grid>

                    </Grid>

                  </div>
                  <div className={classes.phoneSize}>
                    <Grid container>

                      <Grid item md={5}>
                        <p >{experience.what}</p>
                      </Grid>
                      <Grid item md={5}>
                        <p >{experience.where}</p>
                      </Grid>
                      <Grid item md={2}>
                        <span>{experience.from}&nbsp;&#8764;&nbsp;{experience.to}</span>
                      </Grid>

                    </Grid>
                  </div>


                </Grid>


              </React.Fragment>
            ))
            }
          </CardContent>
        </Card>
      }
    </React.Fragment>
  )
}

export default withStyles(style)(TutorProfileExperience)