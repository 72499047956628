import React, { Component } from "react";
import { Form, Sticky } from "semantic-ui-react";
import { connect } from "react-redux";
import PlaceInput from "../../../app/common/form/PlaceInput";
import SelectInput from "../../../app/common/form/SelectInput";
import Autosuggest from 'react-autosuggest'
import { reduxForm, Field } from "redux-form";
import { withFirestore } from "react-redux-firebase";
import {
  composeValidators,
  combineValidators,
  isRequired,
  hasLengthGreaterThan
} from "revalidate";
import Select from "react-select";
import InputBase from "@material-ui/core/InputBase";
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';



const styles = theme => ({
  gridWidth: {
    maxWidth: '1000px',
    marginRight: 'auto',
    marginLeft: 'auto',
    paddingTop: 20

  },
  inputs: {
    margin: '0 0 20px 0',
    borderTop: '1px solid #e1e1e6',
    [theme.breakpoints.down('xs')]: {
      paddingRight: 20,
      paddingLeft: 20,
    }
  },
  inputbase: {
    width: '200px',
    padding: '0% 0 2% 0',
  },
  inputbase1: {
    width: '200px',
    padding: '0% 0 5% 0',
    marginBottom: 5
  },
  filter: {
    paddingBottom: '10px',
    overflow: 'visible',
    marginTop: '88px',
    borderTop: 'none',
    boxShadow: 'none',
    borderBottom: '1px solid #e1e1e6',
    [theme.breakpoints.down('xs')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: 0,
      marginBottom: 10,
      borderBottom: 'none',
      borderRadius: 0,
      borderRight: '1px solid #cec7d1',
      boxShadow: 'none',
    }
  },

  searchbtn: {
    maxHeight: '38px',
    backgroundColor: '#669fff',
    color: '#4ca7ab',
    padding: '10px 50px',
    height: '100%',
    width: '100%',
    borderRadius: '7px',
    marginLeft: 20,
    marginTop: 22,
    '&:hover': {
      color: '#4ca7ab',
      backgroundColor: '#325b9f',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: 20,
      width: '100%',
      marginLeft: 0,
      marginTop: 22
    },
  },
  location: {
    marginLeft: 20,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      marginTop: 15,

    }
  },
  location1: {
    marginLeft: 40,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0
    }
  },
  searchBoxTypo: {
    fontWeight: 600,
    fontSize: '1.1rem',
    color: '#585c60',

  },
  inputBaseStyle: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {

      padding: 0
    }
  }



})

class TutorSearchForm2 extends Component {
  render() {

    const {
      onSelect,
      contextRef,
      onChangeSubject,
      onChange,
      onChangeLang,
      handleSearch,
      langOptions,
      invalid,
      submitting,
      pristine,
      suggestions,
      onSuggestionsFetchRequested,
      onSuggestionsClearRequested,
      getSuggestionValue,
      renderSuggestion,
      subject,
      value,
      classes,
      locationValue,
      levelLabel,
      langLabel,
      levelOptions,
      scriptLoaded
    } = this.props;

    // const inputProps = {
    //   placeholder: "level",
    //   value: `${levelLabel.label}`,
    //   onChange: onChange
    // };

    // console.log("searchform2", levelLabel)
    return (
      <Card className={classes.filter}>
        <Form className={classes.inputs}>
          <Grid container className={classes.gridWidth}>
            <Grid item xs={12} sm={12} md={3} className={classes.location1}>
              <Typography className={classes.searchBoxTypo}>Location</Typography>

              <InputBase
                className={classes.inputBaseStyle}
                id="autocomplete"
                label="Where"
                placeholder=' '
                InputLabelProps={{
                  shrink: true,
                }}
                defaultValue={locationValue}
                input={{ color: 'red' }}

              />
            </Grid>
            <Grid item xs={12} sm={3} md={3} className={classes.location}>
              {/* <Field
                type="text"
                component={SelectInput}
                suggestions={suggestions}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
                name="subject"
              /> */}
              <Typography className={classes.searchBoxTypo}>Level</Typography>

              <Select
                className={classes.inputBaseStyle}
                onChange={onChangeSubject}
                name="level"
                placeholder=" "
                options={levelOptions}
                defaultValue={levelLabel}
              />


            </Grid>
            <Grid item xs={12} sm={5} md={3} className={classes.location}>
              <Typography className={classes.searchBoxTypo}>Languages</Typography>

              <Select
                className={classes.inputBaseStyle}
                isMulti
                options={langOptions}
                placeholder=" "
                onChange={onChangeLang}
                defaultValue={langLabel}
              />
            </Grid>
            <Grid item xs={12} sm={2} md={1} >
              <Button className={classes.searchbtn} onClick={() => handleSearch()} type="button">
                <i class="material-icons" style={{color: 'white'}}>
                  search
                </i>
              </Button>
            </Grid>
          </Grid>


          {/* <input
              className={classes.inputbase}
              id="autocomplete"
              label="Where"
              name='location'
              placeholder='Your Location'
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(data) => handleSearch(data, 'location')}
              defaultValue={locationValue}

            /> */}



          {/*  
              <Field
                type="text"
                component={SelectInput}
                suggestions={suggestions}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                renderSuggestion={renderSuggestion}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                getSuggestionValue={getSuggestionValue}
                inputProps={inputProps}
                name="subject"
                
              />  */}

        </Form>

      </Card>
    );
  }
}

export default withStyles(styles)(
  withFirestore(
    connect()(
      reduxForm({ form: "tutorSearchForm", enableReinitialize: true, })(
        TutorSearchForm2
      )
    ))
);