import React, { Component } from "react";
import _ from 'lodash'
// import { Segment, Icon, Rating, Item } from "semantic-ui-react";
import { Link, NavLink } from "react-router-dom";
import { openModal } from "../../modals/modalActions";
// import UnauthModal from "../../modals/UnauthModal";
//Material-Ui Components
// import GradeIcon from '@material-ui/icons/Grade';
// import StarsIcon from '@material-ui/icons/Stars';
import StarRatings from 'react-star-ratings';
import Add from '@material-ui/icons/Add';
import Create from '@material-ui/icons/Create';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Select from "react-select";
import { langOptions } from "../../../app/common/langOptions";
import { withStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ChatBubbleOutline from '@material-ui/icons/ChatBubbleOutline';
import Message from '@material-ui/icons/Message';
import Tooltip from '@material-ui/core/Tooltip';
import {
  MuiThemeProvider, createMuiTheme
} from "@material-ui/core/styles";
import RecommendInformation from "../../modals/RecommendInformation";
import './TutorProfile.css'

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "0.8em",
        color: "#585c60",
        backgroundColor: "white",
        border: '1px solid #585c60',
        padding: '2px 2px 2px 2px'
      }
    }
  }
});



const styles = theme => ({
  cardStyle: {
    backgroundImage: 'url("/assets/Profile_Header_Banner.png")',
    maxHeight: '45vh',
    minHeight: '45vh',
    width: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    color: '#fff',
    [theme.breakpoints.down('xs')]: {
      backgroundImage: 'none',
      borderRadius: 0,
      maxHeight: '800px',
      borderTop: 'none',
      boxShadow: 'none',
      marginTop: 0,
      color: '#585c60',
    }
  },
  cardContents: {
    maxWidth: '900px',
    marginRight: 'auto',
    marginLeft: 'auto',
    maxHeight: '900px'
  },
  componentsPosition: {
    marginLeft: -50,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,

    }
  },
  tutorProfileHeaderStyle: {
    marginTop: '90px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '86px'
    }
  },
  imageStyle: {
    marginTop: '100px'
  },
  avatar: {
    marginTop: 50,
    width: 180,
    height: 180,
    boxShadow: '10px 10px 10px #red',
    [theme.breakpoints.down('xs')]: {
      width: 130,
      height: 130,
      marginTop: 20,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  avatarHover: {
    marginTop: 50,
    width: 180,
    height: 180,
    boxShadow: '10px 10px 10px #red',
    '&:hover': {
      display: 'block',
      zIndex: 0
    },
    [theme.breakpoints.down('xs')]: {
      width: 130,
      height: 130,
      marginTop: 20,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },

  badgeStyle: {
    float: 'left',
    borderRadius: 0,
    marginTop: 10,
    width: '26px',
    height: 'auto',
    marginRight: 10
  },
  createStyle: {
    float: 'right',
    color: '#fff',
    [theme.breakpoints.down('xs')]: {
      color: '#4ca7ab'
    },
    '&:hover': {
      color: '#e1e1e6'
    }
  },
  container: {
    marginTop: 50,
    display: 'block',
    [theme.breakpoints.down('xs')]: {
      marginTop: 0
    }
  },
  langStyle: {
    color: '#fff',
    border: '1px solid #fff',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    borderRadius: 6,
    padding: '8px 16px',
    marginTop: '5px',
    verticalAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      color: '#4ca7ab',
      border: '1px solid #4ca7ab',
      backgroundColor: 'rgba(0, 0, 0, 0)',
    }

  },
  ResumeUserInformation1: {
    marginLeft: '50px',
    [theme.breakpoints.down('xs')]: {
      marginTop: 20,
      marginLeft: '25px'
    }
  },
  ResumeUserInformation2: {
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      paddingTop: 0
    }
  },
  nameBottom: {
    marginBottom: 30,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 10
    }
  },
  addBtnStyle: {
    marginTop: 5,
    cursor: 'pointer',
    color: '#fff',
    border: '1px solid #fff',
    backgroundColor: '#rgba(0, 0, 0, 0)',
    borderRadius: 50,
  },
  deleteIcon: {
    color: '#fff',
    border: '1px solid #fff',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    borderRadius: 6,
    paddingRight: '12px',
    marginBottom: '6px',
    [theme.breakpoints.down('xs')]: {
      color: '#4ca7ab',
      border: '1px solid #4ca7ab',
      backgroundColor: 'rgba(0, 0, 0, 0)',
    }
  },
  xBtn: {
    color: '#fff',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    borderStyle: 'none',
    position: 'relative',
    cursor: 'pointer',
    fontSize: '18px',
    padding: '1px 2px 5px 8px',
    marginRight: '3px',
    [theme.breakpoints.down('xs')]: {
      color: '#4ca7ab',
      padding: '1px 2px 5px 8px',

    }
  },
  educationStyle: {
    fontSize: '1.2rem',
    listStyle: 'none',
    color: '#585c60',
    paddingTop: '7px',
    paddingLeft: '30px',
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '25px',
      fontSize: '1.1rem',

    }
  },
  edit: {
    width: 20,
    marginRight: 'auto',
    marginLeft: 'auto',
    position: 'absolute',
    display: 'none',
    '&:hover': {
      display: 'block',
      zIndex: 2
    }
  },
  editColor: {
    color: '#fff',
    [theme.breakpoints.down('xs')]: {
      color: '#4ca7ab'
    }
  },
  iconStyle2: {
    color: "#fff",
    top: "7px",
    cursor: 'pointer',
    width: 25,
    [theme.breakpoints.down("sm")]: {
      float: 'right'
    },

  },
  responsiveCity: {
    display: 'flex',
    [theme.breakpoints.down("xs")]: {
      display: 'none'
    }
  },
  responsiveCityPhone: {
    display: 'none',
    [theme.breakpoints.down("xs")]: {
      display: 'block'
    }
  },
  nameStyle: {
    fontSize: '2.6rem',
    letterSpacing: '1.5px',
    [theme.breakpoints.down("xs")]: {
      fontSize: '1.8rem',
    }

  },
  openRecommandBadges: {
    border: '1px solid #b0b4dc',
    backgroundColor: '#fff',
    color: '#b0b4dc',
    borderRadius: 50,
    fontSize: '0.6rem',
    fontWeight: 600,
    padding: '2px 4px 2px 4px'
  }


})





class TutorProfileHeader extends Component {



  getChannelId = (userId) => {
    const currentUserId = this.props.user.uid;
    //console.log("GetChannelId", userId)
    return userId < currentUserId ? `${userId}_${currentUserId}` : `${currentUserId}_${userId}`
  }

  changeChannel = (userId) => {
    const channelId = this.getChannelId(userId);

    //console.log(channelId)
  }

  render() {

    const {
      tutorProfile,
      isOwner,
      // goingToEvent,
      // isGoing,
      // cancelGoingToEvent,
      // loading,
      // authenticated,
      openModal,
      openResumeUserLanguage,
      handleResumeUserLanguageClickOpen,
      handleResumeUserLanguageClickClose,
      handleSubmitLanguage,
      handleRemoveLanguage,
      // handleChangeSelectEducation,
      // handleChange,
      handleSelectLanguages,
      classes,
    } = this.props;

    let languageList = []
    _.forEach(tutorProfile.languages, (k, v) => {
      languageList.push(v)
    })
    const ratingWithLessDecimals = parseFloat(tutorProfile.rating).toFixed(1)
    //console.log(ratingWithLessDecimals)

    let badgeList = []
    let i = 0;
    _.forEach(tutorProfile.badges, (k, v) => {

      if (k === true) {
        badgeList.push(v)
      } else {
        badgeList.push("null")
      }
      // console.log("k", k);
      // console.log("v", v);
      i++;
    })


    return (
      <React.Fragment>
        <div className={classes.tutorProfileHeaderStyle}>
          <Dialog
            fullWidth
            open={openResumeUserLanguage}
            onClose={() => handleResumeUserLanguageClickClose()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title"></DialogTitle>
            <DialogContent style={{ height: 200, overflow: 'visible' }}
            >
              <DialogContentText id="alert-dialog-description">
                <Select
                  isMulti
                  name="langs"
                  options={langOptions}
                  // defaultValue={{
                  //   value: "English",
                  //   label: "English",
                  //   nativeName: "English",
                  //   isFixed: true,
                  //   backgroundColor: "black"
                  // }}
                  onChange={(data, e) => handleSelectLanguages(data, e)}
                />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleResumeUserLanguageClickClose()} color="primary">CANCEL</Button>
              <Button onClick={() => handleSubmitLanguage()} color="primary" autoFocus>SAVE</Button>
            </DialogActions>
          </Dialog>
        </div>
        <Card className={classes.cardStyle}>
          <CardContent className={classes.cardContents}>
            <Grid container>
              <Grid item xs={12} md={3}>
                {isOwner ? <div>
                  <Avatar
                    className={classes.avatarHover}
                    component={NavLink}
                    to={`/settings/photos`}
                    circular
                    src={tutorProfile.tutorPhotoURL || "/assets/user.png"}
                  />
                  <div className={classes.edit}><a href="#" ><i className={classes.editColor} class="fa fa-pencil fa-lg"></i></a></div>
                </div>
                  :
                  <div>
                    <Avatar
                      className={classes.avatar}
                      circular
                      src={tutorProfile.tutorPhotoURL || "/assets/user.png"}
                    />
                  </div>
                }
              </Grid>
              <Grid item xs={12} md={9} className={classes.componentsPosition}>
                <Typography>
                  {isOwner ? (
                    <Link
                      className={classes.createStyle}
                      to={`/manage/${tutorProfile.tutorUid}`}
                    >
                      <Create />
                    </Link>
                  )
                    :
                    (
                      ''
                      // <Link
                      //   className={classes.createStyle}
                      //   to={`/chat/${this.getChannelId(tutorProfile.tutorUid)}`}
                      // >
                      //   <img src="/assets/Message_non.png" className={classes.iconStyle2} />
                      // </Link>
                    )
                  }
                </Typography>

                <Grid container className={classes.container}>
                  <Grid item xs={12} md={8} className={classes.ResumeUserInformation1}>
                    <div className={classes.nameBottom}>
                      {tutorProfile.firstName && tutorProfile.lastName ? (
                        <h3 className={classes.nameStyle}>
                          {" "}
                          {tutorProfile.firstName} {tutorProfile.lastName}
                        </h3>

                      ) : (
                          <h3 className={classes.nameStyle}> {tutorProfile.displayName} </h3>
                        )}

                      {/* {!tutorProfile.badges &&
                    <span>
                      <span><Avatar className={classes.badgeStyle} src="/assets/badge.png" alt="badge" /></span>
                      <span><Avatar className={classes.badgeStyle} src="/assets/badge.png" alt="badge" /></span>
                      <span><Avatar className={classes.badgeStyle} src="/assets/badge.png" alt="badge" /></span>
                    </span>
                  } */}
                      {/* <span>
                    {badgeList && badgeList.map(badge => {
                      switch (true) {
                        case badge === "basic":
                          return <span>
                            <MuiThemeProvider theme={theme}>
                              <Tooltip
                                title="Basic Badge"
                              >
                                <Avatar
                                  className={classes.badgeStyle}
                                  src="/assets/Verified.png"
                                  alt="Basic Badge" />
                              </Tooltip>

                            </MuiThemeProvider>

                          </span>
                        case badge === "education":
                          return <span>
                            <MuiThemeProvider theme={theme}>
                              <Tooltip
                                title="Education Badge">
                                <Avatar
                                  className={classes.badgeStyle}
                                  src="/assets/Education.png"
                                  alt="Education Badge" />
                              </Tooltip>
                            </MuiThemeProvider>

                          </span>
                        case badge === "professional":
                          return <span>
                            <MuiThemeProvider theme={theme}>
                              <Tooltip
                                title="Professional Badge"
                              >
                                <Avatar className={classes.badgeStyle} src="/assets/Teacher.png" alt="Professional Badge" />
                              </Tooltip>
                            </MuiThemeProvider>
                          </span>
                        case badge === "null":
                          return <span>
                            <Avatar className={classes.badgeStyle} style={{ width: "18px" }} src="/assets/badge.png" alt="badge" /></span>

                      }
                    })}
                  </span> */}
                      {/* <button className={classes.openRecommandBadges} onClick={() => openModal('RecommendInformation')}>?</button> */}
                      {/* {tutorProfile.rating === 0 ?
                    <div style={{ color: "#f4c150", fontWeight: "bold", display: 'block', marginBottom: 5, marginTop: 5 }} >
                      Be the first one to write the review about {tutorProfile.firstName || tutorProfile.displayName}
                    </div>
                    :
                    <div style={{ marginBottom: 5, marginTop: 5 }}>
                      <StarRatings
                        rating={tutorProfile.rating}
                        starDimension="25px"
                        starSpacing="1px"
                        starRatedColor="#F7D220"
                      /> ({ratingWithLessDecimals})
                    </div>
                  } */}
                    </div>
                    <div style={{ fontSize: '1.3rem' }}>
                      {isOwner ?
                        <div style={{ marginBottom: 15 }}>
                          {/* <Icon name="clock" /> Last LoggedIn: {tutorProfile.date} | */}
                          <i className="material-icons" style={{ color: '#FF5D5D', display: 'inline', float: 'left', marginLeft: -4, marginRight: 10 }}>
                            place
                          </i>
                          {tutorProfile.formattedAddress} <p>(only city & state are visible)</p>
                        </div>
                        :
                        <div style={{ marginBottom: 15 }}>
                          {/* <Icon name="clock" /> Last LoggedIn: {tutorProfile.date} | */}
                          <i className="material-icons" style={{ color: '#FF5D5D', display: 'inline', float: 'left', marginLeft: -4, marginRight: 10 }}>
                            place
                          </i>
                          {tutorProfile.city}
                        </div>

                      }
                    </div>

                    {!isOwner && languageList.map((lang, i) => (
                      <span key={i}><button className={classes.langStyle}>{lang}</button>&nbsp;</span>

                    ))}

                    {isOwner && languageList.map((lang, i) => (
                      <span> <button key={i} className={classes.deleteIcon} >
                        <button
                          className={classes.xBtn}
                          onClick={(e) => handleRemoveLanguage(e, lang)}>x</button>
                        {/* <i
                      style={{
                        position: 'relative',
                        marginLeft: -19,
                        cursor: 'pointer',
                        fontSize: '18px',
                      }}
                      
                    >
                      clear
                      </i> */}
                        {lang}
                      </button>&nbsp;</span>

                    ))
                    }
                    {isOwner &&
                      <span style={{
                        display: 'inline',
                        float: 'right',
                        marginTop: -2
                      }}
                      ><Add
                          className={classes.addBtnStyle}
                          onClick={() => handleResumeUserLanguageClickOpen()}
                        />
                      </span>

                    }

                  </Grid>
                  {/* <Grid item xs={12} md={5} className={classes.ResumeUserInformation2}>

                {tutorProfile.educations && tutorProfile.educations.map((education, i) => (
                  <li className={classes.educationStyle} key={i}>
                    {education.where}
                  </li>
                ))}

              </Grid> */}
                </Grid>
              </Grid>
            </Grid>










            {/* <Segment clearing>
              {!isOwner && (
                <div>
                  {isGoing && (
                    <Button onClick={() => cancelGoingToEvent(tutorProfile)}>
                      Cancel My Place
                  </Button>
                  )}
                  {!isGoing && authenticated && (
                    <Button
                      loading={loading}
                      onClick={() => goingToEvent(tutorProfile)}
                      color="teal"
                    >
                      {" "}
                      JOIN THIS EVENT
                  </Button>
                  )}
                  {!authenticated && (
                    <Button
                      onClick={() => openModal('UnauthModal')}
                      loading={loading}
  
                      color="teal"
                    >
                      {" "}
                      JOIN THIS EVENT
                  </Button>
                  )}
                </div>
              )}
            </Segment> */}
          </CardContent>
        </Card>

      </React.Fragment>
    );
  }
}


export default withStyles(styles)(TutorProfileHeader);
