/**
 * @author Phil Teare
 * using wikipedia data
 */
export const langOptions = [
    {
        value: "English",
        label: "English",
        nativeName: "English",
        isFixed: true
    },
    {
        value: "French",
        label: "French",
        nativeName: "français, langue française"
    },
    {
        value: "Chinese(Mandarin)",
        label: "Chinese(Mandarin)",
        nativeName: "中文 (Zhōngwén), 粤语"
    },
    {
        value: "Chinese(Cantonese)",
        label: "Chinese(Cantonese)",
        nativeName: "中文 (Zhōngwén), 汉语"
    },
    {
        value: "Panjabi, Punjabi",
        label: "Panjabi, Punjabi",
        nativeName: "ਪੰਜਾਬੀ, پنجابی‎"
    },
    {
        value: "Hindi",
        label: "Hindi",
        nativeName: "हिन्दी, हिंदी"
    },
    {
        value: "Arabic",
        label: "Arabic",
        nativeName: "العربية"
    },
    {
        value: "Korean",
        label: "Korean",
        nativeName: "한국어 (韓國語), 조선말 (朝鮮語)"
    },
    {
        value: "Japanese",
        label: "Japanese",
        nativeName: "日本語 (にほんご／にっぽんご)"
    },
    {
        value: "Tagalog",
        label: "Tagalog",
        nativeName: "Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔"
    },
    {
        value: "Spanish; Castilian",
        label: "Spanish; Castilian",
        nativeName: "español, castellano"
    },
    {
        value: "German",
        label: "German",
        nativeName: "Deutsch"
    },
    {
        value: "Italian",
        label: "Italian",
        nativeName: "Italiano"
    },
    {
        value: "Russian",
        label: "Russian",
        nativeName: "русский язык"
    },
    {
        value: "Urdu",
        label: "Urdu",
        nativeName: "اردو"
    },
    {
        value: "Persian",
        label: "Persian",
        nativeName: "فارسی"
    },
    {
        value: "Bengali",
        label: "Bengali",
        nativeName: "বাংলা"
    },
    {
        value: "Portuguese",
        label: "Portuguese",
        nativeName: "Português"
    },
    {
        value: "Vietnamese",
        label: "Vietnamese",
        nativeName: "Tiếng Việt"
    },
    {
        value: "Javanese",
        label: "Javanese",
        nativeName: "basa Jawa"
    },
    {
        value: "Tamil",
        label: "Tamil",
        nativeName: "தமிழ்"
    },
    {
        value: "Telugu",
        label: "Telugu",
        nativeName: "తెలుగు"
    },
    {
        value: "Marathi (Marāṭhī)",
        label: "Marathi (Marāṭhī)",
        nativeName: "मराठी"
    }, 
    {
        value: "Turkish",
        label: "Turkish",
        nativeName: "Türkçe"
    },



    {
        value: "Abkhaz",
        label: "Abkhaz",
        nativeName: "аҧсуа"
    },
    {
        value: "Afar",
        label: "Afar",
        nativeName: "Afaraf"
    },
    {
        value: "Afrikaans",
        label: "Afrikaans",
        nativeName: "Afrikaans"
    },
    {
        value: "Akan",
        label: "Akan",
        nativeName: "Akan"
    },
    {
        value: "Albanian",
        label: "Albanian",
        nativeName: "Shqip"
    },
    {
        value: "Amharic",
        label: "Amharic",
        nativeName: "አማርኛ"
    },
    
    {
        value: "Aragonese",
        label: "Aragonese",
        nativeName: "Aragonés"
    },
    {
        value: "Armenian",
        label: "Armenian",
        nativeName: "Հայերեն"
    },
    {
        value: "Assamese",
        label: "Assamese",
        nativeName: "অসমীয়া"
    },
    {
        value: "Avaric",
        label: "Avaric",
        nativeName: "авар мацӀ, магӀарул мацӀ"
    },
    {
        value: "Avestan",
        label: "Avestan",
        nativeName: "avesta"
    },
    {
        value: "Aymara",
        label: "Aymara",
        nativeName: "aymar aru"
    },
    {
        value: "Azerbaijani",
        label: "Azerbaijani",
        nativeName: "azərbaycan dili"
    },
    {
        value: "Bambara",
        label: "Bambara",
        nativeName: "bamanankan"
    },
    {
        value: "Bashkir",
        label: "Bashkir",
        nativeName: "башҡорт теле"
    },
    {
        value: "Basque",
        label: "Basque",
        nativeName: "euskara, euskera"
    },
    {
        value: "Belarusian",
        label: "Belarusian",
        nativeName: "Беларуская"
    },
   
    {
        value: "Bihari",
        label: "Bihari",
        nativeName: "भोजपुरी"
    },
    {
        value: "Bislama",
        label: "Bislama",
        nativeName: "Bislama"
    },
    {
        value: "Bosnian",
        label: "Bosnian",
        nativeName: "bosanski jezik"
    },
    {
        value: "Breton",
        label: "Breton",
        nativeName: "brezhoneg"
    },
    {
        value: "Bulgarian",
        label: "Bulgarian",
        nativeName: "български език"
    },
    {
        value: "Burmese",
        label: "Burmese",
        nativeName: "ဗမာစာ"
    },
    {
        value: "Catalan; Valencian",
        label: "Catalan; Valencian",
        nativeName: "Català"
    },
    {
        value: "Chamorro",
        label: "Chamorro",
        nativeName: "Chamoru"
    },
    {
        value: "Chechen",
        label: "Chechen",
        nativeName: "нохчийн мотт"
    },
    {
        value: "Chichewa; Chewa; Nyanja",
        label: "Chichewa; Chewa; Nyanja",
        nativeName: "chiCheŵa, chinyanja"
    },
    
    {
        value: "Chuvash",
        label: "Chuvash",
        nativeName: "чӑваш чӗлхи"
    },
    {
        value: "Cornish",
        label: "Cornish",
        nativeName: "Kernewek"
    },
    {
        value: "Corsican",
        label: "Corsican",
        nativeName: "corsu, lingua corsa"
    },
    {
        value: "Cree",
        label: "Cree",
        nativeName: "ᓀᐦᐃᔭᐍᐏᐣ"
    },
    {
        value: "Croatian",
        label: "Croatian",
        nativeName: "hrvatski"
    },
    {
        value: "Czech",
        label: "Czech",
        nativeName: "česky, čeština"
    },
    {
        value: "Danish",
        label: "Danish",
        nativeName: "dansk"
    },
    {
        value: "Divehi; Dhivehi; Maldivian;",
        label: "Divehi; Dhivehi; Maldivian;",
        nativeName: "ދިވެހި"
    },
    {
        value: "Dutch",
        label: "Dutch",
        nativeName: "Nederlands, Vlaams"
    },
    
    {
        value: "Esperanto",
        label: "Esperanto",
        nativeName: "Esperanto"
    },
    {
        value: "Estonian",
        label: "Estonian",
        nativeName: "eesti, eesti keel"
    },
    {
        value: "Ewe",
        label: "Ewe",
        nativeName: "Eʋegbe"
    },
    {
        value: "Faroese",
        label: "Faroese",
        nativeName: "føroyskt"
    },
    {
        value: "Fijian",
        label: "Fijian",
        nativeName: "vosa Vakaviti"
    },
    {
        value: "Finnish",
        label: "Finnish",
        nativeName: "suomi, suomen kieli"
    },
    
    {
        value: "Fula; Fulah; Pulaar; Pular",
        label: "Fula; Fulah; Pulaar; Pular",
        nativeName: "Fulfulde, Pulaar, Pular"
    },
    {
        value: "Galician",
        label: "Galician",
        nativeName: "Galego"
    },
    {
        value: "Georgian",
        label: "Georgian",
        nativeName: "ქართული"
    },
    {
        value: "Greek, Modern",
        label: "Greek, Modern",
        nativeName: "Ελληνικά"
    },
    {
        value: "Guaraní",
        label: "Guaraní",
        nativeName: "Avañeẽ"
    },
    {
        value: "Gujarati",
        label: "Gujarati",
        nativeName: "ગુજરાતી"
    },
    {
        value: "Haitian; Haitian Creole",
        label: "Haitian; Haitian Creole",
        nativeName: "Kreyòl ayisyen"
    },
    {
        value: "Hausa",
        label: "Hausa",
        nativeName: "Hausa, هَوُسَ"
    },
    {
        value: "Hebrew (modern)",
        label: "Hebrew (modern)",
        nativeName: "עברית"
    },
    {
        value: "Herero",
        label: "Herero",
        nativeName: "Otjiherero"
    },
   
    {
        value: "Hiri Motu",
        label: "Hiri Motu",
        nativeName: "Hiri Motu"
    },
    {
        value: "Hungarian",
        label: "Hungarian",
        nativeName: "Magyar"
    },
    {
        value: "Interlingua",
        label: "Interlingua",
        nativeName: "Interlingua"
    },
    {
        value: "Indonesian",
        label: "Indonesian",
        nativeName: "Bahasa Indonesia"
    },
    {
        value: "Interlingue",
        label: "Interlingue",
        nativeName: "Originally called Occidental; then Interlingue after WWII"
    },
    {
        value: "Irish",
        label: "Irish",
        nativeName: "Gaeilge"
    },
    {
        value: "Igbo",
        label: "Igbo",
        nativeName: "Asụsụ Igbo"
    },
    {
        value: "Inupiaq",
        label: "Inupiaq",
        nativeName: "Iñupiaq, Iñupiatun"
    },
    {
        value: "Ido",
        label: "Ido",
        nativeName: "Ido"
    },
    {
        value: "Icelandic",
        label: "Icelandic",
        nativeName: "Íslenska"
    },
    
    {
        value: "Inuktitut",
        label: "Inuktitut",
        nativeName: "ᐃᓄᒃᑎᑐᑦ"
    },
    
    
    {
        value: "Kalaallisut, Greenlandic",
        label: "Kalaallisut, Greenlandic",
        nativeName: "kalaallisut, kalaallit oqaasii"
    },
    {
        value: "Kannada",
        label: "Kannada",
        nativeName: "ಕನ್ನಡ"
    },
    {
        value: "Kanuri",
        label: "Kanuri",
        nativeName: "Kanuri"
    },
    {
        value: "Kashmiri",
        label: "Kashmiri",
        nativeName: "कश्मीरी, كشميري‎"
    },
    {
        value: "Kazakh",
        label: "Kazakh",
        nativeName: "Қазақ тілі"
    },
    {
        value: "Khmer",
        label: "Khmer",
        nativeName: "ភាសាខ្មែរ"
    },
    {
        value: "Kikuyu, Gikuyu",
        label: "Kikuyu, Gikuyu",
        nativeName: "Gĩkũyũ"
    },
    {
        value: "Kinyarwanda",
        label: "Kinyarwanda",
        nativeName: "Ikinyarwanda"
    },
    {
        value: "Kirghiz, Kyrgyz",
        label: "Kirghiz, Kyrgyz",
        nativeName: "кыргыз тили"
    },
    {
        value: "Komi",
        label: "Komi",
        nativeName: "коми кыв"
    },
    {
        value: "Kongo",
        label: "Kongo",
        nativeName: "KiKongo"
    },
   
    {
        value: "Kurdish",
        label: "Kurdish",
        nativeName: "Kurdî, كوردی‎"
    },
    {
        value: "Kwanyama, Kuanyama",
        label: "Kwanyama, Kuanyama",
        nativeName: "Kuanyama"
    },
    {
        value: "Latin",
        label: "Latin",
        nativeName: "latine, lingua latina"
    },
    {
        value: "Luxembourgish, Letzeburgesch",
        label: "Luxembourgish, Letzeburgesch",
        nativeName: "Lëtzebuergesch"
    },
    {
        value: "Luganda",
        label: "Luganda",
        nativeName: "Luganda"
    },
    {
        value: "Limburgish, Limburgan, Limburger",
        label: "Limburgish, Limburgan, Limburger",
        nativeName: "Limburgs"
    },
    {
        value: "Lingala",
        label: "Lingala",
        nativeName: "Lingála"
    },
    {
        value: "Lao",
        label: "Lao",
        nativeName: "ພາສາລາວ"
    },
    {
        value: "Lithuanian",
        label: "Lithuanian",
        nativeName: "lietuvių kalba"
    },
    {
        value: "Luba-Katanga",
        label: "Luba-Katanga",
        nativeName: ""
    },
    {
        value: "Latvian",
        label: "Latvian",
        nativeName: "latviešu valoda"
    },
    {
        value: "Manx",
        label: "Manx",
        nativeName: "Gaelg, Gailck"
    },
    {
        value: "Macedonian",
        label: "Macedonian",
        nativeName: "македонски јазик"
    },
    {
        value: "Malagasy",
        label: "Malagasy",
        nativeName: "Malagasy fiteny"
    },
    {
        value: "Malay",
        label: "Malay",
        nativeName: "bahasa Melayu, بهاس ملايو‎"
    },
    {
        value: "Malayalam",
        label: "Malayalam",
        nativeName: "മലയാളം"
    },
    {
        value: "Maltese",
        label: "Maltese",
        nativeName: "Malti"
    },
    {
        value: "Māori",
        label: "Māori",
        nativeName: "te reo Māori"
    },
    
    {
        value: "Marshallese",
        label: "Marshallese",
        nativeName: "Kajin M̧ajeļ"
    },
    {
        value: "Mongolian",
        label: "Mongolian",
        nativeName: "монгол"
    },
    {
        value: "Nauru",
        label: "Nauru",
        nativeName: "Ekakairũ Naoero"
    },
    {
        value: "Navajo, Navaho",
        label: "Navajo, Navaho",
        nativeName: "Diné bizaad, Dinékʼehǰí"
    },
    {
        value: "Norwegian Bokmål",
        label: "Norwegian Bokmål",
        nativeName: "Norsk bokmål"
    },
    {
        value: "North Ndebele",
        label: "North Ndebele",
        nativeName: "isiNdebele"
    },
    {
        value: "Nepali",
        label: "Nepali",
        nativeName: "नेपाली"
    },
    {
        value: "Ndonga",
        label: "Ndonga",
        nativeName: "Owambo"
    },
    {
        value: "Norwegian Nynorsk",
        label: "Norwegian Nynorsk",
        nativeName: "Norsk nynorsk"
    },
    {
        value: "Norwegian",
        label: "Norwegian",
        nativeName: "Norsk"
    },
    {
        value: "Nuosu",
        label: "Nuosu",
        nativeName: "ꆈꌠ꒿ Nuosuhxop"
    },
    {
        value: "South Ndebele",
        label: "South Ndebele",
        nativeName: "isiNdebele"
    },
    {
        value: "Occitan",
        label: "Occitan",
        nativeName: "Occitan"
    },
    {
        value: "Ojibwe, Ojibwa",
        label: "Ojibwe, Ojibwa",
        nativeName: "ᐊᓂᔑᓈᐯᒧᐎᓐ"
    },
    // {
    //     value: "Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic",
    //     label: "Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic",
    //     nativeName: "ѩзыкъ словѣньскъ"
    // },
    {
        value: "Oromo",
        label: "Oromo",
        nativeName: "Afaan Oromoo"
    },
    {
        value: "Oriya",
        label: "Oriya",
        nativeName: "ଓଡ଼ିଆ"
    },
    {
        value: "Ossetian, Ossetic",
        label: "Ossetian, Ossetic",
        nativeName: "ирон æвзаг"
    },
    {
        value: "Pāli",
        label: "Pāli",
        nativeName: "पाऴि"
    },
   
    {
        value: "Polish",
        label: "Polish",
        nativeName: "polski"
    },
    {
        value: "Pashto, Pushto",
        label: "Pashto, Pushto",
        nativeName: "پښتو"
    },
    
    {
        value: "Quechua",
        label: "Quechua",
        nativeName: "Runa Simi, Kichwa"
    },
    {
        value: "Romansh",
        label: "Romansh",
        nativeName: "rumantsch grischun"
    },
    {
        value: "Kirundi",
        label: "Kirundi",
        nativeName: "kiRundi"
    },
    {
        value: "Romanian, Moldavian, Moldovan",
        label: "Romanian, Moldavian, Moldovan",
        nativeName: "română"
    },
    
    {
        value: "Sanskrit (Saṁskṛta)",
        label: "Sanskrit (Saṁskṛta)",
        nativeName: "संस्कृतम्"
    },
    {
        value: "Sardinian",
        label: "Sardinian",
        nativeName: "sardu"
    },
    {
        value: "Sindhi",
        label: "Sindhi",
        nativeName: "सिन्धी, سنڌي، سندھی‎"
    },
    {
        value: "Northern Sami",
        label: "Northern Sami",
        nativeName: "Davvisámegiella"
    },
    {
        value: "Samoan",
        label: "Samoan",
        nativeName: "gagana faa Samoa"
    },
    {
        value: "Sango",
        label: "Sango",
        nativeName: "yângâ tî sängö"
    },
    {
        value: "Serbian",
        label: "Serbian",
        nativeName: "српски језик"
    },
    {
        value: "Scottish Gaelic; Gaelic",
        label: "Scottish Gaelic; Gaelic",
        nativeName: "Gàidhlig"
    },
    {
        value: "Shona",
        label: "Shona",
        nativeName: "chiShona"
    },
    {
        value: "Sinhala, Sinhalese",
        label: "Sinhala, Sinhalese",
        nativeName: "සිංහල"
    },
    {
        value: "Slovak",
        label: "Slovak",
        nativeName: "slovenčina"
    },
    {
        value: "Slovene",
        label: "Slovene",
        nativeName: "slovenščina"
    },
    {
        value: "Somali",
        label: "Somali",
        nativeName: "Soomaaliga, af Soomaali"
    },
    {
        value: "Southern Sotho",
        label: "Southern Sotho",
        nativeName: "Sesotho"
    },
    
    {
        value: "Sundanese",
        label: "Sundanese",
        nativeName: "Basa Sunda"
    },
    {
        value: "Swahili",
        label: "Swahili",
        nativeName: "Kiswahili"
    },
    {
        value: "Swati",
        label: "Swati",
        nativeName: "SiSwati"
    },
    {
        value: "Swedish",
        label: "Swedish",
        nativeName: "svenska"
    },
   
    {
        value: "Tajik",
        label: "Tajik",
        nativeName: "тоҷикӣ, toğikī, تاجیکی‎"
    },
    {
        value: "Thai",
        label: "Thai",
        nativeName: "ไทย"
    },
    {
        value: "Tigrinya",
        label: "Tigrinya",
        nativeName: "ትግርኛ"
    },
    {
        value: "Tibetan Standard, Tibetan, Central",
        label: "Tibetan Standard, Tibetan, Central",
        nativeName: "བོད་ཡིག"
    },
    {
        value: "Turkmen",
        label: "Turkmen",
        nativeName: "Türkmen, Түркмен"
    },
   
    {
        value: "Tswana",
        label: "Tswana",
        nativeName: "Setswana"
    },
    {
        value: "Tonga (Tonga Islands)",
        label: "Tonga (Tonga Islands)",
        nativeName: "faka Tonga"
    },
   
    {
        value: "Tsonga",
        label: "Tsonga",
        nativeName: "Xitsonga"
    },
    {
        value: "Tatar",
        label: "Tatar",
        nativeName: "татарча, tatarça, تاتارچا‎"
    },
    {
        value: "Twi",
        label: "Twi",
        nativeName: "Twi"
    },
    {
        value: "Tahitian",
        label: "Tahitian",
        nativeName: "Reo Tahiti"
    },
    {
        value: "Uighur, Uyghur",
        label: "Uighur, Uyghur",
        nativeName: "Uyƣurqə, ئۇيغۇرچە‎"
    },
    {
        value: "Ukrainian",
        label: "Ukrainian",
        nativeName: "українська"
    },
    
    {
        value: "Uzbek",
        label: "Uzbek",
        nativeName: "zbek, Ўзбек, أۇزبېك‎"
    },
    {
        value: "Venda",
        label: "Venda",
        nativeName: "Tshivenḓa"
    },
    
    {
        value: "Volapük",
        label: "Volapük",
        nativeName: "Volapük"
    },
    {
        value: "Walloon",
        label: "Walloon",
        nativeName: "Walon"
    },
    {
        value: "Welsh",
        label: "Welsh",
        nativeName: "Cymraeg"
    },
    {
        value: "Wolof",
        label: "Wolof",
        nativeName: "Wollof"
    },
    {
        value: "Western Frisian",
        label: "Western Frisian",
        nativeName: "Frysk"
    },
    {
        value: "Xhosa",
        label: "Xhosa",
        nativeName: "isiXhosa"
    },
    {
        value: "Yiddish",
        label: "Yiddish",
        nativeName: "ייִדיש"
    },
    {
        value: "Yoruba",
        label: "Yoruba",
        nativeName: "Yorùbá"
    },
    {
        value: "Zhuang, Chuang",
        label: "Zhuang, Chuang",
        nativeName: "Saɯ cueŋƅ, Saw cuengh"
    }
]

// export const lang = {
//     "ab":{
//         "name": "Abkhaz",
//         "nativeName": "аҧсуа"
//     },
//     "aa":{
//         "name":"Afar",
//         "nativeName":"Afaraf"
//     },
//     "af":{
//         "name":"Afrikaans",
//         "nativeName":"Afrikaans"
//     },
//     "ak":{
//         "name":"Akan",
//         "nativeName":"Akan"
//     },
//     "sq":{
//         "name":"Albanian",
//         "nativeName":"Shqip"
//     },
//     "am":{
//         "name":"Amharic",
//         "nativeName":"አማርኛ"
//     },
//     "ar":{
//         "name":"Arabic",
//         "nativeName":"العربية"
//     },
//     "an":{
//         "name":"Aragonese",
//         "nativeName":"Aragonés"
//     },
//     "hy":{
//         "name":"Armenian",
//         "nativeName":"Հայերեն"
//     },
//     "as":{
//         "name":"Assamese",
//         "nativeName":"অসমীয়া"
//     },
//     "av":{
//         "name":"Avaric",
//         "nativeName":"авар мацӀ, магӀарул мацӀ"
//     },
//     "ae":{
//         "name":"Avestan",
//         "nativeName":"avesta"
//     },
//     "ay":{
//         "name":"Aymara",
//         "nativeName":"aymar aru"
//     },
//     "az":{
//         "name":"Azerbaijani",
//         "nativeName":"azərbaycan dili"
//     },
//     "bm":{
//         "name":"Bambara",
//         "nativeName":"bamanankan"
//     },
//     "ba":{
//         "name":"Bashkir",
//         "nativeName":"башҡорт теле"
//     },
//     "eu":{
//         "name":"Basque",
//         "nativeName":"euskara, euskera"
//     },
//     "be":{
//         "name":"Belarusian",
//         "nativeName":"Беларуская"
//     },
//     "bn":{
//         "name":"Bengali",
//         "nativeName":"বাংলা"
//     },
//     "bh":{
//         "name":"Bihari",
//         "nativeName":"भोजपुरी"
//     },
//     "bi":{
//         "name":"Bislama",
//         "nativeName":"Bislama"
//     },
//     "bs":{
//         "name":"Bosnian",
//         "nativeName":"bosanski jezik"
//     },
//     "br":{
//         "name":"Breton",
//         "nativeName":"brezhoneg"
//     },
//     "bg":{
//         "name":"Bulgarian",
//         "nativeName":"български език"
//     },
//     "my":{
//         "name":"Burmese",
//         "nativeName":"ဗမာစာ"
//     },
//     "ca":{
//         "name":"Catalan; Valencian",
//         "nativeName":"Català"
//     },
//     "ch":{
//         "name":"Chamorro",
//         "nativeName":"Chamoru"
//     },
//     "ce":{
//         "name":"Chechen",
//         "nativeName":"нохчийн мотт"
//     },
//     "ny":{
//         "name":"Chichewa; Chewa; Nyanja",
//         "nativeName":"chiCheŵa, chinyanja"
//     },
//     "zh":{
//         "name":"Chinese",
//         "nativeName":"中文 (Zhōngwén), 汉语, 漢語"
//     },
//     "cv":{
//         "name":"Chuvash",
//         "nativeName":"чӑваш чӗлхи"
//     },
//     "kw":{
//         "name":"Cornish",
//         "nativeName":"Kernewek"
//     },
//     "co":{
//         "name":"Corsican",
//         "nativeName":"corsu, lingua corsa"
//     },
//     "cr":{
//         "name":"Cree",
//         "nativeName":"ᓀᐦᐃᔭᐍᐏᐣ"
//     },
//     "hr":{
//         "name":"Croatian",
//         "nativeName":"hrvatski"
//     },
//     "cs":{
//         "name":"Czech",
//         "nativeName":"česky, čeština"
//     },
//     "da":{
//         "name":"Danish",
//         "nativeName":"dansk"
//     },
//     "dv":{
//         "name":"Divehi; Dhivehi; Maldivian;",
//         "nativeName":"ދިވެހި"
//     },
//     "nl":{
//         "name":"Dutch",
//         "nativeName":"Nederlands, Vlaams"
//     },
//     "en":{
//         "name":"English",
//         "nativeName":"English"
//     },
//     "eo":{
//         "name":"Esperanto",
//         "nativeName":"Esperanto"
//     },
//     "et":{
//         "name":"Estonian",
//         "nativeName":"eesti, eesti keel"
//     },
//     "ee":{
//         "name":"Ewe",
//         "nativeName":"Eʋegbe"
//     },
//     "fo":{
//         "name":"Faroese",
//         "nativeName":"føroyskt"
//     },
//     "fj":{
//         "name":"Fijian",
//         "nativeName":"vosa Vakaviti"
//     },
//     "fi":{
//         "name":"Finnish",
//         "nativeName":"suomi, suomen kieli"
//     },
//     "fr":{
//         "name":"French",
//         "nativeName":"français, langue française"
//     },
//     "ff":{
//         "name":"Fula; Fulah; Pulaar; Pular",
//         "nativeName":"Fulfulde, Pulaar, Pular"
//     },
//     "gl":{
//         "name":"Galician",
//         "nativeName":"Galego"
//     },
//     "ka":{
//         "name":"Georgian",
//         "nativeName":"ქართული"
//     },
//     "de":{
//         "name":"German",
//         "nativeName":"Deutsch"
//     },
//     "el":{
//         "name":"Greek, Modern",
//         "nativeName":"Ελληνικά"
//     },
//     "gn":{
//         "name":"Guaraní",
//         "nativeName":"Avañeẽ"
//     },
//     "gu":{
//         "name":"Gujarati",
//         "nativeName":"ગુજરાતી"
//     },
//     "ht":{
//         "name":"Haitian; Haitian Creole",
//         "nativeName":"Kreyòl ayisyen"
//     },
//     "ha":{
//         "name":"Hausa",
//         "nativeName":"Hausa, هَوُسَ"
//     },
//     "he":{
//         "name":"Hebrew (modern)",
//         "nativeName":"עברית"
//     },
//     "hz":{
//         "name":"Herero",
//         "nativeName":"Otjiherero"
//     },
//     "hi":{
//         "name":"Hindi",
//         "nativeName":"हिन्दी, हिंदी"
//     },
//     "ho":{
//         "name":"Hiri Motu",
//         "nativeName":"Hiri Motu"
//     },
//     "hu":{
//         "name":"Hungarian",
//         "nativeName":"Magyar"
//     },
//     "ia":{
//         "name":"Interlingua",
//         "nativeName":"Interlingua"
//     },
//     "id":{
//         "name":"Indonesian",
//         "nativeName":"Bahasa Indonesia"
//     },
//     "ie":{
//         "name":"Interlingue",
//         "nativeName":"Originally called Occidental; then Interlingue after WWII"
//     },
//     "ga":{
//         "name":"Irish",
//         "nativeName":"Gaeilge"
//     },
//     "ig":{
//         "name":"Igbo",
//         "nativeName":"Asụsụ Igbo"
//     },
//     "ik":{
//         "name":"Inupiaq",
//         "nativeName":"Iñupiaq, Iñupiatun"
//     },
//     "io":{
//         "name":"Ido",
//         "nativeName":"Ido"
//     },
//     "is":{
//         "name":"Icelandic",
//         "nativeName":"Íslenska"
//     },
//     "it":{
//         "name":"Italian",
//         "nativeName":"Italiano"
//     },
//     "iu":{
//         "name":"Inuktitut",
//         "nativeName":"ᐃᓄᒃᑎᑐᑦ"
//     },
//     "ja":{
//         "name":"Japanese",
//         "nativeName":"日本語 (にほんご／にっぽんご)"
//     },
//     "jv":{
//         "name":"Javanese",
//         "nativeName":"basa Jawa"
//     },
//     "kl":{
//         "name":"Kalaallisut, Greenlandic",
//         "nativeName":"kalaallisut, kalaallit oqaasii"
//     },
//     "kn":{
//         "name":"Kannada",
//         "nativeName":"ಕನ್ನಡ"
//     },
//     "kr":{
//         "name":"Kanuri",
//         "nativeName":"Kanuri"
//     },
//     "ks":{
//         "name":"Kashmiri",
//         "nativeName":"कश्मीरी, كشميري‎"
//     },
//     "kk":{
//         "name":"Kazakh",
//         "nativeName":"Қазақ тілі"
//     },
//     "km":{
//         "name":"Khmer",
//         "nativeName":"ភាសាខ្មែរ"
//     },
//     "ki":{
//         "name":"Kikuyu, Gikuyu",
//         "nativeName":"Gĩkũyũ"
//     },
//     "rw":{
//         "name":"Kinyarwanda",
//         "nativeName":"Ikinyarwanda"
//     },
//     "ky":{
//         "name":"Kirghiz, Kyrgyz",
//         "nativeName":"кыргыз тили"
//     },
//     "kv":{
//         "name":"Komi",
//         "nativeName":"коми кыв"
//     },
//     "kg":{
//         "name":"Kongo",
//         "nativeName":"KiKongo"
//     },
//     "ko":{
//         "name":"Korean",
//         "nativeName":"한국어 (韓國語), 조선말 (朝鮮語)"
//     },
//     "ku":{
//         "name":"Kurdish",
//         "nativeName":"Kurdî, كوردی‎"
//     },
//     "kj":{
//         "name":"Kwanyama, Kuanyama",
//         "nativeName":"Kuanyama"
//     },
//     "la":{
//         "name":"Latin",
//         "nativeName":"latine, lingua latina"
//     },
//     "lb":{
//         "name":"Luxembourgish, Letzeburgesch",
//         "nativeName":"Lëtzebuergesch"
//     },
//     "lg":{
//         "name":"Luganda",
//         "nativeName":"Luganda"
//     },
//     "li":{
//         "name":"Limburgish, Limburgan, Limburger",
//         "nativeName":"Limburgs"
//     },
//     "ln":{
//         "name":"Lingala",
//         "nativeName":"Lingála"
//     },
//     "lo":{
//         "name":"Lao",
//         "nativeName":"ພາສາລາວ"
//     },
//     "lt":{
//         "name":"Lithuanian",
//         "nativeName":"lietuvių kalba"
//     },
//     "lu":{
//         "name":"Luba-Katanga",
//         "nativeName":""
//     },
//     "lv":{
//         "name":"Latvian",
//         "nativeName":"latviešu valoda"
//     },
//     "gv":{
//         "name":"Manx",
//         "nativeName":"Gaelg, Gailck"
//     },
//     "mk":{
//         "name":"Macedonian",
//         "nativeName":"македонски јазик"
//     },
//     "mg":{
//         "name":"Malagasy",
//         "nativeName":"Malagasy fiteny"
//     },
//     "ms":{
//         "name":"Malay",
//         "nativeName":"bahasa Melayu, بهاس ملايو‎"
//     },
//     "ml":{
//         "name":"Malayalam",
//         "nativeName":"മലയാളം"
//     },
//     "mt":{
//         "name":"Maltese",
//         "nativeName":"Malti"
//     },
//     "mi":{
//         "name":"Māori",
//         "nativeName":"te reo Māori"
//     },
//     "mr":{
//         "name":"Marathi (Marāṭhī)",
//         "nativeName":"मराठी"
//     },
//     "mh":{
//         "name":"Marshallese",
//         "nativeName":"Kajin M̧ajeļ"
//     },
//     "mn":{
//         "name":"Mongolian",
//         "nativeName":"монгол"
//     },
//     "na":{
//         "name":"Nauru",
//         "nativeName":"Ekakairũ Naoero"
//     },
//     "nv":{
//         "name":"Navajo, Navaho",
//         "nativeName":"Diné bizaad, Dinékʼehǰí"
//     },
//     "nb":{
//         "name":"Norwegian Bokmål",
//         "nativeName":"Norsk bokmål"
//     },
//     "nd":{
//         "name":"North Ndebele",
//         "nativeName":"isiNdebele"
//     },
//     "ne":{
//         "name":"Nepali",
//         "nativeName":"नेपाली"
//     },
//     "ng":{
//         "name":"Ndonga",
//         "nativeName":"Owambo"
//     },
//     "nn":{
//         "name":"Norwegian Nynorsk",
//         "nativeName":"Norsk nynorsk"
//     },
//     "no":{
//         "name":"Norwegian",
//         "nativeName":"Norsk"
//     },
//     "ii":{
//         "name":"Nuosu",
//         "nativeName":"ꆈꌠ꒿ Nuosuhxop"
//     },
//     "nr":{
//         "name":"South Ndebele",
//         "nativeName":"isiNdebele"
//     },
//     "oc":{
//         "name":"Occitan",
//         "nativeName":"Occitan"
//     },
//     "oj":{
//         "name":"Ojibwe, Ojibwa",
//         "nativeName":"ᐊᓂᔑᓈᐯᒧᐎᓐ"
//     },
//     "cu":{
//         "name":"Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic",
//         "nativeName":"ѩзыкъ словѣньскъ"
//     },
//     "om":{
//         "name":"Oromo",
//         "nativeName":"Afaan Oromoo"
//     },
//     "or":{
//         "name":"Oriya",
//         "nativeName":"ଓଡ଼ିଆ"
//     },
//     "os":{
//         "name":"Ossetian, Ossetic",
//         "nativeName":"ирон æвзаг"
//     },
//     "pa":{
//         "name":"Panjabi, Punjabi",
//         "nativeName":"ਪੰਜਾਬੀ, پنجابی‎"
//     },
//     "pi":{
//         "name":"Pāli",
//         "nativeName":"पाऴि"
//     },
//     "fa":{
//         "name":"Persian",
//         "nativeName":"فارسی"
//     },
//     "pl":{
//         "name":"Polish",
//         "nativeName":"polski"
//     },
//     "ps":{
//         "name":"Pashto, Pushto",
//         "nativeName":"پښتو"
//     },
//     "pt":{
//         "name":"Portuguese",
//         "nativeName":"Português"
//     },
//     "qu":{
//         "name":"Quechua",
//         "nativeName":"Runa Simi, Kichwa"
//     },
//     "rm":{
//         "name":"Romansh",
//         "nativeName":"rumantsch grischun"
//     },
//     "rn":{
//         "name":"Kirundi",
//         "nativeName":"kiRundi"
//     },
//     "ro":{
//         "name":"Romanian, Moldavian, Moldovan",
//         "nativeName":"română"
//     },
//     "ru":{
//         "name":"Russian",
//         "nativeName":"русский язык"
//     },
//     "sa":{
//         "name":"Sanskrit (Saṁskṛta)",
//         "nativeName":"संस्कृतम्"
//     },
//     "sc":{
//         "name":"Sardinian",
//         "nativeName":"sardu"
//     },
//     "sd":{
//         "name":"Sindhi",
//         "nativeName":"सिन्धी, سنڌي، سندھی‎"
//     },
//     "se":{
//         "name":"Northern Sami",
//         "nativeName":"Davvisámegiella"
//     },
//     "sm":{
//         "name":"Samoan",
//         "nativeName":"gagana faa Samoa"
//     },
//     "sg":{
//         "name":"Sango",
//         "nativeName":"yângâ tî sängö"
//     },
//     "sr":{
//         "name":"Serbian",
//         "nativeName":"српски језик"
//     },
//     "gd":{
//         "name":"Scottish Gaelic; Gaelic",
//         "nativeName":"Gàidhlig"
//     },
//     "sn":{
//         "name":"Shona",
//         "nativeName":"chiShona"
//     },
//     "si":{
//         "name":"Sinhala, Sinhalese",
//         "nativeName":"සිංහල"
//     },
//     "sk":{
//         "name":"Slovak",
//         "nativeName":"slovenčina"
//     },
//     "sl":{
//         "name":"Slovene",
//         "nativeName":"slovenščina"
//     },
//     "so":{
//         "name":"Somali",
//         "nativeName":"Soomaaliga, af Soomaali"
//     },
//     "st":{
//         "name":"Southern Sotho",
//         "nativeName":"Sesotho"
//     },
//     "es":{
//         "name":"Spanish; Castilian",
//         "nativeName":"español, castellano"
//     },
//     "su":{
//         "name":"Sundanese",
//         "nativeName":"Basa Sunda"
//     },
//     "sw":{
//         "name":"Swahili",
//         "nativeName":"Kiswahili"
//     },
//     "ss":{
//         "name":"Swati",
//         "nativeName":"SiSwati"
//     },
//     "sv":{
//         "name":"Swedish",
//         "nativeName":"svenska"
//     },
//     "ta":{
//         "name":"Tamil",
//         "nativeName":"தமிழ்"
//     },
//     "te":{
//         "name":"Telugu",
//         "nativeName":"తెలుగు"
//     },
//     "tg":{
//         "name":"Tajik",
//         "nativeName":"тоҷикӣ, toğikī, تاجیکی‎"
//     },
//     "th":{
//         "name":"Thai",
//         "nativeName":"ไทย"
//     },
//     "ti":{
//         "name":"Tigrinya",
//         "nativeName":"ትግርኛ"
//     },
//     "bo":{
//         "name":"Tibetan Standard, Tibetan, Central",
//         "nativeName":"བོད་ཡིག"
//     },
//     "tk":{
//         "name":"Turkmen",
//         "nativeName":"Türkmen, Түркмен"
//     },
//     "tl":{
//         "name":"Tagalog",
//         "nativeName":"Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔"
//     },
//     "tn":{
//         "name":"Tswana",
//         "nativeName":"Setswana"
//     },
//     "to":{
//         "name":"Tonga (Tonga Islands)",
//         "nativeName":"faka Tonga"
//     },
//     "tr":{
//         "name":"Turkish",
//         "nativeName":"Türkçe"
//     },
//     "ts":{
//         "name":"Tsonga",
//         "nativeName":"Xitsonga"
//     },
//     "tt":{
//         "name":"Tatar",
//         "nativeName":"татарча, tatarça, تاتارچا‎"
//     },
//     "tw":{
//         "name":"Twi",
//         "nativeName":"Twi"
//     },
//     "ty":{
//         "name":"Tahitian",
//         "nativeName":"Reo Tahiti"
//     },
//     "ug":{
//         "name":"Uighur, Uyghur",
//         "nativeName":"Uyƣurqə, ئۇيغۇرچە‎"
//     },
//     "uk":{
//         "name":"Ukrainian",
//         "nativeName":"українська"
//     },
//     "ur":{
//         "name":"Urdu",
//         "nativeName":"اردو"
//     },
//     "uz":{
//         "name":"Uzbek",
//         "nativeName":"zbek, Ўзбек, أۇزبېك‎"
//     },
//     "ve":{
//         "name":"Venda",
//         "nativeName":"Tshivenḓa"
//     },
//     "vi":{
//         "name":"Vietnamese",
//         "nativeName":"Tiếng Việt"
//     },
//     "vo":{
//         "name":"Volapük",
//         "nativeName":"Volapük"
//     },
//     "wa":{
//         "name":"Walloon",
//         "nativeName":"Walon"
//     },
//     "cy":{
//         "name":"Welsh",
//         "nativeName":"Cymraeg"
//     },
//     "wo":{
//         "name":"Wolof",
//         "nativeName":"Wollof"
//     },
//     "fy":{
//         "name":"Western Frisian",
//         "nativeName":"Frysk"
//     },
//     "xh":{
//         "name":"Xhosa",
//         "nativeName":"isiXhosa"
//     },
//     "yi":{
//         "name":"Yiddish",
//         "nativeName":"ייִדיש"
//     },
//     "yo":{
//         "name":"Yoruba",
//         "nativeName":"Yorùbá"
//     },
//     "za":{
//         "name":"Zhuang, Chuang",
//         "nativeName":"Saɯ cueŋƅ, Saw cuengh"
//     }
// }