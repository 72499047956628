import React from 'react'
import { connect } from 'react-redux'
import { Grid } from '@material-ui/core';
import StudentDetailedHeader from './StudentDetailedHeader';
import StudentDetailedChat from './StudentDetailedChat';
import StudentDetailedInfo from './StudentDetailedInfo';
import StudentDetailedSidebar from './StudentDetailedSidebar';

const mapState = (state, ownProps) => {
    const studentId = ownProps.match.params.id;

    let student = {};

    if (studentId && state.students.length > 0){
        student = state.students.filter(student => student.id === studentId)[0];
    }

    return {
        student
    }
}

const StudentDetailedPage = ({student}) => {
    return (  
        <div style={{ marginTop: 100 }}>
            <Grid container>
                <Grid item md={8}>
                    <StudentDetailedHeader student={student} />
                    <StudentDetailedInfo student={student}/>
                    <StudentDetailedChat />
                </Grid>

                <Grid item md={4}>
                    <StudentDetailedSidebar />
                </Grid>
            </Grid>
        </div>
    )
}

export default connect(mapState)(StudentDetailedPage);