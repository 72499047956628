import firebase from 'firebase/app'
import _ from 'lodash'
import { toastr } from "react-redux-toastr";
import { objectToArray } from '../../../app/common/util/helpers';

export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST'
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS'
export const GET_PROFILE_ERROR = 'GET_PROFILE_ERROR'

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST'
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS'
export const UPDATE_PROFILE_ERROR = 'UPDATE_PROFILE_ERROR'

export const UPDATE_PROFILE_FIELDS_REQUEST = 'UPDATE_PROFILE_FIELDS_REQUEST'
export const UPDATE_PROFILE_FIELDS_SUCCESS = 'UPDATE_PROFILE_FIELDS_SUCCESS'
export const UPDATE_PROFILE_FIELDS_ERROR = 'UPDATE_PROFILE_FIELDS_ERROR'

export const UPDATE_PROFILE_EDUCATION_REQUEST = 'UPDATE_PROFILE_EDUCATION_REQUEST'
export const UPDATE_PROFILE_EDUCATION_SUCCESS = 'UPDATE_PROFILE_EDUCATION_SUCCESS'
export const UPDATE_PROFILE_EDUCATION_ERROR = 'UPDATE_PROFILE_EDUCATION_ERROR'

export const UPDATE_PROFILE_EXPERIENCE_REQUEST = 'UPDATE_PROFILE_EXPERIENCE_REQUEST'
export const UPDATE_PROFILE_EXPERIENCE_SUCCESS = 'UPDATE_PROFILE_EXPERIENCE_SUCCESS'
export const UPDATE_PROFILE_EXPERIENCE_ERROR = 'UPDATE_PROFILE_EXPERIENCE_ERROR'

export const CREATE_PROFILE_REQUEST = 'CREATE_PROFILE_REQUEST'
export const CREATE_PROFILE_SUCCESS = 'CREATE_PROFILE_SUCCESS'
export const CREATE_PROFILE_ERROR = 'CREATE_PROFILE_ERROR'

export const REMOVE_PROFILE_EDUCATION_REQUEST = 'REMOVE_PROFILE_EDUCATION_REQUEST'
export const REMOVE_PROFILE_EDUCATION_SUCCESS = 'REMOVE_PROFILE_EDUCATION_SUCCESS'
export const REMOVE_PROFILE_EDUCATION_ERROR = 'REMOVE_PROFILE_EDUCATION_ERROR'

export const REMOVE_PROFILE_EXPERIENCE_REQUEST = 'REMOVE_PROFILE_EXPERIENCE_REQUEST'
export const REMOVE_PROFILE_EXPERIENCE_SUCCESS = 'REMOVE_PROFILE_EXPERIENCE_SUCCESS'
export const REMOVE_PROFILE_EXPERIENCE_ERROR = 'REMOVE_PROFILE_EXPERIENCE_ERROR'

export const UPDATE_PROFILE_SUBJECT_REQUEST = 'UPDATE_PROFILE_SUBJECT_REQUEST'
export const UPDATE_PROFILE_SUBJECT_SUCCESS = 'UPDATE_PROFILE_SUBJECT_SUCCESS'
export const UPDATE_PROFILE_SUBJECT_ERROR = 'UPDATE_PROFILE_SUBJECT_ERROR'

export const UPDATE_PROFILE_DESCRIPTION_REQUEST = 'UPDATE_PROFILE_DESCRIPTION_REQUEST'

export const REMOVE_PROFILE_SUBJECT_REQUEST = 'REMOVE_PROFILE_SUBJECT_REQUEST'
export const REMOVE_PROFILE_SUBJECT_SUCCESS = 'REMOVE_PROFILE_SUBJECT_SUCCESS'
export const REMOVE_PROFILE_SUBJECT_ERROR = 'REMOVE_PROFILE_SUBJECT_ERROR'

export const UPDATE_RESUME_USER_INFO_REQUEST = 'UPDATE_RESUME_USER_INFO_REQUEST'
export const UPDATE_RESUME_USER_INFO_SUCCESS = 'UPDATE_RESUME_USER_INFO_SUCCESS'
export const UPDATE_RESUME_USER_INFO_ERROR = 'UPDATE_RESUME_USER_INFO_ERROR'

export const CREATE_REVIEW_REQUEST = 'CREATE_REVIEW_REQUEST'
export const CREATE_REVIEW_SUCCESS = 'CREATE_REVIEW_SUCCESS'
export const CREATE_REVIEW_ERROR = 'CREATE_REVIEW_ERROR'

export const GET_REVIEWS_REQUEST = 'GET_REVIEWS_REQUEST'
export const GET_REVIEWS_SUCCESS = 'GET_REVIEWS_SUCCESS'
export const GET_REVIEWS_ERROR = 'GET_REVIEWS_ERROR'

export const PROFILE_UPLOAD_IMAGE_SUCCESS = 'PROFILE_UPLOAD_IMAGE_SUCCESS'
export const PROFILE_UPLOAD_IMAGE_ERROR = 'PROFILE_UPLOAD_IMAGE_ERROR'

export const PROFILE_BY_UID = 'PROFILE_BY_UID'

export const SET_EMPTY_PROFILE = "SET_EMPTY_PROFILE"

export const createProfileRequest = (payload, showSuccess, showError, history) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({ type: CREATE_PROFILE_REQUEST });

        const uid = getFirebase().auth().currentUser.uid
        const state = getState()
        const firestore = getFirestore()
        const batch = firestore.batch()
        const {
            phoneNumber,
            educations,
            languages,
            subjects,
            content,
            lat,
            lng,
            addressComponents,
            locationName,
            formattedAddress,
            placeId,
            icon,
            vicinity,
            utc_offset,
            url,
        } = payload
        const location = new firebase.firestore.GeoPoint(
            lat,
            lng,
        )

        // convert an array of objects to hash objects
        let subjectsHash = {}
        subjects.forEach(item => {
            subjectsHash[item.name] = item
        })

        // state data
        const {
            firstName,
            lastName,
            avatarUrl,
            email
        } = state.users.me

        // Add a new profile
        const profileDoc = firestore.collection('profiles').doc(uid)
        batch.set(
            profileDoc, {
                user: {
                    uid,
                    firstName,
                    lastName,
                    avatarUrl,
                    email,
                },
                phoneNumber,
                location,
                addressInfo: {
                    addressComponents,
                    locationName,
                    formattedAddress,
                    placeId,
                    icon,
                    vicinity,
                    utc_offset,
                    url,
                },
                subjects: subjectsHash,
                educations,
                languages,
                content,
                rating: 0 // initial review is 0 out of 5
            }
        )
        // Update a user's hasProfile
        const userDoc = firestore.collection('users').doc(uid)
        batch.update(userDoc, "hasProfile", true)

        // Commit the batch
        batch.commit().then(() => {
            dispatch({ type: CREATE_PROFILE_SUCCESS });
            dispatch({ type: 'UPDATE_HAS_PROFILE' })
            showSuccess("Congratulation. Please add or change your profile photo!")
            history.push('/account') // redirect to home page
        }).catch(error => {
            const { message } = error
            showError(message)
            dispatch({ type: CREATE_PROFILE_ERROR, error });
        })
    }
}

export const setEmptyProfile = () => {
    return {
        type: SET_EMPTY_PROFILE,
        payload:{
            current: "empty",
        }
    }
}

export const getProfileRequest = (uid) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({ type: GET_PROFILE_REQUEST })
        //console.log(uid);
        const profileRef = getFirestore().collection('profiles').doc(uid)

        profileRef.get().then(doc => {
            if (doc.exists) {
                dispatch({
                    type: GET_PROFILE_SUCCESS,
                    current: doc.data(),
                });
            }
        }).catch(error => {
            console.log("Error getting document:", error);
            toastr.error(
                "Not Found",
                "Sorry, we could not find the tutor you were looking for"
            );
            this.props.history.push("/error");

            dispatch({
                type: GET_PROFILE_ERROR,
                error: error
            });
        });
    }
}



export const updateProfileRequest = (payload, showSuccess, showError) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({ type: UPDATE_PROFILE_REQUEST })
        const {
            lat,
            lng,
            addressInfo
        } = payload

        const firestore = getFirestore()
        const uid = getFirebase().auth().currentUser.uid
        const profileRef = firestore.collection('profiles').doc(uid)
        const location = new firebase.firestore.GeoPoint(
            lat,
            lng,
        )
        profileRef.update({
            location,
            addressInfo
        }).then(() => {
            // console.log("UPDATE_PROFILE_SUCCESS")
            window.location.reload();
        }).catch(err => {
            showError(err.message)
        });
    }
}

export const updateProfileFieldsRequest = (payload, showSuccess, showError) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({ type: UPDATE_PROFILE_FIELDS_REQUEST })
        const firestore = getFirestore()
        const uid = getFirebase().auth().currentUser.uid
        const profileRef = firestore.collection('profiles').doc(uid)
        profileRef.update(payload).then(() => {
            window.location.reload();
        }).catch(err => {
            showError(err.message)
        });
    }
}

export const updateProfileEducationRequest = (payload, showSuccess, showError) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({ type: UPDATE_PROFILE_EDUCATION_REQUEST })

        const firestore = getFirestore()
        const uid = getFirebase().auth().currentUser.uid
        const profileRef = firestore.collection('profiles').doc(uid)

        const schoolRef = firestore.collection('schools').doc(payload.where.trim().toLowerCase())


        try {
            await profileRef.get().then(profile => {
                if (!profile.exists) {
                    console.log('Document does not exist!')
                }
                let newEducations = profile.data().educations
                newEducations.push(payload)
                profileRef.update({ educations: newEducations });
            })

            await schoolRef.get().then(list => {
                let newList = {};
                if (list.data() === undefined) {
                    //console.log('Document does not exist!')
                    //console.log(uid, getFirebase().auth().currentUser)
                    newList[uid] = { "id": uid };
                    schoolRef.set({ list: newList });
                    //console.log("What is wrong?", newList)
                } else {
                    newList = list.data().list
                    newList[uid] = { "id": uid };
                    schoolRef.update({ list: newList });
                }
                //console.log("In tutoraction newPeopleListFOrSchool", newList)

            }).then(() => {
                window.location.reload();

            })
        } catch (error) {
            console.log(error)
            // showError(error)
        }

    }
}

export const removeProfileEducationRequest = (payload, showSuccess, showError) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({ type: REMOVE_PROFILE_EDUCATION_REQUEST })

        const firestore = getFirestore()
        const uid = getFirebase().auth().currentUser.uid
        const profileRef = firestore.collection('profiles').doc(uid)

        let value = ""
        //console.log("1", payload);

        try {
            let i = 0;
            let j = 0;
            let k = 0;
            
            await profileRef.get().then(profile => {
                profile.data().educations.forEach(item => {
                    console.log(item.where)
                    if (j === payload) {
                        value = item.where
                        console.log("The school is", value)
                    }
                    j++;
                })
            });

            const schoolRef = await firestore.collection('schools').doc(value.trim().toLowerCase())

            await profileRef.get().then(profile => {
                profile.data().educations.forEach(edu => {
                    k++;
                    console.log("The j should start from 2", j)
                    
                    if (edu.where.trim().toLowerCase() === value.trim().toLowerCase()) {
                        console.log("edu.where.trim().toLowerCase()",edu.where.trim().toLowerCase() )
                        ++i
                    }
                    if (k === j) {
                        if (!profile.exists) {
                            console.log('Document does not exist!')
                        }
                        let newEducations = profile.data().educations
                        newEducations.splice(payload, 1);
                        profileRef.update({ educations: newEducations });
                    }
                })
            });

            await schoolRef.get().then(list => {
                let newList = {};

                if (!list.exists) {
                    console.log('Document does not exist!');
                }
                newList = list.data().list

                //console.log("newList", newList);
                //console.log("newList[uid]",newList[uid])
                if (i === 1) {
                    delete newList[uid]
                    schoolRef.update({ list: newList });
                }

                // console.log("i",i)
                // console.log("j",j)
            }).then(() => {
                window.location.reload();
                console.log("Success")
            })

        } catch (error) {
            console.log(error)
            // showError(error)
        }
    }
}
//LANGUAGEs
export const updateProfileLanguageRequest = (payload, showSuccess, showError) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({ type: UPDATE_PROFILE_EDUCATION_REQUEST })

        const firestore = getFirestore()
        const uid = getFirebase().auth().currentUser.uid
        const profileRef = firestore.collection('profiles').doc(uid)

        firestore.runTransaction(transaction => {
            // This code may get re-run multiple times if there are conflicts.
            return transaction.get(profileRef).then(profile => {
                if (!profile.exists) {
                    console.log('Document does not exist!')
                }

                let newLanguages = profile.data().languages
                let newObject = payload.languages;

                function extend(obj, src) {
                    for (var key in src) {
                        if (src.hasOwnProperty(key)) obj[key] = src[key];
                    }
                    return obj;
                }

                // example
                var newLang = extend(newLanguages, newObject)
                transaction.update(profileRef, { languages: newLang });
            });
        }).then(() => {
            // showSuccess('Updated!')
            window.location.reload();
        }).catch(error => {
            console.log(error)
        });
    }
}

export const removeProfileLanguageRequest = (payload, showSuccess, showError) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({ type: REMOVE_PROFILE_EDUCATION_REQUEST })

        const firestore = getFirestore()
        const uid = getFirebase().auth().currentUser.uid
        const profileRef = firestore.collection('profiles').doc(uid)

        firestore.runTransaction(transaction => {
            // This code may get re-run multiple times if there are conflicts.
            return transaction.get(profileRef).then(profile => {
                if (!profile.exists) {
                    console.log('Document does not exist!')
                }
                let newLanguages = profile.data().languages
                delete newLanguages[`${payload}`];
                transaction.update(profileRef, { languages: newLanguages });
            });
        }).then(() => {
            // showSuccess('Updated!')
            window.location.reload();
        }).catch(error => {
            console.log(error)
        });
    }
}

export const updateProfileDescriptionRequest = (payload, showSuccess, showError) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({ type: UPDATE_PROFILE_DESCRIPTION_REQUEST })

        const firestore = getFirestore()
        const uid = getFirebase().auth().currentUser.uid
        const profileRef = firestore.collection('profiles').doc(uid)

        firestore.runTransaction(transaction => {
            // This code may get re-run multiple times if there are conflicts.
            return transaction.get(profileRef).then(profile => {
                if (!profile.exists) {
                    console.log('Document does not exist!')
                }
                let newDescription = payload.description
                console.log(payload)
                console.log(newDescription)
                transaction.update(profileRef, { description: `${newDescription}` });
            });
        }).then(() => {
            // showSuccess('Updated!')
            window.location.reload();
        }).catch(error => {
            console.log(error);
        });
    }
}

export const updateProfileExperienceRequest = (payload, showSuccess, showError) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({ type: UPDATE_PROFILE_EXPERIENCE_REQUEST })

        const firestore = getFirestore()
        const uid = getFirebase().auth().currentUser.uid
        const profileRef = firestore.collection('profiles').doc(uid)

        firestore.runTransaction(transaction => {
            // This code may get re-run multiple times if there are conflicts.
            return transaction.get(profileRef).then(profile => {
                if (!profile.exists) {
                    console.log('Document does not exist!')
                }
                let newExperiences = [];
                const Checking = profile.data().experiences

                if (Checking) {
                    newExperiences = Checking;
                }
                //console.log(newExperiences)
                newExperiences.push(payload)
                transaction.update(profileRef, { experiences: newExperiences });
            });
        }).then(() => {
            // showSuccess('Updated!')
            window.location.reload();
        }).catch(error => {
            console.log(error);
        });
    }
}

export const removeProfileExperienceRequest = (payload, showSuccess, showError) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({ type: REMOVE_PROFILE_EXPERIENCE_REQUEST })

        const firestore = getFirestore()
        const uid = getFirebase().auth().currentUser.uid
        const profileRef = firestore.collection('profiles').doc(uid)

        firestore.runTransaction(transaction => {
            // This code may get re-run multiple times if there are conflicts.
            return transaction.get(profileRef).then(profile => {
                if (!profile.exists) {
                    console.log('Document does not exist!')
                }
                let newExperiences = profile.data().experiences
                newExperiences.splice(payload, 1);
                transaction.update(profileRef, { experiences: newExperiences });
            });
        }).then(() => {
            // showSuccess('Updated!')
            window.location.reload();
        }).catch(error => {
            showError(error)
        });
    }
}

export const updateProfileSubjectRequest = (payload, showSuccess, showError) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({ type: UPDATE_PROFILE_SUBJECT_REQUEST })

        const firestore = getFirestore()
        const uid = getFirebase().auth().currentUser.uid
        const profileRef = firestore.collection('profiles').doc(`${uid}`);

        firestore.runTransaction(transaction => {
            // This code may get re-run multiple times if there are conflicts.
            return transaction.get(profileRef).then(profile => {
                if (!profile.exists) {
                    console.log('Document does not exist!')
                }
                let newSubjects = profile.data().subjects;
                let newSubjectList = profile.data().subjectList;
                newSubjects[`${payload.name}_${payload.level}`] = payload;
                newSubjectList[profile.data().subjectList.length] = payload.name

                transaction.update(profileRef, { subjects: newSubjects, subjectList: newSubjectList });
            });
        }).then(() => {
            // showSuccess('Updated!')
            window.location.reload();
        }).catch(error => {
            showError(error)
        });
    }
}

export const removeProfileSubjectRequest = (payload, showSuccess, showError) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({ type: REMOVE_PROFILE_SUBJECT_REQUEST })

        const firestore = getFirestore()
        const uid = getFirebase().auth().currentUser.uid
        const profileRef = firestore.collection('profiles').doc(uid)

        console.log("payload", payload);

        firestore.runTransaction(transaction => {
            // This code may get re-run multiple times if there are conflicts.
            return transaction.get(profileRef).then(profile => {
                if (!profile.exists) {
                    console.log('Document does not exist!')
                }


                let newSubjects = profile.data().subjects;

                let newSubjectList = profile.data().subjectList;
                delete newSubjects[`${payload.name}_${payload.level}`];


                for (let i = 0; i < profile.data().subjectList.length; i++) {
                    if (newSubjectList[i] === payload.name) {
                        newSubjectList.splice(i, 1);
                        break;
                    }
                }

                // delete newSubjectList[i] = 

                transaction.update(profileRef, { subjects: newSubjects, subjectList: newSubjectList });
            });
        }).then(() => {
            // showSuccess('Updated!')
            window.location.reload();
        }).catch(error => {
            console.log(error)
        });
    }
}


/*
 * Create a review under the profile
 */
export const createReviewRequest = (payload, showSuccess, showError) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({ type: CREATE_REVIEW_REQUEST });

        const uid = getFirebase().auth().currentUser.uid
        const firestore = getFirestore()
        const state = getState()
        const { reviewTitle, reviewContent, reviewRating } = payload
        console.log(payload);
        firestore.collection('profiles').doc(payload.uid).collection('reviews').doc(uid).set({
            user: {
                email: state.users.me.email,
                avatarUrl: state.users.me.avatarUrl,
                displayName: state.users.me.displayName,
            },
            title: reviewTitle,
            content: reviewContent,
            rating: reviewRating,
            show: true,
        }).then(() => {
            window.location.reload();
        }).catch(error => {
            const { message } = error
            showError(message)
            dispatch({ type: CREATE_REVIEW_ERROR, error });
        });
    }
}

export const updateResumeUserInfoRequest = (payload, showSuccess, showError) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        dispatch({ type: UPDATE_RESUME_USER_INFO_REQUEST });

        const firestore = getFirestore()
        const uid = getFirebase().auth().currentUser.uid
        const profileRef = firestore.collection('profiles').doc(uid)

        profileRef.update({
            languages: payload
        }).then(() => {
            // showSuccess('Updated!')
            window.location.reload();
        }).catch(error => {
            showError(error)
        });
    }
}

export const getReviewsRequest = (payload, showSuccess, showError) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const currentUser = getFirebase().auth().currentUser
        if (currentUser !== null) {
            dispatch({ type: GET_REVIEWS_REQUEST });

            const firestore = getFirestore()
            const profileRef = firestore.collection('profiles').doc(payload).collection('reviews')

            profileRef.where("show", "==", true)
                .get()
                .then(querySnapshot => {
                    let reviews = []
                    querySnapshot.forEach((doc) => {
                        // doc.data() is never undefined for query doc snapshots
                        reviews.push(doc.data())
                    });
                    dispatch({
                        type: GET_REVIEWS_SUCCESS,
                        reviews: reviews,
                    });
                }).catch(error => {
                    console.log(error)
                });
        }
    }
}

export const uploadProfileImage = (payload) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        // const image = payload.image
        // const storageRef = getFirebase().storage().ref()
        // const mountainImagesRef = storageRef.child(`images/${image.name}`);

        // mountainImagesRef.put(file).then((snapshot) => {
        //     console.log(snapshot)
        //     // dispatch({ type: PROFILE_CREATE_SUCCESS });
        // }).catch(error => {
        //     console.log(error)
        //     // dispatch({ type: PROFILE_CREATE_ERROR });
        // })
    }
}