import React, { Component } from 'react';
import { Modal } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withStyles } from "@material-ui/core/styles";

import { closeModal, openModal } from "./modalActions";
import { Item, List, Image } from "semantic-ui-react";

import { Link } from "react-router-dom";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';



const actions = { closeModal, openModal };

const styles = theme => ({
    subjectTitle: {
        fontSize: '1.5rem',
        color: '#4ca7ab',
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            maxWidth: '250px',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    },
    header: {
        backgroundColor: '#f4f5f7',
        paddingTop: 20,
        paddingBottom: 20
    },
    cardStyle: {
        paddingBottom: 5
    },
    badgeName: {
        fontSize: '1.3rem',
        fontWeight: 600,
        color: '#585c60',
    },
    badgeDesc: {
        fontSize: '1rem',
        fontWeight: 400,
        marginBottom: 20,
        marginTop: 5,
        color: '#585c60',
        
    },
    icons1: {
        marginTop: 5,
        marginBottom: 20,
        marginRight: 20,
        width: '40px',
        height: '40px',
        float: 'left',
    },
    icons2: {
        marginTop: 25,
        marginBottom: 20,
        width: '40px',
        height: '40px',
        display: 'block',
        float: 'left',
        marginRight: 20,
        [theme.breakpoints.down('xs')]: {
            marginTop: 25
        }
    },
    icons3: {
        marginTop: 20,
        marginBottom: 20,
        width: '40px',
        height: '40px',
        float: 'left',
        marginRight: 20,
        [theme.breakpoints.down('xs')]: {
            marginTop: 20
        }
    },
    items: {
        marginLeft: 10,
        marginRight: 20,
        paddingTop: 10,
        paddingBottom: 0,
        [theme.breakpoints.down('xs')]: {
            marginRight: 5
        }
    },
    modalStyle: {
        width: '100px'
    },
    recommedImgStyle: {
        borderBottom: '1px solid #f4f5f7',
        display: 'flex'
    },
    recommedImgStyle1: {
        display: 'flex'
    }
})

class RecommendInformation extends Component {
    state = {
        isOpen: true,
        step: 1,
    }



    render() {
        const { closeModal, openModal, classes } = this.props;

        return (
            <Modal
                size='tiny'
                open={true}
                onClose={closeModal}
                isOpen={true}
                className={classes.modalStyle}
                style={{ maxWidth: '450px' }}
            >
                <Card className={classes.cardStyle}>
                    <CardContent className={classes.header}>
                        <Typography className={classes.subjectTitle} >There are more badges you can have!
                        </Typography>
                    </CardContent>
                    <CardContent style={{ paddingBottom: 10, paddingRight: 20, paddingLeft: 20 }} >
                        <Grid container >
                            <Grid className={classes.items} item xs={12} md={12} >
                                <div className={classes.recommedImgStyle}>
                                    <img className={classes.icons1} src="/assets/Education.png" alt="Education" />
                                    <span>
                                        <label className={classes.badgeName} >Education Badge</label>
                                        <div className={classes.badgeDesc} >Are You a Student or Graduated? Send us a proof!</div>
                                    </span>
                                </div>
                                <div className={classes.recommedImgStyle}>
                                    <img className={classes.icons2} src="/assets/Teacher.png" alt="Education"  >
                                    </img>
                                    <span style={{ marginTop: '20px' }}>
                                        <lable className={classes.badgeName} >Professional Badge</lable>
                                        <div className={classes.badgeDesc} >Are You a Professional Teacher? This badge is for you</div>
                                    </span>
                                </div>
                                <div className={classes.recommedImgStyle1}>
                                    <img className={classes.icons3} src="/assets/Verified.png" alt="Education"  >
                                    </img>
                                    <span style={{ marginTop: '20px' }}>
                                        <lable className={classes.badgeName} >Basic Badge</lable>
                                        <div className={classes.badgeDesc} >The Easiest Way to be Recognized. All you need is 5 minutes phone call!</div>
                                    </span>
                                </div>
                            </Grid>

                            {/* <Grid className={classes.items} item xs={7} md={9}>

                                <lable className={classes.badgeName} >Professional Badge</lable>
                                <div className={classes.badgeDesc} >Are You a Professional Teacher? This badge is for you</div>

                                <lable className={classes.badgeName} >Basic Badge</lable>
                                <div className={classes.badgeDesc} >The Easiest Way to be Recognized. All you need is 5 minutes phone call!</div>
                            </Grid> */}
                        </Grid>
                    </CardContent>
                </Card>
            </Modal>
        )

    };




}

export default connect(null, actions)(withStyles(styles)(RecommendInformation));




