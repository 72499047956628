import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

//Material UI
import { Grid } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';

const styles = theme => ({
    box: {
        paddingBottom: 80,
        marginBottom: 60
    },
    boxStyle: {
        border: '1px solid none',
        width: '90%',
        marginRight: 'auto',
        marginLeft: 'auto',
        marginTop: 80,
        height: '250px',
        boxShadow: 'none',
        margin: 0,
    },
    boxStyle1: {
        border: '1px solid none',
        width: '90%',
        marginRight: 'auto',
        marginLeft: 'auto',
        marginTop: 80,
        height: '250px',
        margin: 0,
        display: 'flex',
        boxShadow: 'none',
        [theme.breakpoints.down('xs')]: {
            display: 'none',

        }
    },
    boxStyle2: {
        border: '1px solid none',
        width: '90%',
        marginRight: 'auto',
        marginLeft: 'auto',
        marginTop: 20,
        height: '250px',
        margin: 0,
        boxShadow: 'none',
        display: 'none',
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            marginTop: 80,
        }
    },
    introHeader1: {
        fontSize: '2rem',
        color: '#585c60',
        textAlign: 'left',
        fontWeight: 600,
        paddingTop: 80,
        paddingBottom: 10,
        ght: 700,
        fontFamily: 'proxima-nova, sans-serif ',
        letterSpacing: '1.2px',
        paddingLeft: 20,
        [theme.breakpoints.down('xs')]: {
            textAlign: 'left',
            paddingLeft: 20,
            paddingTop: 20,
            paddingBottom: 0

        }
    },
    introHeader2: {
        width: '90%',
        marginRight: 'auto',
        marginLeft: 'auto',
        fontSize: '2rem',
        color: '#585c60',
        textAlign: 'right',
        fontWeight: 600,
        paddingTop: 100,
        paddingBottom: 10,
        paddingLeft: 0,
        fontFamily: 'proxima-nova, sans-serif ',
        letterSpacing: '1.2px',

        [theme.breakpoints.down('xs')]: {
            textAlign: 'left',
            paddingTop: 20,
            paddingBottom: 0

        }
    },
    introDesc: {
        color: '#585c60',
        width: '90%',
        marginRight: 'auto',
        marginLeft: 'auto',
        fontFamily: 'proxima-nova, sans-serif ',
        letterSpacing: '1.2px',
        fontSize: '1.1rem',
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.3rem',
            marginTop: '-3px'

        }

    },
    secondDescription: {
        textAlign: 'right',
        padding: '0 20px 20px 20px',
        fontFamily: 'proxima-nova, sans-serif ',
        letterSpacing: '1.2px',
        fontSize: '1.1rem',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'left',
            fontSize: '1.3rem',
            marginTop: '-3px'
        }
    },
    checkMark: {
        width: '40px',
        verticalAlign: 'middle',
        marginRight: 10
    }

})


const Introduction = ({
    classes,
    courseInfo
}) => {

    return (
        <div className={classes.box}>
            <Grid container>
                <Grid item xs={12} md={5}>
                    <Card className={classes.boxStyle}>
                        <Avatar
                            style={{
                                borderRadius: 0,
                                width: '100%',
                                height: '100%'
                            }}
                            src="/assets/Ontarioillust.png"
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} md={7}>
                    <Typography className={classes.introHeader1}>
                        100% Ontario Curriculum
                        </Typography>
                    <Typography className={classes.introDesc}>
                        {courseInfo.curriculum}
                    </Typography>
                </Grid>
                <Grid item xs={12} >
                    <Card className={classes.boxStyle2} >
                        <Avatar
                            style={{
                                borderRadius: 0,
                                width: '100%',
                                height: '100%',
                            }}
                            src="/assets/Levelillustration.png"
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} md={7}>
                    <Typography className={classes.introHeader2}>
                        For All Levels 
                    </Typography>
                    <Typography className={classes.secondDescription}>
                        {courseInfo.allLevels}
                    </Typography>
                </Grid>

                <Grid item xs={12} md={5} >
                    <Card className={classes.boxStyle1}>
                        <Avatar
                            style={{
                                borderRadius: 0,
                                width: '100%',
                                height: '100%'
                            }}
                            src="/assets/Levelillustration.png"
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} md={5}>
                    <Card className={classes.boxStyle}>
                        <Avatar
                            style={{
                                marginTop: -20,
                                borderRadius: 0,
                                width: '100%',
                                height: '100%'
                            }}
                            src="/assets/QAillust.png"
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} md={7}>
                    <Typography className={classes.introHeader1} style={{marginTop: 10}}>
                        Ask Questions at Any Time
                        </Typography>
                    <Typography className={classes.introDesc}>
                        Having difficulities solving our problems? Ask questions at any time. Our selected tutors will answer your questions. You can also contact tutors if you need help in person.
                    </Typography>
                </Grid>
            </Grid>
        </div>
    )
}

export default withStyles(styles)(Introduction)