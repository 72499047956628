import * as actionTypes from './chatActionConstants';

const initialChannelState = {
    //isPrivateChannel: false,
    notifications: [],
    currentChannel: {
      id: 'noChannel',
    }
}

const chatReducer = (state = initialChannelState, action) => {
    switch(action.type) {
      case actionTypes.SET_CURRENT_CHANNEL:
        return {
          ...state,
          currentChannel:action.payload.currentChannel
        }
      case actionTypes.SET_NOTIFICATIONS:
        return {
          ...state,
          notifications:action.payload.notifications
        }
      case actionTypes.FETCH_CHATMESSAGES:
        return {
          ...state,
          chatMessages:action.payload.loaddedMessages,
          currentChannel:action.payload.channel
        }
      default:
        return state;
    }
  }

  export default chatReducer