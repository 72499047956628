import React from 'react'
import { Segment, Item, Header, Image, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';



const StudentDetailedHeader = ({ student }) => {
    return (
        <div>
            <Segment.Group>
                <Segment basic attached='top' style={{ padding: '0' }}>

                    <Segment basic>
                        <Item.Group>
                            <Item>
                                <Item.Content>
                                    <p>{student.title}</p>
                                    <p>
                                        Hosted by <strong>{student.hostedBy}</strong>
                                    </p>

                                    <p>{student.description}</p>

                                </Item.Content>
                            </Item>
                        </Item.Group>
                    </Segment>
                </Segment>

                <Segment attached='bottom'>
                    <Button>Cancel my place</Button>
                    <Button color='teal'>join this event</Button>
                    <Button as={Link} to={`/managestudent/${student.id}`} color='orange' floated='right'>
                        manage event
                    </Button>
                </Segment>
            </Segment.Group>
        </div>
    )
}

export default StudentDetailedHeader
