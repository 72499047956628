import React from "react";
import firebase from '../../../app/config/firebase'
import { connect } from 'react-redux'
import { Item, List } from "semantic-ui-react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Switch from "react-switch";
import { toastr } from "react-redux-toastr";

const style = (theme) => ({
    subjectTitle: {
        fontSize: '1.5rem',
        marginBottom: 10,
        paddingBottom: 20,
        borderBottom: '1px solid #e1e1e6'
    },
    cardStyle: {
        paddingTop: 20,
        paddingLeft: 20,
        paddingRight: 20,
        marginTop: 20,
        boxShadow: 'none',
        border: '1px solid #e1e1e6',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            borderRadius: 0,
        }
    },
    icons1: {
        borderRadius: 0,
        marginBottom: '40px'

    },
    icons2: {
        borderRadius: 0,
        marginBottom: '55px',
        [theme.breakpoints.up('xs')]: {
            marginBottom: '10px'
        },

    },
    items: {
        marginBottom: '20px'
    },
    veri: {
        marginTop: 60,
        [theme.breakpoints.down('sm')]: {
            marginTop: 40
        }
    },
    revealButton: {
        margin: '5px 15px 5px 30px',
        backgroundColor: '#4ca7ab',
        color: '#ffffff',
        padding: '5px 20px',
        borderRadius: '10px',
        padding: '5px 20px',
        '&:hover': {
          backgroundColor: '#0A4065',
          color: '#ffffff'
        },
      },

})

const mapState = (state) => ({
    searches: state.searches,
})



class TutorProfileSidebar extends React.Component {

    state = {
        revealed: false,
        visible: false,
    }




    componentDidMount() {
        const { tutorProfile } = this.props;

        if (tutorProfile.contactVisible === undefined) {
            this.setState({ visible: false })
        } else {
            this.setState({ visible: tutorProfile.contactVisible })
        }
    }

    handleChange = (checked) => {
        const { tutorProfile } = this.props;
        firebase.firestore().collection(`profiles`)
            .doc(tutorProfile.tutorUid)
            .update({
                contactVisible: checked,
            })
            .then(() => toastr.success('Success', `${checked ? 'Your Contact Info is Now Visible to Other Users' : 'Your Contact Info is Now Hidden from Other Users'}`))
            .catch(err => console.log(err));
        console.log(checked)
        this.setState({ visible: checked });
    }

    displayContacts = (phone, email) => {
        const { classes, isOwner } = this.props;



        if (!isOwner && !this.state.revealed) {
            return (
                <div style={{marginBottom: 20}}>
                    <Item.Content>
                        <p style={{ color: '#585c60' , fontSize: '1.5rem', margin: 0, padding: 0, fontWeight: 600}}>Phone</p>
                        <Item.Description style={{fontSize: '1.1rem', margin: 0, padding: 0}} onClick={this.revealContacts} > {phone.substr(0, 5)}XXXXX <span> <Button className={classes.revealButton}>REVEAL</Button></span></Item.Description>
                    </Item.Content>
                    <br />
                    <Item.Content>
                        <p style={{ color: '#585c60' , fontSize: '1.5rem', margin: 0, padding: 0, fontWeight: 600}}>email</p>
                        <Item.Description style={{fontSize: '1.1rem', margin: 0, padding: 0}} onClick={this.revealContacts}>{email.substr(0, 5)}XXXXX <span> <Button className={classes.revealButton}>REVEAL</Button></span> </Item.Description>
                    </Item.Content>
                    <br />
                </div>
            )
        } else {
            return (
                <div style={{marginBottom: 20}}>
                    <Item.Content>
                        <p style={{ color: '#585c60', fontSize: '1.5rem', margin: 0, padding: 0, fontWeight: 600 }}>Phone</p>
                        <Item.Description style={{fontSize: '1.1rem', margin: 0, padding: 0}}>{phone} </Item.Description>
                    </Item.Content>
                    <br />
                    <Item.Content>
                        <p style={{ color: '#585c60', fontSize: '1.5rem', margin: 0, padding: 0 , fontWeight: 600}}>email</p>
                        <Item.Description style={{fontSize: '1.1rem', margin: 0, padding: 0}}>{email}</Item.Description>
                    </Item.Content>
                    <br />
                </div>
            )
        }

    }

    revealContacts = () => {
        const info = [];
        const { tutorProfileId, tutorProfile, userProfile, searches, userAuth } = this.props;
        this.setState({ revealed: true });

        const possibleMatchingsRef = firebase.firestore().collection('possibleMatchings').doc(`${userAuth.uid}_${tutorProfileId}`)
        let searchInfo = {
            searchedSubject: searches.subject,
            searchedLanguages: searches.langs,
            searchedLocation: searches.location,
        }

        info.push(searchInfo);

        possibleMatchingsRef.get().then(snap => {
            if (snap.data() === undefined) {
                possibleMatchingsRef
                    .set({
                        senderId: userAuth.uid,
                        senderName: userProfile.displayName || userProfile.firstName || 'No Name Found',
                        receiverId: tutorProfileId,
                        receiverName: tutorProfile.displayName || tutorProfile.firstName || 'No Name Found',
                        detail: info
                    });
            } else {
                snap.data().detail.map(detail => info.push(detail));
                possibleMatchingsRef.update({ detail: info });
            }
            // console.log("In tutoraction newPeopleListFOrSchool", newList)
        })
            .catch(error => console.log(error));
    }

    render() {
        const {
            tutorProfile,
            attendees,
            authenticated,
            openModal,
            loading,
            classes,
            isOwner,
            visibilityClass,
            isOpen
        } = this.props

        return (
            <Card
                className={visibilityClass + ' ' + classes.cardStyle}
            // className={classes.cardStyle}
            >
                <Typography className={classes.subjectTitle} style={{ color: '#585c60' }}>Contact Information
                </Typography>
                {isOwner && <div style={{ display: 'inline' }} >
                    <Switch
                        className={classes.switchStyle}
                        onChange={this.handleChange.bind(this)}
                        checked={this.state.visible}
                        onColor="#c9e9ff"
                        onHandleColor="#4ca7ab"
                        handleDiameter={20}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={13}
                        width={35}
                    />
                </div>
                }
                    <List relaxed divided>
                        {authenticated ?
                            (this.displayContacts(tutorProfile.phone, tutorProfile.email)) :
                            <div>
                                <Item.Content>
                                    <button
                                        className={classes.btnstyle}
                                        onClick={() => openModal('UnauthModal')}
                                        loading={loading}  >
                                        LogIn to View</button>
                                </Item.Content>
                            </div>
                        }

                    </List>


            </Card>
        );
    }
}


export default withStyles(style)(connect(mapState)(TutorProfileSidebar));
