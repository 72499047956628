import React from 'react';
import { Form, Label } from 'semantic-ui-react';
import { withStyles } from '@material-ui/core/styles';

const TextInput = ({input, value, width, type, placeholder, meta:{touched, error}, rows, min, style, enabled, disabled }) => {
  return (
    <Form.Field error={touched && !!error} width={width} >
        <input {...input} placeholder={placeholder} type={type} min={min} rows={rows} width={width} style={style} enabled={enabled} disabled={disabled}/>
        {touched && error && <Label basic color='red'>{error}</Label>}
    </Form.Field>
  )
}

export default TextInput
