import firebase from '../../app/config/firebase'
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import { compose } from "redux";
import { withFirestore, firestoreConnect, isEmpty } from "react-redux-firebase";
import { connect } from "react-redux";
import { openModal, closeModal } from "../modals/modalActions";
import { courseOptions } from '../../app/common/courseOptions'
import nl2br from 'react-newline-to-break';
import { toastr } from "react-redux-toastr";
import LoadingComponent from '../../app/layout/LoadingComponent'
//Material UI
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import BuyContent from './testPrepContents/BuyContent';
import LookingTutor from './testPrepContents/LookingTutor';
import './TestPrep.css'
import SimpleTabs from './testPrepContents/SimpleTabs';
import Button from '@material-ui/core/Button';

const styles = theme => ({
    bannerStyle: {
        backgroundImage: 'url("/assets/Banner_course.png")',
        width: '100vw',
        height: '60vh',
        borderRadius: 0,
        marginTop: 90,
        backgroundSize: 'cover',
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            height: '38vh',
            backgroundImage: 'none',
            backgroundColor: 'rgba(0, 0, 0, 0.7)'
        }
    },
    headerStyle1: {
        marginTop: 100,
        [theme.breakpoints.down('xs')]: {
            marginTop: 50
        }
    },
    headerStyle: {
        color: '#fff',
        fontSize: '3rem',
        textAlign: 'right',
        letterSpacing: '2px',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'left',
            marginLeft: '10%',
            marginRight: '10%',
            fontSize: '2.2rem',
        }
    },
    questionNumbersStyle:{
        display: 'inline',
        marginLeft: 15,
        [theme.breakpoints.down('xs')]: {
            display: 'block',
            marginLeft: 0,
        
        }
    },
    maxWidthStyle: {
        maxWidth: '1200px',
        marginRight: 'auto',
        marginLeft: 'auto',
        display: 'flex',
        float: 'center'
    },
    descStyle: {
        color: '#fff',
        fontSize: '1.1rem',
        textAlign: 'right',
        paddingLeft: '25%',
        display: 'flex',
        fontFamily: 'proxima-nova, sans-serif ',
        letterSpacing: '1px',
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    hiddenStyle: {
        display: 'block',

        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    hiddenStyle1: {
        display: 'none',
        [theme.breakpoints.down('xs')]: {
            display: 'block'
        }
    },
    responsiveStyle: {
        display: 'none',
        [theme.breakpoints.down('xs')]: {
            display: 'block',
            zIndex: 2,
            position: 'fixed',
            bottom: 0,
            height: '70px',
            width: '100%',
            backgroundColor: 'white',
            color: '#585c60',
            boxShadow: '1px 2px 5px 3px #e1e1e1'
        }
    },
    responsiveStyle2: {
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            display: 'block',
            zIndex: 2,
            position: 'fixed',
            bottom: 0,
            maxHeight: '60%',
            width: '100%',
            backgroundColor: 'white',
            color: '#585c60',
            boxShadow: '1px 2px 5px 3px #e1e1e1',
        }
    },
    xBtnStyle: {
        width: '20px',
        float: 'right',
        "&:hover": {
            backgroundColor: 'white'
        }
    },
    msgStyle: {
        borderRadius: 5,
        backgroundColor: "#ff5d5d",
        color: 'white',
        marginTop: 15,
        padding: '9px 22px',
        marginBottom: 7,
        position: 'fixed',
        zIndex: 5,
        "&:hover": {
            backgroundColor: '#ff5d5d'
        }
    },
    msgStyle2: {
        backgroundColor: "white",
        color: '#585c60',
        marginTop: 14,
        zIndex: 5,
        float: 'right',
        fontSize: '1.1rem'

    },
    msgToTr: {
        display: 'flex',
        marginLeft: 20,
        marginTop: '24px',
        fontWeight: 600
    },
    msgToTr2: {
        display: 'flex',
        marginLeft: 20,
        marginTop: 15,
        fontWeight: 600,
        fontSize: '1.3rem'
    },
    rightAlign: {

    }
})

const mapState = state => {
    return {
        auth: state.firebase.auth,
        userProfile: state.firebase.profile,
        requesting: state.firestore.status.requesting,
        loading: state.async.loading,
        tutorProfileChat: state.firestore.ordered.reviews,
    };
};


const actions = {
    openModal,
    closeModal
}



class CourseDescription extends Component {
    state = {
        opened: false,
        courseInfo: {},
        questions: [],
        paymentResult: '',
        purchaseResultLoading: false,
        loading: true,
        tutorProfile: {}
    }


    handleStripePayment = (props) => {
        this.props.openModal("StripeModal", null, props);
    };

    toggleShow = () => {
        this.setState({
            opened: !this.state.opened,
        });
    }

    async componentDidMount() {
        const { match, auth } = this.props;
        var courses = courseOptions;
        var theCourse;
        var theQuestions;
        let text;
        let i = 0;
        var tutorProfile;

        if (auth.uid) {

            firebase.firestore().collection('profiles').doc(auth.uid).get().then(doc => {
                if (doc.data()) {
                    tutorProfile = doc.data()
                    this.setState({
                        tutorProfile: tutorProfile
                    })
                }
            }
            )
        }
        await courses.map(course => {
            if (course.id === match.params.id) {
                theCourse = course
                i++
            }
        })

        if (i === 0) {
            toastr.error(
                "Not Found",
                "Sorry, we could not find the course you were looking for"
            );
            this.props.history.push('/')

        }


        await firebase.firestore().collection('questions').doc(`${match.params.id}`).get().then((doc) => {
            theQuestions = doc.data().list
        }).catch(err => console.log)

        // console.log(theCourse.description)
        this.setState({ courseInfo: theCourse, questions: theQuestions })
    }

    render() {
        const {
            classes,
            auth,
            tutorProfileChat,
            deleteTutorProfileComment,
            addTutorProfileComment,

            openModal,
            userProfile
        } = this.props;

        const { courseInfo, questions, tutorProfile } = this.state;
        const authenticated = auth.isLoaded && !auth.isEmpty;
        var buttonType = '';
        var newCourseInfo = courseInfo;

        if (tutorProfile.complete) {
            buttonType = 'download';
        } else if (userProfile.studentPass) {
            buttonType = 'discount';
            newCourseInfo.price = 499

            if (userProfile.myCourses) {
                userProfile.myCourses.map(myCourse => {
                    if (myCourse.title === courseInfo.buyTitle) {
                        buttonType = 'download'
                    }
                })
            }
        } else {
            if (userProfile.myCourses) {
                userProfile.myCourses.map(myCourse => {
                    if (myCourse.title === courseInfo.buyTitle) {
                        buttonType = 'download'
                    }
                })
            }
        }
        return (

            <div>
                <div
                    className={classes.fontStyle}>
                    <Grid container className={classes.bannerStyle}>
                        <Grid item xs={12} md={6} className={classes.maxWidthStyle}>
                            <div className={classes.headerStyle1}>
                                <h3 className={classes.headerStyle}>{courseInfo.title}</h3>
                                <h4 className={classes.headerStyle}>
                                    {courseInfo.grade}
                                    <span className={classes.questionNumbersStyle}>{courseInfo.questionNumbers}</span>
                                </h4>
                                <Typography
                                    className={classes.descStyle}
                                >
                                    {nl2br(courseInfo.description)}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={5} className={classes.hiddenStyle} >
                            <BuyContent
                                thumbnail={courseInfo.thumbnail}
                                handleStripePayment={() => this.handleStripePayment(newCourseInfo)}
                                userProfile={userProfile}
                                buttonType={buttonType}
                                courseInfo={courseInfo}
                                authenticated={authenticated}
                            />
                            <LookingTutor />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.maxWidthStyle}>
                        <Grid item xs={12} md={7}>
                            <SimpleTabs
                                userId={auth.uid}
                                tutorProfileChat={tutorProfileChat}
                                deleteTutorProfileComment={deleteTutorProfileComment}
                                addTutorProfileComment={addTutorProfileComment}
                                tutorProfileId={tutorProfile.tutorUid}
                                authenticated={authenticated}
                                openModal={openModal}
                                courseInfo={courseInfo}
                                questions={questions}
                                buttonType={buttonType}
                                userProfile={userProfile}
                            />
                        </Grid>
                    </Grid>


                    <div className={this.state.opened ? classes.responsiveStyle2 : classes.responsiveStyle}>
                        <Grid container>
                            <Grid item xs={8}>
                                <span className={this.state.opened ? classes.msgToTr2 : classes.msgToTr}>
                                    {this.state.opened ? <p>{courseInfo.buyTitle}</p> : <p>If you are interested in this course</p>}
                                </span>
                            </Grid>
                            <Grid item xs={4}>
                                <Button
                                    className={this.state.opened ? classes.msgStyle2 : classes.msgStyle}
                                    onClick={this.toggleShow}
                                >
                                    {this.state.opened ? <img
                                        src="/assets/X_button.png"
                                        className={classes.xBtnStyle}
                                    />
                                        : "Download"}
                                </Button>
                            </Grid>
                            {this.state.opened ? <Grid container>
                                <Grid item xs={12} md={4} className={classes.hiddenStyle1} >


                                    <BuyContent
                                        thumbnail={courseInfo.thumbnail}
                                        handleStripePayment={() => this.handleStripePayment(newCourseInfo)}
                                        userProfile={userProfile}
                                        buttonType={buttonType}
                                        courseInfo={courseInfo}
                                        authenticated={authenticated}
                                    />
                                </Grid>
                            </Grid>
                                : " "
                            }
                        </Grid>


                    </div>
                </div>

            </div>


        )

    }



}


export default withStyles(styles)(connect(mapState, actions)(CourseDescription))