
import React, { Component } from "react";
import { Segment, Item, Icon, List, Label, Button } from "semantic-ui-react";
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import StarRatings from 'react-star-ratings';
import { connect } from "react-redux";
import _ from 'lodash'
import Avatar from '@material-ui/core/Avatar';
import format from 'date-fns/format'

const styles = theme => ({
    info: {
        marginBottom: 10,
        [theme.breakpoints.down('xs')]: {
            marginBottom: 10,
        }
    },

    priceStyle: {
        textAlign: 'center',
        border: '5px solid #fff',
        backgroundColor: '#4ca7ab',
        color: 'white',
        marginTop: -40,
        marginLeft: 50,
        height: '60px',
        width: '60px',
        zIndex: 1,
        borderRadius: 50,
        fontSize: '1.3rem',
        lineHeight: 0.1,

    },
    haha: {
        padding: '3% 0 3% 5%',
        [theme.breakpoints.down('xs')]: {
            padding: '5% 0 0 5%',
        }
    },
    location: {
        backgroundColor: '#F4F5F7',
        padding: '1% 1% 1% 5%',
        borderTop: ' 1px solid #E1E1E6',
        borderBottom: ' 1px solid #E1E1E6',
        height: '36px',
        [theme.breakpoints.down('xs')]: {
            height: '32px',
        }
    },
    description: {
        padding: '2% 2% 2% 5%',
        backgroundImage: 'linear-gradient(#f7f7f7, #ededed)'
    },
    btn: {
        padding: '1% 2% 1% 2%',
        color: '#fff',
        backgroundColor: '#4ca7ab',
        '&:hover': {
            color: '#fff',
            backgroundColor: '#0A4065',
        },
    },
    cardStyle: {
        paddingTop: '2%',
        marginBottom: 30,
        [theme.breakpoints.down('xs')]: {
            marginBottom: 40,
            display: 'block',
            width: '95%',
            marginRight: 'auto',
            marginLeft: 'auto'
        }
    },
    langBtn: {
        fontSize: '0.9rem',
        display: 'inlineBlock',
        height: '20px',
        border: '1px solid #4ca7ab',
        backgroundColor: '#4ca7ab',
        color: 'white',
        fontWeight: 400,
        paddingLeft: 6,
        marginRight: 10,
        marginBottom: 15,
        borderRadius: 20,
        [theme.breakpoints.down('xs')]: {
            marginRight: 5,
        }
    },
    tinyImg: {
        display: 'none',
        [theme.breakpoints.down('xs')]: {
            display: 'block',
            zIndex: 0
        }
    },
    smallImg: {
        display: 'block',
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        }
    },
    hourlyStyle: {
        padding: '4px 0 4px 20px',
        border: '1px solid #4ca7ab',
        backgroundColor: '#4ca7ab',
        color: 'white',
        borderTopLeftRadius: '15px',
        borderBottomLeftRadius: '15px',
        display: 'block',
        marginLeft: '30%',
        boxShadow: '3px 5px 10px #b1b1b1',
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        }
    },
    descriptionStyle: {
        [theme.breakpoints.down('xs')]: {
            marginLeft: 10
        }
    },
    cityStyle: {
        marginLeft: 6,
        display: 'inline',
        float: 'left',
        [theme.breakpoints.down('xs')]: {
            maxWidth: '240px',
            overFlow: 'hidden'
        }
    },
    badgeStyle: {
        float: 'left',
        borderRadius: 0,
        marginTop: 10,
        width: '26px',
        height: 'auto',
        marginRight: 10
    },
    toggleStyle: {
        color: '#999',
        overflow: "hidden",
        position: "relative",
        lineHeight: "1.2em",
        maxHeight: "2.4em",
        textAlign: "justify",
        marginRight: "2em",
        paddingRight: "1em",

        "&&:before": {
            content: '"..."',
            position: "absolute",
            right: 0,
            bottom: 0
        },

    },
    langStyle: {
        color: '#4ca7ab',
        border: '1px solid #4ca7ab',
        backgroundColor: '#c9eaff',
        borderRadius: 6,
        padding: '1px 4px 2px 4px',
        marginTop: '5px',
        verticalAlign: 'center',

    },

});

const mapState = (state) => ({
    searchedSubject: state.searches.subject === undefined ? "" : state.searches.subject.value,
    lat: state.searches.lat,
    lng: state.searches.lng,
})

function distance(lat1, lon1, lat2, lon2, unit) {
    if ((lat1 == lat2) && (lon1 == lon2)) {
        return 0;
    }
    else {
        var radlat1 = Math.PI * lat1 / 180;
        var radlat2 = Math.PI * lat2 / 180;
        var theta = lon1 - lon2;
        var radtheta = Math.PI * theta / 180;
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist);
        dist = dist * 180 / Math.PI;
        dist = dist * 60 * 1.1515;
        //DEFAULT UNIT IS M (MILE)
        if (unit == "K") { dist = dist * 1.609344 }
        if (unit == "N") { dist = dist * 0.8684 }
        return dist;
    }
}

class AdminUsersListItem extends Component {


    render() {
        const { user, classes, lat, lng } = this.props;


        return (

            <Card className={classes.cardStyle}>
                <CardContent className={classes.haha} >
                    <Item.Group>
                        <Grid container spacing={24} >
                            <Grid item md={3} className={classes.smallImg} >
                                <a href={user.avatarUrl || user.photoURL} target="_blank">
                                <Item.Image
                                    size="small"
                                    circular
                                    src={user.avatarUrl || user.photoURL|| '/assets/user.png'} />
                                </a>
                            </Grid>
                            <Grid item xs={4} md={3} className={classes.tinyImg} >
                                <Item.Image
                                    className={{
                                        zIndex: -1, position: 'absolute',
                                    }}
                                    size="tiny"
                                    circular
                                    src={user.avatarUrl || '/assets/user.png'} />

                            </Grid>
                            <Grid className={classes.descriptionStyle} item xs={7} md={5}  >
                                <Item.Content style={{ color: 'black' }}>
                                    <Item.Header className={classes.info} style={{ fontSize: '1.5rem', fontWeight: 900, color: '#585c60' }}>
                                        {user.displayName || user.firstName}
                                    </Item.Header>
                                </Item.Content>
                                <Item.Content style={{ color: 'black' }}>
                                    <p><b>Id</b>: {user.id}</p>
                                    <p>email: {user.email}</p>
                                    
                                    {user.hasProfile === true ? 
                                        <Item.Content as={Link}
                                        to={`/tutor/${user.id}`}><p>has Profile: True</p></Item.Content>
                                        :
                                        <p>has Profile: {`${user.hasProfile}`}</p>
                                    }
                                    
                                     
                                    {user.createdAt ? 
                                    <p>Created: {`${format(user.createdAt.toDate(),'D MMM YYYY')}`}</p>
                                    :
                                    <p>Created: N/A</p>
                                    }
                                </Item.Content>
                            </Grid>
                        </Grid>
                    </Item.Group>
                </CardContent>

                <CardContent className={classes.location} >
                    <span>
                        {/* <Icon name="clock" /> Last LoggedIn: {format(tutor.date.toDate(), 'dddd Do MMMM')} at {format(tutor.date.toDate(), 'HH:mm')}| */}
                        <i className="material-icons" style={{ color: '#FF5D5D', display: 'inline', float: 'left' }}>
                            place
                        </i>
                       <span>
                           Joined
                       </span>
                        

                    </span>
                </CardContent>
            </Card>
        );
    }
}

export default withStyles(styles)(connect(mapState)(AdminUsersListItem));


// import React, { Component } from 'react';
// import { Segment, Item, Icon, List, Button, Label } from 'semantic-ui-react';
// import { Link } from 'react-router-dom'

// import format from 'date-fns/format'


// class AdminUsersListItem extends Component {
//   render() {
//     const {user} = this.props
//     return (
//     <Segment.Group>
//         <Segment>
//           <Item.Group>
//             <Item>
//               <Item.Image size="tiny" circular src={user.avatarUrl} />
//               <Item.Content>
//                 <Item.Header as={Link} to={`/user/${user.id}`}>{user.firstName}</Item.Header>
//               </Item.Content>
//             </Item>
//           </Item.Group>
//         </Segment>
        
//         <Segment clearing>
//         <span>Hello</span>
          
//         </Segment>
//       </Segment.Group>
//     );
//   }
// }

// export default AdminUsersListItem;
