import React from 'react'
import firebase from "../../app/config/firebase";
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';


import { reduxForm } from "redux-form";
import { withFirestore } from "react-redux-firebase";
import { toastr } from 'react-redux-toastr'

import home from './assets/contactUs.png';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {
    Form,
} from "semantic-ui-react";
import TextInput from "../../app/common/form/TextInput";
import { Field } from "redux-form";
import TextArea from "../../app/common/form/TextArea";

import {
    composeValidators,
    combineValidators,
    isRequired,
    hasLengthGreaterThan,
    isNumeric,
    createValidator
} from 'revalidate'


const styles = theme => ({
    aboutUsImage: {
        height: '100%',
        width: '100%',
        margin: 0,
        paddingBottom: 80,
        backgroundImage: 'linear-gradient( #669FFF, #4CA7AB)',
        [theme.breakpoints.down('xs')]: {
            maxHeight: 400,
        }

    },
    widthStyle: {
        maxWidth: '1180px',
        marginRight: 'auto',
        marginLeft: 'auto',
        [theme.breakpoints.down('xs')]: {
            maxWidth: '90%',

        }

    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'proxima-nova, sans-serif ',
        marginBottom: 100
    },
    aboutUsHeader: {
        display: 'block',
        paddingTop: 150,
        fontSize: '4rem',
        color: 'white',
        paddingBottom: 10,
        textAlign: 'right',
        letterSpacing: '2px',
        fontFamily: 'proxima-nova, sans-serif ',
        [theme.breakpoints.down('xs')]: {
            paddingTop: 140,

        }

    },
    contactUsQuestion: {
        color: 'white',
        fontSize: '2rem',
        margin: 0,
        padding: 0,
        textAlign: 'right',
        letterSpacing: '1.4px',
        fontFamily: 'proxima-nova, sans-serif ',
        fontWeight: 600,
        [theme.breakpoints.down('xs')]: {
            textAlign: 'left',

        }
    },
    aboutUsMain: {
        maxWidth: '1180px',
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.down('xs')]: {
            maxWidth: '90%'
        }
    },
    circleStyle: {
        display: 'flex',
        paddingTop: 180,
        marginLeft: '40px',
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    typingStyle: {
        fontSize: '1.2rem',
        lineHeight: 2

    },
    typingStyle1: {
        fontSize: '1.2rem',
        lineHeight: 2,
        color: '#4ca7ab',
        fontWeight: 600,
        letterSpacing: 1.5,

    },
    basicinfo: {
        paddingTop: 40,
        color: '#585c60',
        [theme.breakpoints.down('xs')]: {
            paddingTop: 40
        }
    },
    formStyle: {
        padding: 0,
        margin: 0,
        color: '#585c60',
        fontSize: '1.2rem',
        letterSpacing: '1px'
    },
    btnStyle: {
        backgroundColor: '#4ca7ab',
        borderRadius: 5,
        color: '#fff',
        fontWeight: 300,
        padding: '7px 42px',
        fontSize: '1.1rem',
        letterSpacing: '1.7px',
        border: 'none',
        cursor: 'pointer',
        float: 'right',
        marginTop: 20,
        '&:hover': {
            backgroundColor: '#4ca7ab',
            color: '#fff',
        }
    },
    firstDot: {
        width: '20px',
        display: 'block',
        marginBottom: 20
    },
    secondDot: {
        width: '10px',
        display: 'block',
        marginBottom: 13,
        marginLeft: '4px',

    },
    thirdDot: {
        marginLeft: '6.5px',
        width: '5px',
        display: 'block',
        marginBottom: 13
    },
    responsiveDots: {
        display: 'none',
        [theme.breakpoints.down('xs')]: {
            display: 'block'
        }
    },
    otherWays: {
        borderTop: 'none',
        marginTop: 0,
        paddingTop: 80,
        width: '90%',
        marginRight: 'auto',
        marginLeft: 'auto',
        fontFamily: 'proxima-nova, sans-serif ',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginTop: 20,
            paddingTop: 20,
            borderTop: '1px solid #c2c7d1'
        }
    },
    otherWaysHeader: {
        fontSize: '1.5rem',
        fontWeight: 600,
        letterSpacing: '1.5px',
        fontFamily: 'proxima-nova, sans-serif ',
        color: '#585c60',
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.2rem',

        }
    },
    descStyle: {
        marginBottom: 20,
        fontFamily: 'proxima-nova, sans-serif ',
    },
    descStyle1: {
        fontFamily: 'proxima-nova, sans-serif ',
        marginTop: 10,
        color: '#585c60'
    },
    fieldWidth: {
        maxWidth: '630px',
        marginTop: 40
    },
    imageStyle: {
        width: '45px',
        marginRight: 20
    }





});
const mapState = {

}

const actions = {

}

// const validate = combineValidators({
//     firstName: isRequired({ message: "This field is required" }),
//     lastName: isRequired({ message: "This field is required" }),
//     phone: isRequired({ message: "This field is required" }),
//     email: isRequired({ message: "This field is required" }),
//     city: isRequired({ message: "This field is required" }),
//     categories: isRequired({ message: "This field is required" }),

//     // subjects: isRequired({ message: "This field is required" }),
//     // levels: isRequired({ message: "This field is required" }),
//     // price: isRequired({ message: "This field is required" }),
//     // education: isRequired({ message: "This field is required" }),
//     // what: isRequired({ message: "This field is required" }),
//     // from: isRequired({ message: "This field is required" }),
//     // EducationDetails: isRequired({ message: "This field is required" }),
//     description: composeValidators(
//       isRequired({ message: "Please enter a description" }),
//       hasLengthGreaterThan(4)({ message: "it needs to be at least 5 characters" })
//     )()
//   });

const Circle = () => {
    var circles = [
        <img style={{ width: '30px', marginRight: '30px', verticalAlign: 'middle' }} src='./assets/Dot_White.png' />,
        <img style={{ width: '20px', marginRight: '30px', verticalAlign: 'middle' }} src='./assets/Dot_White.png' />,
        <img style={{ width: '15px', marginRight: '30px', verticalAlign: 'middle' }} src='./assets/Dot_White.png' />,
        <img style={{ width: '10px', marginRight: '30px', verticalAlign: 'middle' }} src='./assets/Dot_White.png' />,
        <img style={{ width: '5px', marginRight: '30px', verticalAlign: 'middle' }} src='./assets/Dot_White.png' />];

    for (var i = 0; i < 4; i++) {
        return <p>{circles}</p>
    }
}

const isValidEmail = createValidator(
    message => value => {
        if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
            return message
        }
    },
    'Invalid email address'
)

const validate = combineValidators({
    firstName: isRequired({ message: 'Your First Name is required' }),
    lastName: isRequired({ message: 'Your Last Name is required' }),
    phoneNumber: composeValidators(
        isRequired({ message: 'Your phone number is required' }),
        isNumeric({
            message: 'Must be a number'
        }),
    )(),
    email: composeValidators(
        isValidEmail,
        isRequired({ message: 'Your email address is required' }),
    )(),
    message: composeValidators(
        isRequired({ message: 'Please enter a message' }),
        hasLengthGreaterThan(40)({ message: 'message needs to be at least 40 characers' }),
    )(),

})

class ContactUsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            message: ''
        }
    }


    handleSubmit = (payload) => {
        const firestore = firebase.firestore();
        const userFeedbackRef = firestore.collection("userFeedback");
        userFeedbackRef.add(payload)
        toastr.success("Success", "Your message sent")
    }



    render() {
        const { classes, } = this.props;

        return (
            <div className={classes.root}>

                <div className={classes.aboutUsImage}>
                    <div className={classes.widthStyle}>
                        <Grid container>
                            <Grid item md={4}>
                                <h2 className={classes.aboutUsHeader} >Contact Us</h2>
                                <p className={classes.contactUsQuestion}>DO YOU HAVE</p>
                                <p className={classes.contactUsQuestion}>QUESTION?</p>
                            </Grid>
                            <Grid item md={7} className={classes.circleStyle}>
                                <Circle />
                            </Grid>
                        </Grid>


                    </div>
                </div>

                <div className={classes.aboutUsMain}>
                    <Grid container>
                        <Grid item md={7}>
                            <h1 className={classes.basicinfo}>Get in Touch!</h1>
                            <div className={classes.responsiveDots}>
                                <img
                                    className={classes.thirdDot}
                                    src='/assets/Dot.png'
                                />
                                <img
                                    className={classes.secondDot}
                                    src='/assets/Dot.png'
                                />

                                <img
                                    className={classes.firstDot}
                                    src='/assets/Dot.png'
                                />
                            </div>
                            <div className={classes.descStyle}>
                                <p className={classes.formStyle}>Have a question about Tossmath? Please Contact Us Using the Form Below.</p>
                                
                            </div>

                            <Form autoComplete="off" onSubmit={this.props.handleSubmit(this.handleSubmit)}  >
                                <div className={classes.fieldWidth}>
                                    <Field
                                        label="first name"
                                        name="firstName"
                                        type="text"
                                        value={this.state.firstName}
                                        component={TextInput}
                                        placeholder="First Name"
                                    />
                                    <Field
                                        name="lastName"
                                        type="text"
                                        value={this.state.lastName}
                                        component={TextInput}
                                        placeholder="Last Name"

                                    />
                                    <Field
                                        name="phoneNumber"
                                        type="text"
                                        value={this.state.phoneNumber}
                                        component={TextInput}
                                        placeholder="Phone"
                                    />
                                    <Field
                                        name="email"
                                        type="text"
                                        value={this.state.email}
                                        component={TextInput}
                                        placeholder="Email Address"
                                    />
                                    <Field
                                        name="message"
                                        type="text"
                                        value={this.state.message}
                                        component={TextArea}
                                        placeholder="Message"
                                    />
                                    <Button
                                        className={classes.btnStyle}
                                        type="submit"
                                    >
                                        SUBMIT
                                    </Button>
                                </div>

                            </Form>

                        </Grid>
                        <Grid item md={4}>
                            <div className={classes.otherWays}>
                                <p className={classes.otherWaysHeader}>Other ways to connect</p>
                                <a href='https://www.facebook.com/tossmath' target="_blank"><img className={classes.imageStyle} src='./assets/Facebook_logo.png'/></a>
                                <a href='https://www.instagram.com/tossmath_toronto/' target="_blank"> <img className={classes.imageStyle} src='./assets/Instagram_bttn.png' /></a>
                                <p className={classes.descStyle1}>Follow us on Our Facebook and Instagram pages for more math problems and education news</p>
                            </div>
                        </Grid>
                    </Grid>



                    {/* <Form>
                        <Grid container>
                            <Grid item xs={12} md={5} className={classes.textInputStyle}>
                                <Typography className={classes.titleStyle}>First Name*</Typography>
                                <Field

                                    name="firstName"
                                    type="text"
                                    component={TextInput}
                                    placeholder="First Name"
                                />
                            </Grid>
                            <Grid item md={1} />
                            <Grid item xs={12} md={5}>
                                <Typography className={classes.titleStyle}>Last Name*</Typography>
                                <Field
                                    className={classes.textInputStyle}
                                    name="lastName"
                                    type="text"
                                    component={TextInput}
                                    placeholder="Last Name"
                                />
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <Typography className={classes.titleStyle}>E-mail*</Typography>
                                <Field
                                    className={classes.textInputStyle}
                                    name="email"
                                    type="email"
                                    component={TextInput}
                                    placeholder="Email"
                                />
                            </Grid>
                            <Grid item md={1} />
                            <Grid item xs={12} md={5}>
                                <Typography className={classes.titleStyle}>PhoneNumber*</Typography>
                                <Field
                                    style={{ width: '100%', borderStyle: 'none', borderRadius: 3, padding: '10px 15px' }}
                                    className={classes.textInputStyle}
                                    name="phoneNumber"
                                    type="phone"
                                    component="input"
                                    placeholder="phoneNumber"
                                />
                            </Grid>
                            <Grid item xs={11} md={10} style={{ paddingBottom: 40 }}>
                                <Typography className={classes.titleStyle}>Message*</Typography>

                                <TextField
                                    style={{width: '100%',borderBottom: '2px solid white', zindex: 5,  '&:hover': {borderBottom: '2px solid white', zindex:55}}}
                                    placeholder="Placeholder"
                                    multiline
                                    
                                />
                            </Grid>
                        </Grid>
                    </Form> */}

                </div>
            </div>
        )
    }
}





export default withStyles(styles)(withFirestore(
    connect(
        null, null
    )(
        reduxForm({ form: "contactUsForm", enableReinitialize: true, validate })(
            ContactUsPage
        )
    )
));
