import * as types from './userActions';

const initState = {
    isFetching: false,
    me: '',
    followers: []
}

const userReducer = (state = initState, action) => {
    switch (action.type) {
        // GET USER
        case types.GET_USER_REQUEST:
            return {
                ...state,
                isFetching: true
            }
        case types.GET_USER_SUCCESS:
            return {
                ...state,
                isFetching: false,
                me: action.me
            }
        case types.GET_USER_ERROR:
            return {
                ...state,
                isFetching: false
            }
        // UPDATE USER
        case types.UPDATE_USER_REQUEST:
            return {
                ...state,
                isFetching: true
            }
        case types.UPDATE_USER_SUCCESS:
            return {
                ...state,
                isFetching: false
            }
        case types.UPDATE_USER_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
            }
        // UPDATE USER IMG
        case types.UPDATE_USER_IMG_REQUEST:
            return {
                ...state,
                isFetching: true
            }
        case types.UPDATE_USER_IMG_SUCCESS:
            return {
                ...state,
                me: {
                    ...state.me,
                    avatarUrl: action.avatarUrl
                },
                isFetching: false
            }
        case types.UPDATE_USER_IMG_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
            }
        // GET FOLLOWERS
        case types.GET_USER_FOLLOWERS_REQUEST:
            return {
                ...state,
                isFetching: true,
            }
        case types.GET_USER_FOLLOWERS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                followers: action.followers
            }
        case types.GET_USER_FOLLOWERS_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
            }
        case types.UPDATE_HAS_PROFILE:
            return {
                ...state,
                me: {
                    ...state.me,
                    hasProfile: true,
                }
            }
        default:
            return state
    }
}

export default userReducer