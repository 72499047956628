import React, { Component } from "react";
import { connect } from 'react-redux'
import TutorListItem from "./TutorListItem";
import InfiniteScroll from 'react-infinite-scroller'
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash'
import Pagination from 'react-paginating';
import { setSearchPageNumber } from '../TutorSearchForm/searchActions'
import { levels } from "../../../app/common/categories";

const style = {
  pageItem: {
    display: "inline",
    position: "relative",
    padding: "0.5rem 0.75rem",
    marginLeft: "2px",
    marginRight: "2px",
    lineHeight: "1.25",
    color: "#585c60",
    backgroundColor: "#fff",
    border: "1px solid #fff",
    cursor: 'pointer',
    borderRadius: 5,

  },
};

const mapState = (state) => ({
  searchState: state.searches,
  pageNumber: state.searches.searchPage,
})

const actions = {
  setSearchPageNumber,
}

class TutorList extends Component {

  state = {
    currentPage: this.props.pageNumber,
    start: (this.props.pageNumber - 1) * 10,
    end: this.props.pageNumber * 10,
  }

  componentDidMount() {
    this.setState({ currentPage: this.state.pageNumber });
  }

  // componentWillUnmount(){
  //   this.props.setSearchPageNumber(this.props.searchState,1);
  // }

  handlePageChange = (page, e) => {
    let theStart = (page - 1) * 10;
    let theEnd = page * 10;

    this.props.setSearchPageNumber(this.props.searchState, page);

    this.setState({
      currentPage: page,
      start: theStart,
      end: theEnd,
    });


    window.scrollTo(0, 0);
  };

  render() {
    const { tutors, level, locationValue, searchState } = this.props;
    const { currentPage, start, end } = this.state;

    let tutorList = [];

    const limit = 10;
    const pageCount = 6
    const total = tutors.length

    tutors.sort(function (a, b) {
      var badgeA = 0;
      var badgeB = 0;

      _.forEach(a.badges, (k, v) => {
        if (k === true) {
          badgeA++
        }
      })

      _.forEach(b.badges, (k, v) => {
        if (k === true) {
          badgeB++
        }
      })

      if (badgeA === badgeB) {
        var photoUpdatedA = a.photoUpdated;
        var photoUpdatedB = b.photoUpdated;

        if (photoUpdatedA === photoUpdatedB) {
          var ratingA = a.rating;
          var ratingB = b.rating;

          if (ratingA === ratingB) {
            var rankA = a.rank;
            var rankB = b.rank;

            return rankB - rankA;
          }

          return ratingB - ratingA //sort by date DESC
        }

        return photoUpdatedB - photoUpdatedA
      }

      return badgeB - badgeA;
    })

    {
      tutors && tutors.map(tutor => (
        tutorList.push(
          <TutorListItem
            key={tutor.tutorUid}
            tutor={tutor}
          />
        )
      ))
    }
    return (
      <div >

        {tutors.length >= 1 ? tutors.slice(start, end).map(tutor => (
          <TutorListItem
            key={tutor.tutorUid}
            tutor={tutor}
          />
        )) :
          <div style={{ textAlign: 'center' }}>
            <img
              style={{
                width: '200px',
                height: 'auto',
              }}
              src="/assets/emptyTutor.png"
            />
            <p
              style={{
                fontSize: '1.2rem',
                textAlign: 'center',
                marginTop: 20,
              }}
            >
              Be the First One to Teach
            </p>
            <p style={{
              fontSize: '1.2rem',
              textAlign: 'center',
              marginBottom: 300,
              marginTop: 10,
            }}>
              {searchState.level.label} in {searchState.location} !
            </p>
          </div>}


        <Pagination
          total={total}
          limit={limit}
          pageCount={pageCount}
          currentPage={currentPage}
        >
          {({
            pages,
            currentPage,
            hasNextPage,
            hasPreviousPage,
            previousPage,
            nextPage,
            totalPages,
            getPageItemProps
          }) => (
              <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                <button
                  {...getPageItemProps({
                    pageValue: 1,
                    onPageChange: this.handlePageChange,
                    style: style.pageItem
                  })}
                >
                  first
            </button>

                {hasPreviousPage && (
                  <button
                    {...getPageItemProps({
                      pageValue: previousPage,
                      onPageChange: this.handlePageChange,
                      style: style.pageItem
                    })}
                  >
                    {"<"}
                  </button>
                )}

                {pages.map(page => {
                  let activePage = null;
                  if (currentPage === page) {
                    activePage = { backgroundColor: "#4ca7ab", color: 'white' };
                  }
                  return (
                    <button
                      {...getPageItemProps({
                        pageValue: page,
                        key: page,
                        style: activePage,
                        onPageChange: this.handlePageChange,
                        style: { ...style.pageItem, ...activePage }
                      })}
                    >
                      {page}
                    </button>
                  );
                })}

                {hasNextPage && (
                  <button
                    {...getPageItemProps({
                      pageValue: nextPage,
                      onPageChange: this.handlePageChange,
                      style: style.pageItem
                    })}
                  >
                    {">"}
                  </button>
                )}

                <button
                  {...getPageItemProps({
                    pageValue: totalPages,
                    onPageChange: this.handlePageChange,
                    style: style.pageItem
                  })}
                >
                  last
            </button>
              </div>
            )}
        </Pagination>
      </div>
    );
  }
}

export default connect(mapState, actions)(TutorList);

