import { createReducer } from '../../app/common/util/reducerUtil'
import { CREATE_STUDENT, UPDATE_STUDENT, DELETE_STUDENT } from './StudentConstants';

const initialState = [
    {
        id: '1',
        title: 'trip to tower of londen',
        date: '2018-03-27',
        category: 'culture',
        description: 'alksjd alkjd lakjsd laisdj alksd mkalsdj ',
        city: 'London, UK',
        venue: "Tower of London, St Katharine's & Wapping, London",
        hostedBy: 'Bob',
        hostPhotoURL: 'https://randomuser.me/api/portraits/men/20.jpg',
        attendees: [{
            id: 'a',
            name: 'Bob',
            photoURL: 'https://randomuser.me/api/portraits/men/20.jpg',
        },
        {
            id: 'b',
            name: 'Sam',
            photoURL: 'https://randomuser.me/api/portraits/men/22.jpg',
        }
        ]
    },
    {
        id: '2 ',
        title: 'trip to tower of londen',
        date: '2018-03-27',
        category: 'culture',
        description: 'alksjd alkjd lakjsd laisdj alksd mkalsdj ',
        city: 'London, UK',
        venue: "Tower of London, St Katharine's & Wapping, London",
        hostedBy: 'Bob',
        hostPhotoURL: 'https://randomuser.me/api/portraits/men/20.jpg',
        attendees: [{
            id: 'a',
            name: 'Bob',
            photoURL: 'https://randomuser.me/api/portraits/men/20.jpg',
        },
        {
            id: 'b',
            name: 'Sam',
            photoURL: 'https://randomuser.me/api/portraits/men/22.jpg',
        }
        ]
    }
];

const createStudent = (state, payload) => {
    return [...state, payload.student]
}

const updateStudent = (state, payload) => {
    return [
        ...state.filter(student => student.id !== payload.student.id), payload.student
    ]
}

const deleteStudent = (state, payload) => {
    return [
        ...state.filter(student => student.id !== payload.studentId)
    ]
}

export default createReducer(initialState, {
    [CREATE_STUDENT]: createStudent,
    [UPDATE_STUDENT]: updateStudent,
    [DELETE_STUDENT]: deleteStudent
})