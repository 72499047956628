import React, { Fragment } from 'react'
import { Segment, Header, Button, Comment, Form } from 'semantic-ui-react';

const StudentDetailedChat = () => {
    return (
        <Fragment>
            <Segment
                textAlign='center'
                attached='top'
                inverted
                color='teal'
                style={{ border: 'none' }}
            >
                <Header>Chat about this event</Header>
            </Segment>

            <Segment attached>
                <Comment.Group>
                    <Comment>
                        <Comment.Avatar src='' />
                        <Comment.Content>
                            <Comment.Author as='a'>matt</Comment.Author>
                            <Comment.Metadata>
                                <div>today at 5:42pm</div>
                            </Comment.Metadata>
                            <Comment.Text>How artistic!</Comment.Text>
                            <Comment.Actions>
                                <Comment.Action>Reply</Comment.Action>

                            </Comment.Actions>
                        </Comment.Content>
                    </Comment>

                    <Comment>
                        <Comment.Avatar src='' />
                        <Comment.Content >
                            <Comment.Author as='a'>Elliot Fu</Comment.Author>
                            <Comment.Metadata>Yesterday at 12:30AM</Comment.Metadata>
                            <Comment.Text>
                                <p>this has been useful for my research. thanks</p>
                            </Comment.Text>
                            <Comment.Actions>
                                <Comment.Action>Reply</Comment.Action>
                            </Comment.Actions>
                        </Comment.Content>
                        <Comment.Group>
                            <Comment>
                                <Comment.Avatar src='' />
                                <Comment.Metadata>
                                    <div>just now</div>
                                </Comment.Metadata>
                                <Comment.Text>Elliot you are alwas</Comment.Text>
                                <Comment.Actions>
                                    <Comment.Action>reply</Comment.Action>
                                </Comment.Actions>
                            </Comment>
                        </Comment.Group>
                    </Comment>
                    <Comment>
                        <Comment.Avatar src='' />
                        <Comment.Content>
                            <Comment.Author as=''>Joe Henderson</Comment.Author>
                            <Comment.Metadata>
                                <div>5 days ago</div>
                            </Comment.Metadata>
                            <Comment.Text>dude, this is awesome.</Comment.Text>
                            <Comment.Actions>
                                <Comment.Action>reply</Comment.Action>
                            </Comment.Actions>

                        </Comment.Content>
                    </Comment>
                    <Form reply>
                        <Form.TextArea />
                        <Button
                            content='add reply'
                            labelPosition='left'
                            icon='edit'
                            primary />
                    </Form>
                </Comment.Group>
            </Segment>
        </Fragment>
    )
}

export default StudentDetailedChat
