import React, { Component, useEffect } from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";


import TutorProfilesDashboard from "../../features/tutors/TutorProfilesDashboard/TutorProfilesDashboard";
import NavBar from "../../features/nav/NavBar/NavBar";
import SettingsDashboard from "../../features/user/Settings/SettingsDashboard";
import UserDetailedPage from "../../features/user/UserDetailed/UserDetailedPage";
import TutorForm from "../../features/tutors/TutorForm/TutorForm";
import TutorForm2 from "../../features/tutors/TutorForm/TutorForm2";
import TutorProfilePage from "../../features/tutors/TutorProfile/TutorProfilePage";
import StudentsDashboard from "../../features/students/StudentsDashboard/StudentsDashboard";
import StudentForm from "../../features/students/StudentForm/StudentForm";
import StudentDetailedPage from "../../features/students/StudentDetailed/StudentDetailedPage";

import ChatPage from '../../features/user/Settings/Chat/ChatPage'
import Main from "../common/maintenance/MaintenancePage";
import PeopleDashboard from '../../features/user/PeopleDashboard/PeopleDashboard'
import HomePage from "../../features/home/HomePage";
import HomePage02 from "../../features/home/HomePage02";
import AdminPage from "../../features/admin/AdminPage";
import AdminEmailPage from "../../features/admin/AdminEmailPage";
import NotFound from "../../app/layout/NotFound";
import aboutUs from "../../features/Footer/aboutUs";
import contactUs from "../../features/Footer/contactUs";
import faq from "../../features/Footer/faq";
import privacyAndPolicy from "../../features/Footer/privacyAndPolicy";
import termsAndCondition from "../../features/Footer/termsAndCondition";
import ModalManager from '../../features/modals/ModalManager'
import { UserIsAuthenticated } from '../../features/auth/authWrapper'
import Footer from '../../features/Footer/footer'
import StudentPassPage from "../../features/home/StudentPassBanner/StudentPassPage";
import CourseDescription from "../../features/TestPrep/CourseDescription";

import TestPrepPage from '../../features/TestPrep/TestPrepPage'

//actions
import { getUserRequest } from '../../features/user/userAction'
import { focusTab, blurTab } from '../common/util/tabFocus/tabFoucsActions'
import Course from "../../features/home/Course";

const mapDispatchToProps = (dispatch) => {
  return {
    getUserRequest: () => dispatch(getUserRequest()),
    focusTab: () => dispatch(focusTab()),
    blurTab: () => dispatch(blurTab()),
  }
}


class App extends Component {

  componentDidMount() {
    this.props.getUserRequest();
    window.addEventListener("focus", this.onFocus)
    window.addEventListener("blur", this.onBlur)
  }

  componentWilUnmount() {
    window.removeEventListener("focus", this.onFocus)
    window.removeEventListener("blur", this.onBlur)
  }

  onFocus = () => {
    this.props.focusTab();

  };

  onBlur = () => {
    this.props.blurTab();

  };


  render() {

    return (
      <div>
        <ModalManager />
        <NavBar />

        <Switch>
          <Route exact path="/" component={HomePage} />
        </Switch>



        <Route
          path="/(.+)"
          render={() => (
            <div style={{ minHeight: '100vh' }}>
              <Switch>
                <Route path="/testprep" component={withRouter(HomePage02)} />

                <Route path="/tutors" component={withRouter(TutorProfilesDashboard)} />
                <Route path="/tutor/:id" component={withRouter(TutorProfilePage)} />
                <Route path="/settings" component={UserIsAuthenticated(SettingsDashboard)} />
                <Route path="/manage/:id" component={UserIsAuthenticated(TutorForm2)} />
                <Route path='/createtutor' component={UserIsAuthenticated(TutorForm)} />

                {/* <Route path='/profile/:id' component={withRouter(UserIsAuthenticated(UserDetailedPage))} />
                <Route path='/people' component={UserIsAuthenticated(PeopleDashboard)} />*/}
                <Route path='/error' component={NotFound} />

                {/* Footer Menus */}
                <Route path='/aboutUs' component={aboutUs} />
                <Route path='/contactUs' component={contactUs} />
                <Route path='/faq' component={faq} />
                <Route path='/privacyAndPolicy' component={privacyAndPolicy} />
                <Route path='/termsAndCondition' component={termsAndCondition} />
                {/* Admin Page */}
                {/* <Route path='/somethingcomplicatedforadminpage' component={UserIsAuthenticated(AdminPage)} /> */}
                {/* <Route path='/adminemail' component={UserIsAuthenticated(AdminEmailPage)} /> */}
                {/* TestPrep */}
                <Route path='/testprep' component={TestPrepPage} /> 
                <Route path="/courses" exact component={Course} />
                <Route path="/courses/:id" component={withRouter(CourseDescription)} />
                <Route path="/studentpass" component={StudentPassPage} />

                {/* Student Page */}
                <Route path='/students' component={StudentsDashboard} />
                <Route path='/students/:id' component={StudentDetailedPage} />
                <Route path='/createstudent' component={StudentForm} />

                {/* Chat */}
                <Route path='/chat/:id' component={UserIsAuthenticated(ChatPage)} />
                <Redirect from='*' to='/error' />


                {/* Favicon */}
                {/* <Route path='/favicon.ico' component={favicon} /> */}

              </Switch>
            </div>
          )}
        />
        <Footer />
      </div>
    );
  }
}

export default connect(null, mapDispatchToProps)(App);

