import { CREATE_STUDENT, UPDATE_STUDENT, DELETE_STUDENT } from "./StudentConstants";

export const createStudent = (student) => {
    return { 
        type: CREATE_STUDENT,
        payload: {
            student
        }
    }
}

export const updateStudent = (student) => {
    return { 
        type: UPDATE_STUDENT,
        payload: {
            student
        }
    }
}

export const deleteStudent = (studentId) => {
    return { 
        type: DELETE_STUDENT,
        payload: {
            studentId
        }
    }
}