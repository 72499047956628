import React from 'react'
import Autosuggest from 'react-autosuggest'
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  

})
const SelectInput = ({
  
  suggestions,
  onSuggestionsFetchRequested,
  onSuggestionsClearRequested,
  getSuggestionValue,
  renderSuggestion,

  inputProps }) => {

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
      highlightFirstSuggestion={true}
      focusInputOnSuggestionClick={true}
    />
  )
}

export default withStyles(styles)(SelectInput)