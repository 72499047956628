import { combineReducers } from 'redux';
import { reducer as FormReducer } from 'redux-form'
import { reducer as toastrReducer } from 'react-redux-toastr'
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import tutorReducer from '../../features/tutors/tutorReducer'
import modalReducer from '../../features/modals/modalReducer';
import authReducer from '../../features/auth/authReducer'
import asyncReducer from '../../features/async/asyncReducer'
import searchReducer from '../../features/tutors/TutorSearchForm/searchReducer'
import profileReducer from '../../features/tutors/TutorSearchForm/profileReducer'
import userReducer from '../../features/user/userReducer';
import categoryReducer from '../common/categoryReducer'
import adminReducer from '../../features/admin/adminReducer'
import chatReducer from '../../features/user/Settings/Chat/chatReducer'
import tabFocusReducer from '../common/util/tabFocus/tabFocusReducer'
import StudentReducer from '../../features/students/StudentReducer';



const rootReducer = combineReducers({
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    tutors: tutorReducer,
    form: FormReducer,
    modals: modalReducer,
    auth: authReducer,
    async: asyncReducer,
    toastr: toastrReducer,
    searches: searchReducer,
    profiles: profileReducer,
    user: userReducer,
    categories: categoryReducer,
    usersInAdminPage: adminReducer,
    chat: chatReducer,
    tabFocus: tabFocusReducer,
    students: StudentReducer
});

export default rootReducer