import React, { Component } from "react";
import firebase from '../../../app/config/firebase'
import { connect } from "react-redux";
import { createStudent, deleteStudent, updateStudent } from '../StudentActions'

//Material UI
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { Button } from 'semantic-ui-react';
import StudentList from "../StudentList/StudentList/StudentList";


import { NavLink, Link } from 'react-router-dom'




const mapState = (state) => ({
    student: state.students
})

const actions = {
    createStudent,
    deleteStudent,
    updateStudent
}

class StudentsDashboard extends Component {
   

    handleDeleteEvent = (id) => {
        this.props.deleteStudent(id);
    }

    render() {
        const { students } = this.props;
        return (
            <div style={{ marginTop: 100, width: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
                <Button as={Link} to='/createstudent'>CLICK</Button>

                <Card >
                    <Grid container>
                        <Grid item xs={12} md={8} >
                            <StudentList
                                students={students}
                                deleteEvent={this.handleDeleteEvent}
                            />

                        </Grid>
                        <Grid item xs={12} md={3}>
                            <h2>activity feed </h2>
                        </Grid>



                    </Grid>

                </Card>

            </div>
        );

    }
}

export default connect(mapState, actions)(StudentsDashboard)