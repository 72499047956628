import * as types from './categoryContants';

const initialState = {
  subjects: [],
}

const categoryReducer = (state = initialState, action) => {
    switch (action.type) {
        // GET PROFILE
        case types.GET_CATEGORY_REQUEST:
            return {
                ...state,
                isFetching: true
            }
        case types.GET_CATEGORY_SUCCESS:
            return {
                ...state,
                subjects: action.result,
                isFetching: false
            }
        case types.GET_CATEGORY_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.error
            }
       
        case types.CREATE_CATEGORY_REQUEST:
            return {
                ...state,
                isFetching: true,
            }
        case types.CREATE_CATEGORY_SUCCESS:
            return {
                ...state,
                isFetching: false,
                
            }
        case types.CREATE_CATEGORY_ERROR:
            return {
                ...state,
                isFetching: false,
            }
        default:
            return {
                ...state,
                error: action.error
            }
    }
}

export default categoryReducer;