

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';


const styles = theme => ({
    root: {
        backgroundImage: 'url("/assets/tossmassPreviewBG.png")',
        height: '100vh',
        width: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        marginTop: 40,
        textAlign: 'center',
        fontFamily: 'proxima-nova, sans-serif ',
        paddingTop: 140,
        [theme.breakpoints.down('xs')]: {
            paddingTop: 100
        }
    },
    paymentCardImage: {
        width: '200px',
        height: 'auto',
        marginRight: 'auto',
        marginLeft: 'auto',
        borderRadius: 0,
        [theme.breakpoints.down('xs')]: {
            width: '120px'
        }
    },
    paymentHeader: {
        marginTop: 40,
        marginBottom: 40,
        fontSize: '3rem',
        color: '#585c60',
        [theme.breakpoints.down('xs')]: {
            width: '90%',
            fontSize: '2.2rem'
        }


    },
    paymentDesc: {
        maxWidth: '400px',
        marginRight: 'auto',
        marginLeft: 'auto',
        marginBottom: 40,
        [theme.breakpoints.down('xs')]: {
            width: '90%',
            fontSize: '1rem'
        }
    },
    btnStyle: {
        backgroundColor: '#4ca7ab',
        borderRadius: 5,
        color: '#fff',
        fontWeight: 300,
        padding: '7px 42px',
        fontSize: '1.1rem',
        letterSpacing: '1.7px',
        border: 'none',
        cursor: 'pointer',
        '&:hover': {
            color: '#fff',
            backgroundColor: '#4ca7ab',
          },
    }

});


const NotFound = ({
    classes,

}) => {

    return (
        <div
            className={classes.root}
        >
            <Avatar
                className={classes.paymentCardImage}
                src="/assets/Error_icon.png"
            >
            </Avatar>
            <Typography
                className={classes.paymentHeader}
            >
                Oops!
                <br/>
                <h3>Page Not Found</h3>
            </Typography>
            <Typography
                className={classes.paymentDesc}
            >
                Sorry. We could not find the page you requested
            </Typography>
            <Button
                className={classes.btnStyle}
                component={Link}
                to='/'
        >
                Go To Home

            </Button>




        </div >
    )
}


export default withStyles(styles)(NotFound)
