import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';

const styles = theme => ({
    photoPage: {
        textAlign: 'center',

    },
    btnStyle: {
        backgroundColor: '#4ca7ab',
        marginRight: 'auto',
        marginLeft: 'auto',
        marginBottom: 10,
        color: '#fff',
        marginTop: 20,
        '&:hover': {
            color: '#fff',
            backgroundColor: '#4ca7ab',
        },
    },
    imageStyle: {
        width: 200,
        height: 200,
        borderRadius: 0,
        marginRight: 'auto',
        marginLeft: 'auto',
        marginBottom: 20
    },
    inputStyle: {
        width: '300px',
        marginLeft: 'auto',
        marginRight: 'auto'
    }

})


class TutorPhotoUpload extends Component {

    render() {
        const {
            handlePhotoChange,
            handlePhotoUpload,
            image,
            url,
            progress,
            classes
        } = this.props;
        return (
            <div className={classes.photoPage}>
                <progress value={progress} max="100" />
                <br />

                <Avatar
                    src={url || 'https://via.placeholder.com/150'}
                    className={classes.imageStyle}
                    alt='Uploaded images'
                />

                <div style={{width: '200px', marginRight: 'auto', marginLeft: 'auto'}}>
                    <input
                        className={classes.inputStyle}
                        width="100px"
                        type='file'
                        onChange={(e) => handlePhotoChange(e)}
                    />
                </div>
                <Button
                    className={classes.btnStyle}
                    onClick={(e) => handlePhotoUpload(e)}
                >
                    UPLOAD
                 </Button>

            </div>
        )
    }
}

export default withStyles(styles)(TutorPhotoUpload);