import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux'
import { NavLink, Link } from 'react-router-dom'
import Typography from '@material-ui/core/Typography';
import {
    Image,

    Header,
    Divider,
    Button,

    Icon
} from "semantic-ui-react";
import Dropzone from "react-dropzone";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';


const styles = theme => ({
    basicbox: {
        marginTop: 40,
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: '60px',
        boxShadow: 'none',
        borderBottom: '1px solid #e1e1e6',
        paddingBottom: '40px',
        [theme.breakpoints.down("xs")]: {
            paddingTop: 10,
            width: "100%",

        },
    },
    headerbg: {
        margin: 0,

    },
    displayname: {
        marginTop: '2%',


    },
    bttn: {
        backgroundColor: '#4ca7ab'
    },
    whitebar: {
        width: 50,
        backgroundColor: 'white',
    },
    imageStyle: {
        maxHeight: "180px",
        maxWidth: "180px",
        marginBottom: 20,
        [theme.breakpoints.down('xs')]: {
            marginLeft: 0,
        }

    },
    btnStyle: {
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center'
        }
    },
    imageResponsive: {
        
        [theme.breakpoints.down('xs')]: {
        width: 'auto',
        marginRight: 'auto',
        marginLeft: 'auto'
        }
    },
    photoTagStyle:{
        marginTop: 40,
        [theme.breakpoints.down('xs')]: {
            marginTop: 40
        }
    }


})

const query = ({ auth }) => {
    return [{
        collection: 'users',
        doc: auth.uid,
        subcollections: [{ collection: 'photos' }],
        storeAs: 'photos'
    }]
}

const actions = {

};

const mapState = (state) => ({
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    photos: state.firestore.ordered.photos,
    loading: state.async.loading
})

class TutorProfilePhotoUpload extends Component {

    render() {
        const { photos, profile, loading, classes, uploadImage,
            onDrop,
            cancelCrop,
            cropImage,
            files,
        } = this.props;
        let filteredPhotos;
        if (photos) {
            filteredPhotos = photos.filter(photo => {
                return photo.url !== profile.photoURL;
            })
        }
        return (

            <Card className={classes.basicbox}>
                <div className={classes.headerbg}>
                    <Header style={{ color: '#585c60', marginBottom: -30, fontSize: '1.2rem' }}  content="Upload Photo" />
                    <Grid container >
                        <Grid  item className={classes.photoTagStyle} xs={8} md={4}>
                            <Header  color="#585c60" sub content="Step 1 - Add Photo" />
                            <Dropzone className={classes.imageStyle} onDrop={onDrop} multiple={false}>
                                <div style={{ paddingTop: "20px" }}>
                                    <Icon name="upload" size="huge" />
                                </div>
                            </Dropzone>

                        </Grid>
                        <Grid  item className={classes.photoTagStyle} xs={8} md={4}>
                            <Header  sub color="#585c60" content="Step 2 - Resize image" />
                            {files[0] && (
                                <Cropper
                                    className={classes.imageStyle}
                                    ref="cropper"
                                    src={files[0].preview}
                                    aspectRatio={1}
                                    viewMode={0}
                                    dragMode={"move"}
                                    guides={false}
                                    scalable={true}
                                    cropBoxMovable={true}
                                    cropBoxResizable={true}
                                    crop={() => cropImage(this.refs.cropper)}
                                />
                            )}
                            {files[0] && (
                                <div className={classes.btnStyle}>
                                    <Button.Group>
                                        <Button loading={loading} onClick={uploadImage} style={{ width: '85px' }} positive icon='check' />
                                        <Button disabled={loading} onClick={cancelCrop} style={{ width: '85px' }} icon='close' />
                                    </Button.Group>
                                </div>
                            )}
                        </Grid>
                        <Grid  item className={classes.photoTagStyle} xs={8} md={4}>
                            <Header  sub color="#585c60" content="Step 3 - Profile Preview" />
                            <Image className={classes.imageStyle} src={profile.photoURL || '/assets/user.png'} />

                        </Grid>
                    </Grid>
                </div>
            </Card>
        );
    }
}

export default withStyles(styles)(compose(
    connect(mapState, actions),
    firestoreConnect(auth => query(auth))
)(TutorProfilePhotoUpload));
