import React, { Component, Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';


const styles = theme => ({
    background: {
        maxWidth: '1200px',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: 200,
        marginTop: 100,
        height: '400px',
        display: 'block',
        [theme.breakpoints.down('xs')]: {
            marginBottom: 40,
            marginTop: 40
        }
    },
    TossmathHeader: {
        fontSize: '3rem',
        marginBottom: 0,
        fontWeight: 600,
        [theme.breakpoints.down('xs')]: {
            fontSize: '2rem',

        }
    },
    profileImage: {
        width: '100%',
        height: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        marginTop: 40,
        borderRadius: 0,
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        }
    },
    profileImageResponsive: {
        display: 'none',
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            textAlign: 'left',
            borderRadius: 0,
            width: '100%',
            height: '100%',
            marginTop: 20,
            marginRight: 'auto',
            marginLeft: 'auto',
            marginBottom: 40
        }
    },
    logoStyle: {
        borderRadius: 0,
        width: '200px',
        textAlign: 'right',
        marginBottom: 40

    },
    girdFirst: {
        marginTop: 135,
        paddingLeft: 40,
        letterSpacing: '2px',
        fontSize: '2.3rem',
        color: '#585c60',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'left',
            width: '80%',
            marginTop: 40,
            marginRight: 'auto',
            marginLeft: 'auto',
            paddingLeft: 0
        }
    },
    fontStyle1: {
        color: '#585c60',
        fontWeight: 600,
        marginBottom: 20,
        marginTop: 0,
        fontFamily: 'proxima-nova, sans-serif ',
        fontSize: '3rem',
        [theme.breakpoints.down('xs')]: {
            marginBottom: 10,
            fontSize: '1.7rem'
        }
    },
    fontStyle2: {
        fontFamily: 'proxima-nova, sans-serif ',
        letterSpacing: '1px',
        fontSize: '1.3rem',
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.1rem'
        }
    }

})


export const TutorVideoDemo = ({
    classes
}) => (
        <div className={classes.background}>
            <Grid container >
                <Grid item xs={12} md={6}>
                    <Avatar
                        className={classes.profileImage}
                        src="/assets/Video_Profile_Banner.png"
                    />
                </Grid>

                <Grid item xs={12} md={6} >
                    <div className={classes.girdFirst} >
                        {/* <p className={classes.TossmathHeader}>
                            <span style={{ color: '#4ca7ab' }}>Toss</span>math
                        </p> */}
                        <Typography
                            className={classes.fontStyle1}
                        >Video Profile
                        </Typography>
                        <Avatar
                            className={classes.profileImageResponsive}
                            src="/assets/Video_Profile_Banner.png"
                        />
                        <p
                            className={classes.fontStyle2}
                        >
                            Find out how our tutors can help you achieve academic success from video profile!
                            <br />Our video profile will give you a live experience of who you will be learning from.
                        </p>
                    </div>
                </Grid>

            </Grid>
        </div>
    )

export default withStyles(styles)(TutorVideoDemo);
