import React from 'react';
import { connect } from 'react-redux'
import { Modal, Form, Segment, Label, Divider } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import { combineValidators, composeValidators, isRequired, createValidator } from 'revalidate'
import TextInput from '../../../app/common/form/TextInput';
import { registerUser, facebookSocialLogin, googleSocialLogin } from '../authActions'
import SocialLogin from '../SocialLogin/SocialLogin'
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { closeModal, openModal } from "../../modals/modalActions";

const styles = theme => ({
  btnStyle: {
    backgroundColor: '#4ca7ab',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginBottom: 10,
    width: "100%",
    color: '#fff',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#0A4065',
    },
  },
  cursorStyle: {
    cursor: 'pointer',
    fontSize: '0.9rem'
  }

});
const actions = {
  closeModal,
  openModal,
  registerUser,
  facebookSocialLogin,
  googleSocialLogin
}

const isValidEmail = createValidator(
  message => value => {
    if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      return message
    }
  },
  'Invalid email address'
)

const isValidPassword = createValidator(
  message => value => {
    if (value && ! /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/i.test(value)) {
      return message
    }
  },
  'Password must be Minimum eight characters, at least one uppercase letter, one lowercase letter and one number'
)

const validate = combineValidators({
  firstName: isRequired('firstName'),
  lastName: isRequired('lastName'),

  email: composeValidators(
    isRequired('email'),
    isValidEmail
  )(),
  password: composeValidators(
    isRequired('password'),
    isValidPassword
  )(),

})

const RegisterForm = ({ handleSubmit, registerUser, error, invalid, submitting, facebookSocialLogin,
  googleSocialLogin, classes, openModal, closeModal }) => {
  return (
    <div>
      <Form size="large" onSubmit={handleSubmit(registerUser)}>
        <Field
          name="firstName"
          type="text"
          component={TextInput}
          placeholder="First Name"
        />
        <Field
          name="lastName"
          type="text"
          component={TextInput}
          placeholder="Last Name"
        />
        <Field
          name="email"
          type="email"
          component={TextInput}
          placeholder="Email"
        />
        <Field
          name="password"
          type="password"
          component={TextInput}
          placeholder="Password"
        />
        {error && <Label basic color='red'>{error}</Label>}
        <Button type="submit" disabled={invalid || submitting} className={classes.btnStyle}>
          Register
          </Button>
        <a className={classes.cursorStyle} onClick={() => openModal("LoginModal")}>Already Have an Account?</a>
        <Divider horizontal>
          Or
        </Divider>
        <SocialLogin facebookSocialLogin={facebookSocialLogin} googleSocialLogin={googleSocialLogin} />
      </Form>
    </div>
  );
};

export default withStyles(styles)(
  connect(
    null, actions)(
      reduxForm({ form: 'registerForm', validate }
      )(
        RegisterForm))
);