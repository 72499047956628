import React, { Component } from 'react'
import firebase from '../../app/config/firebase'
import TutorSearchForm from '../tutors/TutorSearchForm/TutorSearchForm'
import _ from 'lodash'
import { NavLink, Link } from 'react-router-dom'
import { langOptions } from "../../app/common/langOptions";
import { categories, levels, subjects } from "../../app/common/categories";
import { courseOptions } from "../../app/common/courseOptions";
import { connect } from 'react-redux';
import { compose } from 'recompose'
import { getUserRequest } from '../../features/user/userAction'
import { getCategoryRequest } from '../../app/common/categoryActions'
import { withStyles } from '@material-ui/core/styles';
import { toastr } from "react-redux-toastr";
import Script from 'react-load-script';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { openModal } from '../modals/modalActions'
import uuidv4 from 'uuid/v4';
import uuid from 'uuid';
import RecentlyTutorList from './RecentlyTutor/RecentlyTutorList';
import RecommendedTutorList from './RecommendedTutor/RecommendedTutorList';
import closeServiceModal from "../modals/closeServiceModal";
import CourseList from '../TestPrep/CourseList';
import StudentPassBanner from './StudentPassBanner/StudentPassBanner';
import TutorVideoDemo from './StudentPassBanner/TutorVideoDemo';
import TutorQuiz from './StudentPassBanner/TutorQuiz';
import ThreeStepsExplanation from './StudentPassBanner/ThreeStepsExplanation';

const styles = theme => ({
  gridStyle: {
    backgroundImage: 'url("/assets/BackgroundImage/Toronto_view_Banner.png")',
    width: '100vw',
    height: '110vh',
    backgroundSize: 'cover',
    borderRadius: '0px',
    zIndex: -2,
    marginTop: 90,
    [theme.breakpoints.down('xs')]: {
      backgroundImage: 'none',
      height: '100%',
    },
  },
  cardDisplay: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  girdFirst: {
    marginTop: '180px',
    letterSpacing: '2px',
    display: 'block',
    maxWidth: '600px',
    float: 'right',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  fontStyle1: {
    fontSize: '3.2rem',
    color: '#fff',
    fontWeight: 900,
    textAlign: 'right',
    marginRight: 80,
    marginBottom: 0,
    marginTop: 0

  },
  fontStyle2: {
    fontSize: '1.9rem',
    color: '#fff',
    textAlign: 'right',
    marginRight: 80,
    fontWeight: 300,
    marginTop: 20,
    [theme.breakpoints.down('xs')]: {
      color: '#585c60',
      fontSize: '1.1rem',
      textAlign: 'left',
      width: '80%',
      marginRight: 'auto',
      marginLeft: 'auto',
      marginTop: 80,
    }
  },
  downloadBtn: {
    backgroundColor: 'Transparent',
    color: '#fff',
    fontWeight: 600,
    fontSize: '1.1rem',
    letterSpacing: '1.7px',
    border: '1px solid white',
    padding: '8px 25px',
    borderRadius: 5,
    cursor: 'pointer',
    float: 'right',
    marginRight: 80,
    marginTop: 20,
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    '&:hover': {
      borderBottom: '1px solid white',
      color: '#fff'
    }
  },
  downloadBtn1: {
    backgroundColor: 'Transparent',
    color: '#669fff',
    fontWeight: 600,
    fontSize: '1.1rem',
    letterSpacing: '1.7px',
    border: 'none',
    textDecoration: 'underline',
    display: 'flex',
    width: '80%',
    marginRight: 'auto',
    marginLeft: 'auto',
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  heroText: {
    fontSize: '100%',
    marginTop: '-24%',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '800px',
    color: '#ffffff',
    zIndex: 5,

    [theme.breakpoints.down('xs')]: {
      display: 'none',

    },
  },
  backgroundwhite: {
    backgroundColor: '#fff',


  },
  subject: {
    width: "100%",
    height: "auto",
    borderRadius: '0px',
  },

  location: {
    width: "100%",
    height: "auto",
    textAlign: 'center',
    borderRadius: '0px',
    [theme.breakpoints.down('xs')]: {
      width: '60%',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: 40

    },

  },
  language: {
    width: "100%",
    height: "auto",
    borderRadius: '0px',

  },

  steps: {
    height: '200%',
    width: '90vw',
    marginTop: '5%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: '5%',
    [theme.breakpoints.down('xs')]: {
      padding: 0

    },
  },
  dot1: {
    marginTop: '50%',
    marginRight: 'auto',
    marginLeft: 'auto',
    height: 'auto',
    width: 50,
    borderRadius: '0px',
    [theme.breakpoints.down('xs')]: {
      display: 'none'

    },

  },
  secondhead: {
    zIndex: -1,
    fontSize: '3rem',
    fontWeight: 600,
    color: '#585c60',
    marginTop: 110,
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '1180px',
    marginBottom: 40,
    fontFamily: 'proxima-nova, sans-serif ',
    [theme.breakpoints.down('xs')]: {
      width: '80%',
      fontSize: '2em',
      marginTop: 40,
      paddingTop: 40,

    },
  },

  cloud: {
    borderRadius: '0px',
    width: '100vw',
    height: '80vh',
    marginTop: '7%',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
  },
  boxes: {
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: '-32%',
    width: 550,
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
    height: '200%',
  },
  box1: {
    height: 'auto',
    width: '150px',
    borderRadius: '0px',
    marginRight: 10,
    margin: 0,
    dispaly: 'inline',
    float: 'left'
  },
  box2: {
    height: 'auto',
    width: '150px',
    borderRadius: '0px',

  },
  box3: {
    height: 'auto',
    width: '150px',
    borderRadius: '0px',

  },
  boxes2: {
    width: 550,
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: '0px',
    [theme.breakpoints.down('xs')]: {
      display: 'none',

    },
  },
  seneca: {
    width: 400,
    height: 'auto',

    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: '0px',
    [theme.breakpoints.down('xs')]: {
      width: 300,
      float: 'center'
    },
  },
  senecabox: {
    display: 'block',
    marginTop: '100px',
    paddingTop: '40px',
    backgroundColor: 'white',
    height: 140,
    width: '100vw',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('xs')]: {
      height: 80,
      float: 'center',
      marginTop: '150px',

    },
  },
  verifiedStyle: {
    marginTop: 100,
    marginBottom: -80,
    display: 'flex',
    textAlign: 'center',
    width: '80%',
    marginRight: 'auto',
    marginLeft: 'auto',
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  midtermDescription: {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '1180px',
    marginTop: -30,
    color: '#585c60',
    [theme.breakpoints.down('xs')]: {
      width: '80%',
      marginRight: 'auto',
      marginLeft: 'auto',
      fontSize: '1.2rem'
    }
  },
  responsive1: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'flex'
    }
  },
  responsive2: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }

  }




});



//**********Suggestion Functions */
const getSuggestions = value => {

  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  return inputLength === 0 ? [] : subjects.filter(lang =>
    lang.value.toLowerCase().slice(0, inputLength) === inputValue
  );
};

const getSuggestionValue = suggestion => suggestion.value;

const renderSuggestion = suggestion => (
  <div style={{ listStyle: 'none' }}>
    {suggestion.value}
  </div>
);
//**************************** */

class HomePage extends Component {

  state = {
    testSubjectOptions: {},
    lat: '',
    lng: '',
    formattedAddress: '',
    subject: '',
    lang: ['English'],
    value: '',
    suggestions: [],
    topTutorsList: [],
    recentTutorsList: [],

  }

  getTutors = () => {
    let loadedTopTutors = [];
    let loaddedRecentTutors = [];
    const profileRef = firebase.firestore().collection('profiles');

    profileRef.where("top", ">", 0).get()
      .then(snap => {
        snap.forEach(function (doc) {
          // console.log(doc.id, " => ", doc.data());
          loadedTopTutors.push(doc.data())
        }
        )
      })
    profileRef.orderBy('created', 'desc').limit(10).get()
      .then(snap => {
        snap.forEach(function (doc) {
          // console.log(doc.id, " => ", doc.data());
          loaddedRecentTutors.push(doc.data())
        }
        )
        this.setState({
          topTutorsList: loadedTopTutors,
          recentTutorsList: loaddedRecentTutors,
        })
        // console.log('loadedTopTutors', loadedTopTutors);
        // console.log('loaddedRecentTutors', loaddedRecentTutors)
      })
      .catch(err => console.log(err));
  }

  componentDidMount() {

    this.props.getUserRequest()
    this.props.getCategoryRequest()
    this.getTutors()
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  onChangeSubject = (item) => {
    this.setState({
      level: item.value,
      levelLabel: item.label
    })
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
      level: newValue
    });
    // console.log(this.state.subject)
  };

  onChangeLang = (options) => {
    let langList = [];
    _.forEach(options, (option) => {
      langList.push(option.value);
    });
    this.setState(state => {
      // console.log(langList)
      return { lang: langList };
    });
  }

  handleScriptLoad = () => {
    var options = {
      types: ['geocode'],
    };

    // Initialize Google Autocomplete
    /*global google*/ // To disable any eslint 'google not defined' errors
    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById('autocomplete'),
      options,
    );

    // Fire Event when a suggested name is selected
    this.autocomplete.addListener('place_changed', this.handlePlaceSelect);
  }

  handlePlaceSelect = async () => {
    // Extract City From Address Object
    let addressObject = this.autocomplete.getPlace();
    let address = addressObject.address_components;

    // Check if address is valid
    if (address) {
      // Set State
      this.setState({
        addressComponents: addressObject.address_components,
        lat: addressObject.geometry.location.lat(),
        lng: addressObject.geometry.location.lng(),
        locationName: addressObject.name,
        formattedAddress: addressObject.formatted_address,
        placeId: addressObject.place_id,
        icon: addressObject.icon,
        plusCode: addressObject.plus_code,
        vicinity: addressObject.vicinity,
        utc_offset: addressObject.utc_offset,
        url: addressObject.url
      });
    }
  }

  handleSearch = (e) => {
    const { level, levelLabel, lang, formattedAddress, lat, lng } = this.state;
    const distance = [25];

    if (level === '' || lang.length === 0 || formattedAddress === '') {
      this.props.history.push({
        pathname: '/',
      })
      return toastr.error("Oops", "Please fill the form correctly")
    }

    const levelValue = { label: levelLabel, value: level };
    const langValue = [];

    _.forEach(lang, (item) => {
      langValue.push({
        label: item,
        value: item
      })
    })

    this.props.searchBy(
      langValue,
      levelValue,
      formattedAddress,
      lat,
      lng,
      distance,
      1,
      0,
      100,
      {
        university: true,
        college: true,
        high: true,
        middle: true,
        elementary: true,
        early: true,
        casual: true,
      }
    )
    this.props.history.push({
      pathname: '/tutors',
    })

    // console.log(Date.now())

    // if(this.props.auth.uid !== '25TdcSZIqmQzzysNs9Jy2roIZSF2' && this.props.auth.uid !== '3N7ClcLcUsegmIiFnLi6dF7XmjI2' && this.props.auth.uid !== 'vCsTGN3ZVsNRLRkD5h5uueyndO12' && this.props.auth.uid !== 'CiPm4mUV7SQtsEAE6wnDAMEjvYr1' ){
    if (!this.props.auth.isEmpty) {
      if (!this.props.admin) {
        firebase.firestore().collection('mainSearches').doc(`${Date.now()}`).set({
          a_name: this.props.auth.displayName || `${this.props.profile.firstName} ${this.props.profile.lastName}`,
          b_level: levelValue.value,
          c_formattedAddress: formattedAddress,
          langValue,
          uid: this.props.auth.uid,
          a_date: new Date(Date.now())
        })
        // .then(() => {
        //   return firebase.firestore().collection('users').doc(this.props.auth.uid).collection('searches').doc(`${uuid()}`).update({
        //     langValue,
        //     subjectValue,
        //     formattedAddress,
        //     date: new Date(Date.now())
        //   })
        // }).catch(err => console.log(err));
      }
    } else {
      return firebase.firestore().collection('mainSearches').doc(`${Date.now()}`).set({
        a_name: 'Not LoggedIn',
        b_level: levelValue.value,
        c_formattedAddress: formattedAddress,
        langValue,
        a_date: new Date(Date.now())
      })
    }
    // }


  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: getSuggestions(value)
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  reSendVerificationEmail = () => {
    this.props.openModal("EmailVerificationModal")
  }




  render() {
    // const {history} = this.props;
    // const { classes } = this.props;
    const { value, suggestions, topTutorsList, recentTutorsList } = this.state;
    const { classes, subjectOptions, auth, openModal, closeModal, profile } = this.props;
    const authenticated = auth.isLoaded && !auth.isEmpty;


    const tutorList2 = [

      {
        subjectList: ['English', 'Math'],
        tutorDisplayName: 'John Nam',
        tutorPhotoURL: '/assets/user.png',
        city: 'Toronto, ON'
      },
      {
        subjectList: ['English', 'Math'],
        tutorDisplayName: 'John Nam',
        tutorPhotoURL: '/assets/user.png',
        city: 'Toronto, ON'
      },
      {
        subjectList: ['English', 'Math'],
        tutorDisplayName: 'John Nam',
        tutorPhotoURL: '/assets/user.png',
        city: 'Toronto, ON'
      },
      {
        subjectList: ['English', 'Math'],
        tutorDisplayName: 'John Nam',
        tutorPhotoURL: '/assets/user.png',
        city: 'Toronto, ON'
      },
      {
        subjectList: ['English', 'Math'],
        tutorDisplayName: 'John Nam',
        tutorPhotoURL: '/assets/user.png',
        city: 'Toronto, ON'
      },
      {
        subjectList: ['English', 'Math'],
        tutorDisplayName: 'John Nam',
        tutorPhotoURL: '/assets/user.png',
        city: 'Toronto, ON'
      },
    ]



    // openModal('closeServiceModal')
    return (

      <div>
        {/* <Script
          url="https://maps.googleapis.com/maps/api/js?key=AIzaSyC_B3o6e4mHhWjdZij2lYWPqK_Tw5O_jC8&libraries=places&language=en"
          onLoad={this.handleScriptLoad}
        /> */}
        {/* <Avatar className={classes.img} src="/assets/Tossmath_Landing_web.png" alt="heroimg"  >
        </Avatar> */}
        <Grid
          className={classes.gridStyle}
          container
        >

          <Grid item xs={0} md={7}>
            <div className={classes.girdFirst} >
              <h1
                className={classes.fontStyle1}
              >Having Difficulties with Mathematics?</h1>
              {/* <h1
                className={classes.fontStyle1}
              >with Tossmath</h1> */}
              <h3
                className={classes.fontStyle2}
              >
                Browse Our Handpicked Math Tutors in Toronto and Download Our Course Prep Packages
              </h3>

              <a
                href="/testprep"
                className={classes.downloadBtn}
              >
                DOWNLOAD
              </a>
            </div>



            {(authenticated && !auth.emailVerified && auth.providerData[0].providerId !== "facebook.com") &&
              <p className={classes.verifyDesktop}>
                <a className={classes.verifiedStyle} onClick={() => {
                  this.reSendVerificationEmail();
                }}>
                  If You Haven't Received the Verification Email, Plesae Click this Link
              </a>
              </p>
            }</Grid>
          <Grid item xs={12} md={5}>
            <TutorSearchForm
              handleSearch={this.handleSearch}
              onChangeSubject={this.onChangeSubject}
              onChange={this.onChange}
              onChangeLang={this.onChangeLang}
              subjectOptions={subjects}
              levelOptions={levels}
              langOptions={langOptions}
              handleScriptLoad={this.handleScriptLoad}
              value={value}
              suggestions={suggestions}
              onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
              onSuggestionsClearRequested={this.onSuggestionsClearRequested}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={renderSuggestion}
            />
          </Grid>
        </Grid>
        <div style={{marginTop: 40}}>
          <h3
            className={classes.fontStyle2}
          >
            Browse Our Course Package
              </h3>
          <a
            href="/testprep"
            className={classes.downloadBtn1}
          >
            Course Package &#62;
              </a>
        </div>
        {/* <div
          className={classes.cardDisplay}
        >
          <TutorSearchForm
            handleSearch={this.handleSearch}
            onChangeSubject={this.onChangeSubject}
            onChange={this.onChange}
            onChangeLang={this.onChangeLang}
            subjectOptions={subjects}
            langOptions={langOptions}
            levelOptions={levels}
            value={value}
            suggestions={suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            handleScriptLoad={this.handleScriptLoad}
            id="autocomplete"
          />
        </div> */}



        {/* <h2 className={classes.secondhead} id="midtermPrep">Test Preps- Part A</h2>
        <div className={classes.midtermDescription}>
          <p
          >
            100% Follwing the Ontario Curriculum and designed to teach students from concepts to competition level problems. Part A includes the first four chapters of the course.
          </p>
        </div>


        <CourseList
          courseOptions={courseOptions}
          auth={auth}
          firebase={firebase}
          admin={this.props.admin}
          profile={profile}
        /> */}

        {/* <h2 className={classes.secondhead + ' ' + classes.responsiveLine}>Our Top Tutors</h2> */}

        {/* <RecommendedTutorList
          tutorLists={topTutorsList}
        // tutorLists={tutorList2}
        /> */}
        {/* <h2 className={classes.secondhead}>Recently Added Tutors</h2>

        <RecentlyTutorList
          tutorLists={recentTutorsList}
        /> */}
        {/* <StudentPassBanner /> */}
        <div className={classes.responsive1}>
          <TutorQuiz />
        </div>
        <TutorVideoDemo />
        <div className={classes.responsive2}>
          <TutorQuiz />
        </div>
        <ThreeStepsExplanation />

        <div className={classes.senecabox}>
          <Avatar className={classes.seneca} src="/assets/SenecaHelix.png" alt="helix"  >
          </Avatar>
        </div>

      </div >



    );
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserRequest: () => dispatch(getUserRequest()),
    searchBy: (lang, level, formattedAddress, lat, lng, distance, searchPage, priceLow, priceHigh) => dispatch({
      type: 'SEARCH_BY',
      langs: lang,
      level: level,
      location: formattedAddress,
      lat: lat,
      lng: lng,
      distance: distance,
      searchPage: searchPage,
      priceLow,
      priceHigh,
    }),
    getCategoryRequest: () => dispatch(getCategoryRequest()),
    openModal: (payload) => dispatch(openModal(payload)),
  }
}

const mapState = (state) => ({
  subjectOptions: state.categories.subjects,
  auth: state.firebase.auth,
  admin: state.firebase.profile.admin,
  profile: state.firebase.profile,
})

export default withStyles(styles)(
  compose(
    connect(
      mapState, mapDispatchToProps)
  )(
    HomePage)
);
