import firebase from "../../app/config/firebase";
import { toastr } from "react-redux-toastr";
import { FETCH_TUTORPROFILES } from "../tutors/tutorConstants";
import cuid from "cuid";
import moment from "moment";
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError
} from "../async/asyncActions";

export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_ERROR = "GET_USER_ERROR";

export const getUserRequest = () => {
  return async (dispatch, _, { getFirebase, getFirestore }) => {
    try {
      dispatch(asyncActionStart());
      const currentUser = await getFirebase().auth().currentUser
      // console.log("getUserRequest in Useraction",currentUser)

      if (currentUser !== null) {
        dispatch({ type: GET_USER_REQUEST });

        const uid = currentUser.uid;
        const userRef = getFirestore().collection('users').doc(uid);
        userRef.get().then((doc) => {
          dispatch({
            type: GET_USER_SUCCESS,
            me: doc.data(),
          })
        }
        ).catch(error => {
          console.log("error getting document", error)
          dispatch({
            type: GET_USER_ERROR,
            error: error
          })
        })
      }
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      console.log(error)
    }
  }
}

export const updateProfile = user => async (
  dispatch,
  getState,
  { getFirebase }
) => {
  const firebase = getFirebase();
  const { isLoaded, isEmpty, ...updatedUser } = user;
  if (updatedUser.dateOfBirth && updatedUser.dateOfBirth._isAMomentObject) {
    updatedUser.dateOfBirth = moment(updatedUser.dateOfBirth).toDate();
  }
  try {
    await firebase.updateProfile(updatedUser);
    toastr.success("Success", "Profile updated");
  } catch (error) {
    console.log(error);
  }
};

export const uploadProfileImage = (file, fileName) => async (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const imageName = cuid();
  const firebase = getFirebase();
  const firestore = getFirestore();
  const user = firebase.auth().currentUser;
  const path = `${user.uid}/user_images`;
  const options = {
    name: imageName
  };
  try {
    dispatch(asyncActionStart());
    //upload the file to firebase storage
    let uploadedFile = await firebase.uploadFile(path, file, null, options);
    //get url of image
    let downloadURL = await uploadedFile.uploadTaskSnapshot.ref.getDownloadURL();

    //get userdoc
    let userDoc = await firestore.get(`users/${user.uid}`);
    const tutorProfileDocRef = await firestore.collection('profiles').doc(`${user.uid}`).get();
    
    //check if user has photo, if not update porifle with new image
    if (!userDoc.data().photoURL || userDoc.data().photoURL === '/assets/user.png' || fileName==='tutorForm') {
      await firebase.updateProfile({
        photoURL: downloadURL,
        photoUpdated: true,
        lastUpdated: moment().toDate(),
      });
      await user.updateProfile({
        photoURL: downloadURL,
        photoUpdated: true,
        lastUpdated: moment().toDate(),
      });
      if (tutorProfileDocRef.exists) {
        await firestore.collection('profiles').doc(`${user.uid}`)
          .update({
            tutorPhotoURL: downloadURL,
            photoUpdated: true,
          })
      }
    }


    
    //add the new photo to photos collection
    await firestore.add(
      {
        collection: "users",
        doc: user.uid,
        subcollections: [{ collection: "photos" }]
      },
      {
        name: imageName,
        url: downloadURL
      }
    );
    dispatch(asyncActionFinish());
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
    throw new Error("Problem uploading photos");
  }
};


export const uploadProfileVideo = (file, fileName) => async (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const videoName = cuid();
  const firebase = getFirebase();
  const firestore = getFirestore();
  const user = firebase.auth().currentUser;
  const path = `${user.uid}/user_video`;
  const options = {
    name: fileName.replace(/ /g, '_'), 
  };
  try {
    dispatch(asyncActionStart());
    //upload the file to firebase storage
    let uploadedFile = await firebase.uploadFile(path, file, null, options);
    //get url of image
    let downloadURL = await uploadedFile.uploadTaskSnapshot.ref.getDownloadURL();
    //get userdoc
    let userDoc = await firestore.get(`users/${user.uid}`);
    
    
      await firebase.updateProfile({
        tutorVideo: downloadURL,
        tutorVideoUpdated: true,
        lastUpdated: moment().toDate(),
      });
      
      await user.updateProfile({
        tutorVideo: downloadURL,
        tutorVideoUpdated: true,
        lastUpdated: moment().toDate(),
      });
  
    //add the new photo to photos collection
    await firestore.add(
      {
        collection: "users",
        doc: user.uid,
        subcollections: [{ collection: "video" }]
      },
      {
        name: videoName,
        url: downloadURL
      }
    );
    dispatch(asyncActionFinish());

  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
    throw new Error("Problem uploading photos");
  }
};

export const deletePhoto = photo => async (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firebase = getFirebase();
  const firestore = getFirestore();
  const user = firebase.auth().currentUser;
  try {
    await firebase.deleteFile(`${user.uid}/user_images/${photo.name}`);
    await firestore.delete({
      collection: "users",
      doc: user.uid,
      subcollections: [{ collection: "photos", doc: photo.id }]
    });
  } catch (error) {
    console.log(error);
    throw new Error("Problem deleting the photo");
  }
};

export const setMainPhoto = photo => async (
  dispatch,
  getState,
) => {
  dispatch(asyncActionStart())
  const firestore = firebase.firestore();
  const user = firebase.auth().currentUser;
  const today = new Date(Date.now);
  let userDocRef = firestore.collection('users').doc(user.uid);

  try {
    let batch = firestore.batch();

    //Updated:
    await batch.update(userDocRef, {
      photoURL: photo.url,
      lastUpdated: moment().toDate(),
    });

    const tutorProfileDocRef = await firestore.collection('profiles').doc(`${user.uid}`).get();
    if (tutorProfileDocRef.exists) {
      await firestore.collection('profiles').doc(`${user.uid}`)
        .update({
          tutorPhotoURL: photo.url,
          lastUpdated: moment().toDate(),
        })
    }


    await userDocRef.collection('myReviews').get().then(querySnapshot => {
      querySnapshot.forEach(doc => {
        // console.log(doc.id, " => ", doc.data());
        firestore.collection('profiles').doc(`${doc.id}`).collection('reviews').doc(`${user.uid}`).update({ photoURL: photo.url })
      })
    })


    await batch.commit();
    dispatch(asyncActionFinish());

  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
    throw new Error("Problem setting main photo");
  }
};

export const goingToEvent = tutorProfile => async (
  dispatch,
  getState,
) => {
  dispatch(asyncActionStart())
  const firestore = firebase.firestore();
  const user = firebase.auth().currentUser;
  const photoURL = getState().firebase.profile.photoURL;

  const attendee = {
    going: true,
    joinDate: Date.now(),
    photoURL: photoURL || "/assets/user.png",
    displayName: user.displayName,
    host: false
  };
  try {
    let eventDocRef = firestore.collection('profiles').doc(tutorProfile.id);
    let eventAttendeeDocRef = firestore.collection('event_attendee').doc(`${tutorProfile.id}_${user.uid}`);

    await firestore.runTransaction(async (transaction) => {
      await transaction.get(eventDocRef);
      await transaction.update(eventDocRef, {
        [`attendees.${user.uid}`]: attendee
      });
      await transaction.set(eventAttendeeDocRef, {
        tutorProfileId: tutorProfile.id,
        userUid: user.uid,
        host: false
      })

    });

    dispatch(asyncActionFinish());
    toastr.success("Success", "You have signed up to the event");
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
    toastr.error("Oops", "Problem signing up to event");
  }
};

export const cancelGoingToEvent = event => async (
  dispatch,
  getState,
  { getFirestore, getFirebase }
) => {
  const firestore = getFirestore();
  const firebase = getFirebase();
  const user = firebase.auth().currentUser;
  try {
    await firestore.update(`profiles/${event.id}`, {
      [`attendees.${user.uid}`]: firestore.FieldValue.delete()
    });
    await firestore.delete(`event_attendee/${event.id}_${user.uid}`);
    toastr.success("Success", "You have removed yourself from the event");
  } catch (error) {
    console.log(error);
    toastr.error("Oops", "something went wrong");
  }
};

export const getUserTutorProfile = (userUid, activeTab) => async (
  dispatch,
  getState
) => {
  dispatch(asyncActionStart());
  const firestore = firebase.firestore();
  const today = new Date(Date.now());
  let tutorProfileRef = firestore.collection("event_attendee");
  let query;
  switch (activeTab) {
    case 1:
      query = tutorProfileRef.where("userUid", "==", userUid);
      break;
    case 3:
      query = tutorProfileRef
        .where("userUid", "==", userUid)
        .where("host", "==", true);
      break;
    default:
      query = tutorProfileRef
        .where("userUid", "==", userUid)
        .where("host", "==", true);
  }

  try {
    let querySnap = await query.get();
    let tutorProfiles = [];

    for (let i = 0; i < querySnap.docs.length; i++) {
      let tut = await firestore
        .collection("profiles")
        .doc(querySnap.docs[i].data().tutorProfileId)
        .get();
      tutorProfiles.push({ ...tut.data(), id: tut.id });
    }
    dispatch({ type: FETCH_TUTORPROFILES, payload: { tutorProfiles } });
    dispatch(asyncActionFinish());
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

export const followUser = userToFollow => async (dispatch, getState, { getFirebase, getFirestore }) => {
  const firestore = getFirestore();
  const firebase = getFirebase();
  const user = firebase.auth().currentUser;
  const following = {
    photoURL: userToFollow.photoURL || '/assets/user.png',
    city: userToFollow.city || 'unknown city',
    displayName: userToFollow.displayName
  }
  try {
    await firestore.set(
      {
        collection: 'users',
        doc: user.uid,
        subcollections: [{ collection: 'following', doc: userToFollow.id }]
      },
      following
    );
  } catch (error) {
    console.log(error);
  }
}


export const unfollowUser = (userToUnfollow) =>
  async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();
    const user = firebase.auth().currentUser;
    try {
      await firestore.delete({
        collection: 'users',
        doc: user.uid,
        subcollections: [{ collection: 'following', doc: userToUnfollow.id }]
      })
    } catch (error) {
      console.log(error)
    }
  }