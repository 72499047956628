import React from 'react';
import { Card, Grid, Header, Image, Menu, Segment, Tab } from 'semantic-ui-react';
import { Link } from 'react-router-dom'
import format from 'date-fns/format'  
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({


})

const panes = [
  {menuItem: 'All Events', pane: {key:'allEvents'}},
  {menuItem: 'Past Events', pane: {key:'pastEvents'}},
  {menuItem: 'Hosting', pane: {key:'hosting'}},
]

const UserDeteiledEvents = ({tutorProfiles, tutorProfileLoading, changeTab, classes}) => {
  return (
    <Grid.Column width={12}>
      <Segment attached loading={tutorProfileLoading}>
        <Header icon="calendar" content="Events" />
        <Tab onTabChange={(e, data) => changeTab(e,data)} panes={panes} menu={{secondary:true, pointing:true}} />
        <br/>

        <Card.Group itemsPerRow={5}>
        {tutorProfiles && tutorProfiles.map((tutorProfile)=> (
          <Card as={Link} to={`/tutor/${tutorProfile.id}`} key={tutorProfile.id}>
            <Image src={tutorProfile.tutorPhotoURL} />
            <Card.Content>
              <Card.Header textAlign="center">{tutorProfile.tutorDisplayName}</Card.Header>
              <Card.Meta textAlign="center">
                <div>
                  {format(tutorProfile.created.toDate(), 'DD MMM YYYY')}
                </div>

              </Card.Meta>
            </Card.Content>
          </Card>
        ))}
        </Card.Group>
      </Segment>
    </Grid.Column>
  );
};

export default withStyles(styles)(UserDeteiledEvents);
