import React, { Component } from 'react'
import firebase from '../../app/config/firebase';

class adminEmailPage extends Component {

   sendMail = () => {
       firebase.database().ref('mail').push().set({
           timestamp: firebase.database.ServerValue.TIMESTAMP,
       })
       .then(() => console.log('YAY!'))
       .catch(err => console.log(err));

   }

    render() {

        return (
            <div style={{ marginTop: '150px' }}>
                <button onClick={this.sendMail}>SEND MAIL</button>
            </div>
        )
    }
}

export default adminEmailPage