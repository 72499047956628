import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import _ from "lodash";
import Script from 'react-load-script';
import { toastr } from "react-redux-toastr";
import { compose } from 'recompose'
//categories
import { langOptions } from "../../../app/common/langOptions";
import { levels as levelOptions } from "../../../app/common/categories";
//Actions
import { getTutorProfilesForDashboard } from "../tutorActions";
import { searchByRequest } from "../TutorSearchForm/searchActions";
//Components
import LoadingComponent from "../../../app/layout/LoadingComponent";
import TutorList from "../TutorList/TutorList";
import TutorSearchForm2 from "../TutorSearchForm/TutorSearchForm2";
//Filters
import TutorDistanceFilter from "../TutorFilters/TutorDistanceFilter/TutorDistanceFilter";
import TutorPriceFilter from "../TutorFilters/TutorPriceFilter/TutorPriceFilter";
import TutorLevelFilter from "../TutorFilters/TutorLevelFilter/TutorLevelFilter";
//UI Components
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import { Loader } from "semantic-ui-react";
import Drawer from '@material/react-drawer';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  form2: {
    zIndex: 2,
    display: 'block',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },

  searchbox: {
    marginTop: "7%",
    marginLeft: "auto",
    marginRight: "auto",
    width: 900,
    zIndex: 1,
    borderTop: '5px solid #4ca7ab',
    padding: '0% 5% 3% 3%',
    display: 'inlineBlock',
    borderRadius: '7px',
    overflow: 'visible',
    [theme.breakpoints.up('xs')]: {
      height: 180
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 120,
      width: '95%',
      marginLeft: 'auto',
      marginRight: 'auto'
    },
  },

  filterDash: {
    maxWidth: '1100px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  filter1: {
    marginTop: 40,
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  filter2: {
    marginTop: 40,
    [theme.breakpoints.down('xs')]: {
      marginTop: '88px'
    }
  },
  filter3: {
    marginTop: 40,
    [theme.breakpoints.down('xs')]: {
      marginTop: '150px',
      opacity: 0.2,
      zIndex: -1,
    }
  },
  buttonStyle: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      float: 'left',
      display: 'flex',
      marginTop: 100,
      position: 'fixed',
      zIndex: 2,
    }
  },
  toggleBtn: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      bottom: 50,
      right: 30,
      borderRadius: 50,
      width: 44,
      height: 44,
      border: '1px solid #669fff',
      backgroundColor: '#fff',
      boxShadow: '0 0 10px #e1e1e6',
      position: 'fixed',
      zIndex: 10,
      "&:hover": {
        backgroundColor: '#fff',
      }
    }
  },
  filterIcon: {
    width: '21px',
    height: '21px',
    margin: 'auto',
  },
  toggleBtn1: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      marginTop: 90,
      color: 'white',
      border: 0,
      backgroundColor: '#4ca7ab',
      width: '100%',
      height: '50px',
      cursor: 'pointer',
      borderShadow: 'none',
      paddingLeft: 20,
      paddingTop: 10,
      "&:hover": {
        backgroundColor: '#4ca7ab',
      }
    }
  },
  
  filterOpen: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      fontSize: '1.3rem',
      color: 'white',
      fontWeight: 600,
      marginLeft: 5,
      marginTop: -3
    }
  }




})


const mapStateToProps = state => {
  return {
    langs: state.searches.langs,
    subject: state.searches.subject,
    level:state.searches.level,
    location: state.searches.location,
    lat: state.searches.lat,
    lng: state.searches.lng,
    searchList: state.searches.list,
    filteredList: state.searches.filteredList,
    isFetching: state.searches.isFetching,
    tutors: state.firestore.ordered.tutorProfiles,
    loading: state.async.loading,
    distance: state.searches.distance,
    priceLow: state.searches.priceLow,
    priceHigh: state.searches.priceHigh,
  };
};


const mapDispatchToProps = dispatch => {
  return {
    searchByRequest: payload => dispatch(searchByRequest(payload)),
    searchBy: (lang, level, formattedAddress, lat, lng, distance, searchPage, priceLow, priceHigh) =>
      dispatch({
        type: "SEARCH_BY",
        langs: lang,
        level: level,
        location: formattedAddress,
        lat: lat,
        lng: lng,
        distance: distance,
        searchPage: searchPage,
        priceLow,
        priceHigh
      }),
  };
};


//**********Suggestion Functions */
const getSuggestions = value => {

  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  return inputLength === 0 ? [] : levelOptions.filter(lang =>
    lang.value.toLowerCase().slice(0, inputLength) === inputValue
  );
};

const getSuggestionValue = suggestion => suggestion.value;

const renderSuggestion = suggestion => (
  <div style={{ listStyle: 'none' }}>
    {suggestion.value}
  </div>
);
//**************************** */


class TutorProfilesDashboard extends Component {
  state = {
    moreTutorProfiles: false,
    loadingInitial: true,
    loadedTutorProfiles: [],
    contextRef: {},
    location: {
      lat: "",
      lng: ""
    },
    formattedAddress: "",
    lang: ['English'],
    level: "",
    langList: [],
    value: '',
    suggestions: [],
    open: false,
    intervalId: 0
  };


  handleToggle = () => {
    this.setState((prevState) => {
      return { open: !prevState.open }
    })
    window.scrollTo(0, 0);
  }

  handleToggleClose = () => {
    this.setState({ open: false });
  }


  async componentDidMount() {
    // let next = await this.props.getTutorProfilesForDashboard();
    const { lat, lng, langs, level, location } = this.props;
    // console.log(lat, lng);
    let langList = [];
    _.forEach(langs, lang => {
      langList.push(lang.value);
    });

    this.setState({
      langList: langList,
      level: level.value,
      formattedAddress: location,
      location: {
        lat: lat,
        lng: lng
      },
      lat,
      lng
    });

    let languages = [];
    _.each(langs, lang => {
      languages.push(lang.value);
    });

    // fetch profile data
    this.props.searchByRequest({
      langList: languages,
      level: level.value,
      location: {
        lat: lat,
        lng: lng,
      },
      distance: this.props.distance ? this.props.distance[0] : 25,
      price: {
        priceLow: this.props.priceLow ? this.props.priceLow : 0,
        priceHigh: this.props.priceHigh ? this.props.priceHigh : 100,
      }
    });
  }

  handleContextRef = contextRef => this.setState({ contextRef });

  //*******************AutoSuggest Inner Functions****** */
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: getSuggestions(value)
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  //*********************************** */

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  onChangeSubject = (item) => {
    this.setState({ level: item.value })
    // console.log(this.state.subject)
  }


  // onChangeLang = (options) => {
  //   let langList = [];
  //   _.forEach(options, (option) => {
  //     langList.push(option.value);
  //   });
  //   this.setState(state => {
  //     // console.log(langList)
  //     return { lang: langList };
  //   });
  // }

  handleScriptLoad = () => {
    var options = {
      types: ['geocode'],
    };

    // Initialize Google Autocomplete
    /*global google*/ // To disable any eslint 'google not defined' errors
    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById('autocomplete'),
      options,
    );

    // Fire Event when a suggested name is selected
    this.autocomplete.addListener('place_changed', this.handlePlaceSelect);
  }

  handlePlaceSelect = () => {
    // Extract City From Address Object

    let addressObject = this.autocomplete.getPlace();
    let address = addressObject.address_components;


    if (address) {
      // Set State
      this.setState({
        addressComponents: addressObject.address_components,
        lat: addressObject.geometry.location.lat(),
        lng: addressObject.geometry.location.lng(),
        locationName: addressObject.name,
        formattedAddress: addressObject.formatted_address,
        placeId: addressObject.place_id,
        icon: addressObject.icon,
        plusCode: addressObject.plus_code,
        vicinity: addressObject.vicinity,
        utc_offset: addressObject.utc_offset,
        url: addressObject.url
      });
    }
  }


  onChangeLang = (options) => {
    let langList = [];
    _.forEach(options, (option) => {
      langList.push(option.value);
    });
    this.setState(state => {
      // console.log(langList)
      return { langList };
    });
  }

  handleSearch = (e) => {
    const { level, langList, formattedAddress, lat, lng } = this.state;

    if (level === '' || langList.length === 0 || formattedAddress === '') {
      // this.props.history.push({
      //   pathname: '/',
      // })
      return toastr.error("Oops", "Please fill the form correctly")
    }

    const levelValue = { label: level, value: level };
    const langValue = [];

    _.forEach(langList, (item) => {
      langValue.push({
        label: item,
        value: item
      })
    })

    this.props.searchByRequest({
      langList: this.state.langList,
      level: this.state.level,
      location: {
        lng: this.state.lng,
        lat: this.state.lat,
      },
      distance: this.props.distance,
      searchPage: 1,
    })

    this.props.searchBy(
      langValue,
      levelValue,
      formattedAddress,
      lat,
      lng,
      this.props.distance,
      1
    )
  }

  render() {
    const { isFetching, searchList, location, level, langs, classes, loading, filteredList } = this.props;
    const { moreTutorProfiles, loadedTutorProfiles, value, suggestions } = this.state;
    // console.log("In dashboard render", subject.value);
    // check location, subject, langs

    // console.log('dashboard', level)
    if (level === "" || langs.length === 0 || location === "") {
      return <Redirect to="/" />;
    }
    let sideDrawer;
    let backDrawer;

    if (this.state.open) {
      sideDrawer = <Drawer
        style={{
          width: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 10,
        }}
      ><button onClick={this.handleToggle} className={classes.toggleBtn1} >
          <Typography className={classes.filterOpen}>Filter</Typography>
        </button>
        <TutorSearchForm2
          style={{ zIndex: 2 }}
          levelOptions={levelOptions}
          langOptions={langOptions}
          //Default Values
          levelLabel={level}
          langLabel={this.props.langs}
          locationValue={this.props.location}
          handleSearch={this.handleSearch}
          onChangeSubject={this.onChangeSubject}
          onChangeLang={this.onChangeLang}
          suggestions={suggestions}
          value={value}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
        />

        {/* <TutorDistanceFilter />
        <TutorPriceFilter /> */}
        {/* {isFetching ? '' :
          <TutorLevelFilter
            tutorList={searchList}
          />
        } */}
      </Drawer>;
      backDrawer = <div
        onClick={this.handleToggleClose}
        style={{
          position: 'fixed',
          width: '100%',
          height: '100%',
          background: 'rgba(0,0,0,0.3)',
          zIndex: 2
        }} />
    }

    return (
      <div>
        <Script
          url="https://maps.googleapis.com/maps/api/js?key=AIzaSyC_B3o6e4mHhWjdZij2lYWPqK_Tw5O_jC8&libraries=places&language=en"
          onLoad={this.handleScriptLoad}
        />
        <div  >
          <div className={classes.form2}>
            <TutorSearchForm2
              levelOptions={levelOptions}
              langOptions={langOptions}
              //Default Values
              levelLabel={level}
              langLabel={this.props.langs}
              locationValue={this.props.location}
              handleSearch={this.handleSearch}
              onChangeSubject={this.onChangeSubject}
              onChangeLang={this.onChangeLang}
              suggestions={suggestions}
              value={value}
              onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
              onSuggestionsClearRequested={this.onSuggestionsClearRequested}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={renderSuggestion}
            />
          </div>

          {this.state.open ? " " : <button onClick={this.handleToggle} className={classes.toggleBtn} >
            <img className={classes.filterIcon} src="/assets/Filter_Icon2.png" />
          </button>}

          {/* <DrawerToggle /> */}
          {sideDrawer}
          {backDrawer}

          <Grid className={classes.filterDash} container >

            <Grid className={classes.filter1} item xs={12} md={4}>

              <TutorDistanceFilter />
              <TutorPriceFilter />

              {/* {isFetching ? '' :
                <TutorLevelFilter
                  tutorList={searchList}
                />
              } */}
            </Grid>
            <Grid className={this.state.open ? classes.filter3 : classes.filter2} item xs={12} md={8} >
              <div ref={this.handleContextRef}>
                {isFetching ? <LoadingComponent inverted /> :
                  <TutorList
                    level={this.state.level}
                    locationValue={this.props.location}
                    loading={loading}
                    moreTutorProfiles={moreTutorProfiles}
                    tutors={filteredList || searchList}
                    getNextTutorProfiles={this.getNextTutorProfiles}
                  />
                }
              </div>
            </Grid>
          </Grid>
          <Loader active={loading} />
        </div>
      </div>
    );

  }
}

export default withStyles(styles)(
  compose(
    connect(
      mapStateToProps, mapDispatchToProps)
  )(
    TutorProfilesDashboard)
);