import React, { Component } from "react";
import firebase from "../../../../app/config/firebase";
import { NavLink, Link } from 'react-router-dom'
import { connect } from "react-redux";
import { Button, Form, Header, Item, ItemContent } from "semantic-ui-react";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import MailOutline from "@material-ui/icons/MailOutline";

const styles = theme => ({
  basicbox: {
    marginTop: 20,
    paddingTop: 20,
    paddingBottom: 20,
    width: "95%",
    marginLeft: "auto",
    marginRight: "auto",
    display: 'flex',
    boxShadow: 'none',
    border: '1px solid #e1e1e6',
    borderRadius: 10,
    fontFamily: 'proxima-nova, sans-serif ',
    color: '#585c60',
    [theme.breakpoints.down("xs")]: {
      display: 'none'
    }
  },
 
  headerbg: {
    margin: "3% 5% 3% 5%"
  },
  displayname: {
    marginTop: "2%"
  },
  bttn: {
    backgroundColor: "#4ca7ab"
  },
  whitebar: {
    width: 50,
    backgroundColor: "white"
  },
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60
  },
  hdfont: {
    display: "inlineBlock",
    color: "#4ca7ab"
  },
  nameStyle: {
    fontSize: "1.7rem",
    fontFamily: 'proxima-nova, sans-serif ',
    color: '#585c60',
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.4rem",
      marginLeft: 40
    }
  },
  emailStyle: {
    fontSize: "0.9rem",
    marginTop: 5,
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.9rem",
      marginLeft: 40
    }
  },
  emailIcon: {
    color: '#585c60',
    marginTop: '-22px',
    marginLeft: 30,
    [theme.breakpoints.down("xs")]: {
      marginTop: '-10px'
    }
  }
});

class ChatUserInfo extends Component {
  state = {};

  // displayLastMessage = (channelId) => {
  //   const messagesRef= firebase.database().ref('chats').child(channelId).child('messages');

  //   messagesRef
  // }

  render() {
    const { classes, currentUserId, currentUser } = this.props;
    // console.log(currentUser);
    return (
      <React.Fragment>
       
        <Grid container>
          <Grid item xs={12} md={12}>
            <Card className={classes.basicbox}>
              <Grid container>
                <Grid item xs={2} md={2}>
                  <div className={classes.headerbg}>
                    <Avatar
                      style={{ marginLeft: 20, width: 60, height: "auto" }}
                      src={currentUser.photoURL || "/assets/user.png"}
                    />
                  </div>
                </Grid>
                <Grid item xs={10} md={9}>
                  <Typography className={classes.nameStyle}>
                    {currentUser.displayName}
                  </Typography>
                  <Typography className={classes.emailStyle}>
                    <MailOutline style={{fontSize: '20px'}} />
                    <p
                      className={classes.emailIcon}
                    >
                      {currentUser.email}
                    </p>
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(connect(null)(ChatUserInfo));
