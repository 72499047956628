import { createReducer } from '../../app/common/util/reducerUtil';
import { CREATE_TUTORPROFILE, UPDATE_TUTORPROFILE, DELETE_TUTORPROFILE, FETCH_TUTORPROFILES } from './tutorConstants';

const initialState = [];

export const createTutorProfile = (state, payload) => {
    return [...state, Object.assign({}, payload.tutorProfile)]
}

export const updateTutorProfile = (state, payload) => {
    return [
        ...state.filter(tutorProfile => tutorProfile.id !== payload.tutorProfile.id),
        Object.assign({}, payload.tutorProfile)
    ]
}

export const deleteTutorProfile = (state, payload) => {
    return [                                                    //tutorProfileId 를 tutorUid로 바꿈
        ...state.filter(tutorProfile => tutorProfile.id !== payload.tutorUid)
    ]
}
export const fetchTutorProfiles = (state, payload) => {
    return payload.tutorProfiles
}

export default createReducer(initialState, {
    [CREATE_TUTORPROFILE]: createTutorProfile,
    [UPDATE_TUTORPROFILE]: updateTutorProfile,
    [DELETE_TUTORPROFILE]: deleteTutorProfile,
    [FETCH_TUTORPROFILES]: fetchTutorProfiles
})