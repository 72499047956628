import React, { Component } from "react";
import { Segment, Form, Sticky } from "semantic-ui-react";
import { connect } from "react-redux";
import PlaceInput from "../../../app/common/form/PlaceInput";
import SelectInput from "../../../app/common/form/SelectInput";
import Autosuggest from 'react-autosuggest'
import { reduxForm, Field } from "redux-form";
import { withFirestore } from "react-redux-firebase";
import {
  composeValidators,
  combineValidators,
  isRequired,
  hasLengthGreaterThan
} from "revalidate";
import Select from "react-select";
import InputBase from "@material-ui/core/InputBase";
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Script from 'react-load-script';
const styles = theme => ({
  searchbox: {
    maxWidth: 400,
    zIndex: 1,
    padding: '3%',
    display: 'inlineBlock',
    borderRadius: '7px',
    overflow: 'visible',
    marginTop: '120px',
    fontFamily: 'proxima-nova, sans-serif ',
    color: '#585c60',
    maxHeight: 450,
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
      borderRadius: 0,
      padding: '10% 10% 0 10%',
      boxShadow: 'none',
      borderTop: '5px solid #669fff',
    },
  },
  header: {
    marginTop: 0,
    marginBottom: '20px',
    color: '#585c60',
    [theme.breakpoints.down('xs')]: {
      marginTop: 20
    }
  },
  depadding: {
    padding: 8,
  },
  location: {
    marginRight: '-2%',
  },
  searchbtn: {
    marginTop: 17,
    marginBottom: 10,
    maxHeight: '38px',
    backgroundColor: '#669fff',
    color: '#ffffff',
    padding: '10px 50px',
    height: '100%',
    width: '100%',
    borderRadius: '7px',
    '&:hover': {
      color: 'white',
      backgroundColor: '#669fff',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: 0,
      marginTop: 30,
      width: '80vw',
      marginLeft: 'auto',
      marginRight: 'auto'
    },
  },
  searchBoxTypo: {
    padding: 0,
    display: 'block',
    fontWeight: 'bold',
    color: "#707070",
    [theme.breakpoints.down('md')]: {
    },
  },
  findBest: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: 0,
      marginTop: 20,
      color: '#454545',
    },
    [theme.breakpoints.up('xs')]: {
      marginBottom: 30,
      marginTop: 200,
      color: '#454545',
    }
  },




})

//Validating the Fields
// const validate = combineValidators({
//   subject: isRequired({ message: "This field is required" }),
//   langs: isRequired({ message: "This field is required" }),
//   city: isRequired({ message: "This field is required" }),
// });


class TutorSearchForm extends Component {
  render() {

    const {
      onSelect,
      contextRef,
      onChangeSubject,
      onChange,
      onChangeLang,
      handleSearch,
      subjectOptions,
      levelOptions,
      langOptions,
      invalid,
      submitting,
      pristine,
      suggestions,
      onSuggestionsFetchRequested,
      onSuggestionsClearRequested,
      getSuggestionValue,
      renderSuggestion,
      subject,
      value,
      classes,
      handleScriptLoad,
      id
    } = this.props;

    const inputProps = {
      placeholder: 'Subjects',
      value,
      onChange: onChange
    };


    return (
      <Card className={classes.searchbox} >
        <Script
          url="https://maps.googleapis.com/maps/api/js?key=AIzaSyC_B3o6e4mHhWjdZij2lYWPqK_Tw5O_jC8&libraries=places&language=en"
          onLoad={handleScriptLoad}
        />
        <Form context={contextRef} className={classes.formStyle}>
          <h1
            className={classes.header}
          >Find The Best Tutors</h1>
              <Typography className={classes.searchBoxTypo} >Location</Typography>
              <InputBase
                style={{height: '5px', marginTop: 15, marginBottom: 15,}}
                id={id? id: "autocomplete"}
                label="Where"
                placeholder=' '
                fullWidth
                // InputLabelProps={{
                //   shrink: true,
                  
                // }}
                
              />
              <Typography className={classes.searchBoxTypo}>Levels</Typography>
              {/* <Field
                type="text"
                component={SelectInput}
                suggestions={suggestions}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
                name="subject"
              /> */}

              <Select
              style={{margin: 0,}}
                onChange={onChangeSubject}
                name="level"
                placeholder=" "
                options={levelOptions}
              />


              <Typography className={classes.searchBoxTypo}>Languages</Typography>

              <Select
                className={classes.selectStyle}
                isMulti
                options={langOptions}
                defaultValue={{
                  value: "English",
                  label: "English",
                  nativeName: "English",
                  isFixed: true,
                  backgroundColor: "red"
                }}
                onChange={onChangeLang}
              />
              <Button className={classes.searchbtn} onClick={() => handleSearch()} type="button">
                SEARCH
              </Button>
          
        </Form>
      </Card>
    );
  }
}

export default withStyles(styles)(
  withFirestore(
    connect()(
      reduxForm({ form: "tutorSearchForm", enableReinitialize: true, })(
        TutorSearchForm
      )
    )
  )
);