import React, { Component } from "react";
import { Comment, Button } from "semantic-ui-react";
import TutorProfileChatForm from "./TutorProfileChatForm";
import { Link } from "react-router-dom";
import distanceInWords from "date-fns/distance_in_words";
import Divider from '@material-ui/core/Divider';
import StarRatings from 'react-star-ratings';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const style = (theme) => ({
  reviewBackground: {
  },
  subjectTitle: {
    fontSize: '1.6rem',
    marginLeft: 20,
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 20
    }
  },
  cardStyle: {
    marginTop: 20,
    overflow: 'visible',
    maxWidth: '95%',
    marginLeft: 'auto',
    marginRight: 'auto',
    minHeight: '50px',
    boxShadow: 'none',
    border: '1px solid #e1e1e6',
    paddingTop: 8,
    paddingBottom: 8,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: 0,
      borderRadius: 0,
      borderRight: 'white',
      borderLeft: 'white',

    }
  },

  dialBtnStyle1: {
    padding: '8px 16px',
    backgroundColor: '#fff',
    borderRadius: 5,
    color: '#FF5D5D',
    cursor: 'pointer',
    border: '1px solid #FF5D5D',
    '&:hover': {
      backgroundColor: '#FF5D5D',
      color: '#fff'
    },
  },
  cardHeaderStyle: {
    paddingBottom: 0
  },
})

class TutorProfileChat extends Component {
  state = {
    showReplyForm: false,
    selectedCommentId: null,
    rating: 0,
    reviewOpen: false,
  };

  resetRatingToZero = () => {
    this.setState({
      rating: 0,
      reviewOpen: false,
    })
  }

  closeReviewAfterSubmit = () => {
    this.setState({
      showReplyForm: false
    })
  }

  hanldeToggleReview = (rating) => {
    this.setState({
      reviewOpen: !this.state.reviewOpen,
      rating: rating
    })
  }

  changeRating = (newRating, name) => {
    //console.log('The Rating is', newRating);
    this.setState({
      rating: newRating
    });

  }

  handleOpenReplyForm = id => () => {
    this.setState({
      showReplyForm: true,
      selectedCommentId: id
    });
  };

  handleCloseReplyForm = () => {
    this.setState({
      selectedCommentId: null,
      showReplyForm: false
    });
  };


  render() {
    const {
      addTutorProfileComment,
      deleteTutorProfileComment,
      tutorProfileId,
      tutorProfileChat,
      authenticated,
      openModal,
      loading,
      userId,
      classes
    } = this.props;
    const { rating } = this.state;
    let reviewFound = false;
    let submittedRating;
    let defaultText = "";
    if (tutorProfileChat) {
      tutorProfileChat.forEach(function (review) {
        if (review.id === userId) {
          submittedRating = review.rating;
          defaultText = review.text;
          return reviewFound = true
        }
      });

    }

    return (
      <div className={classes.reviewBackground}>
        <Grid container style={{ maxWidth: '900px', marginRight: 'auto', marginLeft: 'auto' }}>
          <Grid item xs={12} md={12}>
            <Card className={classes.cardStyle}>
              <CardContent
                className={classes.cardHeaderStyle}

              >
                <Typography style={{ color: '#4ca7ab' }} className={classes.subjectTitle}>Review
                </Typography>
              </CardContent>

              <CardContent >
                <Comment.Group>
                  {tutorProfileChat &&
                    tutorProfileChat.map(comment => (
                      <Comment key={comment.id}>
                        <Comment.Avatar
                          src={comment.photoURL || "/assets/user.png"}
                        />
                        <Comment.Content>
                          <Comment.Author as={Link} to={`/profile/${comment.uid}`}>
                            {comment.displayName}
                          </Comment.Author>
                          <Comment.Metadata>
                            <div>{distanceInWords(comment.date, Date.now())} ago</div>

                          </Comment.Metadata>
                        </Comment.Content>
                        <Comment.Content>
                          <div>
                            <StarRatings
                              rating={comment.rating}
                              starDimension="25px"
                              starSpacing="1px"
                              starRatedColor="#ff5d5d"
                            />
                          </div>
                          <Comment.Text>{comment.text}</Comment.Text>
                        </Comment.Content>
                      </Comment>
                    ))}
                  <br />
                  <Divider />
                </Comment.Group>
              </CardContent >

            </Card>

            <Card className={classes.cardStyle} style={{ marginBottom: 40 }}>
              <CardContent
                className={classes.cardHeaderStyle}

              >
                {(tutorProfileChat && authenticated && reviewFound && !this.state.reviewOpen) &&
                  <Button onClick={() => this.hanldeToggleReview(submittedRating)}>Edit Review</Button>
                }
                {(tutorProfileChat && authenticated && reviewFound && this.state.reviewOpen) &&
                  <div>
                    <TutorProfileChatForm
                      changeRating={this.changeRating}
                      rating={rating}
                      authenticated={authenticated}
                      addTutorProfileComment={addTutorProfileComment}
                      userId={userId}
                      deleteTutorProfileComment={deleteTutorProfileComment}
                      tutorProfileId={tutorProfileId}
                      resetRatingToZero={this.resetRatingToZero}
                      defaultText={defaultText}
                      closeReviewAfterSubmit={this.closeReviewAfterSubmit}
                      form={"newComment"}
                      enableDelete={true}
                      hanldeToggleReview={this.hanldeToggleReview}
                    />
                    {/* <span>
                <button className={classes.dialBtnStyle1} onClick={this.hanldeToggleReview}>Cancel</button>
              </span> */}

                  </div>
                }


                {tutorProfileChat && authenticated && !reviewFound &&
                  <TutorProfileChatForm
                    changeRating={this.changeRating}
                    rating={rating}
                    authenticated={authenticated}
                    addTutorProfileComment={addTutorProfileComment}
                    tutorProfileId={tutorProfileId}
                    resetRatingToZero={this.resetRatingToZero}
                    form={"newComment"}
                    enableDelete={false}
                  />
                }

              </CardContent>
            </Card >


          </Grid>
        </Grid>

      </div >
    );
  }
}

export default withStyles(style)(TutorProfileChat);