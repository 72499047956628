import firebase from '../../../app/config/firebase';
import React, { Component } from "react";
import { Segment, Item, Icon, List, Label, Button } from "semantic-ui-react";
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import StarRatings from 'react-star-ratings';
import { connect } from "react-redux";
import _ from 'lodash'
import Avatar from '@material-ui/core/Avatar';
import { objectToArray } from "../../../app/common/util/helpers";
import Tooltip from '@material-ui/core/Tooltip';

const styles = theme => ({
  info: {
    marginBottom: 20,
    marginTop: 10,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 15,
    }
  },

  priceStyle: {
    textAlign: 'center',
    border: '5px solid #fff',
    backgroundColor: '#4ca7ab',
    color: 'white',
    marginTop: -40,
    marginLeft: 50,
    height: '60px',
    width: '60px',
    zIndex: 1,
    borderRadius: 50,
    fontSize: '1.3rem',
    lineHeight: 0.1,

  },
  haha: {
    padding: '3% 0 3% 5%',
    [theme.breakpoints.down('xs')]: {
      padding: '5% 0 0 5%',
    }
  },
  location: {
    backgroundColor: '#F4F5F7',
    padding: '1% 1% 1% 5%',
    borderTop: ' 1px solid #E1E1E6',
    borderBottom: ' 1px solid #E1E1E6',
    height: '36px',
    [theme.breakpoints.down('xs')]: {
      height: '32px',

    }
  },
  description: {
    marginTop: 10,
    marginBottom: 10,
    marginRight: 30,
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  btn: {
    padding: '1% 2% 1% 2%',
    color: '#fff',
    backgroundColor: '#4ca7ab',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#0A4065',
    },
  },
  cardStyle: {
    paddingTop: '2%',
    marginBottom: 30,
    boxShadow: 'none',
    border: '1px solid #e1e1e6',
    borderRadius: 10,
    color: '#585c60',
    fontFamily: 'proxima-nova, sans-serif ',  
    [theme.breakpoints.down('xs')]: {
      marginBottom: 20,
      display: 'block',
      width: '100%',
      marginRight: 'auto',
      marginLeft: 'auto',
      borderRadius: 0,
      border: '1px solid #e1e1e6',
      borderLeftColor: 'white',
      borderRightColor: 'white'
    }
  },
  langBtn: {
    fontSize: '0.9rem',
    display: 'inlineBlock',
    height: '20px',
    border: '1px solid #4ca7ab',
    backgroundColor: '#4ca7ab',
    color: 'white',
    fontWeight: 300,
    paddingLeft: 6,
    marginRight: 10,
    marginBottom: 15,
    borderRadius: 20,
    [theme.breakpoints.down('xs')]: {
      marginRight: 5,
    }
  },
  tinyImg: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      zIndex: 0,
      marginTop: 10
    }
  },
  smallImg: {
    display: 'block',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    }
  },
  hourlyStyle: {
    padding: '4px 0 4px 20px',
    border: '1px solid #4ca7ab',
    backgroundColor: '#4ca7ab',
    color: 'white',
    borderTopLeftRadius: '15px',
    borderBottomLeftRadius: '15px',
    display: 'block',
    marginLeft: -20,
    boxShadow: '3px 5px 10px #b1b1b1',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    }
  },
  descriptionStyle: {
    [theme.breakpoints.down('xs')]: {
      marginLeft: 10
    }
  },
  cityStyle: {
    marginLeft: 6,
    display: 'inline',
    float: 'left',
    color: '#585c60',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '240px',
      overFlow: 'hidden',
      fontSize: '1rem',
      marginLeft: 2
    }
  },
  badgeStyle: {
    float: 'left',
    display: 'block',
    borderRadius: 0,
    marginTop: 10,
    width: '26px',
    height: 'auto',
    marginRight: 15,
    [theme.breakpoints.down('xs')]: {
      width: '22px'
    }
  },
  toggleStyle: {
    color: '#585c60',
    overflow: "hidden",
    position: "relative",
    lineHeight: "1.2em",
    maxHeight: "2.4em",
    textAlign: "justify",
    marginRight: 0,
    paddingRight: 0,

    "&&:before": {
      content: '"..."',
      position: "absolute",
      right: 0,
      bottom: 0
    },

  },
  langStyle: {
    color: '#4ca7ab',
    border: '1px solid #4ca7ab',
    backgroundColor: '#fff',
    borderRadius: 6,
    padding: '2px 6px 2px 6px',
    marginTop: '5px',
    verticalAlign: 'center',

  },

  subjectStyle: {
    fontSize: '0.9rem',
    width: '100%',
    margin: 0,
    display: 'block',
  },
  locationStyle: {
    margin: 0,
    [theme.breakpoints.down('xs')]: {
      marginTop: 10
    }
  },
  videoProfileStyle:{
    display: 'inline-block',
    marginLeft: 20,
    color: '#e1e1e6',
    fontFamily: 'proxima-nova, sans-serif ',  

  },
  videoProfileStyle1:{
    display: 'inline-block',
    marginLeft: 20,
    color: '#585c60',
    fontFamily: 'proxima-nova, sans-serif ',  


  },
  playActivateIcon: {
    width: '20px',
    height: 'auto',
    display: 'inline-block',
    top: 7,
    marginRight: 10
  },
  playIconStyle: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'inline-block',
      width: '20px',
      height: 'auto',
      marginLeft: 10,
      top: 5
      }
  }

});

const mapState = (state) => ({
  searchedLevel: state.searches.level === undefined ? "" : state.searches.level.value,
  lat: state.searches.lat,
  lng: state.searches.lng,
})

function distance(lat1, lon1, lat2, lon2, unit) {
  if ((lat1 == lat2) && (lon1 == lon2)) {
    return 0;
  }
  else {
    var radlat1 = Math.PI * lat1 / 180;
    var radlat2 = Math.PI * lat2 / 180;
    var theta = lon1 - lon2;
    var radtheta = Math.PI * theta / 180;
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;
    dist = dist * 60 * 1.1515;
    //DEFAULT UNIT IS M (MILE)
    if (unit == "K") { dist = dist * 1.609344 }
    if (unit == "N") { dist = dist * 0.8684 }
    return dist;
  }
}

class TutorListItem extends Component {
  state = {
    hovered: false
  }

  render() {
    const { tutor, classes, lat, lng } = this.props;
    //storing tutot's subject list object to subjectHash
    const levelsHash = tutor.levels === undefined ? {} : tutor.levels
    let levelList = []
    let newLevelList = []
    _.forEach(levelsHash, (item) => {
      levelList.push(item)
    })

    // console.log(levelList)
    // console.log(tutor.searchedLevel)

    let filteredLevelList =
      levelList.filter(level => level.id === `${tutor.searchedLevel}`);
    _.forEach(filteredLevelList, (item) => {
      newLevelList.push(item.price);
    })

    // console.log(filteredLevelList)


    // const price = Math.max(...newLevelList);
    const price = filteredLevelList[0].price

    let languageList = []
    _.forEach(tutor.languages, (k, v) => {
      switch (v) {
        case "English":
          v = "Eng";
          break;
        case "Korean":
          v = "Kor";
          break;
        case "French":
          v = "Fr";
          break;
        case "Chinese(Mandarin)":
          v = "Mandarin";
          break;
        case "Chinese(Cantonese)":
          v = "Cantonese";
          break;
        case "Panjabi, Punjabi":
          v = "Pan";
          break;
        case "Hindi":
          v = "Hin";
          break;
        case "Japanese":
          v = "Jpn";
          break;
        case "Spanish; Castilian":
          v = "Spa";
          break;
        case "Italian":
          v = "Ita";
          break;
        case "Portuguese":
          v = "Por";
          break;
        case "German":
          v = "Ger";
          break;
        case "Russian":
          v = "Rus";
          break;
        case "Persian":
          v = "Per";
          break;
        case "Bengali":
          v = "Ben";
          break;
        case "Vietnamese":
          v = "Vie";
          break;
        case "Turkish":
          v = "Tur";
          break;
        case "Urdu":
          v = "Urd";
          break;
        case "Tamil":
          v = "Tam";
          break;
        case "Arabic":
          v = "Ara";
          break;
        case "Marathi (Marāṭhī)":
          v = "Mar";
          break;
        case "Hungarian":
          v = "Hun";
          break;
      }
      languageList.push(v)
    })

    const ratingWithLessDecimals = parseFloat(tutor.rating).toFixed(1)
    const dist = distance(tutor.location.latitude, tutor.location.longitude, lat, lng, "K")

    let text = tutor.description;


    if (tutor.description) {
      text = text.replace(/\[n1\]/g, '');
    }

    let badgeList = []
    let i = 0;
    _.forEach(tutor.badges, (k, v) => {

      if (k === true) {
        badgeList.push(v)
      } else {
        badgeList.push("null")
      }
      // console.log("k", k);
      // console.log("v", v);
      i++;
    })

    let displayedLevels = this.props.searchedLevel;

    // var subjectList2 = tutor.subjectList.filter(function (elem, index, self) {
    //   return index === self.indexOf(elem);
    // })
    // if (subjectList2.length >= 2) {
    //   for (let i = 0; i < subjectList2.length; i++) {
    //     if (i > 1) {
    //       displayedSubjects.push('');
    //     } else if (i === 1) {
    //       displayedSubjects.push(subjectList2[i], '...');
    //     } else {
    //       displayedSubjects.push(subjectList2[i], ', ');
    //     }
    //   }
    // } else {
    //   displayedSubjects = subjectList2[0];
    // }


    return (

      <Card className={classes.cardStyle}>
        <CardContent className={classes.haha} >
          <Item.Group
            as={Link}
            to={`/tutor/${tutor.tutorUid}`}>
            <Grid container>

              <Grid item xs={3} md={3} className={classes.smallImg} >
                <Item.Image
                  size="small"
                  circular
                  src={tutor.tutorPhotoURL || '/assets/user.png'} />
                <Typography
                    className={tutor.videoURL ? classes.videoProfileStyle1 : classes.videoProfileStyle}                  
                >
                  <Avatar
                    src={tutor.videoURL ? '/assets/Icons/Play_Activate_icon.png' : '/assets/Icons/Play_deActivate_icon.png'}
                    className={classes.playActivateIcon}
                  />
                  Video Profile
                </Typography>
              </Grid>
              <Grid item xs={4} md={3} className={classes.tinyImg} >
                <Item.Image
                  className={{
                    zIndex: -1, position: 'absolute',
                  }}
                  size="tiny"
                  circular
                  src={tutor.tutorPhotoURL || '/assets/user.png'} />

                <div className={classes.priceStyle} style={{ zIndex: '5', position: 'absolute' }}>
                  <p style={{
                    marginTop: 6,
                    marginBottom: 0,
                  }}>${price}</p>
                  <p style={{ marginTop: -8 }}>/hr</p>
                </div>


              </Grid>
              <Grid className={classes.descriptionStyle} item xs={7} md={7}  >
                <Item.Content style={{ color: 'black' }}>
                  <Item.Header className={classes.info} style={{ fontSize: '1.8rem', fontWeight: 600, color: '#585c60' }}>
                    {tutor.displayName || tutor.firstName}
                    <Avatar
                      src={tutor.videoURL ? '/assets/Icons/Play_Activate_icon.png' : '/assets/Icons/Play_deActivate_icon.png'}
                      className={classes.playIconStyle}
                    />
                  </Item.Header>
                  <Item.Description >
                    {tutor.rating === 0 ? "" :
                      <div style={{ marginBottom: 3 }}>
                        <StarRatings
                          rating={tutor.rating}
                          starDimension="20px"
                          starSpacing="1px"
                          starRatedColor="#fae810"
                          starRadius="50px"
                        /> ({ratingWithLessDecimals})
                      </div>
                    }
                    {/* <div className={classes.subjectStyle}>{displayedSubjects}</div> */}

                    {/* <Grid container>
                      <div className={classes.subjectStyle}>
                        {!tutor.badges &&
                          <div>
                            <Avatar
                              className={classes.badgeStyle}
                              src="/assets/badge.png" alt="badge"
                            />
                            <Avatar
                              className={classes.badgeStyle}
                              src="/assets/badge.png" alt="badge"
                            />
                            <Avatar
                              className={classes.badgeStyle}
                              src="/assets/badge.png" alt="badge"
                            />
                          </div>
                        }
                        {badgeList && badgeList.map(badge => {
                          switch (true) {
                            case badge === "basic":
                              return <Tooltip
                                title="Basic Badge"
                              >
                                <Avatar
                                  className={classes.badgeStyle}
                                  src="/assets/Verified.png"
                                  alt="Basic Badge"
                                />
                              </Tooltip>

                            case badge === "education":
                              return <Tooltip
                                title="Education Badge"
                              >
                                <Avatar
                                  className={classes.badgeStyle}
                                  src="/assets/Education.png"
                                  alt="Education Badge"
                                />
                              </Tooltip>

                            case badge === "professional":
                              return <Tooltip
                                title="Professional Badge "
                              >
                                <Avatar
                                  className={classes.badgeStyle}
                                  src="/assets/Teacher.png"
                                  alt="Professional Badge"
                                />
                              </Tooltip>
                            case badge === "null":
                              return <Avatar
                                className={classes.badgeStyle}
                                style={{ width: '16px', height: '22px', marginLeft: 2 }}
                                src="/assets/badge.png"
                                alt="badge"
                              />

                          }
                        })}
                      </div>
                    </Grid> */}
                    <div className={classes.subjectStyle}>
                      {languageList.map((lang, i) => (
                        <span key={i}><button className={classes.langStyle}>{lang}</button>&nbsp;</span>

                      ))}
                    </div>

                    <div className={classes.description} >
                      <Typography className={classes.toggleStyle} >
                        {text}
                      </Typography>
                    </div>
                    <div className={classes.locationStyle}>
                      {/* <Icon name="clock" /> Last LoggedIn: {format(tutor.date.toDate(), 'dddd Do MMMM')} at {format(tutor.date.toDate(), 'HH:mm')}| */}
                      <i
                        className="material-icons"
                        style={{
                          color: '#FF5D5D',
                          display: 'inline',
                          float: 'left',
                          fontSize: '17px',
                          marginLeft: -3
                        }}
                      >
                        place
                      </i>
                      <p className={classes.cityStyle} style={{ display: 'inline', float: 'left' }}>
                        {tutor.city}, {tutor.province} &nbsp;&nbsp; | &nbsp;&nbsp;{dist.toFixed(1)}km
                      </p>

                    </div>
                    {/* Badge Here */}




                  </Item.Description>
                  {tutor.cancelled &&
                    <Label style={{ top: '-40px' }} ribbon='right' color='red' content='this event had been cancelled' />}
                </Item.Content>
              </Grid>
              <Grid item md={2}>
                <h2 className={classes.hourlyStyle}>${price}/hr</h2>
              </Grid>
            </Grid>
          </Item.Group>
        </CardContent>

        {/* <CardContent className={classes.location} >
          <span>
            { <Icon name="clock" /> Last LoggedIn: {format(tutor.date.toDate(), 'dddd Do MMMM')} at {format(tutor.date.toDate(), 'HH:mm')}| }
            <i className="material-icons" style={{ color: '#FF5D5D', display: 'inline', float: 'left' }}>
              place
            </i>
            <span className={classes.cityStyle} style={{ marginLeft: 6, display: 'inline', float: 'left' }}>
              {tutor.city}, {tutor.province} &nbsp;&nbsp;
|              &nbsp;&nbsp;{dist.toFixed(1)}km
            </span>

          </span>
        </CardContent>
        <div className={classes.description} >

          <Typography className={classes.toggleStyle} >
            {text}

          </Typography>
        </div> */}

      </Card>
    );
  }
}

export default withStyles(styles)(connect(mapState)(TutorListItem));
