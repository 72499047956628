import React, { Component } from 'react';
import { Modal } from 'semantic-ui-react';
import { connect } from 'react-redux';
import CheckoutForm from '../payment/stripe/CheckoutForm'
import { closeModal } from "./modalActions";
import { Elements, StripeProvider } from 'react-stripe-elements';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from "@material-ui/core/styles";


const styles = theme => ({
    badge: {
        width: '100px',
        borderRadius: 0,
        textAlign: 'center'
    }
})


const actions = { closeModal };

const mapState = (state) => ({
    contentInfo: state.modals.data,
    user: state.firebase.auth,
})

class StripeModal extends Component {
    
    render() {
        const { amount, user, classes, price, contentInfo } = this.props;

        const livePublishableKey = 'pk_live_rCbMCU3bVxhxFUCvN52wWpX4004kt3RM7F';
        const testPublishableKey = 'pk_test_gsx4v9NbvshOzOWnnJlXdotm00AH5uWmMc'
        // console.log(price);

        return (
            <Modal
                size='mini'
                open={true}
                onClose={this.props.closeModal}
                style={{ marginTop: 20 }}
            >
                {/* <Modal.Header>
                    <Avatar className={classes.badge} src="/assets/Tossmath_logo.png" alt="banner"  >
                    </Avatar>
                </Modal.Header> */}

                <Modal.Content>
                    <Modal.Description>
                        <StripeProvider apiKey={livePublishableKey}>
                            <div style={{ width: '100%', backgroundColor: 'white' }} className="example">
                                <h1 style={{ color: '#585c60', }}>
                                    <span style={{ color: '#4ca7ab' }}>Toss</span>
                                    math
                                    </h1>
                                    <h2>{contentInfo.buyTitle}</h2>
                                <Elements>
                                    <CheckoutForm
                                        contentInfo={contentInfo}
                                        //amount={contentInfo.price}
                                        user={user}
                                        //description={contentInfo.buyTitle}
                                     />
                                </Elements>
                            </div>
                        </StripeProvider>
                    </Modal.Description>
                    {/* <Avatar className={classes.badge} src="/assets/powered_by_stripe 2.png" alt="banner"  >
                    </Avatar> */}
                    <center><a href='https://stripe.com/' target="_blank"><img src="/assets/powered_by_stripe 2.png" style={{textAlign:'center'}} alt='stripeBadge'/></a></center>
                </Modal.Content>
            </Modal>
        );
    }
}

export default connect(mapState, actions)(withStyles(styles)(StripeModal));


