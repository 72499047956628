import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles'

import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    aboutUsImage: {
        maxWidth: '1180px',
        marginRight: 'auto',
        marginLeft: 'auto'
    },
    firstElement: {
        fontFamily: 'proxima-nova, sans-serif ',
        width: '80%',
        paddingLeft: 40,
        paddingRight: 40,
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    girdFirst: {
        paddingLeft: 40,
        letterSpacing: '2px',
        fontSize: '2.3rem',
        color: '#585c60',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'left',
            width: '80%',
            marginTop: 40,
            marginRight: 'auto',
            marginLeft: 'auto',
            paddingLeft: 0
        }
    },
    headerStyle: {
        fontSize: '3rem',
        marginBottom: 0,
        color: '#585c60',
        fontWeight: 600,
        paddingTop: 150,
        marginBottom: 70,
        textAlign: 'center',
        fontFamily: 'proxima-nova, sans-serif ',
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.7rem',
            paddingTop: 60,
            marginBottom: 30

        }
    },
    fontStyle: {
        fontFamily: 'proxima-nova, sans-serif ',
        letterSpacing: '1px',
        marginBottom: 0,
        fontSize: '1.3rem',
        [theme.breakpoints.down('xs')]: {
        fontSize: '1.1rem',
    }
    },
    iconStyle1: {
        borderRadius: 0,
        width: '70px',
        height: 'auto',
        marginRight: 'auto',
        marginLeft: 'auto',
        marginBottom: 40,
    },
    iconStyle2: {
        borderRadius: 0,
        width: '90px',
        height: 'auto',
        marginRight: 'auto',
        marginLeft: 'auto',
        marginTop: 25,
        marginBottom: 40,
        [theme.breakpoints.down('xs')]: {
            marginTop: 50
        }
    },
    iconStyle3: {
        borderRadius: 0,
        width: '85px',
        height: 'auto',
        marginRight: 'auto',
        marginLeft: 'auto',
        marginTop: 15,
        marginBottom: 35,
        [theme.breakpoints.down('xs')]: {
            marginTop: 50
        }
    },
    titleStyle: {
        fontFamily: 'proxima-nova, sans-serif ',
        letterSpacing: '1px',
        fontSize: '1.8rem',
        fontWeight: 600,
        marginTop: 20,
        marginBottom: 20,
        color: '#585c60',
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.3rem'
        }
    },





});


export const ThreeStepsExplanation = ({ classes }) => (
    <div >
        <div className={classes.aboutUsImage}>
            <Typography
                className={classes.headerStyle}
            >
                3 Steps to Become a Tutor
            </Typography>
            <Grid container className={classes.girdFirst}>
                <Grid
                    item
                    md={4}
                    className={classes.firstElement}
                >
                    <div >
                        <Avatar
                            src='/assets/Icons/Icon_1.png'
                            className={classes.iconStyle1}
                        />

                    </div>
                    <Typography
                        className={classes.titleStyle}
                    >
                        Complete Tutor Profile
                    </Typography>
                    <Typography
                        className={classes.fontStyle}
                    >
                        Tutors have to complete the profile displaying their credentials as a tutor.
                </Typography>
                </Grid>
                <Grid
                    item
                    md={4}
                    className={classes.firstElement}
                >
                    <div  >
                        <Avatar
                            src='/assets/Icons/Icon_2.png'
                            className={classes.iconStyle2}
                        />

                    </div>
                    <Typography
                        className={classes.titleStyle}
                    >
                        Upload Video Profile
                    </Typography>
                    <Typography
                        className={classes.fontStyle}
                    >
                        Video is a powerful tool to introduce oneself. Tutors who upload the video will get unlimited access to our prep materials
                 </Typography>
                </Grid>
                <Grid
                    item
                    md={4}
                    className={classes.firstElement}
                >
                    <div >
                        <Avatar
                            src='/assets/Icons/Icon_3.png'
                            className={classes.iconStyle3}
                        />

                    </div>
                    <Typography
                        className={classes.titleStyle}
                    >
                        Pass Evaluation Test
                    </Typography>
                    <Typography
                        className={classes.fontStyle}
                    >
                        Not everyone can be a tutor at Tossmath. Only tutors who pass our evaluation test can tutor students.
                </Typography>
                </Grid>
            </Grid>
        </div>


    </div>
)


export default withStyles(styles)(ThreeStepsExplanation)
