import React from 'react';
import { connect } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom';
import SettingsNav from './SettingsNav';
import BasicPage from './BasicPage';
import AboutPage from './AboutPage';
import PhotosPage from './PhotosPage';
import AccountPage from './AccountPage';
import ChatsList from './Chat/ChatsList';
import PrivacySettingsPage from './PrivacySettingsPage'
import { updatePassword } from '../../auth/authActions'
import { updateProfile } from '../userAction'
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles'
import MyCourses from './MyCourses';
import SettingMainPage from './SettingMainPage';


const styles = (theme) => ({

  settingStyle: {
    height: '100%',
    maxWidth: '900px',
    marginRight: 'auto',
    marginLeft: 'auto'
  },
  settingNav: {
    display: '',
    [theme.breakpoints.down("xs")]: {
      display: '',
    }
  },

  haha:{
    marginTop: 100,
    display: 'flex',
    width: '100%',
    marginRight: 'auto',
    marginLeft: 'auto',
    [theme.breakpoints.down("xs")]: {
      display: 'none',
    }
  }
})

const actions = {
  updatePassword,
  updateProfile
}

const mapState = (state) => ({
  providerId: state.firebase.auth.providerData[0].providerId,
  user: state.firebase.profile,
  currentUserId: state.firebase.auth.uid
})


const SettingsDashboard = ({ updatePassword, providerId, user, updateProfile, classes, currentUserId }) => {
  return (
    <Grid container className={classes.settingStyle}>
      <Grid item xs={12} md={8} style={{ height: '100%' }}>
        <Switch>
          <Redirect exact from='/settings' to='/settings/main' />
          <Route path='/settings/main' render={() =>  <SettingMainPage currentUser={user} currentUserId={currentUserId}/>} />
          <Route path='/settings/basic' render={() => <BasicPage updateProfile={updateProfile} initialValues={user} />} />
          {/* <Route path='/settings/about' render={() => <AboutPage updateProfile={updateProfile} initialValues={user} />} /> */}
          <Route path='/settings/photos' component={PhotosPage} />
          <Route path='/settings/account' render={() => <AccountPage updatePassword={updatePassword} providerId={providerId} initialValues={user} />} />
          <Route path='/settings/chats' render={() => <ChatsList currentUser={user} currentUserId={currentUserId} />} />
          <Route path='/settings/MyCourses' render={() => <MyCourses currentUser={user} currentUserId={currentUserId} />} />
          {/* <Route path='/settings/privacysettings' render={() => <PrivacySettingsPage currentUser={user} currentUserId={currentUserId}/>} /> */}
        </Switch>
      </Grid>
      <Grid item xs={12} md={3} className={classes.settingNav}>
        <div >
          <SettingsNav />
        </div>
      </Grid>
   
    </Grid>
  )
}

export default withStyles(styles)(connect(mapState, actions)(SettingsDashboard));