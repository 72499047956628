import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';


const styles = theme => ({
    root: {
        backgroundImage: 'url("/assets/tossmassPreviewBG.png")',
        height: '400px',
        width: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        textAlign: 'center',
        fontFamily: 'proxima-nova, sans-serif ',
        [theme.breakpoints.down('xs')]: {
        }
    },
    paymentCardImage: {
        width: '100px',
        height: 'auto',
        marginRight: 'auto',
        marginLeft: 'auto',
        borderRadius: 0,
        [theme.breakpoints.down('xs')]: {
            width: '60px'
        }
    },
    paymentHeader: {
        marginTop: 20,
        marginBottom: 20,
        fontSize: '2rem',
        color: '#585c60',
        [theme.breakpoints.down('xs')]: {
            width: '90%',
            fontSize: '1.3rem'
        }


    },
    paymentDesc: {
        maxWidth: '400px',
        marginRight: 'auto',
        marginLeft: 'auto',
        marginBottom: 20,
        [theme.breakpoints.down('xs')]: {
            width: '90%',
            fontSize: '1rem'
        }
    },
    btnStyle: {
        backgroundColor: '#4ca7ab',
        borderRadius: 5,
        color: '#fff',
        fontWeight: 600,
        padding: '7px 42px',
        fontSize: '1.1rem',
        letterSpacing: '1.7px',
        border: 'none',
        cursor: 'pointer',
        '&:hover': {
            color: '#fff',
            backgroundColor: '#4ca7ab',
          },
    }

});

const PaymentFailure = ({
    classes,
    errorMessage,
    handleStripePayment
}) => {

    return (
        <div
            className={classes.root}
        >
            <Avatar
                className={classes.paymentCardImage}
                src="/assets/Error_icon.png"
            >
            </Avatar>
            <Typography
                className={classes.paymentHeader}
            >
                Payment Declined
            </Typography>
            <Typography
                className={classes.paymentDesc}
            >
                <p style={{fontSize:'20px'}}>{errorMessage} <br/>
                Please Try Again</p>
            </Typography>
            <Button
                className={classes.btnStyle}
                onClick={handleStripePayment}
        >
                Try Again

            </Button>




        </div >
    )
}


export default withStyles(styles)(PaymentFailure)
