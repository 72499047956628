import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles'

import home from './assets/About_us_banner.png';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
	aboutUsImage: {
		background: `url(${home})`,
		height: '100vh',
		width: '100%',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		marginTop: 40,
		[theme.breakpoints.down('xs')]: {
			background: 'none',
			backgroundColor: '#4ca7ab',
			height: '80%'
		}

	},
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		fontFamily: 'proxima-nova, sans-serif ',


	},
	aboutUsHeader: {
		marginTop: '16%',
		padding: '30px 36px 30px 36px',
		opacity: 0.8,
		border: '1px solid #4ca7ab',
		backgroundColor: '#4ca7ab',
		maxWidth: '75%',
		marginRight: 'auto',
		marginLeft: 'auto',
		color: 'white',
		borderRadius: 10,
		[theme.breakpoints.down('xs')]: {
			boxShadow: 'none',
			maxWidth: '90%',
			padding: '10px',

		}
	},
	tossmathHeader: {
		fontSize: '4.5rem',
		letterSpacing: '1.3px',
		fontWeight: 600,
		marginBottom: '10px',
		marginTop: 0,
		color: '#585c60'
	},
	tossmathSecond: {
		fontSize: '1.8rem',
		letterSpacing: '0.5px',
		fontWeight: 400,
		marginBottom: '10px'
	},
	tossmathDesc: {
		margin: 0,
		fontSize: '1.1rem',
		letterSpacing: '0.5px',

	},
	secondElement: {
		maxWidth: '1200px',
		marginRight: 'auto',
		marginLeft: 'auto',
		marginTop: 80,
		marginBottom: 80
	},
	imgStyle: {
		width: '85%',
		marginRight: 'auto',
		marginLeft: 'auto',
	},
	imgStyle1: {
		width: '90%',
		marginRight: 'auto',
		marginLeft: 'auto',
		marginTop: 40
	},
	alignImg: {
		textAlign: 'center',
		display: 'flex',
		[theme.breakpoints.down('xs')]: {
			display: 'none'
		}
	},
	alignImg1: {
		display: 'none',
		[theme.breakpoints.down('xs')]: {
			display: 'flex'
		}

	},
	aboutUsStyle: {
		width: '90%',
		marginRight: 'auto',
		marginLeft: 'auto',
		marginTop: '-20px',
		[theme.breakpoints.down('xs')]: {
		}
	},
	aboutUSDesc: {
		margin: 0,
		fontSize: '1.1rem',
		letterSpacing: '0.5px',
		color: '#585c60'
	},
	firstDot: {
		width: '20px',
		display: 'block',
		marginBottom: 20
	},
	secondDot: {
		width: '10px',
		display: 'block',
		marginBottom: 13,
		marginLeft: '4px',

	},
	thirdDot: {
		marginLeft: '6.5px',
		width: '5px',
		display: 'block',
		marginBottom: 13
	}



});


export const AboutUs = ({ classes }) => (
	<div className={classes.root}>
		<div className={classes.aboutUsImage}>
			<Grid container className={classes.secondElement}>
				<Grid item md={6}>
					<Card className={classes.aboutUsHeader}>
						<p className={classes.tossmathHeader} style={{color: '#fff'}}>Tossmath</p>
						<p className={classes.tossmathSecond}>WHAT IS TOSSMATH?</p>
						<p className={classes.tossmathDesc}>Tossmath is a Toronto-based Math Tutoring platform. Through our strict selection process, we are trying our best to select qualifying tutors and connect them with students seeking help. Tossmath also provides tutors with quality prep materials to improve their teaching. Find the best math tutors in GTA at Tossmath!</p>
					</Card>
				</Grid>
			</Grid>
		</div>

		<div >
			<Grid container className={classes.secondElement}>
				<Grid item md={6} className={classes.alignImg}>
					<img
						className={classes.imgStyle}
						src='/assets/About_us_pic.png'
					/>

				</Grid>
				<Grid item md={5}
					className={classes.aboutUsStyle}
				>
					<p className={classes.tossmathHeader}>About <span style={{ color: '#4ca7ab' }}>Us</span></p>
					<img
						className={classes.thirdDot}
						src='/assets/Dot.png'
					/>
					<img
						className={classes.secondDot}
						src='/assets/Dot.png'
					/>

					<img
						className={classes.firstDot}
						src='/assets/Dot.png'
					/>
					<p className={classes.aboutUSDesc}>
						We have a mission to make the world where everyone can teach and learn from each other.
						That is why Tossmath is providing the prep materials to our tutors for free. With the right tool, everyone can be a great teacher.
						Are you interested in teaching students? Do you need a help with math?
						We are here to help! Please contact us at support@tossmath.ca if you have any questions or suggestions.
					
					</p>

				</Grid>
				<Grid item xs={12} className={classes.alignImg1}>
					<img
						className={classes.imgStyle1}
						src='/assets/About_us_pic.png'
					/>

				</Grid>
			</Grid>
		</div>
	</div>
)

AboutUs.propTypes = {
	classes: PropTypes.object // from enhancer (withStyles)
}

export default withStyles(styles)(AboutUs)
