import React, { Component } from "react";
import {
  Segment,
  Form,
  Button,

  Dropdown
} from "semantic-ui-react";
import { reduxForm, Field } from "redux-form";
import TextInput from "../../../app/common/form/TextInput";
import TextField from '@material-ui/core/TextField'
import TextArea from "../../../app/common/form/TextArea";
import SelectInput from "../../../app/common/form/SelectInput";
import MultipleSelectInput from "../../../app/common/form/MultipleSelectInput";
import DateInput from "../../../app/common/form/DateInput";
import PlaceInput from "../../../app/common/form/PlaceInput";
import LanguageSelectInput from "../../../app/common/form/LanguageSelectInput";


import { categories, levels, subjects } from "../../../app/common/categories";
import Select from "react-select";

import InputBase from "@material-ui/core/InputBase";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import { withStyles } from '@material-ui/core/styles';
import Script from 'react-load-script';

const styles = theme => ({
  cardStyle: {
    padding: '3%',
    boxShadow: 'none',
    [theme.breakpoints.down('xs')]: {
      paddingRight: 30,
      paddingLeft: 30,
      borderRadius: 0

    }
  },
  box: {
    padding: '3%',
    marginTop: '120px',
    marginBottom: '20%',
    marginRight: 'auto',
    marginLeft: 'auto',
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      marginTop: '90px',

    }
  },
  basicinfo: {
    color: '#585c60',
    margin: '10% 0 5% 0',
  },

  continuebtn: {
    fontWeight: 600,
    color: 'white',
    backgroundColor: '#4ca7ab',
    padding: '10px 18px 10px 18px',
    borderRadius: '5px',
    border: '1px solid #4ca7ab',
    cursor: 'pointer',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#0A4065',

    }
  },
  backbtn: {
    marginRight: 10,
    fontWeight: 600,
    color: '#f68484',
    backgroundColor: '#fff',
    border: '1px solid #ff7474',
    padding: '10px 18px 10px 18px',
    borderRadius: '5px',
    cursor: 'pointer',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#ff7474',
    },

  }

})

class TutorBasicDetails2 extends Component {
  state = {
    scriptLoaded: false
  }
  continue = e => {
    e.preventDefault();
    this.props.nextStep();
  };
  handleScriptLoaded = () => this.setState({ scriptLoaded: true });

  getSubjectsByCategory = categoryId => {
    let result = [];
    subjects.map(subject => {
      if (subject.parentId === categoryId) {
        result.push(subject);
      }
    });
    return result;
  };


  render() {
    const {
      disabled,
      tutorProfile,
      handleChange,
      onSelect,
      classes,
      handleSelectLanguages,
      langOptions,
      onClick,
      onFormCancel
    } = this.props;
    const { scriptLoaded } = this.state;
    return (
      <React.Fragment>
        <Script
          url="https://maps.googleapis.com/maps/api/js?key=AIzaSyC_B3o6e4mHhWjdZij2lYWPqK_Tw5O_jC8&libraries=places&language=en"
          onLoad={this.handleScriptLoaded}
        />

        <Grid container>
          <Grid item xs={12} md={6} className={classes.box}>
            <Card className={classes.cardStyle}>


              <Form >
                <h1 className={classes.basicinfo}>Edit Basic Info</h1>
                <Field
                  name="firstName"
                  type="text"
                  component={TextInput}
                  placeholder="First Name"
                />
                <Field
                  name="lastName"
                  type="text"
                  component={TextInput}
                  placeholder="Last Name"
                />
                <Field
                  name="phone"
                  type="text"
                  component={TextInput}
                  placeholder="phone number"
                />
                <Field
                  name="email"
                  type="text"
                  component={TextInput}
                  placeholder="email"
                />
                <Field
                  onSelect={onSelect}
                  scriptLoaded={scriptLoaded}
                  name="city"
                  type="text"
                  component={PlaceInput}
                // onSubmit={(e) => e.preventDefault()}
                />
                <div style={{float: 'right'}}>
                  <button className={classes.backbtn} label="Cancel" onClick={onFormCancel}>CANCEL</button>

                  <button className={classes.continuebtn}
                    disabled={disabled} label="Submit" onClick={onClick}
                  >SUBMIT</button>

                </div>


              </Form>


            </Card>
          </Grid>
        </Grid>

      </React.Fragment >
    );
  }
}

export default withStyles(styles)(TutorBasicDetails2);

